import axios from 'axios';
import { developerActions } from '../../actions/DeveloperActions/creators';
import config from '../../config';
import swal from "sweetalert";

const baseUrl = `${config.apiUrl}`;

const developer = {
    //To create a developer's account
    create_dev_account: (payload) => {
        return async dispatch => {
            dispatch(developerActions.createDeveloperAccountRequest());

            await axios.post(`${baseUrl}/third-party/create_business_account`, payload)
                .then(res => {
                    if (res.data.success === true) {
                        dispatch(developerActions.createDeveloperAccountSuccess(res.data.data));
                        swal({
                            title: "Yaay!",
                            text: 'Developer account created successfully',
                            icon: "success",
                            button: "Nice!",
                        });
                    }
                })
                .catch(error => {
                    dispatch(developerActions.createDeveloperAccountFailure());
                    swal({
                        title: "Oops",
                        text: error.response.data.response_description,
                        icon: "error",
                        button: "Okay!",
                    });
                })
        }
    },

    get_dev_account: (id) => {
        return async dispatch => {
            dispatch(developerActions.getDeveloperAccountRequest());

            await axios.get(`${baseUrl}/third-party/check_developer_account/${id}`,)
                .then(res => {

                    dispatch(developerActions.getDeveloperAccountSuccess(res.data));
                })
                .catch(error => {

                    dispatch(developerActions.getDeveloperAccountFailure(error.response.data));
                })
        }
    },

    get_dev_keys: (id, email) => {
        return async dispatch => {
            dispatch(developerActions.getDeveloperKeyRequest());

            await axios.get(`${baseUrl}/third-party/get_business_keys?id=${id}&email=${email}`,)
                .then(res => {
                    dispatch(developerActions.getDeveloperKeySuccess(res.data.data));
                })
                .catch(error => {
                    dispatch(developerActions.getDeveloperKeyFailure());
                })
        }
    },

    upload_cac_document: (email, files) => {
        const formData = new FormData();
        formData.append('cac_doc', files);

        return async dispatch => {
            dispatch(developerActions.uploadDocumentRequest());

            await axios.patch(`${baseUrl}/third-party/upload_business_docs/?businessSupportEmail=${email}`, formData)
                .then(res => {
                    dispatch(developerActions.uploadDocumentSuccess(res.data.data));
                    swal({
                        title: "Yaay!",
                        text: 'Document uploaded successfully',
                        icon: "success",
                        button: "Nice!",
                    });
                })
                .catch(error => {
                    dispatch(developerActions.uploadDocumentFailure());
                    swal({
                        title: "Oh oh!",
                        text: error.response.data.response_description,
                        icon: "error",
                        button: "Okay!",
                    });
                })
        }
    },

    upload_address_document: (email, files) => {
        const formData = new FormData();
        formData.append('tax_documents', files);

        return async dispatch => {
            dispatch(developerActions.uploadDocumentRequest());

            await axios.patch(`${baseUrl}/third-party/upload_business_docs/?businessSupportEmail=${email}`, formData)
                .then(res => {
                    dispatch(developerActions.uploadDocumentSuccess(res.data.data));
                    swal({
                        title: "Yaay!",
                        text: 'Document uploaded successfully',
                        icon: "success",
                        button: "Nice!",
                    });
                })
                .catch(error => {
                    dispatch(developerActions.uploadDocumentFailure());
                    swal({
                        title: "Oh oh!",
                        text: error.response.data.response_description,
                        icon: "error",
                        button: "Okay!",
                    });
                })
        }
    },

    upload_utility_bill: (email, files) => {
        const formData = new FormData();
        formData.append('utility_bill_doc', files);

        return async dispatch => {
            dispatch(developerActions.uploadDocumentRequest());

            await axios.patch(`${baseUrl}/third-party/upload_business_docs/?businessSupportEmail=${email}`, formData)
                .then(res => {
                    dispatch(developerActions.uploadDocumentSuccess(res.data.data));
                    swal({
                        title: "Yaay!",
                        text: 'Document uploaded successfully',
                        icon: "success",
                        button: "Nice!",
                    });
                })
                .catch(error => {
                    dispatch(developerActions.uploadDocumentFailure());
                    swal({
                        title: "Oh oh!",
                        text: error.response.data.response_description,
                        icon: "error",
                        button: "Okay!",
                    });
                })
        }
    },

    get_document: (m_id, email) => {
        return async dispatch => {
            dispatch(developerActions.getDocumentRequest());

            await axios.get(`${baseUrl}/third-party/get_business_docs/${m_id}/?businessSupportEmail=${email}`)
                .then(res => {
                    dispatch(developerActions.getDocumentSuccess(res.data.data));
                })
                .catch(error => {
                    dispatch(developerActions.getDocumentFailure());
                })
        }
    },
};

export default developer;