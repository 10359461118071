import { userConstants } from "../actions/types";

const initialState = {
  isLoading: false,
  data: [],
};

function savingsHistory(state = initialState, action) {
  switch (action.type) {
    case userConstants.SAVINGS_HISTORY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case userConstants.SAVINGS_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    default:
      return state;
  }
}

export default savingsHistory;
