import axios from "axios";
import { userActions } from "../actions/creators";
import config from "../config";

const baseUrl = `${config.apiUrl}`;

//To  get a user's loyalty
const loyalty = {
  get_loyalty_points: (id) => {
    return async (dispatch) => {
      dispatch(userActions.loyaltyRequest());
      await axios
        .get(`${baseUrl}/incentives/earned/points/${id}`)
        .then((res) => {
          dispatch(userActions.loyaltySuccess(res.data.data));
        });
    };
  },

  get_charge_back: (id) => {
    return async (dispatch) => {
      dispatch(userActions.chargebackRequest());
      await axios
        .get(`${baseUrl}/incentives/chargebacks/user/${id}`)
        .then((res) => {
          dispatch(userActions.chargebackSuccess(res.data.data));
        });
    };
  },

  get_chargeback_value: (id, from, to) => {
    return async (dispatch) => {
      dispatch(userActions.chargebackValueRequest());
      await axios
        .get(
          `${baseUrl}/transaction//user/chargeback/sum/${id}?startDate=${from}&endDate=${to}`
        )
        .then((res) => {
          dispatch(
            userActions.chargebackValueSuccess(res?.data?.transactionsArr[0])
          );
        });
    };
  },

  get_chargeback_config: () => {
    return async (dispatch) => {
      dispatch(userActions.chargebackConfigRequest());
      await axios
        .get(`${baseUrl}/incentives/chargebacks/config`)
        .then((res) => {
          dispatch(userActions.chargebackConfigSuccess(res.data.data));
        });
    };
  },
};

export default loyalty;
