import React, { useState } from "react";
import {
  Badge,
  Grid,
  Paper,
  Avatar,
  Button,
  Input,
  Select,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { connect } from "react-redux";
import { InputGrid } from "../Appearance";
import settings from "../../services/settingsService";
import { Link } from "react-router-dom";
import BackDrop from "../../components/BackDrop";
import { DropzoneDialog } from "material-ui-dropzone";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 30,
    height: 30,
  },
}))(Avatar);

function EditProfileCard(props) {
  const classes = useStyles();
  const [submitted, setSubmitted] = useState(false);
  const [state, setState] = useState({
    open: false,
    files: [],
  });

  const photo = props.user.profile_photo;
  //const url = 'http://api.test.getripay.com';  //test
  const url = "https://api.getripay.com"; //live
  const completeUrl = url + photo;
  const token = props.user.token;

  const [update, setUpdate] = useState({
    first_name: props.user.first_name,
    last_name: props.user.last_name,
    others_name: props.user.others_name ? props.user.others_name : "",
    gender: props.user.gender ? props.user.gender : "",
    marital_status: props.user.marital_status ? props.user.marital_status : "",
    address: props.user.address ? props.user.address : "",
    date_of_birth: props.user.date_of_birth ? props.user.date_of_birth : "",
  });

  const {
    first_name,
    last_name,
    others_name,
    gender,
    marital_status,
    address,
    date_of_birth,
  } = update;

  function handleChange(e) {
    const { name, value } = e.target;
    setUpdate((users) => ({ ...users, [name]: value }));
  }

  function handleClose() {
    setState({
      open: false,
    });
  }

  function handleSave(files) {
    const fil = files ? files[0] : "";
    setState({
      open: false,
    });
    console.log(fil);
    if (fil) {
      props.edit(fil, token);
    }
  }

  function handleOpen() {
    setState({ open: true });
  }

  function handleSubmit(e) {
    e.preventDefault();

    setSubmitted(true);
    if (
      first_name &&
      last_name &&
      others_name &&
      gender &&
      marital_status &&
      address
    ) {
      props.update(update, token);
    }
  }

  return (
    <div>
      {props.isLoading ? (
        <div>
          <BackDrop />
        </div>
      ) : (
        ""
      )}
      <Grid item xs={12} sm={12} md={7} lg={7}>
        <p className="edit-settings"> Edit Profile</p>
        <Paper className="settingsBox2">
          <div className="settings-input-div">
            <form
              className={classes.form}
              name="form"
              encType="multipart/form-data"
            >
              <DropzoneDialog
                open={state.open}
                onSave={handleSave}
                acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                showPreviews={true}
                uploadMultiple={false}
                maxFileSize={5000000}
                onClose={handleClose}
                filesLimit={1}
                showFileNames={true}
                showFileNamesInPreview={true}
              />
              <label htmlFor="contained-button-file">
                {/* profile image */}
                <Badge
                  className={classes.ti}
                  overlap="circle"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  badgeContent={
                    <SmallAvatar alt="avatar" src="/images/upload-icon.svg" />
                  }
                >
                  <Avatar
                    onClick={handleOpen}
                    alt="profile"
                    src={completeUrl}
                    style={{
                      cursor: "pointer",
                      width: "5em",
                      height: "5em",
                      marginBottom: "1em",
                    }}
                  />
                </Badge>
              </label>
            </form>
            <form className={classes.form} name="form" onSubmit={handleSubmit}>
              <FormControl className={classes.formControl}>
                <InputGrid>
                  <InputLabel htmlFor="first_name">First Name</InputLabel>
                  <Input
                    native
                    disabled
                    name="first_name"
                    value={first_name}
                    onChange={handleChange}
                    inputProps={{
                      id: "first_name",
                    }}
                    style={{ width: "100%" }}
                  />
                </InputGrid>
              </FormControl>

              <FormControl className={classes.formControl}>
                <InputGrid>
                  <InputLabel htmlFor="last_name">Last Name</InputLabel>
                  <Input
                    native
                    disabled
                    name="last_name"
                    value={last_name}
                    onChange={handleChange}
                    inputProps={{
                      id: "last_name",
                    }}
                    style={{ width: "100%" }}
                  />
                </InputGrid>
              </FormControl>

              <FormControl className={classes.formControl}>
                <InputGrid>
                  <InputLabel htmlFor="others_name">Middle Name</InputLabel>
                  <Input
                    native
                    name="others_name"
                    value={others_name}
                    onChange={handleChange}
                    inputProps={{
                      id: "others_name",
                    }}
                    style={{ width: "100%" }}
                  />
                  {submitted && !others_name && (
                    <div className="invalid-feedback">
                      Middle name is required
                    </div>
                  )}
                </InputGrid>
              </FormControl>

              <FormControl className={classes.formControl}>
                <InputGrid>
                  <InputLabel htmlFor="gender">Gender</InputLabel>
                  <Select
                    inputProps={{
                      id: "gender",
                    }}
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    style={{ width: "100%" }}
                    value={gender}
                    name="gender"
                    onChange={handleChange}
                    displayEmpty
                  >
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                  </Select>
                </InputGrid>
              </FormControl>

              <FormControl className={classes.formControl}>
                <InputGrid>
                  <InputLabel htmlFor="email">Email Address</InputLabel>
                  <Input
                    native
                    name="email"
                    value={props.user.email}
                    onChange={handleChange}
                    disabled
                    inputProps={{
                      id: "email",
                    }}
                    style={{ width: "100%" }}
                  />
                </InputGrid>
              </FormControl>

              <FormControl className={classes.formControl}>
                <InputGrid>
                  <InputLabel htmlFor="phone_number">Phone Number</InputLabel>
                  <Input
                    native
                    name="phone_number"
                    value={props.user.phone_number}
                    onChange={handleChange}
                    disabled
                    inputProps={{
                      id: "phone_number",
                    }}
                    style={{ width: "100%" }}
                  />
                </InputGrid>
              </FormControl>

              <FormControl className={classes.formControl}>
                <InputGrid>
                  <InputLabel htmlFor="marital_status">
                    Marital Status
                  </InputLabel>
                  <Select
                    inputProps={{
                      id: "marital_status",
                    }}
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    style={{ width: "100%" }}
                    value={marital_status}
                    name="marital_status"
                    onChange={handleChange}
                    displayEmpty
                  >
                    <MenuItem value="single">Single</MenuItem>
                    <MenuItem value="married">Married</MenuItem>
                    <MenuItem value="divorced">Divorced</MenuItem>
                    <MenuItem value="separated">Separated</MenuItem>
                    <MenuItem value="registered">Registered</MenuItem>
                    <MenuItem value="partnership">Partnership</MenuItem>
                  </Select>
                  {submitted && !marital_status && (
                    <div className="invalid-feedback">
                      Marital Status is required
                    </div>
                  )}
                </InputGrid>
              </FormControl>

              <FormControl className={classes.formControl}>
                <InputGrid>
                  <InputLabel htmlFor="address">Address</InputLabel>
                  <Input
                    native
                    name="address"
                    value={address}
                    onChange={handleChange}
                    inputProps={{
                      id: "address",
                    }}
                    style={{ width: "100%" }}
                  />
                  {submitted && !address && (
                    <div className="invalid-feedback">Address is required</div>
                  )}
                </InputGrid>
              </FormControl>

              <FormControl className={classes.formControl}>
                <InputGrid>
                  <label>Date of Birth</label>
                  {/* <InputLabel htmlFor='date_of_birth'>Date of Birth</InputLabel> */}

                  {props.user.date_of_birth ? (
                    <p>
                      {new Date(props.user.date_of_birth).toLocaleDateString()}
                    </p>
                  ) : (
                    <div>
                      <Input
                        native
                        name="date_of_birth"
                        type="date"
                        value={date_of_birth}
                        onChange={handleChange}
                        inputProps={{
                          id: "date_of_birth",
                        }}
                        style={{ width: "100%" }}
                      />
                      {submitted && !date_of_birth && (
                        <div className="invalid-feedback">
                          Date of birth is required
                        </div>
                      )}
                    </div>
                  )}
                </InputGrid>
              </FormControl>

              <div className="mag" style={{ margin: "10px 0" }}>
                <Button
                  disabled={props.isLoading}
                  variant="contained"
                  color="primary"
                  className="settings-button"
                  onClick={handleSubmit}
                >
                  {props.isLoading && <CircularProgress className="circle" />}
                  Update Profile
                </Button>
              </div>
            </form>

            <hr style={{ margin: "40px" }} />
            {props.user.bvn_verify === false ? (
              <Button
                className="loan-button"
                color="primary"
                component={Link}
                to="/validate-bvn"
              >
                Verify BVN
              </Button>
            ) : props.user.bvn_verify === true ? (
              <Button
                style={{ color: "white" }}
                className="loan-button"
                color="primary"
                component={Link}
                to="/upgrade-account"
              >
                Upload Verification Document
              </Button>
            ) : (
              ""
            )}
          </div>
        </Paper>

        {/* <hr
					style={{
						color: 'blue',
						marginLeft: '13px',
						marginTop: '2em',
						marginBottom: '2em',
						marginRight: '16px',
						width: '89%',
					}}
				/>
				<div>
					<p className='security-header'>Add Profile Picture</p>
					<Paper className='settingsBox2'>
						<div className='settings-input-div'>
							<EditProfile />
						</div>
					</Paper>
				</div> */}
      </Grid>
      {/* <Grid item xs={12} sm={12} md={5} lg={5}>
				<Paper className='settingsBox3'>
					<div className='settingsBox3-inner'>
						<p className='card-text-sm'>
							<br />
							lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
						</p>
					</div>
				</Paper>
			</Grid> */}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    update: (update, token) => dispatch(settings.update_user(update, token)),
    edit: (profile_photo, token) =>
      dispatch(settings.edit_profile(profile_photo, token)),
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.userDetails.data,
    isLoading: state.response.isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileCard);
