import axios from 'axios';
import { userActions } from "../actions/creators";
import config from '../config';
import { toast, Flip } from 'react-toastify';
import { history } from '../store/history';
import headers from '../store/authHeader';

const baseUrl = `${config.apiUrl}`;

const security = {
    //Get List of security questions
    get_question: () => {
        return async dispatch => {
            dispatch(userActions.uiFetching())

            await axios.get(`${baseUrl}/user/security_questions`)
                .then((response) => {
                    const securityObj = response.data.data
                    let securityQuestions = []
                    securityObj.forEach(function (arrayItem) {
                        securityQuestions.push(arrayItem)
                    });
                    dispatch(userActions.uiFetched(securityQuestions))
                });
        }
    },

    //Submit security question
    submit_question: (password, items) => {
        return async dispatch => {
            dispatch(userActions.submitSecurityQuestionRequest())
            const user_id = localStorage.getItem('user_id');

            await axios.post(`${baseUrl}/user/setsecurity/${user_id}`, { password, items })
                .then((res) => {
                    dispatch(userActions.submitSecurityQuestionSuccess());
                    toast.success("Security questions set successfully", {
                        position: toast.POSITION.TOP_RIGHT,
                        transition: Flip,
                        autoClose: false,
                    });
                    history.push('/');
                }).catch(err => {

                    dispatch(userActions.submitSecurityQuestionFailure());
                })
        }
    },

    // Set transaction pin
    setTransactionPin: (transaction_pin, password, token) => {

        const user_id = localStorage.getItem('user_id')

        return async dispatch => {

            dispatch(userActions.uiRequesting());
            await axios.post(`${baseUrl}/user/users/set-transaction-pin/${user_id}`, { transaction_pin, password }, {
                "headers": {
                    "Content-Type": 'application/json',
                    "Authorization": `Bearer ${token}`
                }
            }).then(res => {
                dispatch(userActions.uiResponded());
                toast.success("Transaction Pin Set Succesfully", {
                    position: toast.POSITION.TOP_RIGHT,
                    transition: Flip,
                });
            }).catch(error => {
                dispatch(userActions.uiResponded());
                toast.error("Your password is not correct!", {
                    position: toast.POSITION.TOP_RIGHT,
                    transition: Flip,
                })
            })
        }
    }

}


export default security;