import React from 'react';
import {Switch, Route} from 'react-router-dom';
import NewLoan from './NewLoan';


export default function LoanRoot({match}){
    return(
        <div>
            <Switch>
                {/* <Route exact path={`${match.url}/`} component={Loan} /> */}
                <Route exact path={`${match.url}/`} component={NewLoan} />
            </Switch>
        </div>

    );
}