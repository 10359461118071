import { userConstants } from "../actions/types";

const initialState = {
    isLoading: false,
    data: [],
};

function getCardStatus(state = initialState, action) {
    switch (action.type) {
        case userConstants.CARD_STATUS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case userConstants.CARD_STATUS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            };
        case userConstants.CARD_STATUS_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return state;
    }
}

export default getCardStatus;
