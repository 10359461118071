import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import '../../../../css/dashboard.css';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import TransactionScroll from '../../../../components/Skeleton/TransactionScroll'

const useStyles = makeStyles((theme) => ({
    large: {
        width: theme.spacing(6),
        height: theme.spacing(6),
    },
    txnLogo: {
        // width: '30px',
        height: '25px',
        position: 'absolute',
        marginLeft: '-35px',
        marginTop: '-7px',
    },
    small: {
        fontSize: '12px',
        // marginTop: theme.spacing(-2),
        marginLeft: '2px',
    },
    name: {
        fontSize: '12px',
        fontWeight: 'bolder',
        marginLeft: '2px',
    },
    amount: {
        fontWeight: '900',
    },
    success: {
        color: '#00e600',
        fontSize: '12px',
        textAlign: 'right',
        fontWeight: '500',
    },
    fee: {
        color: 'red',
        textAlign: 'right',
        fontWeight: '500',
    },
}));

function VirtualTransactions(props) {
    const classes = useStyles();
    const transactions = props.data ? props.data : []

    return (

            <div style={{ display: props.show ? "none" : "block" }}>
                <ArrowBackIcon onClick={props.dont} style={{ cursor: 'pointer', color: 'red' }} /> BACK
                <Grid container className='history'>
                    <Grid item md={6} lg={6}>
                        <h4>All Virtual Card Transactions</h4>
                    </Grid>
                </Grid>

                {props.loading ? <TransactionScroll /> : ''}

                {transactions.map((trans, i) => (
                    <div key={i} className={trans.status_name === 'approved' ? 'history-table2' : trans.status_name === 'pending' ? 'history-table3' : ''}>
                        <div className='user-inline-items'>
                            <div style={{ borderRight: '1px solid #cccccc', padding: 'none', paddingRight: '5%' }}>
                                <span style={{ fontSize: '10px', display: 'block', color: '#0037ba', fontWeight: 'bold' }}>
                                    {trans.transaction_type ? (trans.transaction_type).toUpperCase() : ''}
                                </span>
                                <span className='trans-amount'>$ {Number(trans.amount).toLocaleString()}</span>
                            </div>

                            <div style={{ borderRight: '1px solid #cccccc', padding: 'none', paddingRight: '5%' }}>
                                <div className='user-inline-items'>
                                    <span style={{ marginLeft: '1px' }}>
                                        <img alt='logo' src='/images/mastercard.png' className={classes.txnLogo} />
                                    </span>
                                    <div style={{marginLeft:'15px' }}>
                                        <span className={classes.name} style={{ display: 'block' }}>
                                            Date: 
											<span style={{ fontSize: '11px', color: 'red', fontWeight: 'bold' }} className={classes.name}>
                                                NGN {new Date(trans.created_at).toLocaleDateString()}
                                            </span>
                                        </span>
                                        <span className={classes.small} style={{ display: 'block' }}>
                                            Reference: 
											<span style={{ fontSize: '11px', color: 'red', fontWeight: 'bold' }} className={classes.name}>
                                            {trans.reference}
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div style={{ borderRight: '1px solid #cccccc', padding: 'none', paddingRight: '5%' }}>
                                <span style={{ display: 'block' }} className={classes.small}>
                                    Amount in Naira:    NGN {Number(trans.amount_in_naira).toLocaleString()}
                                </span>
                                <span style={{ display: 'block' }} className={classes.small}>
                                    Rate:   NGN {Number(trans.rate).toLocaleString()}
                                </span>
                            </div>
                            <div>
                                <span style={{ fontSize: '10px', display: 'block', color: '#0037ba', fontWeight: 'bold' }}>STATUS</span>
                                {trans.status_name === 'pending' ? (
                                    <span className='deb3' style={{ display: 'inline-block' }}>
                                        PENDING
                                    </span>
                                ) : trans.status_name === 'approved' ? (
                                    <span className='deb2' style={{ display: 'inline-block' }}>
                                        APPROVED
                                    </span>
                                ) : (
                                <span style={{ display: 'inline-block' }} className='deb1'>

                                 </span>
                             )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
    );
}



export default VirtualTransactions;
