import React, { useState } from 'react';
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, Switch, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import savings from '../../../../services/savingsService';



const AutomateSwitch = withStyles((theme) => ({
    switchBase: {
        color: theme.palette.grey[500],
        '&$checked': {
            color: theme.palette.blue,
            '& + $track': {
                opacity: 1,
                backgroundColor: 'blue',
                borderColor: '1.5px solid	#87CEFA',
            },
        },
    },
    track: {
        border: `1.0px solid ${theme.palette.grey[500]}`,
        backgroundColor: theme.palette.common.white,
    },
    checked: {
        color: '#f50057',
    },
}))(Switch);


function InviteGroupModal(props) {
    const [checked, setChecked] = useState(false)

    const [inputList, setInputList] = useState([
        {
            email_phone: "",
            group_admin_id: props.user.id,
            invitation_type: checked === true ? 'phone' : 'email',
            group_id: props.id,
            message: 'No message'
        }

    ]);

    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList,
        {
            email_phone: "",
            group_admin_id: props.user.id,
            invitation_type: checked === true ? 'phone' : 'email',
            group_id: props.id,
            message: 'No message'
        }
        ]);
    };

    function submit() {
        const invitations = inputList
        props.invite_to_group({ invitations })

    }

    const automateSavings = (event) => {
        setChecked((prev) => !prev);
    };

    return (
        <div>
            <Dialog
                maxWidth='xs'
                open={props.open}
                onClose={props.close}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle >Invite other people to the group</DialogTitle>

                <DialogContent>

                    {inputList.map((x, i) => {
                        return (

                            <div className="box">
                                <AutomateSwitch
                                    checked={checked}
                                    onChange={automateSavings}
                                    name="checked"

                                />
                                {checked ?

                                    <input
                                        type='number'
                                        name="email_phone"
                                        placeholder="Enter Phone number"
                                        value={x.email_phone}
                                        onChange={e => handleInputChange(e, i)}
                                    />
                                    :
                                    <input
                                        name="email_phone"
                                        type='Email'
                                        placeholder="Enter Email"
                                        value={x.email_phone}
                                        onChange={e => handleInputChange(e, i)}
                                    />

                                }

                                <div className="btn-box">
                                    {inputList.length !== 1 && <button
                                        className="mr10"
                                        onClick={() => handleRemoveClick(i)}>Remove</button>}
                                    {inputList.length - 1 === i && <button onClick={handleAddClick}>Add</button>}
                                </div>
                            </div>
                        );
                    })}
                    <Button
                        fullWidth
                        variant="contained"
                        size="small"
                        color="primary"
                        className='getri-button submit'
                        onClick={submit}
                    >
                        {props.loading ?
                            <CircularProgress style={{ fontSize: '30px', color: '#fff' }} />
                            :
                            ' Invite'
                        }

                    </Button>
                </DialogContent>
            </Dialog>
        </div>

    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        invite_to_group: (data) => dispatch(savings.invite_to_group(data))
    };
};

const mapStateToProps = (state) => {
    return {
        user: state.userDetails.data,
        loading: state.inviteToGroup.isLoading,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InviteGroupModal)
