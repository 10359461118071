import React from "react";
import { Link } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { Grid, Button, Avatar, Badge, Paper } from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import WarningIcon from "@material-ui/icons/Warning";
import PersonPinCircleIcon from "@material-ui/icons/PersonPinCircle";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    maxWidth: "100%",
  },
  large: {
    width: 120,
    height: 120,
  },
  taps: {
    flexGrow: 1,
    minWidth: "12%",
    backgroundColor: theme.palette.background.paper,
  },
  accountTable: {
    width: "260px",
    fontSize: "12px",
    maxHeight: "200px",
    overflow: "auto",
  },
}));

export default function ProfileDeatils({ props }) {
  const classes = useStyles();

  const newList = props.docList ? props.docList : [];

  const photo = props.user.profile_photo;
  //const url = 'http://api.test.getripay.com';  //test
  const url = "https://api.getripay.com"; //live
  const completeUrl = url + photo;

  const SmallAvatar = withStyles(() => ({
    root: {
      width: 30,
      height: 30,
    },
  }))(Avatar);

  return (
    <div>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12} md={6} lg={12}>
          {/* account details section */}
          <Paper className="box1" variant="outlined" square>
            {/* profile image */}
            <Badge
              overlap="circle"
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              badgeContent={
                <SmallAvatar alt="avatar" src="/images/yellow-logo.svg" />
              }
            >
              <Avatar
                alt="profile"
                src={completeUrl}
                className={classes.large}
              />
            </Badge>
            {/* end profile image */}
            <div className="content">
              <p className="name">{props?.balance?.account_name}</p>
              <p className="balance">Account No.</p>
              <p className="account1">{props?.balance?.account_number}</p>
              <h6>Balance</h6>
              <h3>
                NGN {Number(props.balance.available_balance).toLocaleString()}
              </h3>
              <Button
                style={{ width: "70%", height: "35px", marginBottom: "10px" }}
                component={Link}
                to="/fund-account"
                fullWidth
                variant="contained"
                size="small"
                color="primary"
                className="loan-button"
              >
                Fund Account
              </Button>
            </div>
            <hr
              style={{
                color: "#C9D6FF",
                height: "2px",
                backgroundColor: "#C9D6FF",
                border: "none",
              }}
            ></hr>
            <div style={{ textAlign: "left" }}>
              <div>
                <EmailIcon />
                <span className="details-user"> {props.user.email}</span>
              </div>
              <div>
                <PhoneIcon />
                <span className="details-user"> {props.user.phone_number}</span>
              </div>
              <div>
                <PersonPinCircleIcon />
                <span className="details-user"> {props.user.address}</span>
              </div>
            </div>
          </Paper>
        </Grid>

        <Grid item xs>
          <Grid item xs={12} sm={12} md={6} lg={12}>
            {/* account details section */}
            <Paper className="boxDown" variant="outlined" square>
              <div className="action">
                <h3> ACCOUNT ACTION</h3>
                <div className="p">
                  <span>
                    {props.user.user_profile_complete === true ? (
                      <CheckCircleIcon className="icon" />
                    ) : (
                      <ErrorOutlineIcon className="icon1" />
                    )}
                  </span>
                  Personal Information
                </div>
                <div className="p">
                  <span className="icon">
                    {props.user.bvn_verify === true ? (
                      <CheckCircleIcon className="icon" />
                    ) : (
                      <ErrorOutlineIcon className="icon1" />
                    )}
                  </span>
                  BVN
                </div>
                <div className="p">
                  <span className="icon">
                    {props.user.set_transaction_pin === true ? (
                      <CheckCircleIcon className="icon" />
                    ) : (
                      <ErrorOutlineIcon className="icon1" />
                    )}
                  </span>
                  Transaction Pin
                </div>

                <div className="p">
                  {newList.map((list, i) => (
                    <div key={i}>
                      {list.kyc_verification.map((second, i) => (
                        <span key={i}>
                          {list.kyc_verification.length === 0 ? (
                            <WarningIcon className="icon1" />
                          ) : second.status === "" ? (
                            <span>
                              <WarningIcon
                                className="icon1"
                                style={{ color: "grey" }}
                              />
                            </span>
                          ) : second.status === "rejected" ? (
                            <ErrorOutlineIcon className="icon1" />
                          ) : second.status === "approved" ? (
                            <CheckCircleIcon className="icon" />
                          ) : (
                            ""
                          )}
                        </span>
                      ))}

                      {list.name}
                    </div>
                  ))}
                </div>
              </div>
              <Button
                component={Link}
                to="/settings"
                style={{
                  backgroundColor: "white",
                  color: "#0037ba",
                  width: "70%",
                  height: "35px",
                  marginBottom: "10px",
                  borderRadius: "20px",
                  border: "2px solid #0037ba",
                  boxShadow: "none",
                }}
                fullWidth
                variant="contained"
                size="small"
              >
                Edit Profile
              </Button>

              {props.accountRequest.length !== 0 && (
                <>
                  <h3>NEW ACCOUNT REQUESTS</h3>
                  <div className={classes.accountTable}>
                    <table>
                      <tbody>
                        <tr>
                          <th>Account Name</th>
                          <th>Account Type</th>
                          <th>Comment</th>
                          <th>Approval status</th>
                        </tr>
                      </tbody>
                      <tbody>
                        {props.accountRequest.map((item, i) => (
                          <tr key={i}>
                            <td>{item.account_name}</td>
                            <td>{item.account_type}</td>
                            <td>{item.comment}</td>
                            <td>{item.approved ? "Approved" : "Pending"}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
