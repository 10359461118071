import React, { useState } from 'react';
import { Grid, Paper, Button } from '@material-ui/core';
import Popper from '@material-ui/core/Popper';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { FacebookShareButton, TwitterShareButton } from 'react-share';

const useStyles = makeStyles((theme) => ({
    pop: {
        backgroundColor: 'white',
        borderRadius: '5px',
        marginTop: '10px',
        border: '1px solid gray'
    },
    link: {
        fontSize: '12px',
        color: '#0038ba',
        cursor: 'pointer'
    },
    pic: {
        maxWidth: '30px',
    },
    typography: {
        padding: theme.spacing(2),
    },
    len: {
        width: '200px',
    }
}));


function AccountInfoCard({ props }) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => { setAnchorEl(anchorEl ? null : event.currentTarget) };
    const open = Boolean(anchorEl);
    //const id = open ? 'transitions-popper' : undefined;

    const [copySuccess, setCopySuccess] = useState('');

    const url = 'https://online.getripay.com/register/';

    const ref = props.user.referral_id;

    const referralLink = url + ref;

    const doClick = () => {
        navigator.clipboard.writeText(referralLink);
        setCopySuccess('Copied!');
    };

    return (
        <Grid item xs={12} sm={12} md={4} lg={4}>

            {/* account details section */}
            <Paper className='dashboardBox1' >
                <div>
                    <div className='for-inline-items'>
                        <div>
                            <div>
                                <p className='card-badge-new deb1' style={{ width: '100px' }}>Savings ACCOUNT No:</p>
                            </div>
                            <div >
                                <p className='card-text-blue-large'>{props?.balance?.account_number}</p>
                            </div>
                        </div>
                        <div >
                            <p className='card-badge-blue e-cash'>BVN :</p>
                            {props.user.bvn !== '' ?
                                <p className='card-text-blue-large'>{props.user.bvn}</p>
                                :
                                ''
                            }

                        </div>
                    </div>
                    <div className='for-inline-items' >
                        <div>
                            <div >
                                <p className='card-badge-blue e-cash'>BALANCE</p>
                            </div>
                            <div >
                                <p className='card-text-money two'>NGN {Number(props.balance.available_balance).toLocaleString()}</p>
                            </div>
                        </div>
                        <div>

                        </div>
                    </div>
                    <div className='for-inline-items'>
                        <div>
                            <div >
                                <p className='card-badge-blue e-cash' style={{ width: '100px' }}>LEDGER BALANCE</p>
                            </div>
                            <div>
                                <p className='card-text-money-sm'>NGN {Number(props.balance.book_balance).toLocaleString()}</p>
                            </div>
                        </div>
                        <div>
                            <span style={{ fontSize: '11px' }}>{copySuccess}</span>
                            <Popper open={open} anchorEl={anchorEl} transition className={classes.len}>
                                {({ TransitionProps }) => (
                                    <Fade {...TransitionProps} timeout={350}>
                                        <Grid container spacing={3} className={classes.pop}>
                                            <Grid item sm={4} md={4} lg={4}>
                                                <FacebookShareButton url={referralLink} quote='Join me on GetriPay Now!!!!' quote='Cross over to the good side of life by joining me on GetriPay now'>
                                                    <FacebookIcon color='primary' />
                                                </FacebookShareButton>
                                            </Grid>
                                            <Grid item sm={4} md={4} lg={4}>
                                                <TwitterShareButton url={referralLink} title='Cross over to the good side of life by joining me on GetriPay now'>
                                                    <TwitterIcon style={{ color: '#00acee' }} />
                                                </TwitterShareButton>

                                            </Grid>
                                            <Grid item sm={4} md={4} lg={4}>
                                                <div style={{ cursor: 'pointer' }}>
                                                    <FileCopyIcon color='action' onClick={doClick} />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Fade>
                                )}
                            </Popper>
                            <Typography className={classes.link} onMouseOver={handleClick}>Share referral link</Typography>
                        </div>
                    </div>
                    <br />
                    <br />
                    <div className='for-inline-items'>

                        <Button
                            component={Link}
                            to='/fund-account'
                            variant="contained"
                            color="primary"
                            className='loan-button'
                        >
                            Fund Account
                      </Button>

                        <div className='user-badge'>
                            <div >
                                <p className='card-badge'>ACCOUNT NAME</p>
                            </div>
                            <div>
                                <p className='card-active-light'>{props?.balance?.account_name}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Paper>
        </Grid>
    )
}

export default AccountInfoCard

