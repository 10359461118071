import React, { useEffect, useState } from "react";
import physicalCard from "../services/physicalCardService";
import { connect } from "react-redux";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  CircularProgress,
  Typography,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useForm } from "react-hook-form";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const style = { width: "100%" };

function CardRequestModal(props) {
  
  const [submitted, setSubmitted] = useState(false);
  const token = props.details.token;

  const [data, setData] = useState({
    user_id: "",
    first_name: "",
    last_name: "",
    middle_name: "",
    gsm_no: "",
    office_phone_no: props.details?.phone_number,
    account_number: "",
    other_account_no: "",
    email: "",
    "req-ref": "",
    office_address: "",
    office_address_2: "",
    date_of_birth: "",
    marital_status: "",
    sex: "",
    state: "",
    city: "",
    name_on_card: "",
    id_card_type: "",
    id_no: "",
    id_issue_date: "",
    id_expiring_date: "",
    socio_prof_code: "",
    product_code: "",
    title: "",
    nationality: "Nigerian",
  });

  useEffect(() => {
    setData({
      ...data,
      user_id: props.details?.id,
      first_name: props.details?.first_name,
      last_name: props.details?.last_name,
      middle_name: props.details?.others_name,
      gsm_no: props.details?.phone_number,
      office_phone_no: props.details?.phone_number,
      account_number: props.balance?.account_number,
      other_account_no: props.balance?.account_number,
      email: props.details?.email,
      "req-ref":
        `${props.details?.id}` + Math.floor(Math.random() * 900000000000000000),
      office_address: props.details?.address,
      office_address_2: props.details?.address,
      date_of_birth: props.details?.date_of_birth,
      marital_status: "",
      sex: "",
      state: "",
      city: "",
      name_on_card: "",
      id_card_type: "BVN",
      id_no: props.details?.bvn,
      id_issue_date: "1/1/2021",
      id_expiring_date: "1/1/20100",
      socio_prof_code: "001",
      product_code: "353",
      title: "",
      nationality: "Nigerian",
    });
  }, [props.details, props.balance]);

  useEffect(() => {
    if (data.marital_status && data.sex && data.title) {
      setSubmitted(false);
    }
  }, [data]);

  const { register, handleSubmit, errors } = useForm();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((data) => ({ ...data, [name]: value }));
  };

  // SUMBIT CARD REQUEST
  function submit() {
    if (!data.sex && !data.marital_status && !data.title) {
      setSubmitted(true);
    } else {
      props.request_physical_card(
        { ...data, name_on_card: data.first_name + " " + data.last_name },
        token
      );
      setSubmitted(false);
    }
  }

  return (
    <div>
      <Dialog
        open={
          props.status.success === true
            ? false
            : props.open === true
            ? true
            : false
        }
        TransitionComponent={Transition}
        keepMounted
        onClose={props.close}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Please confirm these details before proceeding"}
        </DialogTitle>
        <DialogContent dividers style={{ backgroundColor: "#eee" }}>
          <Grid item lg={6} sm={12} xs={12}>
            <InputLabel htmlFor="marital_status">Title</InputLabel>
            <Select
              style={style}
              value={data.title}
              name="title"
              onChange={handleChange}
              inputRef={register({
                required: true,
              })}
            >
              <MenuItem value="01">MR</MenuItem>
              <MenuItem value="02">MRS</MenuItem>
              <MenuItem value="03">MISS</MenuItem>
              <MenuItem value="04">PROF</MenuItem>
              <MenuItem value="05">DR</MenuItem>
              <MenuItem value="06">ENGR</MenuItem>
              <MenuItem value="07">CHIEF</MenuItem>
            </Select>
            {submitted && !data.title && (
              <p className="error">Please select your title</p>
            )}
          </Grid>

          <Grid container spacing={5}>
            <Grid item lg={6} sm={12} xs={12}>
              <TextField
                name="first_name"
                onChange={handleChange}
                value={data.first_name}
                label="First Name"
                style={{ borderColor: errors.first_name && "red" }}
                inputRef={register({
                  required: "Please enter your first name",
                })}
                style={style}
              />
              {errors.first_name && (
                <p className="error">{errors.first_name.message}</p>
              )}
            </Grid>
            <Grid item lg={6} sm={12} xs={12}>
              <TextField
                name="last_name"
                onChange={handleChange}
                value={data.last_name}
                label="Last Name"
                inputRef={register({
                  required: "Please enter your last name",
                })}
                style={style}
              />
              {errors.last_name && (
                <p className="error">{errors.last_name.message}</p>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <Grid item lg={6} sm={12} xs={12}>
              <TextField
                name="middle_name"
                onChange={handleChange}
                value={data.middle_name}
                label="Middle Name"
                style={style}
              />
            </Grid>

            <Grid item lg={6} sm={12} xs={12}>
              <TextField
                name="office_address"
                onChange={handleChange}
                value={data.office_address}
                label="Address"
                inputRef={register({
                  required: "Pease enter your address",
                })}
                style={style}
              />
              {errors.office_address && (
                <p className="error">{errors.office_address.message}</p>
              )}
              <Typography
                variant="body1"
                style={{ fontSize: "12px", color: "#0037ba" }}
              >
                Enure to put an accurate address, as your card will be delivered
                to that location{" "}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={5}>
            <Grid item lg={6} sm={12} xs={12}>
              <TextField
                name="state"
                onChange={handleChange}
                value={data.state}
                label="State"
                inputRef={register({
                  required: "Please enter your state",
                })}
                style={style}
              />
              {errors.state && <p className="error">{errors.state.message}</p>}
            </Grid>
            <Grid item lg={6} sm={12} xs={12}>
              <TextField
                name="city"
                onChange={handleChange}
                value={data.city}
                label="City"
                inputRef={register({
                  required: "Please enter your city",
                })}
                style={style}
              />
              {errors.city && <p className="error">{errors.city.message}</p>}
            </Grid>
          </Grid>

          <Grid container spacing={5}>
            <Grid item lg={6} sm={12} xs={12}>
              <InputLabel htmlFor="marital_status">Marital Status</InputLabel>
              <Select
                style={style}
                value={data.marital_status}
                name="marital_status"
                onChange={handleChange}
                inputRef={register({
                  required: true,
                })}
              >
                <MenuItem value="S">Single</MenuItem>
                <MenuItem value="M">Married</MenuItem>
                <MenuItem value="D">Divorced</MenuItem>
                <MenuItem value="W">Widowed</MenuItem>
              </Select>
              {submitted && !data.marital_status && (
                <p className="error">Please select your marital status</p>
              )}
            </Grid>
            <Grid item lg={6} sm={12} xs={12}>
              <InputLabel htmlFor="marital_status">Gender</InputLabel>
              <Select
                style={style}
                value={data.sex}
                name="sex"
                onChange={handleChange}
                inputRef={register({
                  required: true,
                })}
              >
                <MenuItem value="F">Female</MenuItem>
                <MenuItem value="M">Male</MenuItem>
              </Select>
              {submitted && !data.sex && (
                <p className="error">Please select your gender</p>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={props.close}
            color="primary"
            className="request-modal-button-cancel"
          >
            CANCEL
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={handleSubmit(submit)}
            type="submit"
            className="request-modal-button"
          >
            {props.isLoading ? (
              <CircularProgress style={{ fontSize: "30px", color: "#fff" }} />
            ) : (
              "SUBMIT"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    request_physical_card: (data, token) =>
      dispatch(physicalCard.request_physical_card(data, token)),
  };
};
const mapStateToProps = (state) => {
  return {
    status: state.getPhysicalCard.data,
    isLoading: state.getPhysicalCard.isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardRequestModal);
