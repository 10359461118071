import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Container,
  Grid,
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
} from "@material-ui/core";
import PropTypes from "prop-types";
import "../../../css/dashboard.css";
import TopBar from "../../../components/TopBar";
import { MainContent, Footer, HeaderBar } from "../../../components/Appearance";
import SideNav from "../../../components/SideNav";
import BottomNav from "../../../components/bottomNavigation";
import { Helmet } from "react-helmet";
import StartSavings from "./components/StartSavings";
import SavingsBox from "./components/SavingsBox";
import { connect } from "react-redux";
import savings from "../../../services/savingsService";
import NoSavings from "./components/NoSavings";
import StartSavingsGroup from "./components/StartSavingsGroup";
import GroupBox from "./components/GroupBox";
import JoinSavingsGroupBox from "./components/JoinSavingsGroupBox";
import NotificationSettingsTab from "./components/NotificationSettingsTab";
import ChangeDateModal from "./components/ChangeDateModal";
import transactions from "../../../services/transactionService";
import { history } from "../../../store/history";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "white",
  },
  content: {
    flexGrow: 1,
    backgroundColor: "white",
  },
  container: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(4),
    backgroundColor: "white",
  },
  large: {
    width: 100,
    height: 100,
  },
  customBadge: {
    color: "white",
    marginRight: "-55px",
    width: "120px",
  },
  tabContainer: {
    borderRadius: "5px",
    border: "2px solid #F0F0F0",
    marginLeft: "10px",
    marginRight: "10px",
  },
}));

const SavingsTabs = withStyles({
  root: {
    backgroundColor: "white",
  },
  indicator: {
    backgroundColor: "#00e600",
    borderBottom: "2px solid #00e600",
    width: "200px",
  },
})(Tabs);

const SavingsTab = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    fontSize: "13px",
    fontWeight: "600",
    textTransform: "none",
    maxWidth: "80%",
    padding: "20px 10px",
    textAlign: "-webkit-center",
    flexDirection: "container",
    justifyContent: "space-around",
  },
}))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function SavingsOverview(props) {
  const classes = useStyles();
  const [toggle, setToggle] = useState(true);
  const [sidenav, setSidenav] = useState("getri-sidenav");
  const [open, setOpen] = useState(false);
  const [openGroup, setOpenGroup] = useState(false);
  const [value, setValue] = useState(0);
  const [listGroup, setListGroup] = useState(false);
  const [changeDate, setChangeDate] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const id = props.user.id;

  const handleClickOpen = () => {
    setOpen(true);
    props.get_product(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickGroup = () => {
    props.get_product(id);
    setOpenGroup(true);
  };

  const handleCloseGroup = () => {
    setOpenGroup(false);
  };

  const handleListGroup = () => {
    props.get_all_savings_group();
    setListGroup(true);
  };

  const closeListGroup = () => {
    setListGroup(false);
  };

  const handleChangeDate = () => {
    setChangeDate(true);
  };

  const handleCloseDate = () => {
    setChangeDate(false);
  };

  const handleToggle = () => {
    const mediaScreen = window.matchMedia(
      `(max-width: 700px),
        (min-device-width: 768px)
        and (max-device-width: 1024px)`
    ); //returns true if

    if (mediaScreen.matches) {
      setSidenav("slideOut");
    } else {
      setToggle(!toggle);
    }
  };
  const hideSidebar = () => {
    setSidenav("slideIn");
  };

  useEffect(() => {
    props.get_user_savings(id, "active");
    props.get_inactive_savings(id, "in-active");
    props.get_wallet(id);
    props.show_date(id);
    props.get_user_group_savings(id);
    props.get_account_balance();
  }, []);

  return (
    <>
      <Helmet>
        <title>GetriPay - Fastest growing digital bank with rewards </title>
        <meta name="description" content="Savings" />
        <meta name="keywords" content="savings" />
      </Helmet>
      <TopBar handleToggle={handleToggle} />
      <SideNav
        toggle={toggle}
        className={sidenav}
        hideSidebar={hideSidebar}
        {...props}
      />
      <MainContent toggle={toggle}>
        <main className={classes.content}>
          <Grid container>
            <Grid item xs={12} md={12} lg={12}>
              <Container maxWidth="lg" className={classes.container}>
                <Container className="main-container">
                  <HeaderBar
                    topic="GetriSave"
                    text={Number(
                      props.balance.available_balance
                    ).toLocaleString()}
                  />
                </Container>
              </Container>
            </Grid>
            <Container>
              <Container>
                <AppBar position="static" className={classes.tabContainer}>
                  <SavingsTabs
                    value={value}
                    onChange={handleChange}
                    aria-label="simple tabs example"
                  >
                    <SavingsTab label="ACTIVE SAVINGS" {...a11yProps(0)} />
                    <SavingsTab label="INACTIVE SAVINGS" {...a11yProps(1)} />
                    <SavingsTab label="GROUP SAVINGS" {...a11yProps(2)} />
                    <SavingsTab
                      label="SAVINGS NOTIFICATIONS"
                      {...a11yProps(3)}
                    />
                  </SavingsTabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                  <Grid container>
                    <Grid item lg={4} className="overview-text">
                      <span>Savings Overview</span>
                    </Grid>

                    <Grid item lg={4} style={{ fontSize: "14px" }}>
                      <span>
                        Withdrawal date:{" "}
                        {new Date(props.date.withdrawal_date).toDateString()}{" "}
                      </span>
                      <span
                        onClick={handleChangeDate}
                        style={{
                          display: "block",
                          cursor: "pointer",
                          color: "#0037ba",
                        }}
                      >
                        Change
                      </span>
                      <ChangeDateModal
                        open={changeDate}
                        close={handleCloseDate}
                        date={props.date}
                      />
                    </Grid>

                    <Grid item lg={4} className="overview-text">
                      <Grid container style={{ fontSize: "14px" }}>
                        <Grid
                          item
                          lg={6}
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            history.push(
                              `wallet-transactions/${props.wallet.id}`
                            )
                          }
                        >
                          GetriSave balance: <br />
                          {props.wallet ? (
                            <>
                              <span style={{ color: "#ff0000" }}>
                                NGN{" "}
                                {Number(
                                  props.wallet.ledger_balance
                                ).toLocaleString()}
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Grid
                          item
                          lg={6}
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            history.push(
                              `available-wallet-transactions/${props.wallet.id}`
                            )
                          }
                        >
                          Amount you can withdraw:
                          {props.wallet ? (
                            <>
                              <span style={{ color: "#0037ba" }}>
                                NGN{" "}
                                {Number(
                                  props.wallet.available_balance
                                ).toLocaleString()}
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container spacing={5}>
                    <Grid item lg={4} sm={12} md={6} xs={12}>
                      <StartSavings
                        open={open}
                        click={handleClickOpen}
                        close={handleClose}
                        title="Start a new savings"
                        image="/images/add_icon.png"
                      />
                    </Grid>
                    {props.userSavings.length === 0 ? (
                      <Grid item lg={4}>
                        <NoSavings
                          image="/images/getriSave_logo_grey.png"
                          title="No Savings History"
                          text="Click on the box by your left to begin "
                        />
                      </Grid>
                    ) : (
                      <>
                        {props.userSavings.map((data, i) => (
                          <Grid item lg={4} sm={12} md={6} xs={12} key={i}>
                            <SavingsBox {...data} balance={props.balance} />
                          </Grid>
                        ))}
                      </>
                    )}
                  </Grid>
                </TabPanel>

                <TabPanel value={value} index={1}>
                  <Grid container>
                    <Grid item lg={4} className="overview-text">
                      <span>In-active savings</span>
                    </Grid>

                    <Grid item lg={4} style={{ fontSize: "14px" }}>
                      <span>
                        Withdrawal date:{" "}
                        {new Date(props.date.withdrawal_date).toDateString()}{" "}
                      </span>
                      <span
                        onClick={handleChangeDate}
                        style={{
                          display: "block",
                          cursor: "pointer",
                          color: "#0037ba",
                        }}
                      >
                        Change
                      </span>
                      <ChangeDateModal
                        open={changeDate}
                        close={handleCloseDate}
                        date={props.date}
                      />
                    </Grid>

                    <Grid item lg={4} className="overview-text">
                      <Grid container style={{ fontSize: "14px" }}>
                        <Grid
                          item
                          lg={6}
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            history.push(
                              `wallet-transactions/${props.wallet.id}`
                            )
                          }
                        >
                          GetriSave balance: <br />
                          {props.wallet ? (
                            <>
                              <span style={{ color: "#ff0000" }}>
                                NGN{" "}
                                {Number(
                                  props.wallet.ledger_balance
                                ).toLocaleString()}
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                        </Grid>
                        <Grid item lg={6}>
                          Amount you can withdraw:
                          {props.wallet ? (
                            <>
                              <span style={{ color: "#0037ba" }}>
                                NGN{" "}
                                {Number(
                                  props.wallet.available_balance
                                ).toLocaleString()}
                              </span>
                            </>
                          ) : (
                            ""
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container spacing={5}>
                    {props.userSavings.length === 0 ? (
                      <Grid item lg={4}>
                        <NoSavings
                          image="/images/getriSave_logo_grey.png"
                          title="No Inactive Savings"
                          text=" "
                        />
                      </Grid>
                    ) : (
                      <>
                        {props.inactive.map((data, i) => (
                          <Grid item lg={4} sm={12} md={6} xs={12} key={i}>
                            <SavingsBox {...data} />
                          </Grid>
                        ))}
                      </>
                    )}
                  </Grid>
                </TabPanel>

                <TabPanel value={value} index={2}>
                  <Grid container>
                    <Grid item lg={6} className="overview-text">
                      <span>Group Savings Overview</span>
                    </Grid>
                    <Grid item lg={6} className="overview-text">
                      <Grid container style={{ fontSize: "14px" }}></Grid>
                    </Grid>
                  </Grid>

                  <Grid container spacing={5}>
                    <Grid item lg={4} sm={12} md={6} xs={12}>
                      <StartSavingsGroup
                        open={openGroup}
                        click={handleClickGroup}
                        close={handleCloseGroup}
                        title="Create a group savings"
                        image="/images/add_icon.png"
                      />
                    </Grid>

                    <Grid item lg={4} sm={12} md={6} xs={12}>
                      <JoinSavingsGroupBox
                        open={listGroup}
                        click={handleListGroup}
                        close={closeListGroup}
                        title="Join a group savings"
                        image="/images/add_icon.png"
                      />
                    </Grid>
                    {props.userSavings.length === 0 ? (
                      <Grid item lg={4} sm={12} md={6} xs={12}>
                        <NoSavings
                          image="/images/getriSave_logo_grey.png"
                          title="No Savings History"
                          text="Click on the box by your left to begin "
                        />
                      </Grid>
                    ) : (
                      <>
                        {props.userGroup &&
                          props.userGroup.map((data, i) => (
                            <Grid item lg={6} sm={12} md={6} xs={12} key={i}>
                              <GroupBox {...data} />
                            </Grid>
                          ))}
                      </>
                    )}
                  </Grid>
                </TabPanel>

                <TabPanel value={value} index={3}>
                  <Grid container>
                    <Grid item lg={3}>
                      {" "}
                    </Grid>
                    <Grid item lg={6}>
                      <NotificationSettingsTab />
                    </Grid>
                    <Grid item lg={3}>
                      {" "}
                    </Grid>
                  </Grid>
                </TabPanel>
              </Container>
            </Container>
          </Grid>
          <BottomNav {...props} />
          {/* <Footer /> */}
        </main>
      </MainContent>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    get_product: (id) => dispatch(savings.get_product(id)),
    get_user_savings: (id, action) =>
      dispatch(savings.get_user_savings(id, action)),
    get_inactive_savings: (id, action) =>
      dispatch(savings.get_inactive_savings(id, action)),
    get_wallet: (id) => dispatch(savings.get_wallet(id)),
    get_savings_history: (id) => dispatch(savings.get_savings_history(id)),
    show_date: (id) => dispatch(savings.show_date(id)),
    get_user_group_savings: (id) =>
      dispatch(savings.get_user_group_savings(id)),
    get_all_savings_group: () => dispatch(savings.get_all_savings_group()),
    get_account_balance: (payload) =>
      dispatch(transactions.get_account_balance(payload)),
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.userDetails.data,
    userSavings: state.fetch.data,
    inactive: state.getInactiveSavings.data,
    wallet: state.walletBalance.data,
    userGroup: state.getUserGroupSavings.data,
    date: state.showWithdrawalDate.data,
    balance: state.getAccountBalance.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SavingsOverview);
