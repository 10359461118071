import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Button,
  Select,
  MenuItem,
  CircularProgress,
} from "@material-ui/core";
import { Helmet } from "react-helmet";
import TopBar from "../../../components/TopBar";
import SideNav from "../../../components/SideNav";
import {
  HeaderBar,
  InputGrid,
  MainContent,
} from "../../../components/Appearance";
import { useStyles } from "../QR/components/utils";
import transactions from "../../../services/transactionService";
import { connect } from "react-redux";
import BackDrop from "../../../components/BackDrop";
import newAccounts from "../../../services/newAccountService";

const style = { marginBottom: "5px" };

const NewAccount = (props) => {
  const classes = useStyles();
  const [toggle, setToggle] = useState(true);
  const [sidenav, setSidenav] = useState("getri-sidenav");
  const [disabled, setDisabled] = useState(false);

  const [data, setData] = useState({
    user_id: props.user?.id,
    primary_account: "",
    account_name: "",
    account_type: "",
    purpose: undefined,
    approve: 0,
  });

  const handleToggle = () => {
    const mediaScreen = window.matchMedia(
      `(max-width: 700px),
        (min-device-width: 768px)
        and (max-device-width: 1024px)`
    ); //returns true if

    if (mediaScreen.matches) {
      setSidenav("slideOut");
    } else {
      setToggle(!toggle);
    }
  };
  const hideSidebar = () => {
    setSidenav("slideIn");
  };

  function handleChange(e) {
    const { name, value } = e.target;
    setData((data) => ({ ...data, [name]: value }));
  }

  useEffect(() => {
    props.get_account_balance();
  }, []);

  const submit = () => {
    props.submit_new_account(data);
  };
  const findAccount = props?.user?.bank_account.filter(
    (item) => item.account_number === data.primary_account
  );

  return (
    <>
      <Helmet>
        <title>GetriPay - Fastest growing digital bank with rewards </title>
        <meta name="description" content="New Account" />
        <meta name="keywords" content="New Account" />
      </Helmet>

      <TopBar handleToggle={handleToggle} />
      <SideNav
        toggle={toggle}
        className={sidenav}
        hideSidebar={hideSidebar}
        {...props}
      />
      <MainContent toggle={toggle}>
        {props.loadingValidate || props.loadingProcess ? (
          <div>
            <BackDrop />
          </div>
        ) : null}
        <main className={classes.content}>
          <Grid container>
            <Grid item xs={12} md={12} lg={12}>
              <Container maxWidth="lg" className={classes.container}>
                <Container className="main-container">
                  <HeaderBar
                    topic="Request New Account"
                    text={Number(
                      props.balance.available_balance
                    ).toLocaleString()}
                  />
                </Container>

                <Grid container>
                  <Grid item xs={12} md={6} lg={6}>
                    <div className={disabled ? "overlay" : ""}></div>
                    <p style={{ marginTop: "20px", textAlign: "center" }}>
                      Fill in the form below to create a new Getripay Account.
                      Creation of account is subject to approval
                    </p>
                    <div className="bulk-payment-plain">
                      <div className="bulkDiv">
                        <InputGrid>
                          <small>
                            Account Name:{" "}
                            {findAccount[0] && findAccount[0].account_name +
                              " " +
                              data.account_name}
                          </small>
                        </InputGrid>

                        <InputGrid>
                          <label>Select Primary Account</label>
                          <Select
                            style={{ width: "100%" }}
                            value={data.primary_account}
                            name="primary_account"
                            onChange={handleChange}
                          >
                            {props.user.bank_account.map((res, i) => {
                              return (
                                <MenuItem key={i} value={res.account_number}>
                                  {res.account_name} - {res.account_number}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          <small style={{ color: "red" }}>
                            The new account will be created off the account you
                            select here.
                          </small>
                        </InputGrid>

                        <InputGrid>
                          <label>Select Account Type</label>
                          <Select
                            style={{ width: "100%" }}
                            value={data.account_type}
                            name="account_type"
                            onChange={handleChange}
                          >
                            <MenuItem value="savings">Savings</MenuItem>
                            <MenuItem value="current">Current</MenuItem>
                          </Select>
                        </InputGrid>

                        <InputGrid>
                          <label style={style}>New Account Name</label>
                          <input
                            className="input-div"
                            type="text"
                            name="account_name"
                            placeholder="Enter an account name here (optional)"
                            value={data.account_name}
                            onChange={handleChange}
                            autoComplete="off"
                          />
                          <small style={{ color: "red" }}>
                            This name will be joined with your existing account
                            name, if filled.
                          </small>
                        </InputGrid>

                        <InputGrid>
                          <label style={style}>Purpose</label>
                          <input
                            className="input-div"
                            type="text"
                            name="purpose"
                            placeholder="Purpose  of account creation (optional)"
                            value={data.purpose}
                            onChange={handleChange}
                            autoComplete="off"
                          />
                        </InputGrid>

                        <Button
                          onClick={submit}
                          fullWidth
                          variant="contained"
                          size="small"
                          disabled={
                            data.account_type === "" ||
                            data.primary_account === "" ||
                            props.loading
                          }
                          className="getri-button"
                          style={{
                            backgroundColor: "#0037ba",
                            color: "#fff",
                          }}
                        >
                          {props.loading ? <CircularProgress /> : "Submit"}
                        </Button>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={6} lg={6}>
                    <div className="bulk-payment-gray"></div>
                  </Grid>
                </Grid>
              </Container>
            </Grid>
          </Grid>
        </main>
      </MainContent>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_account_balance: (payload) =>
      dispatch(transactions.get_account_balance(payload)),
    submit_new_account: (payload) =>
      dispatch(newAccounts.submit_new_account(payload)),
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.userDetails.data,
    balance: state.getAccountBalance.data,
    loading: state.submitNewAccount.isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewAccount);
