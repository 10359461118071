import { userConstants } from "../actions/types";

const initialState = {
    isLoading:false,
    data: [],
};

export function getNewAccount(state = initialState, action) {
    switch (action.type) {
        case userConstants.GET_NEW_ACCOUNT_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case userConstants.GET_NEW_ACCOUNT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            }
        default:
            return state;
    }
};

export function submitNewAccount(state = initialState, action) {
    switch (action.type) {
        case userConstants.SUBMIT_NEW_ACCOUNT_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case userConstants.SUBMIT_NEW_ACCOUNT_SUCCESS:
            return {
                ...state,
                isLoading: false,
            }
        default:
            return state;
    }
};
