import React from 'react';
import { connect } from 'react-redux';
import SavingsModal from '../components/SavingsModal';


function StartSavings(props) {

  return (
    <div className='start-savings start-bg' onClick={props.click}>
      <img src={props.image} />
      <p>{props.title} </p>
      <p style={{ color: 'black' }}>{props.text}</p>
      <SavingsModal open={props.open} close={props.close} {...props} />
    </div>

  )
}


const mapStateToProps = (state) => {
  return {
    plans: state.getSavingsProduct.data,
    user:state.userDetails.data
  };
};


export default connect(mapStateToProps, null)(StartSavings)