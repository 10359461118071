import { userConstants } from '../actions/types'

const initialState = {
    isValidatingReferral: false,
    data: []
}
 
function validateReferral(state = initialState, action) {
    switch (action.type) {
        case userConstants.VALIDATE_REFERRAL_REQUEST:
            return {
                isValidatingReferral: true,
            };
        case userConstants.VALIDATE_REFERRAL_SUCCESS:
            return {
                isValidatingReferral: false,
                data: action.payload,
                success: true
            };
            case userConstants.VALIDATE_REFERRAL_FAILURE:
                return {
                    isValidatingReferral: false,
                    success: false
                };
        default:
            return state
    }
}

export default validateReferral;