import React, { useState } from "react";
import { Chip, Grid, Paper } from "@material-ui/core";
import { Button } from "react-bootstrap";
import InterestDrawer from "./InterestDrawer";

const style = {
  position: "absolute",
  marginTop: "-10px",
  height: "20px",
};

function EarningsCard({ props }) {
  const [drawerActive, setDrawerActive] = useState(false);

  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <Chip label="New" style={style} color="secondary" />
      <Paper className="dashboardBox1">
        <div className="for-inline-items">
          <div>
            <p className="card-text-blue-large"> Interest </p>
          </div>

          <Button
            style={{
              backgroundColor: "#0037ba",
              borderRadius: "300px",
              color: "white",
              fontSize: "10px",
              marginBottom: "-10px",
            }}
            onClick={() => setDrawerActive(true)}
          >
            View interest history
          </Button>
        </div>
        <div>
          <div className="for-inline-items">
            <div className="inner-card">
              <p className="card-text-sm">
                {new Date().toLocaleString("default", {
                  month: "long",
                  year: "numeric",
                })}
              </p>
              <div className="inner-card-text-bold">
                <span className="inner-text-gray">
                  &#8358;{" "}
                  {props?.earnings?.accrued_interest
                    ? Number(props?.earnings?.accrued_interest).toFixed(2)
                    : "0"}
                </span>

                <p className="two">Accrued Interest</p>
              </div>
            </div>
            <div className="inner-card">
              <img
                src="/images/g_confetti.svg"
                alt="logo"
                className="card-icons-2"
              />
              <div className="inner-card-text-bold">
                <span className="inner-text-gray">
                  &#8358;{" "}
                  {props?.earnings?.last_payout_amount
                    ? Number(props?.earnings?.last_payout_amount).toFixed(2)
                    : "0"}
                </span>

                <p className="two">Last payout amount</p>
              </div>
            </div>
          </div>
        </div>
      </Paper>
      <InterestDrawer
        drawerActive={drawerActive}
        setDrawerActive={setDrawerActive}
        details={props.interestHistory}
        loading={props.interestLoading}
        account={props?.balance?.account_number}
        get_interest_history={props.get_interest_history}
      />
    </Grid>
  );
}

export default EarningsCard;
