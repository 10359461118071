import React from 'react';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Tabs, Tab, Typography, Box } from "@material-ui/core";
import PropTypes from "prop-types";

export const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        backgroundColor: "white",
    },
    content: {
        flexGrow: 1,
        backgroundColor: "white",
    },
    container: {
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(4),
        backgroundColor: "white",
    },
    large: {
        width: 100,
        height: 100,
    },
    customBadge: {
        color: "white",
        marginRight: "-55px",
        width: "120px",
    },
    tabContainer: {
        borderRadius: "5px",
        border: "2px solid #F0F0F0",
        marginLeft: "10px",
        marginRight: "10px",
    },
}));

export const SavingsTabs = withStyles({
    root: {
        backgroundColor: "white",
    },
    indicator: {
        backgroundColor: "#00e600",
        borderBottom: "2px solid #00e600",
        width: "200px",
    },
})(Tabs);

export const SavingsTab = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        fontSize: "13px",
        fontWeight: "600",
        textTransform: "none",
        maxWidth: "80%",
        padding: "20px 10px",
        textAlign: "-webkit-center",
        flexDirection: "container",
        justifyContent: "space-around",
    },
}))((props) => <Tab disableRipple {...props} />);
