import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Container, Grid, Tabs, Tab, Box } from '@material-ui/core';
import '../../../css/navbar.css';
import '../../../css/UserProfile.css';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TopBar from '../../../components/TopBar';
import { MainContent, Footer } from '../../../components/Appearance';
import SideNav from '../../../components/SideNav';
import BottomNav from '../../../components/bottomNavigation';
import '../../../css/dashboard.css';
import ListItem from '@material-ui/core/ListItem';
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
import DoneIcon from '@material-ui/icons/Done';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode } from '@fortawesome/free-solid-svg-icons';
import packageJson from '../../../../package.json';
import CreateDeveloperAccountCard from '../../../components/DeveloperDashboards/CreateDeveloperAccountCard';
import DeveloperKeys from '../../../components/DeveloperDashboards/DeveloperKeysTab';
import BusinessRegistrationDocument from '../../../components/DeveloperDashboards/DeveloperBusinessRegistrationDocument';
import TaxDocument from '../../../components/DeveloperDashboards/DeveloperTaxDocument';
import UtilityDocument from '../../../components/DeveloperDashboards/UtilityDocument';
import developer from '../../../services/DeveloperServices/developerService';
import config from '../../../config';
// import LineChart from '../../../components/DeveloperDashboards/OverviewCharts'

import uploadImg from '../../../upload.png';;

const baseUrl = `${config.apiUrl}`;

const useStyles = makeStyles((theme) => ({
	content: {
		flexGrow: 1,
		height: '100vh',
		width: '100%',
		backgroundColor: 'white',
	},
	container: {
		paddingTop: theme.spacing(10),
		paddingBottom: theme.spacing(4),
		height: 'auto',
		width: '100vw',
		backgroundColor: 'white',
	},
	large: {
		width: 120,
		height: 120,
	},
	tabContainer: {
		borderRadius: '5px',
		borderBottom: '2px solid #F0F0F0',
		marginLeft: '10px',
		marginRight: '10px',
	},
	cardSpace: {
		marginTop: '9%',
	},
	imgWrapper: {
		border: '2px solid #F0F0F0',
		width: '280px',
		height: '190px',
		borderRadius: '4px',
		marginBottom: '20px',
	},
	imgStyle: {
		width: '270px',
		height: '180px',
		padding: '5px',
		borderRadius: '4px',
	},
}));

const AccountOverViewTabs = withStyles({
	root: {
		backgroundColor: 'white',
	},
	indicator: {
		backgroundColor: '#ffcc00',
	},
})(Tabs);

const AccountOverViewTab = withStyles((theme) => ({
	root: {
		fontFamily: 'CarmenSans-Bold',
		backgroundColor: theme.palette.background.paper,
		fontSize: '12px',
		fontWeight: '600',
		//marginRight: '40px',
		textTransform: 'none',
		maxWidth: '100%',
		padding: '6px 10px',
		textAlign: '-webkit-center',
		flexDirection: 'container',
		justifyContent: 'space-around',
		'&:hover': {
			color: 'black',
			opacity: 1,
		},
		'&$selected': {
			color: 'black',
			fontWeight: 'bold',
			borderBottom: '4px solid #ffcc00',
			textAlign: '-webkit-center',
		},
		'&:focus': {
			color: 'black',
			fontWeight: 'bold',
			borderBottom: '4px solid #ffcc00',
			textAlign: '-webkit-center',
		},
	},
	selected: {},
}))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`scrollable-auto-tabpanel-${index}`}
			aria-labelledby={`scrollable-auto-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<div style={{ display: 'flex' }}>{children}</div>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `scrollable-auto-tab-${index}`,
		'aria-controls': `scrollable-auto-tabpanel-${index}`,
	};
}

function DeveloperDashboard(props) {
	const classes = useStyles();
	const [value, setValue] = React.useState(0);
	const [toggle, setToggle] = useState(true);
	const [sidenav, setSidenav] = useState('getri-sidenav');

	const handleToggle = () => {
		const mediaScreen = window.matchMedia(
			`(max-width: 700px),
        (min-device-width: 768px)
        and (max-device-width: 1024px)`
		); //returns true if

		if (mediaScreen.matches) {
			setSidenav('slideOut');
		} else {
			setToggle(!toggle);
		}
	};

	const hideSidebar = () => {
		setSidenav('slideIn');
	};

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const id = props.user.id;
	const m_id = props.data.data ? props.data.data.developerAccount.merchantId : '';
	const email = props.data.data ? props.data.data.developerAccount.businessSupportEmail : '';
	const url = `${baseUrl}/third-party/`;

	const cac = props.devDoc.businessRegistrationDocuments;
	const completeUrl = url + cac;

	const address = props.devDoc.taxDocuments;
	const completeUrl2 = url + address;

	const uti = props.devDoc.utilityBillDocuments;
	const completeUrl3 = url + uti;

	useEffect(() => {
		props.get_dev_account(id);
		getKeys();
		getDocument();
	}, [props.data.success]); // eslint-disable-line react-hooks/exhaustive-deps

	function getKeys() {
		if (props.data.success === true && email) {
			props.get_dev_keys(id, email);
		}
	}

	function getDocument() {
		if (props.data.success === true && m_id) {
			props.get_document(m_id, email);
		}
	}

	return (
		<>
			<Helmet>
				<title>GetriPay - Fastest growing digital bank with rewards </title>
				<meta name='description' content='Account overview' />
				<meta name='keywords' content='profile, account overview' />
			</Helmet>
			<TopBar handleToggle={handleToggle} />
			<SideNav toggle={toggle} className={sidenav} hideSidebar={hideSidebar} {...props} />
			<MainContent toggle={toggle}>
				<main className={classes.content}>
					<div className={classes.appBarSpacer} />
					<Grid container>
						<Grid item xs={12} md={12} lg={12}>
							<Container maxWidth='lg' className={classes.container}>
								{/* Body of dashboard */}
								<Grid item xs={12} md={12} lg={12}>
									<Grid container spacing={3} className='mb'>
										<Grid item xs={12} sm={12} md={12} lg={12}>
											<div style={{ display: 'flex' }}>
												<ListItem className={!props.toggle ? 'collapse-list-item' : 'getri-list-item'}>
													<ListItemIcon className='side-icon-div'>
														<div className='sidebar-icon'>
															<FontAwesomeIcon color='gray' style={{ fontSize: '18px' }} icon={faCode} />
														</div>
													</ListItemIcon>
													<span style={{ fontSize: '20px', fontWeight: 'bold' }}>GetriPay Developer Accounts</span>
												</ListItem>
												<span style={{ marginRight: '13%' }}>
													{' '}
													<span style={{ fontWeight: 'bold', fontSize: '12px', color: 'gray' }}>version</span>{' '}
													{packageJson.version}{' '}
												</span>
											</div>

											<br />
											<div position='static' className={classes.tabContainer}>
												<AccountOverViewTabs
													value={value}
													onChange={handleChange}
													textColor='primary'
													variant='scrollable'
													scrollButtons='auto'
													aria-label='scrollable auto tabs example'
													className=''
												>
													<AccountOverViewTab label='Activity Overview' {...a11yProps(0)} />
													<AccountOverViewTab label='Transactions and Metrics' {...a11yProps(1)} />
													<AccountOverViewTab label='Public and Private Keys' {...a11yProps(2)} />
													<AccountOverViewTab label='Business Documents' {...a11yProps(3)} />
													<AccountOverViewTab label='Account Settings' {...a11yProps(4)} />
													<AccountOverViewTab label='Developer Support' {...a11yProps(4)} />
												</AccountOverViewTabs>
											</div>

											<br />

											<Grid
												container
												spacing={0}
												style={{
													borderBottom: '2px solid #F0F0F0',
													marginLeft: '10px',
													width: '98%',
													display: 'flex',
													justifyContent: 'space-between',
												}}
											>
												<div>
													<Chip
														variant='outlined'
														size='small'
														label='SandBox'
														clickable
														color='#ffcc00'
														onDelete={''}
														deleteIcon={<DoneIcon />}
														style={{ marginBottom: '5px', fontSize: '10px', marginRight: '5px' }}
													/>

													<Chip
														size='small'
														icon={<FaceIcon />}
														label='Nodes'
														clickable
														color='#ffcc00'
														onDelete={''}
														deleteIcon={<DoneIcon />}
														style={{ marginBottom: '5px', fontSize: '10px', marginRight: '5px' }}
													/>

													<Chip
														variant='outlined'
														size='small'
														label='Replica Set'
														clickable
														color='#ffcc00'
														onDelete={''}
														deleteIcon={<DoneIcon />}
														style={{ marginBottom: '5px', fontSize: '10px', marginRight: '5px' }}
													/>
												</div>

												<p style={{ fontSize: '10px', fontWeight: 'bold', marginRight: '13%' }}>
													{props.data.data ? props.data.data.developerAccount.businessName : ''}
												</p>
											</Grid>

											<Grid container spacing={0}>
												<TabPanel value={value} index={0} style={{ width: '100%' }}>
													<Grid container item xs={12} sm={12} md={4} lg={4}>
														<Grid
															style={{
																padding: '15px',
																borderRight: '2px solid #F0F0F0',
																borderLeft: '2px solid #F0F0F0',
																borderBottom: '2px solid #F0F0F0',
																borderRadius: '4px',
															}}
														>
															<CreateDeveloperAccountCard {...props} />
														</Grid>
													</Grid>
													<Grid container item xs={12} sm={12} md={12} lg={12} style={{ margin: '20px' }}>
														<div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
															<div style={{ display: 'flex', paddingBottom: '20px', width: '100%' }}>
																<Grid
																	style={{
																		width: '100%',
																		padding: '10px',
																		marginRight: '10px',
																		border: '2px solid #F0F0F0',
																		borderRadius: '4px',
																	}}
																>
																	<br />
																	<br />
																	<br />
																	<br />
																	<br />
																</Grid>
																<Grid
																	style={{
																		width: '100%',
																		padding: '10px',
																		border: '2px solid #F0F0F0',
																		borderRadius: '4px',
																	}}
																>
																	<br />
																	<br />
																	<br />
																	<br />
																	<br />
																</Grid>
															</div>

															<div style={{ display: 'flex', paddingBottom: '20px', width: '100%' }}>
																<Grid
																	style={{
																		width: '100%',
																		padding: '10px',
																		marginRight: '10px',
																		border: '2px solid #F0F0F0',
																		borderRadius: '4px',
																	}}
																>
																	<br />
																	<br />
																	<br />
																	<br />
																	<br />
																</Grid>
																<Grid
																	style={{
																		width: '100%',
																		padding: '10px',
																		border: '2px solid #F0F0F0',
																		borderRadius: '4px',
																	}}
																>
																	<br />
																	<br />
																	<br />
																	<br />
																	<br />
																</Grid>
															</div>
														</div>
													</Grid>
												</TabPanel>

												<TabPanel value={value} index={1}></TabPanel>

												<Grid item xs={12} sm={12} md={12} lg={12}>
													<TabPanel value={value} index={2}>
														<h3 style={{ marginBottom: '25px', color: 'gray' }}>API AND KEYS: </h3>
														<DeveloperKeys {...props} />
													</TabPanel>
												</Grid>

												<TabPanel value={value} index={3}>
													<h3 style={{ marginBottom: '25px', color: 'gray' }}>BUSINESS DOCUMENTS: </h3>
													<div style={{ display: 'flex', paddingBottom: '20px', width: '100%' }}>
														<Grid
															item
															xs={12}
															sm={12}
															md={4}
															lg={4}
															style={{
																width: '100%',
																padding: '20px',
																marginRight: '10px',
																border: '2px solid #F0F0F0',
																borderRadius: '4px',
																textAlign: 'center',
															}}
														>
															<p style={{ fontSize: '10px', fontWeight: 'bold', marginBottom: '20px', color: 'gray' }}>
																Want to Upload Business Registration Document?
															</p>
															{props.devDoc.businessRegistrationDocuments ? (
																<div className={classes.imgWrapper}>
																	<img src={completeUrl} alt='business-doc' className={classes.imgStyle} alt='upload' />
																</div>
															) : (
																<img
																	src={uploadImg}
																	style={{ width: '100px', height: '80px', paddingTop: '10px' }}
																	alt='upload'
																/>
															)}
															<BusinessRegistrationDocument {...props} />
														</Grid>

														<Grid
															item
															xs={12}
															sm={12}
															md={4}
															lg={4}
															style={{
																width: '100%',
																padding: '20px',
																marginRight: '10px',
																border: '2px solid #F0F0F0',
																borderRadius: '4px',
																textAlign: 'center',
															}}
														>
															<p style={{ fontSize: '10px', fontWeight: 'bold', marginBottom: '20px', color: 'gray' }}>
																Want to Upload Utility Bill Document ?
															</p>
															{props.devDoc.utilityBillDocuments ? (
																<div className={classes.imgWrapper}>
																	<img src={completeUrl3} alt='utility-doc' className={classes.imgStyle} alt='upload' />
																</div>
															) : (
																<img
																	src={uploadImg}
																	style={{ width: '100px', height: '80px', paddingTop: '10px' }}
																	alt='upload'
																/>
															)}

															<UtilityDocument {...props} />
														</Grid>

														<Grid
															item
															xs={12}
															sm={12}
															md={4}
															lg={4}
															style={{
																width: '100%',
																padding: '20px',
																border: '2px solid #F0F0F0',
																borderRadius: '4px',
																textAlign: 'center',
															}}
														>
															<p style={{ fontSize: '10px', fontWeight: 'bold', marginBottom: '20px', color: 'gray' }}>
																Want to Upload Tax Document ?
															</p>
															{props.devDoc.taxDocuments ? (
																<div className={classes.imgWrapper}>
																	<img src={completeUrl2} alt='address-doc' className={classes.imgStyle} alt='upload' />
																</div>
															) : (
																<img
																	src={uploadImg}
																	style={{ width: '100px', height: '80px', paddingTop: '10px' }}
																	alt='upload'
																/>
															)}

															<TaxDocument {...props} />
														</Grid>

													</div>
												</TabPanel>
												{/* </Grid> */}

												<TabPanel value={value} index={4} >
													
													{/* <h2 className='history'>Cards</h2> */}
												</TabPanel>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Container>
						</Grid>
					</Grid>
					<BottomNav {...props} />
					<Footer />
				</main>
			</MainContent>
		</>
	);
}

const mapStateToProps = (state) => {
	return {
		user: state.userDetails.data,
		data: state.getDevAccount.data,
		devKey: state.getDevKey.data,
		devDoc: state.getDevDoc.data,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		get_dev_account: (id) => dispatch(developer.get_dev_account(id)),
		get_dev_keys: (id, email) => dispatch(developer.get_dev_keys(id, email)),
		get_document: (m_id, email) => dispatch(developer.get_document(m_id, email)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DeveloperDashboard);
