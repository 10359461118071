import React, { useState } from 'react';
import { DropzoneDialog } from 'material-ui-dropzone';
import Button from '@material-ui/core/Button';
import developer from '../../services/DeveloperServices/developerService';
import { connect } from 'react-redux';
import BackDrop from '../BackDrop';

function BusinessAddressDocument(props) {
	const [state, setState] = useState({
		open: false,
		files: [],
	});

	const email = props.data.data ? props.data.data.developerAccount.businessSupportEmail : '';
	const m_id = props.data.data ? props.data.data.developerAccount.merchantId : '';

	function handleClose() {
		setState({
			open: false,
		});
	}

	function handleSave(files) {
		const fil = files ? files[0] : '';
		setState({
			open: false,
		});
		if (fil) {
			props.upload_address_document(email, fil).then(() => {
				props.get_document(m_id, email);
			});
		}
	}

	function handleOpen() {
		setState({ open: true });
	}

	return (
		<div>
			{props.isLoading && <BackDrop />}
			<Button onClick={handleOpen} style={{ border: '2px solid #F0F0F0', borderRadius: '10px' }}>
				<span style={{ fontSize: '10px' }}> Add Tax Indentification Document</span>
			</Button>
			<DropzoneDialog
				open={state.open}
				onSave={handleSave}
				acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
				showPreviews={true}
				uploadMultiple={false}
				maxFileSize={5000000}
				onClose={handleClose}
				filesLimit={1}
				showFileNames={true}
				showFileNamesInPreview={true}
			/>
		</div>
	);
}

const mapDispatchToProps = (dispatch) => {
	return {
		upload_address_document: (email, files) => dispatch(developer.upload_address_document(email, files)),
	};
};
const mapStateToProps = (state) => {
	return {
		isLoading: state.uploadDoc.isLoading,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessAddressDocument);
