import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import forgot from '../../services/forgotService';
import { useForm } from 'react-hook-form';
import '../../css/register.css';
import '../../css/formfield.css';

const useStyles = makeStyles((theme) => ({
	form: {
		marginTop: '30px',
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
}));

function ChangePassword(props) {
	const classes = useStyles();

	const { register, handleSubmit, watch, errors } = useForm({
		mode: 'onBlur',
	});
	const watchPassword = watch('new_password', '');

	const [password, setPassword] = useState({
		old_password: '',
		new_password: '',
		c_new_password: '',
	});

	const { old_password, new_password, c_new_password } = password;

	function handleChange(e) {
		const { name, value } = e.target;
		setPassword((password) => ({ ...password, [name]: value }));
	}

	function onSubmit(e) {
		if (old_password && new_password && c_new_password) {
			props.reset(old_password, new_password, c_new_password);
		}
	}

	return (
		<form className={classes.form} name='form' onSubmit={handleSubmit(onSubmit)}>
			<div>
				<label>Old password</label>
				<input
					type='password'
					name='old_password'
					className='input-div'
					onChange={handleChange}
					ref={register({
						required: 'Password length should be a Minimum of 6 characters',
						minLength: 6,
					})}
					style={{
						borderColor: errors.old_password && 'red',
						borderBottom: '1px solid lightgray',
						width: '100%',
						marginBottom: '8px',
					}}
				/>
				{errors.old_password && <p className='error'>{errors.old_password.message}</p>}
			</div>

			<div>
				<label>New password</label>
				<input
					type='password'
					name='new_password'
					className='input-div'
					onChange={handleChange}
					ref={register({
						required: 'Password length should be a Minimum of 6 characters',
						min: 6,
					})}
					style={{
						borderColor: errors.new_password && 'red',
						borderBottom: '1px solid lightgray',
						width: '100%',
						marginBottom: '8px',
					}}
				/>
				{errors.new_password && <p className='error'>{errors.new_password.message}</p>}
			</div>

			<div>
				<label>Repeat new password</label>
				<input
					type='password'
					name='c_new_password'
					onChange={handleChange}
					ref={register({
						validate: (value) => value === watchPassword || 'The passwords do not match',
					})}
					style={{
						borderColor: errors.c_new_password && 'red',
						borderBottom: '1px solid lightgray',
						width: '100%',
						marginBottom: '8px',
					}}
				/>
				{errors.c_new_password && <p className='error'>{errors.c_new_password.message}</p>}
			</div>

			<div item xs={12} sm={12} className='mag' style={{ margin: '10px 0' }}>
				<Button variant='contained' color='primary' className='settings-button' type='submit'>
					Save Changes
				</Button>
			</div>
		</form>
	);
}

const mapDispatchToProps = (dispatch) => {
	return {
		reset: (old_password, new_password, c_new_password) =>
			dispatch(forgot.reset_password(old_password, new_password, c_new_password)),
	};
};

export default connect(null, mapDispatchToProps)(ChangePassword);
