import React from "react";
import { Switch, Route } from "react-router-dom";
import BulkPayment from "./BulkPayment";
import Transfer from "./Transfer";
import TransferMultiple from "./TransferMultiple";

export default function TransferRoot({ match }) {
  return (
    <div>
      <Switch>
        <Route exact path={`${match.url}/`} component={Transfer} />
        <Route exact path={`${match.url}/multiple`} component={TransferMultiple} />
        <Route exact path={`${match.url}/file-upload`} component={BulkPayment} />
      </Switch>
    </div>
  );
}
