import React from 'react';
import OfflineHeader from './components/OfflineHeader';
import { Button, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';

function ErrorPage() {

    return (
        <div className='error-page'>
            <OfflineHeader />

            <div >
                <div >
                    <img
                        src="images/404.svg"
                        alt="logo"
                        className="error-page-image"
                    />
                </div>
                <h2>Hey awesome, seems you missed your way, or you're not logged in</h2>
                <h4>I can be your guide to the right path </h4>

                <Grid container>
                    <Grid item lg={4}></Grid>
                    <Grid item lg={4}>
                        <Grid container spacing={3}>
                            <Grid item lg={4}>
                                <Button component={Link} to='/' variant="contained" size="large" fullWidth className='offline-button'>LOGIN</Button>
                            </Grid>
                            <Grid item lg={4}>
                                <Button component={Link} to='/register' variant="contained" size="large" fullWidth className='offline-button'>REGISTER</Button>
                            </Grid>
                            <Grid item lg={4}>
                                <Button component={Link} to='/dashboard' variant="contained" size="large" fullWidth className='offline-button'>DASHBOARD</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={4}></Grid>

                </Grid>





            </div>

        </div>

    );
}

export default ErrorPage;