import { userConstants } from '../actions/types'

const initialState = {
    isTogglingNotifications: false,
    data: '',
}
 
function notificationToggle(state = initialState, action) {
    switch (action.type) {
        case userConstants.NOTIFICATION_TOGGLE_REQUEST:
            return {
                ...state,
                isTogglingNotifications: true,
            
            };
        case userConstants.NOTIFICATION_TOGGLE_SUCCESS:
            return {
                ...state,
                isTogglingNotifications: false,
                data: action.payload, 
            };
        default:
            return state
    }
}

export default notificationToggle;