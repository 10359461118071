import { userConstants } from "../actions/types";

const initialState = {
  isGettingLimit: false,
  data: [],
};

function transactionLimit(state = initialState, action) {
  switch (action.type) {
    case userConstants.GET_TRANS_LIMIT_REQUEST:
      return {
        ...state,
        isGettingLimit: true,
      };
    case userConstants.GET_TRANS_LIMIT_SUCCESS:
      return {
        ...state,
        isGettingLimit: false,
        data: action.payload,
      };
    default:
      return state;
  }
}

export default transactionLimit;
