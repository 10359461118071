import axios from "axios";
import { toast, Flip } from "react-toastify";
import config from "../config";
import { userActions } from "../actions/creators.js";
import { history } from "../store/history";

const baseUrl = `${config.apiUrl}`;

const verify = {
  // Phone-Number Verification
  verify_phone: (token, register_token) => {
    return async (dispatch) => {
      dispatch(userActions.uiRequesting());

      await axios
        .get(
          `${baseUrl}/user/users/${register_token}/verify_token_phone/${token}`,
          { token, register_token }
        )
        .then((res) => {
          dispatch(userActions.uiResponded());

          if (res.data.response_message === "Account created successfully") {
            toast.success("Success", {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
            history.push("/security-question");
          }
        })
        .catch((error) => {
          dispatch(userActions.uiResponded());

          if (error.response.data.response_code === "USM013") {
            toast.error("Token has expired, please resend", {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
          } else if (
            error.response.data.response_message === "Phone Verified"
          ) {
            toast.error("Phone has already been verified, please login", {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
            history.push("/");
          }
        });
    };
  },

  // Email Verification
  verify_email: (register_token) => {
    return async (dispatch) => {
      dispatch(userActions.uiRequesting());

      await axios
        .get(`${baseUrl}/user/users/${register_token}/verify_token_email`)
        .then((res) => {
          dispatch(userActions.uiResponded());

          if (res.data.success === true) {
            toast.success("E-mail Verification Successful, Please Login", {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
            history.push("/");
          }
        })
        .catch((error) => {
          dispatch(userActions.uiResponded());

          if (error.response.data.response_code === "USM013") {
            toast.error("Token has expired, please resend", {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
          } else if (
            error.response.data.response_message === "Email Verified"
          ) {
            toast.error("Email has already been verified, please login", {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
            history.push("/");
          }
        });
    };
  },

  // Login verification
  verify_login: (token) => {
    return async (dispatch) => {
      dispatch(userActions.verifyLoginRequest());

      const email = localStorage.getItem("email");
      const register_token = localStorage.getItem("register_token");

      await axios
        .post(`${baseUrl}/user/users/login-web-token-verify/${token}`, {
          token,
          email,
          register_token,
        })
        .then((res) => {
          dispatch(userActions.verifyLoginSuccess(res.data.data));

          localStorage.setItem("user_id", res.data.data.id);
          localStorage.setItem("token", res.data.data.token);
          localStorage.setItem(
            "account",
            res.data.data.bank_account[0].account_number
          );
          localStorage.setItem("kyc", res.data.data.kyc_level);

          if (res.data.response_code === "USM003") {
            toast.success("Login Successful", {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
            history.push("/dashboard");
            window.location.reload(true);
          }
        })
        .catch((error) => {
          dispatch(userActions.uiResponded());

          if (error.response.data.response_code === "USM008") {
            toast.error("Token has expired, please login again", {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
            history.push("/");
          } else if (error.response.data.response_code === "USM009") {
            toast.error("Invalid Login code", {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
              autoClose: 5000,
            });
            //  window.location.reload(true)
          }
        });
    };
  },

  // Login verification
  verify_security_login: (answer_id, answer) => {
    return async (dispatch) => {
      dispatch(userActions.loginSecurityQuestionRequest());

      const register_token = localStorage.getItem("register_token");
      const email = localStorage.getItem("email");
      const user_id = localStorage.getItem("user_id");

      const path = sessionStorage.getItem("history");

      await axios
        .post(`${baseUrl}/user/users/login-web-question-answer-verify`, {
          register_token,
          email,
          user_id,
          answer_id,
          answer,
        })
        .then((res) => {
          dispatch(userActions.loginSecurityQuestionSuccess());
          dispatch(userActions.verifyLoginSuccess(res.data.data));
          localStorage.setItem("user_id", res.data.data.id);
          localStorage.setItem("token", res.data.data.token);
          localStorage.setItem(
            "account",
            res.data.data.bank_account[0].account_number
          );
          localStorage.setItem("kyc", res.data.data.kyc_level);
          dispatch(
            userActions.switchActiveAccount({
              value: res.data?.data?.bank_account[0]?.account_number,
              label: res.data?.data?.bank_account[0]?.account_number,
              name: res.data?.data?.bank_account[0]?.account_name,
            })
          );

          if (res.data.response_code === "USM003") {
            toast.success("Login Successful", {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });

            if (path) {
              history.push(path);
            } else {
              history.push("/dashboard");
            }
          }
        })
        .catch((error) => {
          dispatch(
            userActions.loginSecurityQuestionFailure(
              error.response.data.response_message
            )
          );
          dispatch(userActions.loginClear());
          if (error.response.data.response_code === "USM008") {
            toast.error("Token has expired, please login again", {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
            history.push("/");
          } else if (error.response.data.response_code === "USM009") {
            toast.error("Invalid Login code", {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
              autoClose: 5000,
            });
          } else if (error.response.data.response_code === "USM026") {
            toast.error("Incorrect Answer. Please answer the next question", {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
              autoClose: 5000,
            });
          }
        });
    };
  },

  logout: () => {
    return (dispatch) => {
      localStorage.clear();
      dispatch(userActions.logout());
    };
  },

  verify_register_bvn_otp: (trans_ref, otp_code, channel) => {
    return async (dispatch) => {
      dispatch(userActions.validateRegisterBvnOtpRequest());

      await axios
        .post(`${baseUrl}/user/register/validate-bvn-otp`, {
          trans_ref,
          otp_code,
          channel,
        })
        .then((res) => {
          dispatch(userActions.validateRegisterBvnOtpSuccess(res.data.success));
        })
        .catch((error) => {
          dispatch(userActions.validateRegisterBvnOtpFailure());
          if (error.response.data.response_code === "USM021") {
            toast.error("Something went wrong while validating bvn", {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
          } else if (error.response.data.response_code === "USM023") {
            toast.error("User already exists", {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
          } else if (error.response.data.response_code === "USM020") {
            toast.error("BVN validation failed", {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
          }
        });
    };
  },

  verify_bvn_registration: (payload) => {
    return async (dispatch) => {
      dispatch(userActions.validateRegisterBvnRequest());

      await axios
        .post(`${baseUrl}/user/register/with-bvn`, payload)
        .then((res) => {
          dispatch(userActions.validateRegisterBvnSuccess());
          history.push("/");
          toast.success("Registration successful. Please login.", {
            position: toast.POSITION.TOP_RIGHT,
            transition: Flip,
          });
        })
        .catch((error) => {
          dispatch(userActions.validateRegisterBvnFailure());

          if (error.response.data.response_code === "USM024") {
            toast.error(
              "Something went wrong while generating user account. Please register again",
              {
                position: toast.POSITION.TOP_RIGHT,
                transition: Flip,
              }
            );
          } else if (error.response.data.response_code === "USM023") {
            toast.error("User already exists", {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
              autoClose: 5000,
            });
          }
        });
    };
  },
};

export default verify;
