import axios from "axios";
import { userActions } from "../actions/creators";
import config from "../config";
import swal from "sweetalert";
import { history } from "../store/history";

const baseUrl = `${config.apiUrl}`;

//To  validate a bulk payment
const bulk = {
  validate_bulk: (data, file) => {
    return async (dispatch) => {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("user_id", data.user_id);
      formData.append("batch_name", data.batch_name);
      formData.append("account_number", data.account_number);
      formData.append("bank_code", data.bank_code);
      formData.append("bank_name", data.bank_name);
      formData.append("account_name", data.account_name);
      formData.append("validation_type", data.validation_type);
      formData.append("transaction_pin", data.transaction_pin);

      dispatch(userActions.validateBulkPaymentRequest());
      await axios
        .post(`${baseUrl}/bulk/validate_bulk_payment`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          dispatch(userActions.validateBulkPaymentSuccess(res.data.data));
        })
        .catch((error) => {
          dispatch(userActions.validateBulkPaymentSuccess());
          swal("Oh oh!", error.response?.data?.response_message, "error");
        });
    };
  },

  validate_manual: (manual, beneficiary) => {
    const validate = {
      ...manual,
      beneficiary,
    };
    return async (dispatch) => {
      dispatch(userActions.validateBulkPaymentRequest());
      await axios
        .post(`${baseUrl}/bulk/validate_bulk_payment`, validate)
        .then((res) => {
          dispatch(userActions.validateBulkPaymentSuccess(res.data.data));
        })
        .catch((error) => {
          dispatch(userActions.validateBulkPaymentSuccess());
          swal("Oh oh!", error.response?.data?.response_message, "error");
        });
    };
  },

  process_payment: (user_id, batch_code, transaction_pin) => {
    return async (dispatch) => {
      dispatch(userActions.processBulkPaymentRequest());
      await axios
        .post(`${baseUrl}/bulk/process_payment`, {
          user_id,
          batch_code,
          transaction_pin,
        })
        .then((res) => {
          dispatch(userActions.processBulkPaymentSuccess(res.data.data));
          swal("Great!", res.data?.response_message || "Success", "success");
          setTimeout(() => {
            history.push("/dashboard/account-overview/bulk-transactions");
          }, 2000);
        })
        .catch((error) => {
          dispatch(userActions.processBulkPaymentSuccess());
          swal(
            "Oh oh!",
            error.response?.data?.response_message || "Something went wrong",
            "error"
          );
        });
    };
  },

  bulk_history: (user_id, selected) => {
    return async (dispatch) => {
      dispatch(userActions.getBulkHistoryRequest());
      await axios
        .get(
          `${baseUrl}/bulk/user/bulk_transactions_batches/${user_id}?page=${selected} `
        )
        .then((res) => {
          dispatch(userActions.getBulkHistorySuccess(res.data.data));
        })
        .catch((error) => {
          dispatch(userActions.getBulkHistorySuccess());
        });
    };
  },

  bulk_details: (batch_id, selected) => {
    return async (dispatch) => {
      dispatch(userActions.getBulkDetailsRequest());
      await axios
        .get(
          `${baseUrl}/bulk/all_bulk_transactions_batch_datas/${batch_id}?page=${selected} `
        )
        .then((res) => {
          dispatch(userActions.getBulkDetailsSuccess(res.data.data));
        })
        .catch((error) => {
          dispatch(userActions.getBulkDetailsSuccess());
        });
    };
  },
};

export default bulk;
