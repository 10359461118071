import response from "../reducers/responseReducer";
import loginUser from "../reducers/loginUserReducer";
import verify from "../reducers/authenticationReducer";
import fetch from "../reducers/transferReducer";
import validate from "../reducers/validateReducer";
import validateReferral from "../reducers/validateReferralReducer";
import transfer from "../reducers/finalTransferReducer";
import verifyLogin from "../reducers/verifyLoginReducer";
import getBillers from "../reducers/getBillersReducer";
import getBillerItem from "../reducers/getBillerItemReducer";
import getBillerProduct from "../reducers/getBillerProductReducer";
import checkLoanEligibility from "../reducers/checkLoanEligibility";
import calculateLoan from "../reducers/calculateLoanReducer";
import transactionCount from "../reducers/transactionCountReducer";
import frequentTransaction from "../reducers/frequentTransactionReducer";
import transactionLimit from "../reducers/transactionLimitReducer";
import transactionFee from "../reducers/transactionFeeReducer";
import getAccountBalance from "../reducers/accountBalanceReducer";
import verifyBvn from "../reducers/verifyBvnReducer";
import verifyBvnOtp from "../reducers/verifyBvnOtpReducer";
import saveBeneficiary from "../reducers/saveBeneficiaryReducer";
import getBeneficiary from "../reducers/getBeneficiaryReducer";
import deleteBeneficiary from "../reducers/deleteBeneficiaryReducer";
import validateBill from "../reducers/validateBillReducer";
import payBill from "../reducers/payBillReducer";
import getLoanHistory from "../reducers/getLoanHistoryReducer";
import registerBvn from "../reducers/registerBvnReducer";
import validateRegisterBvnOtp from "../reducers/validateRegisterBvnOtpReducer";
import verifyRegisterBvn from "../reducers/verifyRegisterBvnReducer";
import loginSecurityQuestion from "../reducers/loginSecurityQuestionReducer";
import getAllNotifications from "../reducers/getAllNotificationReducer";
import notificationToggle from "../reducers/notificationToggleReducer";
import accountStatement from "../reducers/getAccountStatementReducer";
import getPhysicalCard from "../reducers/getPhysicalCardReducer";
import getCardStatus from "../reducers/cardStatusReducer";
import upgradeAccount from "../reducers/upgradeAccountReducer";
import getVirtualCard from "../reducers/virtualCardReducer";
import virtualTrans from "../reducers/virtualCardTransactionReducer";
import createDevAccount from "../reducers/DeveloperReducers/createDevAccountReducer";
import getDevAccount from "../reducers/DeveloperReducers/getDevAccountReducer";
import getDevKey from "../reducers/DeveloperReducers/getDevKeyReducer";
import uploadDoc from "../reducers/DeveloperReducers/uploadDevDocReducer";
import getDevDoc from "../reducers/DeveloperReducers/getDevDocReducer";
import confirmPin from "../reducers/confirmPinReducer";
import {
  loyaltyReducer,
  chargebackReducer,
  chargebackConfigReducer,
  chargebackValueReducer,
} from "../reducers/loyaltyReducer";
import getSavingsProduct from "../reducers/getSavingsProductReducer";
import listUserSavings from "../reducers/listUserSavingsReducer";
import getSavingsProductDetail from "../reducers/savingsProductDetailReducer";
import createSavingsPlan from "../reducers/createSavingsPlanReducer";
import walletBalance from "../reducers/walletBlanceReducer";
import savingsSummary from "../reducers/savingsSummaryReducer";
import savingsHistory from "../reducers/savingsHistoryReducer";
import fundSavings from "../reducers/fundSavingsReducer";
import withdrawSavings from "../reducers/withdrawSavingsReducer";
import changeWithdrawalDate from "../reducers/changeWithdrawalDateReducer";
import showWithdrawalDate from "../reducers/showWithdrawalDateReducer";
import createGroupSavings from "../reducers/createGroupSavingsReducer";
import getUserGroupSavings from "../reducers/getUserGroupSavingsReducer";
import allSavingsGroup from "../reducers/allSavingsGroupReducer";
import savingsGroupDetail from "../reducers/savingsGroupDetailReducer";
import joinGroupSavings from "../reducers/joinGroupSavingsReducer";
import viewGroupInfo from "./viewGroupInfoReducer";
import groupWallet from "../reducers/groupWalletReducer";
import inviteToGroup from "../reducers/inviteToGroupReducer";
import getAuthorize from "../reducers/getAuthorizeReducer";
import savingsNotifications from "../reducers/savingsNotificationsReducer";
import walletTransactions from "../reducers/walletTransactionsReducer";
import availableWalletTransactions from "../reducers/availbaleWalletTransactionsReducer";
import getInactiveSavings from "../reducers/inactiveSavingsReducer";
import {
  getQrMerchantSubmerchant,
  createQrMerchantSubmerchant,
  createSubmerchant,
} from "./qrReducer";
import {
  validateBulkPayment,
  processBulkPayment,
  bulkPaymentHistory,
  bulkPaymentDetails,
} from "./bulkPaymentReducer";
import { getNewAccount, submitNewAccount } from "./accountReducer";
import { getEarnings, getInterestHistory } from "./interestReducer";
import activeAccount from "./activeAccountReducer";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["userDetails", "activeAccount"],
};

const rootReducer = combineReducers({
  response,
  loginUser,
  verify,
  userDetails: verifyLogin,
  fetch,
  validate,
  validateReferral,
  transfer,
  getBillers,
  getBillerItem,
  getBillerProduct,
  checkLoanEligibility,
  calculateLoan,
  transactionCount,
  transactionFee,
  frequentTransaction,
  transactionLimit,
  getAccountBalance,
  validateBill,
  payBill,
  verifyBvn,
  verifyBvnOtp,
  saveBeneficiary,
  getBeneficiary,
  deleteBeneficiary,
  getLoanHistory,
  registerBvn,
  validateRegisterBvnOtp,
  verifyRegisterBvn,
  loginSecurityQuestion,
  getAllNotifications,
  notificationToggle,
  accountStatement,
  getPhysicalCard,
  getCardStatus,
  upgradeAccount,
  getVirtualCard,
  virtualTrans,
  createDevAccount,
  getDevAccount,
  getDevKey,
  uploadDoc,
  getDevDoc,
  confirmPin,
  loyaltyReducer,
  chargebackReducer,
  chargebackValueReducer,
  chargebackConfigReducer,
  getSavingsProduct,
  listUserSavings,
  getSavingsProductDetail,
  createSavingsPlan,
  walletBalance,
  savingsSummary,
  savingsHistory,
  fundSavings,
  withdrawSavings,
  changeWithdrawalDate,
  showWithdrawalDate,
  createGroupSavings,
  getUserGroupSavings,
  allSavingsGroup,
  savingsGroupDetail,
  joinGroupSavings,
  viewGroupInfo,
  groupWallet,
  inviteToGroup,
  getAuthorize,
  savingsNotifications,
  walletTransactions,
  availableWalletTransactions,
  getInactiveSavings,
  getQrMerchantSubmerchant,
  createQrMerchantSubmerchant,
  createSubmerchant,
  validateBulkPayment,
  processBulkPayment,
  bulkPaymentHistory,
  bulkPaymentDetails,
  activeAccount,
  getNewAccount,
  submitNewAccount,
  getEarnings,
  getInterestHistory,
});

export default persistReducer(persistConfig, rootReducer);
