import React, { useState } from "react";
import { Grid, Button } from "@material-ui/core";
import CreateSubMerchantModal from "./components/CreateSubMerchantModal";

export const MerchantProfile = ({ merchant }) => {
  const [openCreate, setOpenCreate] = useState(false);
  const merc = merchant[0];

  const handleOpenCreate = () => {
    setOpenCreate(true);
  };

  const handleCloseCreate = () => {
    setOpenCreate(false);
  };

  return (
    <div>
      <Grid container>
        <Button
          variant="contained"
          color="primary"
          className="merchant-small-button"
          onClick={handleOpenCreate}
        >
          Create new sub merchant
        </Button>
        <CreateSubMerchantModal
          open={openCreate}
          close={handleCloseCreate}
          mch_no={merc?.mach_no}
        />
      </Grid>
      <Grid container spacing={6}>
        <Grid item lg={5}>
          <div className="merchant-box">
            <Grid container>
              <Grid item lg={6} sm={6} xs={6}>
                <p>Merchant Number</p>
                <p>Merchant Name</p>
                <p>Merchant E-mail</p>
                <p>Merchant phone no</p>
                <p>Merchant Tin</p>
                <p>Date Created</p>
                <p>Total Sub Merchants</p>
              </Grid>
              <Grid item lg={6} sm={6} xs={6}>
                <p>{merc?.mach_no}</p>
                <p> {merc?.merchant_name}</p>
                <p> {merc?.merchant_email}</p>
                <p>{merc?.merchant_phone_number}</p>
                <p>{merc?.merchant_tin}</p>
                <p>{new Date(merc?.created_at).toLocaleString()}</p>
                <p>{merc?.submerchant.length}</p>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item lg={7}>
          <div className="merchant-box2">
            <table>
              <tr>
                <th>Sub merchant Name</th>
                <th>Sub merchant Number</th>
                <th>Date created</th>
              </tr>
              {merc?.submerchant && (
                <>
                  {merc?.submerchant.map((res, i) => {
                    return (
                      <tr>
                        <td>{res.sub_name}</td>
                        <td>{res.sub_mch_no}</td>
                        <td>{new Date(res?.created_at).toLocaleString()}</td>
                      </tr>
                    );
                  })}
                </>
              )}
            </table>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
