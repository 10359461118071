import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import security from '../../../services/securityServices';
import updateUser from "../../../services/userObjectUpdate";
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
    form: {
        marginTop: '30px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));

function SetPin(props) {
    const classes = useStyles();
    const token = props.user.token;
    const [transaction, setTransaction] = useState({
        transaction_pin: '',
        c_transaction_pin: '',
        password: '',
    });
    const { transaction_pin, password } = transaction;

    const { register, handleSubmit, watch, errors } = useForm({
        mode: 'onBlur',
    });

    const watchTransactionPin = watch('transaction_pin', '');

    function handleChange(e) {
        const { name, value } = e.target;
        setTransaction((transaction) => ({ ...transaction, [name]: value }));
    }

    function onSubmit(e) {
        setTransaction({
            transaction_pin: '',
            c_transaction_pin: '',
            password: '',
        });
        if (transaction_pin && password) {
            props.pin(transaction_pin, password, token).then(() => {
                props.update_user_object();
            });
        }
    }

    function checkLength() {
        if (transaction_pin.length !== 4) {
            toast.error('Your pin must be 4 digits')
        }
    }

    return (
        <form className={classes.form} name='form' onSubmit={handleSubmit(onSubmit)}>
            <div>
                <Dialog
                    open={props.user.transaction_pin === null}
                    // onClose={() => setIdleModal(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <Link to='dashboard' style={{ textAlign: 'right' }}>
                        Go to dashboard
                       </Link>
                    <DialogTitle id="alert-dialog-title" style={{ color: "red" }}>
                        TRANSACTION PIN
                    </DialogTitle>

                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Set transcation pin before proceeding with your transaction
                    </DialogContentText>

                        <div>
                            <label>Enter pin</label>
                            <input
                                type='number'
                                name='transaction_pin'
                                className='input-div'
                                //value={transaction_pin}
                                onBlur={checkLength}
                                onChange={handleChange}
                                ref={register({
                                    required: 'Maximum of 4 characters',
                                    min: 4,
                                    maxLength: 4
                                })}
                                style={{
                                    borderColor: errors.transaction_pin && 'red',
                                    borderBottom: '1px solid lightgray',
                                    width: '100%',
                                    marginBottom: '8px',
                                }}
                            />
                            {errors.transaction_pin && <p className='error'>{errors.transaction_pin.message}</p>}
                        </div>

                        <div>
                            <label>Confirm pin</label>
                            <input
                                type='number'
                                name='c_transaction_pin'
                                className='input-div'
                                //value={c_transaction_pin}
                                onChange={handleChange}
                                ref={register({
                                    validate: (value) => value === watchTransactionPin || 'Transaction pin does not match',
                                })}
                                style={{
                                    borderColor: errors.c_transaction_pin && 'red',
                                    borderBottom: '1px solid lightgray',
                                    width: '100%',
                                    marginBottom: '8px',
                                }}
                            />
                            {errors.c_transaction_pin && <p className='error'>{errors.c_transaction_pin.message}</p>}
                        </div>
                        <div>
                            <label>Confirm your password</label>
                            <input
                                type='password'
                                name='password'
                                className='input-div'
                                //value={password}
                                onChange={handleChange}
                                ref={register({
                                    required: 'Minimum of 6 characters required',
                                    min: 6,
                                })}
                                style={{
                                    borderColor: errors.password && 'red',
                                    borderBottom: '1px solid lightgray',
                                    width: '100%',
                                    marginBottom: '8px',
                                }}
                            />
                            {errors.password && <p className='error'>{errors.password.message}</p>}
                        </div>

                    </DialogContent>

                    <DialogActions>
                        <Button
                            onClick={handleSubmit(onSubmit)}
                            type='submit'
                            variant="contained"
                            size="small"
                            style={{
                                backgroundColor: "#0037ba",
                                color: "white",
                                width: "100%",
                                height: "30px",
                                marginBottom: "10px",
                                borderRadius: "20px",
                                border: "2px solid #0037ba",
                                boxShadow: "none",
                            }}
                        >
                            Set Pin
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </form>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        pin: (transaction_pin, password, token) => dispatch(security.setTransactionPin(transaction_pin, password, token)),
        update_user_object: (payload) => dispatch(updateUser.update_user_object(payload))
    };
};

const mapStateToProps = (state) => {
    return {
        user: state.userDetails.data
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SetPin);
