import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Grid, Paper, Button } from "@material-ui/core";

function LoansCard(props) {
  const loanHistory = props.loans.slice(props.loans.length - 2);

  return (
    <Grid item xs={12} sm={12} md={4} lg={4}>
      <Paper className="dashboardBox1">
        <div>
          <div className="for-inline-items">
            <div>
              <Grid
                component={Link}
                to="/dashboard/account-overview"
                className="e-cash"
              >
                {" "}
                Most recent loans{" "}
              </Grid>
            </div>
          </div>

          {props.loans.length === 0 ? (
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className="mag">
                <img
                  src="/images/loan-icon.svg"
                  alt="logo"
                  className="loan-img"
                />
              </div>
              <div className="mag">
                <p className="card-text-sm">
                  You have not requested for a <br /> loan yet
                </p>
              </div>
              <div className="mag">
                <Link to="/loan">
                  <Button
                    variant="contained"
                    color="primary"
                    className="loan-button"
                  >
                    Request for a Loan
                  </Button>
                </Link>
              </div>
            </Grid>
          ) : (
            <div className="for-inline-items">
              {loanHistory.map((loan, i) => (
                <div className="inner-card" key={i}>
                  <div>
                    <span
                      style={{
                        fontSize: "10px",
                        display: "block",
                        color: "blue",
                        fontWeight: "bold",
                      }}
                    >
                      Status
                    </span>
                    {loan.status_id === 1 ? (
                      <span
                        className="deb3"
                        style={{ display: "inline-block" }}
                      >
                        {(loan.status_id = "Pending")}
                      </span>
                    ) : loan.status_id === 2 ? (
                      <span
                        className="deb2"
                        style={{ display: "inline-block" }}
                      >
                        {(loan.status_id = "Approved")}
                      </span>
                    ) : loan.status_id === 3 ? (
                      <span
                        className="deb4"
                        style={{ display: "inline-block" }}
                      >
                        {(loan.status_id = "Authorized")}
                      </span>
                    ) : loan.status_id === 4 ? (
                      <span
                        className="deb1"
                        style={{ display: "inline-block" }}
                      >
                        {(loan.status_id = "Rejected")}
                      </span>
                    ) : loan.status_id === 5 ? (
                      <span
                        className="deb4"
                        style={{ display: "inline-block" }}
                      >
                        {(loan.status_id = "Running")}
                      </span>
                    ) : loan.status_id === 6 ? (
                      <span
                        className="deb2"
                        style={{ display: "inline-block" }}
                      >
                        {(loan.status_id = "Paid")}
                      </span>
                    ) : loan.status_id === 7 ? (
                      <span
                        className="deb1"
                        style={{ display: "inline-block" }}
                      >
                        {(loan.status_id = "Overdue")}
                      </span>
                    ) : loan.status_id === 8 ? (
                      <span
                        className="deb1"
                        style={{ display: "inline-block" }}
                      >
                        {(loan.status_id = "Paid Overdue")}
                      </span>
                    ) : (
                      <span
                        style={{ display: "inline-block" }}
                        className="deb1"
                      >
                        {loan.status_id}{" "}
                      </span>
                    )}
                  </div>
                  <div className="inner-card-text-bold">
                    <span className="inner-text-gray">
                      NGN{" "}
                      {Number(loan.total_amount_and_interest).toLocaleString()}
                    </span>
                    <br />
                    <span className="two">
                      Tenure: {loan.tenure} {loan.tenure_type}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </Paper>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    loans: state.getLoanHistory.data,
    isGettingLoanHistory: state.fetch.isGettingLoanHistory,
  };
};

export default connect(mapStateToProps, null)(LoansCard);
