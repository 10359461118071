import { userConstants } from "../actions/types";

const initialState = {
    isLoading: false,
    isCheckingStatus:false,
    data: '',
    status:[]
};

function upgradeAccount(state = initialState, action) {
    switch (action.type) {
        case userConstants.UPGRADE_ACCOUNT_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case userConstants.UPGRADE_ACCOUNT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            };
        case userConstants.UPGRADE_ACCOUNT_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        case userConstants.DOCUMENT_STATUS_REQUEST:
            return {
                ...state,
                isCheckingStatus: true,
            };
        case userConstants.DOCUMENT_STATUS_SUCCESS:
            return {
                ...state,
                isCheckingStatus: false,
                status: action.payload,
            };
        case userConstants.DOCUMENT_STATUS_FAILURE:
            return {
                ...state,
                isCheckingStatus: false,
            };
        default:
            return state;
    }
};

export default upgradeAccount;
