import React, { useState } from "react";
import { Chip, Grid, Paper } from "@material-ui/core";
import ChargebackDrawer from "./ChargebackDrawer";

const style = {
  position: "absolute",
  marginTop: "-10px",
  height: "20px",
};

function LoyaltyCard({ props }) {
  const [drawerActive, setDrawerActive] = useState(false);
  return (
    <Grid item xs={12} sm={12} md={4} lg={4}>
      {/* account details section */}
      <Chip label="New" style={style} color="secondary" />
      <Paper className="dashboardBox1">
        <div>
          <div className="for-inline-items">
            <p className="e-cash">
              {" "}
              Ref code:{" "}
              <span className="card-text-blue-large">
                {" "}
                {props.user.referral_id}
              </span>{" "}
            </p>
            <p className="e-cash">1 available point = 1 free transfer</p>
          </div>

          <div className="for-inline-items">
            <div className="inner-card inner-card-2">
              <p className="e-cash-2">Loyalty points</p>
              <div className="inner-card-text-bold inner-card-text-bold-2">
                <span className="inner-text-gray">
                  {props.loyalty === null
                    ? 0
                    : Number(props.loyalty.totalAvailablePoints).toFixed(1)}
                </span>

                <p className="two" style={{ marginTop: "-1px" }}>
                  Available
                </p>
              </div>
              <div className="inner-card-text-bold inner-card-text-bold-2">
                <span className="inner-text-gray">
                  {props.loyalty === null
                    ? 0
                    : Number(props.loyalty.totalEarnedPoints).toFixed(1)}
                </span>

                <p
                  className="two"
                  style={{ marginTop: "-1px", marginBottom: "-3px" }}
                >
                  Earned
                </p>
              </div>
            </div>
            <div className="inner-card inner-card-2">
              <p className="e-cash-2">Charge backs</p>
              <div className="inner-card-text-bold inner-card-text-bold-2">
                <span className="inner-text-gray">
                  &#8358;{" "}
                  {props.chargebackValue === "" || !props.chargebackValue
                    ? 0
                    : (props.chargebackValue?.total).toLocaleString()}
                </span>

                <p className="two" style={{ marginTop: "-1px" }}>
                  Earned in{" "}
                  <strong>
                    {new Date().toLocaleString("default", {
                      month: "long",
                      year: "numeric",
                    })}
                  </strong>
                </p>
              </div>

              <div className="inner-card-text-bold inner-card-text-bold-2">
                <p
                  className="e-cash cursor"
                  onClick={() => setDrawerActive(true)}
                >
                  View more details
                </p>
              </div>
            </div>
          </div>
        </div>
      </Paper>
      <ChargebackDrawer
        drawerActive={drawerActive}
        setDrawerActive={setDrawerActive}
        config={props.chargebackConfig}
        chargeback={props.chargeback}
        chargebackValue={props.chargebackValue}
      />
    </Grid>
  );
}

export default LoyaltyCard;
