import { developerConstants } from "../DeveloperActions/types";

export const developerActions = {
    createDeveloperAccountRequest,
    createDeveloperAccountSuccess,
    createDeveloperAccountFailure,
    getDeveloperAccountRequest,
    getDeveloperAccountSuccess,
    getDeveloperAccountFailure,
    getDeveloperKeyRequest,
    getDeveloperKeySuccess,
    getDeveloperKeyFailure,
    uploadDocumentRequest,
    uploadDocumentSuccess,
    uploadDocumentFailure,
    getDocumentRequest,
    getDocumentSuccess,
    getDocumentFailure
}


// Create a developer account
function createDeveloperAccountRequest() {
    return {
        type: developerConstants.CREATE_DEVELOPER_ACCOUNT_REQUEST,
    };
}

function createDeveloperAccountSuccess(data) {
    return {
        type: developerConstants.CREATE_DEVELOPER_ACCOUNT_SUCCESS,
        payload: data,
    };
}

function createDeveloperAccountFailure() {
    return {
        type: developerConstants.CREATE_DEVELOPER_ACCOUNT_FAILURE,
    };
}

// Get a developer account details
function getDeveloperAccountRequest() {
    return {
        type: developerConstants.GET_DEVELOPER_ACCOUNT_REQUEST,
    };
}

function getDeveloperAccountSuccess(data) {
    return {
        type: developerConstants.GET_DEVELOPER_ACCOUNT_SUCCESS,
        payload: data,
    };
}

function getDeveloperAccountFailure(data) {
    return {
        type: developerConstants.GET_DEVELOPER_ACCOUNT_FAILURE,
        payload: data,
    };
}


// Get a developer's key
function getDeveloperKeyRequest() {
    return {
        type: developerConstants.GET_DEVELOPER_KEY_REQUEST,
    };
}

function getDeveloperKeySuccess(data) {
    return {
        type: developerConstants.GET_DEVELOPER_KEY_SUCCESS,
        payload: data,
    };
}

function getDeveloperKeyFailure() {
    return {
        type: developerConstants.GET_DEVELOPER_KEY_FAILURE,
    };
}

// To upload a developer's document
function uploadDocumentRequest() {
    return {
        type: developerConstants.UPLOAD_DOCUMENT_REQUEST,
    };
}

function uploadDocumentSuccess(data) {
    return {
        type: developerConstants.UPLOAD_DOCUMENT_SUCCESS,
        payload: data,
    };
}

function uploadDocumentFailure() {
    return {
        type: developerConstants.UPLOAD_DOCUMENT_FAILURE,
    };
}


// To get/view a developer's document
function getDocumentRequest() {
    return {
        type: developerConstants.GET_DOCUMENT_REQUEST,
    };
}

function getDocumentSuccess(data) {
    return {
        type: developerConstants.GET_DOCUMENT_SUCCESS,
        payload: data,
    };
}

function getDocumentFailure() {
    return {
        type: developerConstants.GET_DOCUMENT_FAILURE,
    };
}