import axios from 'axios';
import { userActions } from "../actions/creators";
import config from '../config';
import headers from '../store/authHeader';

const baseUrl = `${config.apiUrl}`;

const notifications = {
    // Get listing of all user's transactions
    get_notifications: () => {
        return async dispatch => {
            dispatch(userActions.getAllNotificationRequest());

            const user_id = localStorage.getItem('user_id');

            await axios.get(`${baseUrl}/notification/user_notification_settings/${user_id}`, { "headers": headers })
                .then((res) => {
                    dispatch(userActions.getAllNotificationSuccess(res.data.data.userNotificationConfig));
                })
        }
    },

    // PROMOTIONS
    toggle_promotions_sms: () => {
        return async dispatch => {
            dispatch(userActions.notificationToggleRequest());

            const user_id = localStorage.getItem('user_id');

            await axios.get(`${baseUrl}/notification/toggle_user_notifications?id=${user_id}&notificationTypeCode=NT_003_PR&notificationChannelCode=NC_001_SM`, { "headers": headers })
                .then((res) => {
                    dispatch(userActions.notificationToggleSuccess(res.data));
                })
        }
    },

    toggle_promotions_email: () => {
        return async dispatch => {
            dispatch(userActions.notificationToggleRequest());

            const user_id = localStorage.getItem('user_id');

            await axios.get(`${baseUrl}/notification/toggle_user_notifications?id=${user_id}&notificationTypeCode=NT_003_PR&notificationChannelCode=NC_002_EM`, { "headers": headers })
                .then((res) => {
                    dispatch(userActions.notificationToggleSuccess(res.data));
                })
        }
    },

    toggle_promotions_push: () => {
        return async dispatch => {
            dispatch(userActions.notificationToggleRequest());

            const user_id = localStorage.getItem('user_id');

            await axios.get(`${baseUrl}/notification/toggle_user_notifications?id=${user_id}&notificationTypeCode=NT_003_PR&notificationChannelCode=NC_003_PS`, { "headers": headers })
                .then((res) => {
                    
                    dispatch(userActions.notificationToggleSuccess(res.data));
                })
        }
    },

    toggle_promotions_app: () => {
        return async dispatch => {
            dispatch(userActions.notificationToggleRequest());

            const user_id = localStorage.getItem('user_id');

            await axios.get(`${baseUrl}/notification/toggle_user_notifications?id=${user_id}&notificationTypeCode=NT_003_PR&notificationChannelCode=NC_004_IA`, { "headers": headers })
                .then((res) => {
                    
                    dispatch(userActions.notificationToggleSuccess(res.data));
                })
        }
    },
    // PROMOTIONS

    // IMPROTANT ACTION
    toggle_important_sms: () => {
        return async dispatch => {
            dispatch(userActions.notificationToggleRequest());

            const user_id = localStorage.getItem('user_id');

            await axios.get(`${baseUrl}/notification/toggle_user_notifications?id=${user_id}&notificationTypeCode=NT_006_IA&notificationChannelCode=NC_001_SM`, { "headers": headers })
                .then((res) => {
                    
                    dispatch(userActions.notificationToggleSuccess(res.data));
                })
        }
    },

    toggle_important_email: () => {
        return async dispatch => {
            dispatch(userActions.notificationToggleRequest());

            const user_id = localStorage.getItem('user_id');

            await axios.get(`${baseUrl}/notification/toggle_user_notifications?id=${user_id}&notificationTypeCode=NT_006_IA&notificationChannelCode=NC_002_EM`, { "headers": headers })
                .then((res) => {
                    
                    dispatch(userActions.notificationToggleSuccess(res.data));
                })
        }
    },

    toggle_important_push: () => {
        return async dispatch => {
            dispatch(userActions.notificationToggleRequest());

            const user_id = localStorage.getItem('user_id');

            await axios.get(`${baseUrl}/notification/toggle_user_notifications?id=${user_id}&notificationTypeCode=NT_006_IA&notificationChannelCode=NC_003_PS`, { "headers": headers })
                .then((res) => {
                    
                    dispatch(userActions.notificationToggleSuccess(res.data));
                })
        }
    },

    toggle_important_app: () => {
        return async dispatch => {
            dispatch(userActions.notificationToggleRequest());

            const user_id = localStorage.getItem('user_id');

            await axios.get(`${baseUrl}/notification/toggle_user_notifications?id=${user_id}&notificationTypeCode=NT_006_IA&notificationChannelCode=NC_004_IA`, { "headers": headers })
                .then((res) => {
                    
                    dispatch(userActions.notificationToggleSuccess(res.data));
                })
        }
    },
    // IMPORTANT ACTION



    //ALERT
    toggle_alert_sms: () => {
        return async dispatch => {
            dispatch(userActions.notificationToggleRequest());

            const user_id = localStorage.getItem('user_id');

            await axios.get(`${baseUrl}/notification/toggle_user_notifications?id=${user_id}&notificationTypeCode=NT_002_AL&notificationChannelCode=NC_001_SM`, { "headers": headers })
                .then((res) => {
                    
                    dispatch(userActions.notificationToggleSuccess(res.data));
                })
        }
    },

    toggle_alert_email: () => {
        return async dispatch => {
            dispatch(userActions.notificationToggleRequest());

            const user_id = localStorage.getItem('user_id');

            await axios.get(`${baseUrl}/notification/toggle_user_notifications?id=${user_id}&notificationTypeCode=NT_002_AL&notificationChannelCode=NC_002_EM`, { "headers": headers })
                .then((res) => {
                    
                    dispatch(userActions.notificationToggleSuccess(res.data));
                })
        }
    },

    toggle_alert_push: () => {
        return async dispatch => {
            dispatch(userActions.notificationToggleRequest());

            const user_id = localStorage.getItem('user_id');

            await axios.get(`${baseUrl}/notification/toggle_user_notifications?id=${user_id}&notificationTypeCode=NT_002_AL&notificationChannelCode=NC_003_PS`, { "headers": headers })
                .then((res) => {
                    
                    dispatch(userActions.notificationToggleSuccess(res.data));
                })
        }
    },

    toggle_alert_app: () => {
        return async dispatch => {
            dispatch(userActions.notificationToggleRequest());

            const user_id = localStorage.getItem('user_id');

            await axios.get(`${baseUrl}/notification/toggle_user_notifications?id=${user_id}&notificationTypeCode=NT_002_AL&notificationChannelCode=NC_004_IA`, { "headers": headers })
                .then((res) => {
                    
                    dispatch(userActions.notificationToggleSuccess(res.data));
                })
        }
    },
    // ALERT


    //LOAN
    toggle_loan_sms: () => {
        return async dispatch => {
            dispatch(userActions.notificationToggleRequest());

            const user_id = localStorage.getItem('user_id');

            await axios.get(`${baseUrl}/notification/toggle_user_notifications?id=${user_id}&notificationTypeCode=NT_005_LO&notificationChannelCode=NC_001_SM`, { "headers": headers })
                .then((res) => {
                    
                    dispatch(userActions.notificationToggleSuccess(res.data));
                })
        }
    },

    toggle_loan_email: () => {
        return async dispatch => {
            dispatch(userActions.notificationToggleRequest());

            const user_id = localStorage.getItem('user_id');

            await axios.get(`${baseUrl}/notification/toggle_user_notifications?id=${user_id}&notificationTypeCode=NT_005_LO&notificationChannelCode=NC_002_EM`, { "headers": headers })
                .then((res) => {
                    
                    dispatch(userActions.notificationToggleSuccess(res.data));
                })
        }
    },

    toggle_loan_push: () => {
        return async dispatch => {
            dispatch(userActions.notificationToggleRequest());

            const user_id = localStorage.getItem('user_id');

            await axios.get(`${baseUrl}/notification/toggle_user_notifications?id=${user_id}&notificationTypeCode=NT_005_LO&notificationChannelCode=NC_003_PS`, { "headers": headers })
                .then((res) => {
                    
                    dispatch(userActions.notificationToggleSuccess(res.data));
                })
        }
    },

    toggle_loan_app: () => {
        return async dispatch => {
            dispatch(userActions.notificationToggleRequest());

            const user_id = localStorage.getItem('user_id');

            await axios.get(`${baseUrl}/notification/toggle_user_notifications?id=${user_id}&notificationTypeCode=NT_005_LO&notificationChannelCode=NC_004_IA`, { "headers": headers })
                .then((res) => {
                    
                    dispatch(userActions.notificationToggleSuccess(res.data));
                })
        }
    },
    // LOAN


    //OTP
    toggle_otp_sms: () => {
        return async dispatch => {
            dispatch(userActions.notificationToggleRequest());

            const user_id = localStorage.getItem('user_id');

            await axios.get(`${baseUrl}/notification/toggle_user_notifications?id=${user_id}&notificationTypeCode=NT_001_OT&notificationChannelCode=NC_001_SM`, { "headers": headers })
                .then((res) => {
                    
                    dispatch(userActions.notificationToggleSuccess(res.data));
                })
        }
    },

    toggle_otp_email: () => {
        return async dispatch => {
            dispatch(userActions.notificationToggleRequest());

            const user_id = localStorage.getItem('user_id');

            await axios.get(`${baseUrl}/notification/toggle_user_notifications?id=${user_id}&notificationTypeCode=NT_001_OT&notificationChannelCode=NC_002_EM`, { "headers": headers })
                .then((res) => {
                    
                    dispatch(userActions.notificationToggleSuccess(res.data));
                })
        }
    },

    toggle_otp_push: () => {
        return async dispatch => {
            dispatch(userActions.notificationToggleRequest());

            const user_id = localStorage.getItem('user_id');

            await axios.get(`${baseUrl}/notification/toggle_user_notifications?id=${user_id}&notificationTypeCode=NT_001_OT&notificationChannelCode=NC_003_PS`, { "headers": headers })
                .then((res) => {
                    
                    dispatch(userActions.notificationToggleSuccess(res.data));
                })
        }
    },

    toggle_otp_app: () => {
        return async dispatch => {
            dispatch(userActions.notificationToggleRequest());

            const user_id = localStorage.getItem('user_id');

            await axios.get(`${baseUrl}/notification/toggle_user_notifications?id=${user_id}&notificationTypeCode=NT_001_OT&notificationChannelCode=NC_004_IA`, { "headers": headers })
                .then((res) => {
                    
                    dispatch(userActions.notificationToggleSuccess(res.data));
                })
        }
    },
    // OTP


    //LOGIN
    toggle_login_sms: () => {
        return async dispatch => {
            dispatch(userActions.notificationToggleRequest());

            const user_id = localStorage.getItem('user_id');

            await axios.get(`${baseUrl}/notification/toggle_user_notifications?id=${user_id}&notificationTypeCode=NT_004_LI&notificationChannelCode=NC_001_SM`, { "headers": headers })
                .then((res) => {
                    
                    dispatch(userActions.notificationToggleSuccess(res.data));
                })
        }
    },

    toggle_login_email: () => {
        return async dispatch => {
            dispatch(userActions.notificationToggleRequest());

            const user_id = localStorage.getItem('user_id');

            await axios.get(`${baseUrl}/notification/toggle_user_notifications?id=${user_id}&notificationTypeCode=NT_004_LI&notificationChannelCode=NC_002_EM`, { "headers": headers })
                .then((res) => {
                    
                    dispatch(userActions.notificationToggleSuccess(res.data));
                })
        }
    },

    toggle_login_push: () => {
        return async dispatch => {
            dispatch(userActions.notificationToggleRequest());

            const user_id = localStorage.getItem('user_id');

            await axios.get(`${baseUrl}/notification/toggle_user_notifications?id=${user_id}&notificationTypeCode=NT_004_LI&notificationChannelCode=NC_003_PS`, { "headers": headers })
                .then((res) => {
                    
                    dispatch(userActions.notificationToggleSuccess(res.data));
                })
        }
    },

    toggle_login_app: () => {
        return async dispatch => {
            dispatch(userActions.notificationToggleRequest());

            const user_id = localStorage.getItem('user_id');

            await axios.get(`${baseUrl}/notification/toggle_user_notifications?id=${user_id}&notificationTypeCode=NT_004_LI&notificationChannelCode=NC_004_IA`, { "headers": headers })
                .then((res) => {
                    
                    dispatch(userActions.notificationToggleSuccess(res.data));
                })
        }
    },
    // LOGIN


}

export default notifications;