import { userConstants } from "../actions/types";

const initialState = {
    isVerifyingRegisterBvn: false,
    data: '',
};

function verifyRegisterBvn(state = initialState, action) {
    switch (action.type) {
        case userConstants.VALIDATE_REGISTER_BVN_REQUEST:
            return {
                ...state,
                isVerifyingRegisterBvn: true,
            };
        case userConstants.VALIDATE_REGISTER_BVN_SUCCESS:
            return {
                ...state,
                isVerifyingRegisterBvn: false,
                data: action.payload,
            };
            case userConstants.VALIDATE_REGISTER_BVN_FAILURE:
                return {
                    ...state,
                    isVerifyingRegisterBvn: false,
                };
        default:
            return state;
    }
};

export default verifyRegisterBvn;
