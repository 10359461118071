import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import "../../css/register.css";
import "../../css/otp.css";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import auth from "../../services/authService";
import { history } from '../../store/history';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Helmet} from 'react-helmet'

const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: "#0038ba",
        minHeight: "100vh",
        [theme.breakpoints.down("sm")]: {
            backgroundColor: 'white'
        },
    },
    error: {
        color: 'red',
        fontSize: '13px',
        fontWeight: '500'
    },
    root: {
        padding: theme.spacing(10, 30, 10, 30),
        maxWidth: "100%",
        maxHeight: "100%",
        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(0, 0, 0, 0),
        },
    },
    image: {
        backgroundImage: "url('/images/login-image.png')",
        backgroundRepeat: "no-repeat",
        backgroundColor:
            theme.palette.type === "light"
                ? theme.palette.grey[50]
                : theme.palette.grey[900],
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderBottomLeftRadius: "5%",
        borderTopLeftRadius: "5%",
        maxHeight: "100%",
    },
    paper: {
        margin: theme.spacing(6, 4),
        display: "flex",
        flexDirection: "column",
    },
    logo: {
        maxWidth: "50%",
        marginBottom: 80
    },
    form: {
        width: "100%",
        marginTop: theme.spacing(1),
    },

    pap: {
        backgroundColor: "white",
        borderBottomRightRadius: "5%",
        borderTopRightRadius: "5%",
        [theme.breakpoints.down("sm")]: {
            borderBottomRightRadius: "0%",
            borderTopRightRadius: "0%",
        },
    },
    margin: {
        width: "17vw",
        [theme.breakpoints.down("sm")]: {
            width: 300,
        },
        [theme.breakpoints.down("xs")]: {
            width: 200,
        },
        marginBottom: 1,
    },
    getri: {
        fontWeight: "bold",
    },

}));

function RegisterWithBvn(props) {
    const classes = useStyles();

    const [bvn, setBvn] = useState('');
    const [error, setError] = useState('');


    function handleChange(e) {
        setBvn(e.target.value)
    }

    function handleSubmit(e) {
        e.preventDefault();

        if (bvn.length < 11 || bvn.length > 11) {
            setError(true)
        } else {
            setError(false);
        }

        if (bvn.length === 11) {
            props.registerBvn(bvn);
        }
    }

    return (
        <div className={classes.header}>
              <Helmet>
                <title>GetriPay - Register-With-BVN</title>
                <meta name="description" content="Register with BVN" />
            </Helmet>
            <Grid container component="main" className={classes.root}>
                <CssBaseline />
                <Grid item xs={false} sm={4} md={7} className={classes.image} />
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={5}
                    component={Paper}
                    elevation={0}
                    square
                    className={classes.pap}
                >
                    <div className={classes.paper}>
                        <div>
                            <img
                                src="/images/getripaylogo.png"
                                alt="logo"
                                className={classes.logo}
                            />
                        </div>
                        <div>
                            <p className='typo'>Create an account</p>
                        </div>

                        <div>
                            <p className='digit'>Please enter your valid BVN</p>
                        </div>

                        <form className={classes.form} name="form" onSubmit={handleSubmit}>
                   
                            <div>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>
                                        <label>BVN</label>
                                        <input maxLength='11' value={bvn} type='bvn' onChange={handleChange} name="bvn" className={error ? 'err': ''} />
                                    </Grid>
                                </Grid>
                            </div>

                            {error ?
                                <div className={classes.error}>ERROR!. BVN must be 11 digits</div>
                                :
                                ''
                            }

                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                size="large"
                                color="primary"
                                className='submit'
                                disabled={props.isRegisteringBvn}
                            >
                                {props.isRegisteringBvn && <CircularProgress className='circle' />}
                                    Continue
                            </Button>

                            <Grid container>
                                <Grid item component={Link} to="/" className='already'>
                                    Already have an account?  <span className='terms'>Login here</span>
                                </Grid>
                            </Grid>
                        </form>
                        <p onClick={history.goBack} className='back'>Go Back</p>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        registerBvn: (bvn) => dispatch(auth.register_bvn(bvn))
    }
};

const mapStateToProps = (state) => {
    return {
        isRegisteringBvn: state.registerBvn.isRegisteringBvn
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterWithBvn);
