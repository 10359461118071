import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { connect } from "react-redux";
import qr from "../../../../services/qrService";

function CreateMerchantModal(props) {
  const [data, setData] = useState({
    user_id: props.user.id,
    bank_no: "090317",
    account_number: props?.balance?.account_number,
  });

  useEffect(() => {
    setData({ ...data, account_number: props?.balance?.account_number });
  }, [props.balance, data.account_number]);

  function submit() {
    props.create_merchant_and_submerchants(data);
  }

  return (
    <div>
      <Dialog
        maxWidth="xs"
        open={props.open}
        onClose={props.close}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent>
          <h3 style={{ textAlign: "center" }}>Create your merchant profile</h3>

          <p
            style={{
              fontSize: "14px",
              textAlign: "center",
              padding: "10px",
              marginBottom: "10px",
            }}
          >
            Clicking on the button below will create a merchant account under
            your GetriPay account {props.user.bank_account[0].account_number}
          </p>
          <hr style={{ marginBottom: "30px" }} />
          <div className="merchant-center">
            <Button
              variant="contained"
              color="primary"
              className="merchant-cancel-button"
              onClick={props.close}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="merchant-small-button"
              onClick={submit}
            >
              {props.loading ? (
                <CircularProgress style={{ fontSize: "30px", color: "#fff" }} />
              ) : (
                "Create Merchant Account"
              )}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    create_merchant_and_submerchants: (data) =>
      dispatch(qr.create_merchant_and_submerchants(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.userDetails.data,
    loading: state.createQrMerchantSubmerchant.isLoading,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateMerchantModal);
