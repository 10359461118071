import React, { useState } from "react";
import { Grid, Paper, Button } from "@material-ui/core";
import CardRequestModal from "../../../../components/cardRequestModal";

function PhysicalCard({ props }) {
  const [open, setOpen] = useState(false);

  function handleClose() {
    setOpen(false);
  }

  const stat = props.cardStatus.length > 0 ? props.cardStatus[0].status : "";

  return (
    <Grid item xs={12} sm={12} md={11} lg={11}>
      <Paper className="dashboardBoxPhysical">
        <p className="virtual-physical-background">COMING SOON</p>
        <div>
          <div className="for-inline-items">
            <div>
              <p className="virtual-physical-text">
                You do not have a physical card yet
              </p>
            </div>
            <div>
              <Button
                disabled
                onClick={() => setOpen(true)}
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: "#0037ba",
                  borderRadius: "500px",
                  textTransform: "capitalize",
                  color: "white",
                  fontSize: "10px",
                }}
              >
                Get Physical Card
              </Button>
            </div>
          </div>
          <div className="for-inline-items">
            <div>
              <p className="card-text-sm">Expiry date will be here</p>
            </div>
            <div style={{ display: "flex" }}>
              <span className="card-dot"></span>
              <p className="card-pending">{stat}</p>
            </div>
          </div>
          <div className="dashboard-card-no">
            <span className="one">****</span>
            <span className="one">****</span>
            <span className="one">****</span>
            <span className="one">****</span>

            <span className="two"> ****</span>
          </div>

          <div className="for-inline-items">
            <img
              src="/images/getripaylogo.png"
              alt="logo"
              className="dashboard-ma"
              style={{ width: "35%", paddingBottom: "20px" }}
            />
            <div>
              <p className="card-text-sm">
                {props.user.first_name.toUpperCase()}{" "}
                {props.user.last_name.toUpperCase()}{" "}
              </p>
            </div>
            <div>
              <p className="card-text-money">{new Date().getFullYear()}</p>
            </div>
          </div>
        </div>
      </Paper>
      <CardRequestModal close={handleClose} open={open} details={props.user} />
    </Grid>
  );
}

export default PhysicalCard;
