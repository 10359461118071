import React, { useState } from "react";
import { Grid, Paper, Button, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import CardRequestModal from "../cardRequestModal";
import { history } from "../../store/history";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function CreditCard({ props }) {
  const [open, setOpen] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  function openCreate() {
    if (props.user.kyc_level < 2) {
      setShowAlert(true);
    } else {
      setOpen(true);
    }
  }

  function pushtoUpgrade() {
    history.push("/validate-bvn");
  }

  const stat = props.cardStatus.length > 0 ? props.cardStatus[0].status : "";

  const cardDetails = props?.cardStatus[0];

  return (
    <>
      {props.cardStatus.length === 0 ? (
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <Paper className="dashboardBoxPhysical">
            <div>
              <div className="for-inline-items">
                <div>
                  <p className="virtual-physical-text">
                    You do not have a physical card yet
                  </p>
                </div>
                <div>
                  <Button
                    onClick={openCreate}
                    variant="contained"
                    color="primary"
                    style={{
                      backgroundColor: "#0037ba",
                      borderRadius: "500px",
                      textTransform: "capitalize",
                      color: "white",
                      fontSize: "10px",
                    }}
                  >
                    Get Physical Card
                  </Button>
                </div>
              </div>
              <div className="for-inline-items">
                <div>
                  <p className="card-text-sm">Expiry date</p>
                </div>
                <div style={{ display: "flex" }}>
                  <span className="card-dot"></span>
                  <p className="card-pending">{stat}</p>
                </div>
              </div>
              <div className="dashboard-card-no">
                <span className="one">****</span>
                <span className="one">****</span>
                <span className="one">****</span>
                <span className="one">****</span>

                <span className="two"> ****</span>
              </div>

              <div className="for-inline-items">
                <img
                  src="/images/getripaylogo.png"
                  alt="logo"
                  className="dashboard-ma"
                  style={{ width: "35%", paddingBottom: "20px" }}
                />
                <div>
                  <p className="card-text-sm">
                    {props.user.first_name.toUpperCase()}{" "}
                    {props.user.last_name.toUpperCase()}{" "}
                  </p>
                </div>
                <div>
                  <p className="card-text-money">{new Date().getFullYear()}</p>
                </div>
              </div>
            </div>
          </Paper>
          <CardRequestModal
            close={() => setOpen(false)}
            open={open}
            details={props.user}
            balance={props.balance}
          />
          {showAlert && (
            <Snackbar
              open={showAlert}
              autoHideDuration={6000}
              onClose={() => setShowAlert(false)}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Alert
                severity="error"
                action={
                  <Button color="inherit" size="small" onClick={pushtoUpgrade}>
                    Link BVN
                  </Button>
                }
              >
                Please validate your BVN first
              </Alert>
            </Snackbar>
          )}
        </Grid>
      ) : (
        <>
          {cardDetails && (
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Paper className="dashboardBoxPhysical">
                <div>
                  <div className="for-inline-items">
                    <div>
                      <p className="virtual-physical-text">
                        {cardDetails.name_on_card}
                      </p>
                    </div>

                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span className="card-dot"></span>
                      <p className="card-pending">{cardDetails.status}</p>
                    </div>
                    {/* <div>
                      <Button
                        onClick={openCreate}
                        variant="contained"
                        color="primary"
                        style={{
                          backgroundColor: "#0037ba",
                          borderRadius: "500px",
                          textTransform: "capitalize",
                          color: "white",
                          fontSize: "10px",
                        }}
                      >
                        Get Physical Card
                      </Button>
                    </div> */}
                  </div>

                  <div className="for-inline-items">
                    <div>
                      <p className="card-text-sm">
                        {cardDetails.office_address}
                      </p>
                    </div>

                    <div>
                      <p className="card-text-sm">{cardDetails.gsm_no}</p>
                    </div>
                  </div>

                  <div className="for-inline-items">
                    <div>
                      {/* <p className="card-text-sm">
                        {cardDetails.office_address_2}
                      </p> */}
                    </div>

                    <div>
                      <p className="card-text-sm">
                        {cardDetails.city}, {cardDetails.state}
                      </p>
                    </div>
                  </div>

                  <div className="dashboard-card-no">
                    <span className="one">****</span>
                    <span className="one">****</span>
                    <span className="one">****</span>
                    <span className="one">****</span>

                    <span className="two"> ****</span>
                  </div>

                  <div className="for-inline-items">
                    <img
                      src="/images/getripaylogo.png"
                      alt="logo"
                      className="dashboard-ma"
                      style={{ width: "35%", paddingBottom: "13px" }}
                    />
                    <div>
                      <p className="card-text-sm">{cardDetails.name_on_card}</p>
                    </div>
                    <div>
                      <p className="card-text-money">
                        {new Date().getFullYear()}
                      </p>
                    </div>
                  </div>
                </div>
              </Paper>
            </Grid>
          )}
        </>
      )}
    </>
  );
}

export default CreditCard;
