import { userConstants } from '../actions/types'

const initialState = {
    isValidating: false,
    data: [],
}

 
function validate(state = initialState, action) {
    switch (action.type) {
        case userConstants.VALIDATING:
            return {
                isValidating: true,
                data: []
            
            };
        case userConstants.VALIDATED:
            return {
                isValidating: false,
                data: action.payload,
 
            };
        default:
            return state
    }
}

export default validate;