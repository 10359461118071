import { userConstants } from "../actions/types";

const initialState = {
  isTransferring: false,
  res_code: "",
  res_data: {},
  success: null,
};

function transfer(state = initialState, action) {
  switch (action.type) {
    case userConstants.TRANSFERRING:
      return {
        ...state,
        isTransferring: true,
        success: false,
      };
    case userConstants.TRANSFERRED:
      return {
        ...state,
        isTransferring: false,
        success: true,
        res_code: action.payload.res_code,
        res_data: action.payload.res_data,
      };
    case userConstants.TRANSFER_FAILURE:
      return {
        ...state,
        isTransferring: false,
      };
    case userConstants.CLEAR_TRANSFER:
      return {
        isTransferring: false,
        res_code: "",
        res_data: {},
        success: null,
      };
    default:
      return state;
  }
}
export default transfer;
