import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Menu, MenuItem, withStyles } from "@material-ui/core";
import "../../../css/dashboard.css";
import TopBar from "../../../components/TopBar";
import { MainContent, Footer, HeaderBar } from "../../../components/Appearance";
import SideNav from "../../../components/SideNav";
import BottomNav from "../../../components/bottomNavigation";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import savings from "../../../services/savingsService";
import { history } from "../../../store/history";
import LinearProgress from "@material-ui/core/LinearProgress";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import transactions from "../../../services/transactionService";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "white",
  },
  content: {
    flexGrow: 1,
    backgroundColor: "white",
  },
  container: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(4),
    backgroundColor: "white",
  },
  large: {
    width: 100,
    height: 100,
  },
  customBadge: {
    color: "white",
    marginRight: "-55px",
    width: "120px",
  },
  txnLogo: {
    height: "25px",
    position: "absolute",
    marginLeft: "-50px",
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      marginLeft: "0px",
    },
  },
  small: {
    fontSize: "12px",
    marginTop: theme.spacing(-2),
    marginLeft: "2px",
  },
  ref: {
    marginTop: theme.spacing(-0.5),
    fontSize: "12px",
    marginLeft: "2px",
    fontWeight: "bolder",
  },
  name: {
    fontSize: "11px",
    fontWeight: "bolder",
    marginLeft: "2px",
    textTransform: "capitalize",
  },
}));

function SavingsHistoryGroup(props) {
  const classes = useStyles();
  const [sort, setSort] = useState(null);
  const [sortLowest, setSortLowest] = useState(null);
  const [sortCredit, setSortCredit] = useState(null);
  const [toggle, setToggle] = useState(true);
  const [sidenav, setSidenav] = useState("getri-sidenav");

  const wallet_id = props.match.params.wallet_id;
  const save_id = props.match.params.slug;
  const group_id = props.match.params.group_id;

  const handleToggle = () => {
    const mediaScreen = window.matchMedia(
      `(max-width: 700px),
        (min-device-width: 768px)
        and (max-device-width: 1024px)`
    ); //returns true if

    if (mediaScreen.matches) {
      setSidenav("slideOut");
    } else {
      setToggle(!toggle);
    }
  };
  const hideSidebar = () => {
    setSidenav("slideIn");
  };

  const handleClick = (event) => {
    setSort(event.currentTarget);
  };

  const handleClose = () => {
    setSort(null);
  };

  // Sort by credit
  const sortByCredit = () => {
    var result = props.saveHistory.filter((obj) => {
      return obj.transaction_type === "credit";
    });
    props.savings_history_sort(result);
  };

  // Sort by debit
  const sortByDebit = () => {
    var result = props.saveHistory.filter((obj) => {
      return obj.transaction_type === "debit";
    });
    props.savings_history_sort(result);
  };

  useEffect(() => {
    props.get_savings_history(save_id);
    props.view_group_info(group_id);
    props.get_group_wallet(wallet_id);
    props.get_savings_summary(save_id);
    props.get_withdrawal_authorities(group_id);
    props.get_account_balance();
  }, []);

  const kak = props.view[0] !== undefined && props.view[0].group;

  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: "#0037ba",
    },
  }))(LinearProgress);

  const target =
    props.view[0] !== undefined && props.view[0].group.maximum_amount;

  const gre = props.view.map((fi) => fi.user);
  const authorized = props.authorize.length;

  const progress = (Number(props.wallet.ledger_balance) / Number(target)) * 100;

  return (
    <>
      <Helmet>
        <title>GetriPay - Fastest growing digital bank with rewards </title>
        <meta name="description" content="Savings" />
        <meta name="keywords" content="savings" />
      </Helmet>
      <TopBar handleToggle={handleToggle} />
      <SideNav
        toggle={toggle}
        className={sidenav}
        hideSidebar={hideSidebar}
        {...props}
      />
      <MainContent toggle={toggle}>
        <main className={classes.content}>
          <Grid container>
            <Grid item xs={12} md={12} lg={12}>
              <Container maxWidth="lg" className={classes.container}>
                <Container className="main-container">
                  <HeaderBar
                    topic="GetriSave"
                    text={Number(
                      props.balance.available_balance
                    ).toLocaleString()}
                  />
                </Container>
              </Container>
            </Grid>
            <Container>
              <Container>
                <Grid container style={{ marginBottom: "30px" }}>
                  <Grid item lg={2} sm={12} xs={12} className="overview-text">
                    <span style={{ fontWeight: "bolder" }}>
                      {props.saveSummary[0] ? props.saveSummary[0].name : ""}
                    </span>
                  </Grid>

                  <Grid item lg={3} sm={6} xs={6}>
                    Group Wallet number: <br />
                    {props.wallet ? (
                      <>
                        <span style={{ color: "#0037ba" }}>
                          {props.wallet.wallet_number}
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item lg={3} sm={6} xs={6}>
                    Group withdrawable: <br />
                    {props.wallet ? (
                      <>
                        <span style={{ color: "#0037ba" }}>
                          NGN{" "}
                          {Number(
                            props.wallet.available_balance
                          ).toLocaleString()}
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item lg={3} sm={6} xs={6} style={{ cursor: "pointer" }}>
                    Group ledger balance:
                    <br />
                    {props.wallet ? (
                      <>
                        <span style={{ color: "#ff0000" }}>
                          NGN{" "}
                          {Number(props.wallet.ledger_balance).toLocaleString()}
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                  </Grid>

                  <Grid item lg={1} sm={6} xs={6}>
                    <span className="back" onClick={history.goBack}>
                      Go back
                    </span>
                  </Grid>
                </Grid>
              </Container>

              <Container>
                <Grid container spacing={3}>
                  <Grid
                    item
                    lg={5}
                    md={12}
                    sm={12}
                    xs={12}
                    className="group-history-box"
                  >
                    <p style={{ fontSize: "13px" }}>
                      Group progress: NGN{" "}
                      {Number(props.wallet.ledger_balance).toLocaleString()} of
                      NGN {Number(target).toLocaleString()}{" "}
                    </p>
                    <p style={{ fontSize: "13px" }}>
                      Participants:{kak.user_join} of {kak.maximum_participants}{" "}
                    </p>
                    <p style={{ fontSize: "13px" }}>
                      Number of authorized withdrawals {authorized} of{" "}
                      {kak.user_join}
                    </p>
                    <br />
                    <BorderLinearProgress
                      variant="determinate"
                      value={progress}
                    />
                    <br />

                    {props.saveSummary[0] && (
                      <table
                        style={{
                          fontSize: "13px",
                          textTransform: "capitalize",
                        }}
                      >
                        <tr>
                          <td style={{ fontWeight: "bolder" }}>Savings Name</td>
                          <td>{props.saveSummary[0].name}</td>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: "bolder" }}>Auto save</td>
                          <td>
                            {props.saveSummary[0] &&
                            props.saveSummary[0].auto_save === 0
                              ? "false"
                              : "true"}
                          </td>
                        </tr>

                        <tr>
                          <td style={{ fontWeight: "bolder" }}>Start Date</td>
                          <td>{props.saveSummary[0].start_date}</td>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: "bolder" }}>
                            Maturity Date
                          </td>
                          <td>{props.saveSummary[0].maturity_date}</td>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: "bolder" }}>
                            Amount saved so far
                          </td>
                          <td>
                            NGN{" "}
                            {Number(
                              props.saveSummary[0].amount_save
                            ).toLocaleString()}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: "bolder" }}>
                            Total amount saved + interest{" "}
                          </td>
                          <td>
                            NGN{" "}
                            {Number(
                              props.saveSummary[0].total_interest_amount_save
                            ).toLocaleString()}
                          </td>
                        </tr>

                        <tr>
                          <td style={{ fontWeight: "bolder" }}>
                            Amount withdrawn
                          </td>
                          <td>
                            NGN{" "}
                            {Number(
                              props.saveSummary[0].amount_withdraw
                            ).toLocaleString()}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: "bolder" }}>
                            Balance left on savings{" "}
                          </td>
                          <td>
                            NGN{" "}
                            {Number(
                              props.saveSummary[0].amount_left
                            ).toLocaleString()}
                          </td>
                        </tr>

                        <tr>
                          <td style={{ fontWeight: "bolder" }}>
                            Balance left + interest{" "}
                          </td>
                          <td>
                            NGN{" "}
                            {Number(
                              props.saveSummary[0]
                                .interest_remain_amount_save_remain
                            ).toLocaleString()}
                          </td>
                        </tr>

                        <tr>
                          <td style={{ fontWeight: "bolder" }}>
                            Target amount
                          </td>
                          <td>
                            NGN{" "}
                            {Number(
                              props.saveSummary[0].target_amount
                            ).toLocaleString()}
                          </td>
                        </tr>

                        <tr>
                          <td style={{ fontWeight: "bolder" }}>
                            Interest accrued
                          </td>
                          <td>NGN {props.saveSummary[0].total_interest}</td>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: "bolder" }}>
                            Interest paid out
                          </td>
                          <td>NGN{props.saveSummary[0].interest_payout}</td>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: "bolder" }}>
                            Interest Rate
                          </td>
                          <td>{props.saveSummary[0].interest_rate} %</td>
                        </tr>

                        <tr>
                          <td style={{ fontWeight: "bolder" }}>Status</td>
                          <td>{props.saveSummary[0].status}</td>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: "bolder" }}>
                            Frequency Type
                          </td>
                          <td>{props.saveSummary[0].frequency_type}</td>
                        </tr>

                        <tr>
                          <td style={{ fontWeight: "bolder" }}>Savings Type</td>
                          <td>
                            {props.saveSummary[0].savings_product.amount_type}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: "bolder" }}>
                            Savings Product Name
                          </td>
                          <td>{props.saveSummary[0].savings_product.name}</td>
                        </tr>
                      </table>
                    )}

                    <table>
                      <tbody>
                        <tr style={{ fontSize: "11px" }}>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Phone No.</th>
                          <th>Amount Saved</th>
                          <th>Authorized Withdrawal?</th>
                        </tr>
                      </tbody>
                      <tbody>
                        {gre.map((blue, i) => (
                          <tr key={i} style={{ fontSize: "11px" }}>
                            <td style={{ textTransform: "capitalize" }}>
                              {blue.first_name} {blue.last_name}
                            </td>
                            <td>{blue.email}</td>
                            <td>{blue.phone_number}</td>

                            {props.view.map((save, i) => (
                              <>
                                {save.user_id === blue.id && (
                                  <td>
                                    NGN
                                    {Number(save.amount_save).toLocaleString()}
                                  </td>
                                )}
                              </>
                            ))}

                            {props.authorize.map((autho, i) => (
                              <>
                                {autho.user_id === blue.id ? (
                                  <td>
                                    <CheckCircleIcon
                                      style={{ color: "#33cc33" }}
                                    />
                                  </td>
                                ) : (
                                  <td>
                                    <ErrorOutlineIcon
                                      style={{ color: "red" }}
                                    />
                                  </td>
                                )}
                              </>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Grid>

                  <Grid item lg={7} md={12} sm={12} xs={12}>
                    {props.saveHistory ? (
                      <>
                        {props.saveHistory.length === 0 ? (
                          <p>No savings history yet</p>
                        ) : (
                          <>
                            {props.saveHistory.map((data, i) => (
                              <div key={i} className="history-table2">
                                <span className="deb2">
                                  {data.transaction_type}
                                </span>

                                <div
                                  style={{
                                    display: "inline-block",
                                    float: "right",
                                  }}
                                >
                                  <span
                                    className={
                                      data.status === "success"
                                        ? "card-success-dot"
                                        : data.status === "pending"
                                        ? "card-pending-dot"
                                        : data.status === "failed"
                                        ? "card-dot"
                                        : ""
                                    }
                                    style={{
                                      display: "inline-block",
                                      float: "left",
                                    }}
                                  ></span>
                                  <span
                                    className={
                                      data.status === "success"
                                        ? "deb2"
                                        : data.status === "pending"
                                        ? "deb3"
                                        : data.status === "failed"
                                        ? "deb1"
                                        : ""
                                    }
                                    style={{
                                      display: "inline-block",
                                      float: "right",
                                      textTransform: "uppercase",
                                    }}
                                  >
                                    {data.status}
                                  </span>
                                </div>

                                <div className="user-inline-items">
                                  <div
                                    style={{
                                      padding: "none",
                                      paddingRight: "10%",
                                    }}
                                  >
                                    <span
                                      style={{ marginTop: "20px" }}
                                      className="trans-amount"
                                    >
                                      NGN {Number(data.amount).toLocaleString()}
                                    </span>
                                  </div>

                                  <div>
                                    <div>
                                      <img
                                        alt="logo"
                                        src="/images/getriSave_logo.png"
                                        className={classes.txnLogo}
                                      />
                                      <div>
                                        <p className={classes.name}>
                                          {data.description}
                                        </p>
                                        <p className={classes.small}>
                                          {data.recipient_no}{" "}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <span className={classes.small}>
                                      {new Date(
                                        data.created_at
                                      ).toLocaleString()}
                                    </span>
                                    <p className={classes.ref}>
                                      {data.trans_ref}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </Container>
            </Container>
          </Grid>
          <BottomNav {...props} />
          {/* <Footer /> */}
        </main>
      </MainContent>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    get_savings_history: (save_id) =>
      dispatch(savings.get_savings_history(save_id)),
    get_savings_summary: (save_id) =>
      dispatch(savings.get_savings_summary(save_id)),
    view_group_info: (group_id) => dispatch(savings.view_group_info(group_id)),
    savings_history_sort: (result) =>
      dispatch(savings.savings_history_sort(result)),
    get_group_wallet: (wallet_id) =>
      dispatch(savings.get_group_wallet(wallet_id)),
    get_withdrawal_authorities: (group_id) =>
      dispatch(savings.get_withdrawal_authorities(group_id)),
    get_account_balance: (payload) =>
      dispatch(transactions.get_account_balance(payload)),
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.userDetails.data,
    saveHistory: state.savingsHistory.data,
    view: state.viewGroupInfo.data,
    wallet: state.groupWallet.data,
    saveSummary: state.savingsSummary.data,
    authorize: state.getAuthorize.data,
    balance: state.getAccountBalance.data,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SavingsHistoryGroup);
