import { userConstants } from '../actions/types'

const initialState = {
    isLoading: false,
    data: [],
}

function joinGroupSavings(state = initialState, action) {
    switch (action.type) {
        case userConstants.JOIN_GROUP_SAVINGS_REQUEST:
            return {
                ...state,
                isLoading: true,

            };
        case userConstants.JOIN_GROUP_SAVINGS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            };
        case userConstants.JOIN_GROUP_SAVINGS_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        default:
            return state
    }
}

export default joinGroupSavings;