import { userConstants } from '../actions/types'

const initialState = {
    isLoading: false,
    data: '' 
}
 
function loginUser(state = initialState, action) {
    switch (action.type) {
        case userConstants.LOGIN_USER_REQUEST:
            return {
                isLoading: true,
                data:''
            };
        case userConstants.LOGIN_USER_SUCCESS:
            return {
                isLoading: false,
                data: action.payload  
            };
            case userConstants.LOGIN_USER_FAILURE:
            return {
                isLoading: false,
                data: action.payload  
            };
            case userConstants.LOGIN_CLEAR:
                return {
                    isLoading: false,
                };
        default:
            return state
    }
}

export default loginUser;