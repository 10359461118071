import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Container,
  Grid,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import BackDrop from "../../../components/BackDrop";
import { connect, useDispatch } from "react-redux";
import Select from "react-select";
import "../../../css/solomon.css";
import { useForm } from "react-hook-form";
import transfers from "../../../services/transferService";
import transactions from "../../../services/transactionService";
import TopBar from "../../../components/TopBar";
import SideNav from "../../../components/SideNav";
import BottomNav from "../../../components/bottomNavigation";
import numeral from "numeral";
import SetPin from "./setPin";
import TransferReceipt from "./newReceipt";
import FundModal from "../FundAccount/fundModal";
import {
  HeaderBar,
  CardHeader,
  CardHeaderNew,
  InputGrid,
  CardBody,
  SmallCard,
  MainContent,
  SuccessCard,
  Footer,
} from "../../../components/Appearance";
import { Helmet } from "react-helmet";
import { userActions } from "../../../actions/creators";
import { history } from "../../../store/history";

function Transfer(props) {
  const [toggle, setToggle] = useState(true);
  const [openFundModal, setOpenFundModal] = useState(false);
  const [sidenav, setSidenav] = useState("getri-sidenav");
  const [checkedB, setCheckedB] = useState(false);
  const [checkedA, setCheckedA] = useState(false);
  const { handleSubmit } = useForm({ mode: "onBlur" });
  const [open, setOpen] = React.useState(false);
  const [reciept, setReciept] = useState(false);
  const [success, setSuccess] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [inputdisabled, setInputdisabled] = useState(true);
  const [selectedOption, setSelectedOption] = useState(null);
  var [beneficiaryAccount, setBeneficiaryAccount] = useState("");
  var [beneficiaryCode, setBeneficiaryCode] = useState("");
  var [image, setImage] = useState("");
  const [transaction_pin, setTransaction_pin] = useState("");
  const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
  const [acc, setAcc] = useState("");
  const [code, setCode] = useState("");
  const [bankName, setBankName] = useState("");
  const [instName, setInstName] = useState("");
  const [show, setShow] = useState(false);
  const [displayBeneficiary, setDisplayBeneficiary] = useState(true);
  let [valid, setValid] = useState({
    bank_code: "",
    account_no: "",
    source_account: props.balance.account_number,
  });

  const req_id = Math.floor(Math.random() * 900000000000000000);
  let { account_no } = valid;

  const dispatch = useDispatch();

  const [recieptdata, setRecieptdata] = useState({
    date: "",
    reciever_name: "",
    transactionID: "",
    bank_name: "",
    bank_logo: "",
    amount: "",
    reciever_acct_num: "",
    owners_acct_num: "",
    owners_acct_name: "",
    narration: "",
  });
  const [transfer, setTransfer] = useState({
    user_id: "",
    request_id: req_id,
    dest_bank_code: "",
    dest_account_no: "",
    dest_account_name: "",
    bvn: "",
    source_account_no: "",
    source_account_name: " ",
    session_id: "",
    amount: "",
    narration: "",
    pin: "",
  });

  const handleClickOpen = () => {
    setOpen(true);
    setDisabled(false);
    setInputdisabled(false);

    if (valid.bank_code === "090317") {
      props.get_transaction_fee(formAmt, "SER_001_TP");
    } else {
      props.get_transaction_fee(formAmt, "SER_002_TB");
    }
  };
  useEffect(() => {
    setTransfer({
      user_id: localStorage.getItem("user_id"),
      request_id: req_id,
      dest_bank_code: beneficiaryCode ? beneficiaryCode : code,
      dest_account_no: beneficiaryAccount ? beneficiaryAccount : acc,
      dest_account_name: props.account.AccountName,
      bvn: props.account.BankVerificationNumber,
      source_account_no: props.balance.account_number,
      source_account_name: props.balance.account_name,
      session_id: props.account.SessionID,
      amount: valueDisplay.split(",").join(""),
      narration: narration,
      pin: "",
    });
  }, [props]); // eslint-disable-line react-hooks/exhaustive-deps

  let {
    user_id,
    request_id,
    dest_bank_code,
    dest_account_no,
    dest_account_name,
    source_account_no,
    source_account_name,
    session_id,
    amount,
    narration,
    pin,
  } = transfer;

  function changeSuccess() {
    if (props.transfermsg == "00") {
      setSuccess(true);
    } else {
      setSuccess(false);
    }
  }

  useEffect(() => {
    props.get_bank();
    props.get_beneficiaries();
    //props.get_transaction_services();
    props.get_account_balance();
  }, []);

  const newAmt = Number(amount.replace(/,/g, ""));
  const bal =
    props.balance.length === 0 ? 0 : Number(props.balance.available_balance);

  useEffect(() => {
    changeSuccess();
    if (props.transfermsg == "00") {
      setRecieptdata({
        ...recieptdata,
        date: props.transferData.data.transactionDate,
        reciever_name: props.transferData.data.dest_account_name,
        transactionID: props.transferData.data.transaction_ref,
        bank_name: props.transferData.data.recipientInstitutionName,
        bank_logo: props.transferData.data.recipientInstitutionLogoUrl,
        amount: props.transferData.data.transactionAmount,
        reciever_acct_num: props.transferData.data.dest_account_no,
        owners_acct_num: props.transferData.data.source_account_no,
        owners_acct_name: props.transferData.data.source_account_name,
        narration: props.transferData.data.narration,
      });
    } else {
      setSuccess(false);
    }
    validateForm();

    if (newAmt > bal) {
      setOpenFundModal(true);
    } else {
      setOpenFundModal(false);
    }
  }, [
    props.transfermsg,
    props.saveBeneficiary,
    transfer.dest_account_no,
    transfer.amount,
    props.transferData,
    props.user.available_balance,
    newAmt,
    bal,
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    validate();
  }, [valid.account_no]);

  function closeFundModal() {
    setOpenFundModal(false);
  }

  function handleChangeValidateBank(e) {
    const { name, value } = e.target;
    setValid((valid) => ({ ...valid, [name]: value }));
    setAcc(e.target.value);
    if (valid.bank_code && valid.account_no.length === 10) {
      validate();
    }
  }

  const options = props.banks.map((banks, i) => ({
    label: banks.InstitutionName,
    value: banks.InstitutionCode,
    picture: banks.BankLogoUrl,
    key: i,
  }));

  const beneficiaryOptions = props.listBeneficiary.map(
    (listBeneficiary, i) => ({
      label:
        listBeneficiary.customer_name +
        " " +
        "||" +
        " " +
        listBeneficiary.account_number,
      value: listBeneficiary.institution_code,
      account_number: listBeneficiary.account_number,
      institution_name: listBeneficiary.institution_name,
      key: i,
    })
  );

  function handleChange(e) {
    const { name, value } = e.target;
    setTransfer((transfer) => ({ ...transfer, [name]: value }));
  }
  // List beneficiaries toggle
  const handleChangeToggle = (event) => {
    setCheckedB((prev) => !prev);
    setDisplayBeneficiary((prevDisplayBeneficiary) => !prevDisplayBeneficiary);
    setShow((prevShow) => !prevShow);
    setValid({
      bank_code: "",
      account_no: "",
      source_account: props.balance.account_number,
    });
    transfer.dest_account_name = "";
  };

  // Close overlay and edit
  const closeOverlay = () => {
    setOpen(false);
    setDisabled(true);
    setInputdisabled(true);
    dispatch(userActions.getTransactionFeeSuccess([]));
  };

  // Save beneficiary toggle
  const handleChangeSaveBeneficiary = (event) => {
    // setCheckedA({ [event.target.name]: event.target.checked });
    setCheckedA((prev) => !prev);
  };

  function validate() {
    if (valid.account_no.length === 10) {
      props.validate_bank(valid);
    }
  }

  function newValidate() {
    if (valid.account_no.length === 10) {
      props.validate_bank(valid);
    }
  }

  const validateForm = () => {
    if (
      transfer.dest_account_no.length !== 10 ||
      transfer.amount.replace(/,/g, "") <= 0 ||
      newAmt > bal
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  function handleChangeGetBank(selectedOption) {
    setSelectedOption(selectedOption);
    setValid((valid) => ({ ...valid, bank_code: selectedOption.value }));
    setCode((valid.bank_code = selectedOption.value));
    setBankName(selectedOption.label);
    setImage(selectedOption.picture);
  }

  function handleChangeGetBeneficary(selectedBeneficiary) {
    setSelectedBeneficiary(selectedBeneficiary);
    setBeneficiaryAccount(
      (valid.account_no = selectedBeneficiary.account_number)
    );
    setBeneficiaryCode((valid.bank_code = selectedBeneficiary.value));
    setInstName(selectedBeneficiary.institution_name);

    if (valid.bank_code && valid.account_no.length === 10) {
      newValidate();
    }
  }

  // Number formatter
  var valueDisplay = numeral(amount).format();
  const formAmt = valueDisplay ? valueDisplay.split(",").join("") : "";

  const result = Number(formAmt) + Number(props.fee);

  function handleSaveBeneficiary() {
    if (
      dest_account_no &&
      dest_bank_code &&
      dest_account_name &&
      bankName &&
      pin
    ) {
      props.save_beneficiary(
        dest_account_no,
        dest_bank_code,
        dest_account_name,
        bankName,
        pin
      );
    }
  }

  function onSubmit() {
    if (
      user_id &&
      request_id &&
      dest_bank_code &&
      dest_account_no &&
      dest_account_name &&
      source_account_no &&
      source_account_name &&
      session_id &&
      amount &&
      pin &&
      pin.length === 4
    ) {
      setDisabled(false);
      props.transfers(transfer);
    }
    if (checkedA) {
      handleSaveBeneficiary();
    }
  }

  function closeReciept() {
    setReciept(false);
  }

  function showSuccessCard() {
    let showCard;
    if (success) {
      showCard = <SuccessCard openReceipt={() => setReciept(true)} />;
    } else {
      showCard = (
        <div className="card" style={{ display: open ? "block" : "none" }}>
          <CardHeader info="Make Payment" />
          <div className="wrapper">
            <ArrowBackIcon onClick={closeOverlay} className="arrow1" />
            <h6 onClick={closeOverlay} className="arrow2">
              BACK
            </h6>
            <CardBody className="card1">
              <SmallCard
                img={image}
                amount={
                  props.fee.length !== 0
                    ? valueDisplay + " " + "+" + " " + props.fee
                    : valueDisplay + " " + "+" + " " + 0
                }
                item1={transfer.dest_account_no}
                item2={transfer.dest_account_name}
              />
            </CardBody>
          </div>
          <div className="wrapper">
            <CardBody className="card2" cardstyle={{ padding: "1em 1em 1em" }}>
              <form>
                {show ? (
                  <InputGrid>
                    <Select
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          "&:hover": { borderColor: "black" },
                          border: "none",
                          borderRadius: "none",
                          borderBottom: "1px solid #999999",
                          boxShadow: "none",
                          padding: "0",
                        }),
                      }}
                      placeholder="Select Beneficiary"
                      value={selectedBeneficiary}
                      options={beneficiaryOptions}
                      isDisabled={true}
                    />
                  </InputGrid>
                ) : (
                  <InputGrid>
                    <Select
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          "&:hover": { borderColor: "black" },
                          border: "none",
                          borderRadius: "none",
                          borderBottom: "1px solid black",
                          boxShadow: "none",
                        }),
                      }}
                      placeholder="Select Account"
                      value={selectedOption}
                      options={options}
                      isDisabled={true}
                    />
                  </InputGrid>
                )}

                <InputGrid>
                  <label>
                    <span>Ngn</span> Amount
                  </label>
                  <input
                    name="amount"
                    value={result}
                    onChange={handleChange}
                    disabled
                    className="input-div"
                  />
                </InputGrid>

                {pin.length > 4 ? (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    Pin must be 4 digits
                  </p>
                ) : (
                  ""
                )}
                <InputGrid>
                  <TextField
                    onChange={handleChange}
                    name="pin"
                    value={pin}
                    className="input-div"
                    required
                    label="Please enter your pin"
                    type="password"
                  />
                </InputGrid>

                <Button
                  onClick={handleSubmit(onSubmit)}
                  fullWidth
                  variant="contained"
                  size="small"
                  color="primary"
                  className="getri-button submit"
                >
                  Transfer
                </Button>
              </form>

              <FormGroup
                row
                style={{
                  marginTop: "20px",
                  display: displayBeneficiary ? "block" : "none",
                }}
              >
                <FormControlLabel
                  control={
                    <Switch
                      checked={checkedA}
                      onChange={handleChangeSaveBeneficiary}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Save Beneficiary"
                />
              </FormGroup>
            </CardBody>
          </div>
        </div>
      );
    }
    return showCard;
  }

  const handleToggle = () => {
    const mediaScreen = window.matchMedia(
      `(max-width: 700px),
        (min-device-width: 768px)
        and (max-device-width: 1024px)`
    ); //returns true if

    if (mediaScreen.matches) {
      setSidenav("slideOut");
      setToggle(true);
    } else {
      setToggle(!toggle);
    }
  };
  const hideSidebar = () => {
    setSidenav("slideIn");
  };

  return (
    <>
      <Helmet>
        <title>GetriPay - Fastest growing digital bank with rewards </title>
        <meta name="description" content="Fund Transfer" />
      </Helmet>
      <TopBar handleToggle={handleToggle} />
      <SideNav
        toggle={toggle}
        className={sidenav}
        hideSidebar={hideSidebar}
        {...props}
      />
      <MainContent toggle={toggle}>
        {props.isFetchin || props.isValidating || props.isTransferring ? (
          <div>
            <BackDrop />
          </div>
        ) : (
          ""
        )}
        {newAmt > bal ? (
          <FundModal open={openFundModal} close={closeFundModal} balance={props.balance} />
        ) : (
          ""
        )}
        <main className="main">
          <Grid container>
            <Grid item xs={12} md={12} lg={12} style={{ height: "inherit" }}>
              <Container className="main-container">
                <HeaderBar
                  topic="Funds Transfer"
                  text={Number(
                    props.balance.available_balance
                  ).toLocaleString()}
                />
              </Container>
              <Grid container style={{ padding: "1px 0", height: "100%" }}>
                <Grid item xs={12} md={6} lg={6} className="card-container">
                  <div className={!inputdisabled ? "notcard" : "card"}>
                    <div className={!inputdisabled ? "overlay" : ""}></div>
                    <CardHeaderNew
                      info={
                        props.user.kyc_level === 1
                          ? "Transfer limit - NGN10,000"
                          : props.user.kyc_level === 2
                          ? "Transfer limit - NGN50,000"
                          : "Transfer limit - NGN1,000,000"
                      }
                    />

                    <FormGroup row style={{ marginTop: "20px" }}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={checkedB}
                            onChange={handleChangeToggle}
                            name="checkedB"
                            color="primary"
                          />
                        }
                        label="Transfer to a saved beneficary"
                      />
                    </FormGroup>

                    <CardBody
                      cardstyle={{
                        padding: "1em 1em 1em",
                        width: "300px",
                      }}
                      className="card2"
                    >
                      <form onSubmit={handleSubmit(onSubmit)}>
                        {show ? (
                          <div>
                            <InputGrid>
                              <Select
                                styles={{
                                  control: (base, state) => ({
                                    ...base,
                                    "&:hover": { borderColor: "black" },
                                    border: "none",
                                    borderRadius: "none",
                                    borderBottom: "1px solid #999999",
                                    boxShadow: "none",
                                    padding: "0",
                                  }),
                                }}
                                placeholder="Select Beneficiary"
                                value={selectedBeneficiary}
                                onChange={handleChangeGetBeneficary}
                                options={beneficiaryOptions}
                                // isDisabled={!inputdisabled}
                              />
                            </InputGrid>

                            <InputGrid>
                              <input
                                name="dest_account_name"
                                onChange={handleChange}
                                value={instName}
                                className="input-div"
                                placeholder="Beneficiary Account Name"
                                disabled
                              />
                            </InputGrid>

                            <InputGrid>
                              <input
                                className="input-div"
                                disabled={!inputdisabled}
                                type="number"
                                onChange={handleChangeValidateBank}
                                name="account_no"
                                value={account_no}
                                placeholder="Account Number"
                              />
                            </InputGrid>
                          </div>
                        ) : (
                          <div>
                            <InputGrid>
                              <Select
                                styles={{
                                  control: (base, state) => ({
                                    ...base,
                                    "&:hover": { borderColor: "black" },
                                    border: "none",
                                    borderRadius: "none",
                                    fontSize: "12px",
                                    borderBottom: "1px solid #999999",
                                    boxShadow: "none",
                                    padding: "0",
                                  }),
                                }}
                                placeholder="Select Bank"
                                value={selectedOption}
                                onChange={handleChangeGetBank}
                                options={options}
                                isDisabled={!inputdisabled}
                              />
                            </InputGrid>
                            <InputGrid>
                              <input
                                className="input-div"
                                maxLength="10"
                                disabled={!inputdisabled}
                                //type="number"
                                name="account_no"
                                value={acc}
                                onChange={handleChangeValidateBank}
                                placeholder="Account Number"
                              />
                            </InputGrid>
                          </div>
                        )}

                        <InputGrid>
                          <input
                            name="dest_account_name"
                            onChange={handleChange}
                            value={dest_account_name}
                            className="input-div"
                            placeholder="Beneficiary Name"
                            disabled
                          />
                        </InputGrid>

                        <InputGrid>
                          <input
                            name="amount"
                            value={valueDisplay}
                            onChange={handleChange}
                            className="input-div"
                            placeholder="NGN Amount"
                            disabled={!inputdisabled}
                          />
                        </InputGrid>

                        <InputGrid>
                          <input
                            name="narration"
                            value={narration}
                            className="input-div"
                            onChange={handleChange}
                            placeholder="Narration"
                            disabled={!inputdisabled}
                          />
                        </InputGrid>

                        <div>
                          <Grid container spacing={1} alignItems="flex-end">
                            <Grid item xs={12} md={12} lg={12}>
                              <Button
                                onClick={handleClickOpen}
                                fullWidth
                                variant="contained"
                                size="small"
                                disabled={!disabled}
                                className="getri-button"
                                style={{
                                  backgroundColor: !disabled ? "" : "#0037ba",
                                  color: "#fff",
                                }}
                              >
                                Continue
                              </Button>

                              <Dialog
                                open={reciept}
                                onClose={closeReciept}
                                aria-labelledby="form-dialog-title"
                              >
                                <DialogContent>
                                  <Grid
                                    container
                                    style={{
                                      width: "25em",
                                    }}
                                  >
                                    <Grid item xs={12} md={12} lg={12}>
                                      <div>
                                        <div className="reciept_head">
                                          <span>Transaction Reciept</span>
                                        </div>
                                        <div className="reciept_date">
                                          <span>{recieptdata.date}</span>
                                        </div>
                                        <div className="reciept_time">
                                          <span>12:45AM</span>
                                        </div>
                                        <div className="reciept_status">
                                          <span>success</span>
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                      <div
                                        style={{
                                          paddingTop: "10px",
                                        }}
                                      >
                                        <div>
                                          <span
                                            style={{
                                              backgroundColor: "#eee",
                                              fontSize: "10px",
                                            }}
                                          >
                                            TRANSACTION TYPE
                                          </span>
                                        </div>
                                        <div className="reciept_type">
                                          <span>Funds Transfer</span>
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          paddingTop: "10px",
                                        }}
                                      >
                                        <div>
                                          <span
                                            style={{
                                              backgroundColor: "#eee",
                                              fontSize: "10px",
                                            }}
                                          >
                                            TRANSACTION ID
                                          </span>
                                        </div>
                                        <div className="trans_id">
                                          <span>
                                            {recieptdata.transactionID}
                                          </span>
                                        </div>
                                      </div>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                      <div className="box-holder">
                                        <div className="funding_acct">
                                          <div className="from_topic">
                                            <span>Funding Account</span>
                                          </div>
                                          <div className="from_body">
                                            <img
                                              alt="logo"
                                              src="/images/yellow-logo.svg"
                                              style={{
                                                width: "1.8em",
                                                height: "1.8em",
                                              }}
                                            />
                                            <div className="bank_name">
                                              <span>Getripay</span>
                                            </div>
                                            <div className="acct_num">
                                              <span>
                                                {recieptdata.owners_acct_num}
                                              </span>
                                            </div>
                                            <div className="owner_name">
                                              <span>
                                                {recieptdata.owners_acct_name}
                                              </span>
                                            </div>
                                            <div className="status sender">
                                              <span>SENT</span>
                                              <span>
                                                NGN {recieptdata.amount}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="dir_arrow">
                                          <div className="arrow">
                                            <span>{">"}</span>
                                          </div>
                                        </div>
                                        <div className="recieving_acct">
                                          <div className="to_topic">
                                            <span>Recieving Account</span>
                                          </div>
                                          <div className="from_body">
                                            <img
                                              alt="logo"
                                              src={recieptdata.bank_logo}
                                              style={{
                                                width: "1.8em",
                                                height: "1.8em",
                                              }}
                                            />
                                            <div className="bank_name">
                                              <span>
                                                {recieptdata.bank_name}
                                              </span>
                                            </div>
                                            <div className="acct_num">
                                              <span>
                                                {recieptdata.reciever_acct_num}
                                              </span>
                                            </div>
                                            <div className="owner_name">
                                              <span>
                                                {recieptdata.reciever_name}
                                              </span>
                                            </div>
                                            <div className="status reciever">
                                              <span>RECIEVED</span>
                                              <span>
                                                NGN {recieptdata.amount}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Grid>
                                  </Grid>
                                  <div
                                    style={{
                                      width: "100%",
                                      margin: "auto",
                                    }}
                                  >
                                    <TransferReceipt data={recieptdata} />
                                  </div>
                                </DialogContent>
                              </Dialog>
                            </Grid>
                          </Grid>
                        </div>
                      </form>
                    </CardBody>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  style={{ backgroundColor: "#f6f6f7" }}
                  className="card-container"
                >
                  {showSuccessCard()}
                </Grid>
              </Grid>
            </Grid>
            {/* Shows set pin modal if a user has not set transaction pin */}
            <SetPin />
          </Grid>
        </main>
        <BottomNav {...props} />
        <Footer />
      </MainContent>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    get_bank: (payload) => dispatch(transfers.get_bank(payload)),
    validate_bank: (payload) => dispatch(transfers.validate_bank(payload)),
    transfers: (payload) => dispatch(transfers.final_transfer(payload)),
    save_beneficiary: (
      dest_account_no,
      dest_bank_code,
      dest_account_name,
      bankName,
      pin
    ) =>
      dispatch(
        transfers.save_beneficiary(
          dest_account_no,
          dest_bank_code,
          dest_account_name,
          bankName,
          pin
        )
      ),
    get_beneficiaries: (payload) =>
      dispatch(transfers.get_beneficiaries(payload)),
    //get_transaction_services: (payload) => dispatch(transactions.get_transaction_services(payload)),
    get_transaction_fee: (amount, serviceCode) =>
      dispatch(transactions.get_transaction_fee(amount, serviceCode)),
    get_account_balance: (payload) =>
      dispatch(transactions.get_account_balance(payload)),
    clear: () => dispatch(transfers.clear_success()),
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.userDetails.data,
    isFetching: state.fetch.isFetching,
    banks: state.fetch.data,
    account: state.validate.data,
    isValidating: state.validate.isValidating,
    isTransferring: state.transfer.isTransferring,
    transfermsg: state.transfer.res_code,
    transferData: state.transfer.res_data,
    saveBeneficiary: state.saveBeneficiary.data,
    isSavingBeneficiary: state.saveBeneficiary.isSavingBeneficiary,
    listBeneficiary: state.getBeneficiary.data,
    fee: state.transactionFee.data,
    balance: state.getAccountBalance.data,
    // transfermsg: state.checkLoanEligibility.success,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Transfer);
