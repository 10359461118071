import { userConstants } from "../actions/types";

const initialState = {
    isLoading:false,
    data: [],
};

export function getQrMerchantSubmerchant(state = initialState, action) {
    switch (action.type) {
        case userConstants.GET_MERCHANT_SUBMERCHANT_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case userConstants.GET_MERCHANT_SUBMERCHANT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            }
        default:
            return state;
    }
};

export function createQrMerchantSubmerchant(state = initialState, action) {
    switch (action.type) {
        case userConstants.CREATE_MERCHANT_SUBMERCHANT_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case userConstants.CREATE_MERCHANT_SUBMERCHANT_SUCCESS:
            return {
                ...state,
                isLoading: false,
            }
        default:
            return state;
    }
};

export function createSubmerchant(state = initialState, action) {
    switch (action.type) {
        case userConstants.CREATE_SUBMERCHANT_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case userConstants.CREATE_SUBMERCHANT_SUCCESS:
            return {
                ...state,
                isLoading: false,
            }
        default:
            return state;
    }
};