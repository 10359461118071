import React from 'react';
import {Grid, Paper} from '@material-ui/core';
import ChangePassword from '../../components/Security/ChangePassword';
import SecurityQuestion from '../../components/Security/SecurityQuestion';
import TransactionPin from '../../components/Security/TransactionPin';

function SecurityCard({ props }) {
	return (
		<div>
			<Grid item xs={12} sm={12} md={7} lg={7}>
				<p className='edit-settings'> Security</p>
				<div>
					<p className='security-header'>Change Password</p>
					<Paper className='settingsBox2'>
						<div className='settings-input-div'>
							<ChangePassword />
						</div>
					</Paper>
				</div>

				<hr
					style={{
						color: 'blue',
						marginLeft: '13px',
						marginTop: '2em',
						marginBottom: '2em',
						marginRight: '16px',
						width: '89%',
					}}
				/>
				<div>
					<p className='security-header'>Set Secuirity Question</p>
					<Paper className='settingsBox2'>
						<div className='settings-input-div'>
							<SecurityQuestion />
						</div>
					</Paper>
				</div>

				<hr
					style={{
						color: 'blue',
						marginLeft: '13px',
						marginTop: '2em',
						marginBottom: '2em',
						marginRight: '16px',
						width: '89%',
					}}
				/>
				<div>
					<p className='security-header'>Set Transaction Pin</p>
					<Paper className='settingsBox2'>
						<div className='settings-input-div'>
							<TransactionPin />
						</div>
					</Paper>
				</div>

			</Grid>
			{/* <Grid item xs={12} sm={12} md={5} lg={5}>
				<Paper className='settingsBox3'>
					<div className='settingsBox3-inner'>
						<p className='card-text-sm'>
							<br />
							lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum
						</p>
					</div>
				</Paper>
			</Grid> */}
		</div>
	);
}

export default SecurityCard;
