import React, { useEffect, useState } from "react";
import { Button, Container, Grid, Radio, RadioGroup, FormControl, FormControlLabel} from "@material-ui/core";
import { connect } from "react-redux";
import BackDrop from '../../components/BackDrop';
import transactions from "../../services/transactionService";
import TopBar from "../../components/TopBar";
import SideNav from "../../components/SideNav";
import BottomNav from "../../components/bottomNavigation";
import { HeaderBar, CardHeader, InputGrid, CardBody, MainContent, Footer, DocSuccessCard } from "../../components/Appearance";
import { Helmet } from 'react-helmet';
import upgradeKyc from "../../services/upgradeAccountService";

function UpgradeAccount(props) {
    const [images, setImages] = useState('');
    const [value, setValue] = React.useState('');
    const [submitted, setSubmitted] = useState(false);
    const [toggle, setToggle] = useState(true);
    const [sidenav, setSidenav] = useState("getri-sidenav");
    const [disabled, setDisabled] = useState(false);

    const [document, setDocument] = useState({
        user_id: props.user.id,
        kyc_level_name: "Getripay Gold",
        kyc_level_id: "3",
        name: value,
        identification_number: '',
        type: 'document'
    });

    const handleToggle = () => {
        const mediaScreen = window.matchMedia(
            `(max-width: 700px),
        (min-device-width: 768px)
        and (max-device-width: 1024px)`
        ); //returns true if

        if (mediaScreen.matches) {
            setSidenav("slideOut");
            setToggle(true);
        } else {
            setToggle(!toggle);
        }
    };
    const hideSidebar = () => {
        setSidenav("slideIn");
    };

    useEffect(() => {
        props.get_account_balance();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setDocument({
            user_id: props.user.id,
            kyc_level_name: "Getripay Gold",
            kyc_level_id: "3",
            name: value,
            identification_number: '',
            type: 'document'
        })
    }, [value]); // eslint-disable-line react-hooks/exhaustive-deps


    function handleChange(e) {
        const { name, value } = e.target;
        setDocument((document) => ({ ...document, [name]: value }));
    }

    const handleChangeRadio = (event) => {
        setValue(event.target.value);
    };

    const token = props.user.token

    function handleSubmit() {
        if (images && document.identification_number) {
            setDisabled(false);
            props.upgrade_to_kyc_3(document, images.file, token)
        };
    };

    const handleChangePhoto = event => {
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            let file = event.target.files[0];
            reader.onloadend = () => {
                setImages({
                    ...images,
                    imagePreview: reader.result,
                    file: file
                });
            };
            reader.readAsDataURL(file);
        }
    };

    function showSuccessCard() {
        let showCard;
        if (props.docStat === true) {
            showCard = (
                <DocSuccessCard />
            );
        }
        return showCard;
    }

    return (
        <>
            <Helmet>
                <title>GetriPay - Fastest growing digital bank with rewards </title>
                <meta name="description" content="Upgrade Account" />

            </Helmet>
            <TopBar handleToggle={handleToggle} />
            <SideNav toggle={toggle} className={sidenav} hideSidebar={hideSidebar}  {...props} />
            <MainContent toggle={toggle}>
                {props.isLoading ? (
                    <div>
                        <BackDrop />
                    </div>
                ) : (
                        ""
                    )}
                <main className="main">
                    <Grid container>
                        <Grid item xs={12} md={12} lg={12} style={{ height: "inherit" }}>
                            <Container className="main-container" >
                                <HeaderBar topic="Account Upgrade" text={Number(props.balance.available_balance).toLocaleString()} />
                            </Container>
                            <Grid container style={{ padding: "1px 0", height: "100%" }}>
                                <Grid item xs={12} md={6} lg={6} className="card-container" >
                                    <div className="card">
                                        <CardHeader info="Please provide the following information/document" />
                                        <FormControl component="fieldset" style={{ marginBottom: '30px' }}>
                                            <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChangeRadio}>
                                                <FormControlLabel value="National ID Card" control={<Radio />} label="National ID Card" />
                                                <FormControlLabel value="Driver's License" control={<Radio />} label="Driver's License" />
                                                <FormControlLabel value="Voter's Card" control={<Radio />} label="Voter's Card " />
                                                <FormControlLabel value="International Passport" control={<Radio />} label="International Passport " />
                                            </RadioGroup>
                                        </FormControl>
                                        <CardBody
                                            cardstyle={{
                                                padding: "1em 1em 1em",
                                                width: "300px",
                                            }}
                                            className="card2">
                                            <form style={{ marginTop: '40px' }} >


                                                <InputGrid >
                                                    <input
                                                        onChange={handleChange}
                                                        className="input-div"
                                                        type="text"
                                                        name="identification_number"
                                                        value={document.identification_number}
                                                        placeholder="Enter document identifcation number"
                                                    />
                                                </InputGrid>

                                                <InputGrid>

                                                    <label className='custom-file-upload'>

                                                        <input

                                                           // accept='image, doc, pdf, docx'
                                                            accept='image'
                                                            id='contained-button-file'
                                                            single='true'
                                                            style={{ display: 'none' }}
                                                            type='file'
                                                            name='images'
                                                            onChange={handleChangePhoto}
                                                            className={'form-control' + (submitted && !images ? ' is-invalid' : '')}

                                                        />

                                                    Click to select document

                                                </label>
                                                </InputGrid>

                                                {submitted && !images && <div className='invalid-feedback'>Please upload a profile picture</div>}

                                                <label htmlFor='contained-button-file'>

                                                    {/* preview */}
                                                    {images.imagePreview ?
                                                        <img src={images.imagePreview} style={{ width: '100%', height: '20em', marginBottom: '1em' }} />
                                                        :
                                                        ''
                                                    }

                                                    {/* preview */}

                                                </label>

                                                <div>
                                                    <Grid container spacing={1} alignItems="flex-end">
                                                        <Grid item xs={12} md={6} lg={6}></Grid>
                                                        <Grid item xs={12} md={12} lg={12}>
                                                            <Button
                                                                onClick={handleSubmit}
                                                                fullWidth
                                                                variant="contained"
                                                                size="small"
                                                                className="getri-button"
                                                                style={{
                                                                    backgroundColor: !disabled ? "#0037ba" : "#0037ba",
                                                                    color: "#fff",
                                                                }}
                                                            >

                                                                Upload
                                                            </Button>

                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </form>
                                        </CardBody>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6} lg={6} style={{ backgroundColor: "#f6f6f7" }} className="card-container">
                                    {showSuccessCard()}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </main>
                <BottomNav {...props} />
                <Footer />
            </MainContent>
        </>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        upgrade_to_kyc_3: (document, images, token) => dispatch(upgradeKyc.upgrade_to_kyc_3(document, images, token)),
        get_account_balance: (payload) => dispatch(transactions.get_account_balance(payload)),
    };
};

const mapStateToProps = (state) => {
    return {
        user: state.userDetails.data,
        balance: state.getAccountBalance.data,
        docStat: state.upgradeAccount.data,
        isLoading: state.upgradeAccount.isLoading
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeAccount);
