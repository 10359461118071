import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, FormControl, Radio, RadioGroup, FormControlLabel, Button, MenuItem, Select, Popover, Typography } from "@material-ui/core";
import "../../../css/dashboard.css";
import TopBar from "../../../components/TopBar";
import { MainContent, Footer, HeaderBar } from "../../../components/Appearance";
import SideNav from "../../../components/SideNav";
import BottomNav from "../../../components/bottomNavigation";
import { Helmet } from 'react-helmet';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import thisStyles from './styles'
import { connect } from "react-redux";
import savings from "../../../services/savingsService";
import ConfirmSaveModal from './components/ConfirmSaveModal';
import { history } from "../../../store/history";
import numeral from "numeral";
import transactions from "../../../services/transactionService";


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        backgroundColor: "white",
    },
    content: {
        flexGrow: 1,
        backgroundColor: "white",
    },
    container: {
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(4),
        backgroundColor: "white",
    },
    large: {
        width: 100,
        height: 100,
    },
    customBadge: {
        color: "white",
        marginRight: '-55px',
        width: '120px'
    },
    fixed: {
        color: '#ff0000',
        fontSize: '12px',
        display: 'block'
    },
    others: {
        color: '#000',
        fontWeight: '700',
        fontSize: '14px'
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        marginTop: '-80px',
        padding: theme.spacing(1),
    },
}));

function StyledRadio(props) {
    const classes = thisStyles();
    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    );
}

export const AutomateSwitch = withStyles((theme) => ({
    switchBase: {
        color: theme.palette.grey[500],
        '&$checked': {
            color: theme.palette.blue,
            '& + $track': {
                opacity: 1,
                backgroundColor: 'blue',
                borderColor: '1.5px solid	#87CEFA',
            },
        },
    },
    track: {
        border: `1.0px solid ${theme.palette.grey[500]}`,
        backgroundColor: theme.palette.common.white,
    },
    checked: {
        color: '#f50057',
    },
}))(Switch);

function NewPlan(props) {
    const classes = useStyles();
    const [toggle, setToggle] = useState(true);
    const [sidenav, setSidenav] = useState("getri-sidenav");
    const [value, setValue] = useState('daily');
    const [value2, setValue2] = useState('0');
    const [showDate, setShowDate] = useState('');
    const [start, setStart] = useState(new Date().toLocaleDateString('en-CA'));
    const [open, setOpen] = useState(false);
    const [checked, setChecked] = useState(true);
    const [saveName, setSaveName] = useState('');
    const [saveAmount, setSaveAmount] = useState('');
    const [targetAmount, setTargetAmount] = useState(0);
    const [rangeAmount, setRangeAmount] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [si, setSi] = useState(0);
    const [newDay, setNewDay] = useState('');

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const openPop = Boolean(anchorEl);

    const handleToggle = () => {
        const mediaScreen = window.matchMedia(
            `(max-width: 700px),
        (min-device-width: 768px)
        and (max-device-width: 1024px)`
        ); //returns true if

        if (mediaScreen.matches) {
            setSidenav("slideOut");
        } else {
            setToggle(!toggle);
        }
    };
    const hideSidebar = () => {
        setSidenav("slideIn");
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const automateSavings = (event) => {
        setChecked((prev) => !prev);
    };

    const [save, setSave] = useState({
        savings_product_id: props.details.id,
        user_id: props.user.id,
        name: saveName,
        user_group_id: 0,
        amount: props.details.amount === '0.00' && props.details.amount_type !== 'range' ? saveAmount : props.details.amount_type === 'range' ? rangeAmount : props.details.amount,
        trans_ref: `${props.user.id}` + Math.floor(Math.random() * 900000000000000000),
        account_no: props.balance.account_number,
        transaction_pin: '',
        auto_save: checked,
        frequency_type: value ? value : 'daily',
        start_date: start,
        maturity_date: showDate ? showDate : '',
        target_amount: props.details.amount === '0.00' && props.details.amount_type !== 'range' ? targetAmount : props.details.amount_type === 'range' ? targetAmount : props.details.amount,
    });

    const product_id = props.match.params.slug;
  
    useEffect(() => {
        props.get_savings_product_detail(product_id);
        props.get_account_balance()
    }, []);


    useEffect(() => {
        calcDate();
        calcTarget();
        convertToDay();
        checkRangeAmount();
        checkRangeAmount2()
        setSave({
            savings_product_id: props.details.id,
            user_id: props.user.id,
            name: saveName,
            user_group_id: 0,
            amount: props.details.amount === '0.00' && props.details.amount_type !== 'range' && value2 === '0' ? saveAmount : props.details.amount === '0.00' && props.details.amount_type !== 'range' && value2 !== '0' ? rangeAmount : props.details.amount_type === 'range' ? rangeAmount : props.details.amount,
            trans_ref: `${props.user.id}` + Math.floor(Math.random() * 9000),
            account_no: props.balance.account_number,
            transaction_pin: '',
            auto_save: checked,
            frequency_type: value ? value : 'daily',
            start_date: start,
            maturity_date: showDate ? showDate : '',
            target_amount: props.details.amount === '0.00' && props.details.amount_type !== 'range' ? targetAmount : props.details.amount_type === 'range' ? targetAmount : props.details.amount,
        })
    }, [props.details, showDate, start, value, value2, saveName, saveAmount, targetAmount, checked, save.target_amount, save.amount, rangeAmount]);

    useEffect(() => {
        if (value2 === '0' && props.details.amount_type !== 'range') {
            setTargetAmount(0);
        }
    }, [value2, targetAmount, rangeAmount, saveAmount, props.details]);

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const handleChange2 = (event) => {
        setValue2(event.target.value);
    };

    function handleChangeSave(e) {
        const { name, value } = e.target;
        setSave((save) => ({ ...save, [name]: value }));
    };

    function handleChangeStart(e) {
        setStart(e.target.value)
    };
    function handleChangeSaveName(e) {
        setSaveName(e.target.value)
    };
    function handleChangeSaveAmount(e) {
        setSaveAmount(e.target.value)
    };
    function handleChangeTargetAmount(e) {
        setTargetAmount(e.target.value)
    };

    function buildOptions() {
        var arr = [];
        for (let i = Number(props.details.min_amount); i <= Number(props.details.max_amount); i += Number(props.details.amount_step)) {
            arr.push(
                <MenuItem key={i} value={i}>
                    NGN {i}
                </MenuItem>
            );
        }
        return arr;
    };

    function daysOfAYear(year) {
        return isLeapYear(year) ? 366 : 365;
    }
    function isLeapYear(year) {
        return year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0);
    }

    let days_in_a_year = daysOfAYear(new Date(start).getFullYear());

    const formAmt = save.target_amount && props.details.amount_type !== 'range' ? save.target_amount.replace(/,/g, "") : save.target_amount && props.details.amount_type === 'range' ? save.target_amount : ''

    function calcTarget() {
        // This is to convert the yearly rate to a daily rate
        let rate = props.details.interest_rate / days_in_a_year
        const mat = props.details.amount_type === 'fixed' || props.details.amount_type === 'range' ? props.details.maturity_period : Number(value2)

        // To get simple interest
        if (props.details.maturity_period_type === 'month') {
            setSi((Number(formAmt) * rate * (mat * 30)) / 100)
        } else if (props.details.maturity_period_type === 'day') {
            setSi((Number(formAmt) * rate * (mat * 1)) / 100)
        } else if (props.details.maturity_period_type === 'week') {
            setSi((Number(formAmt) * rate * (mat * 7)) / 100)
        } else if (props.details.maturity_period_type === 'year') {
            setSi((Number(formAmt) * rate * (mat * days_in_a_year)) / 100)
        };
    };

    function convertToDay() {
        if (props.details.maturity_period_type === 'month') {
            setNewDay(props.details.maturity_period * 30)
        } else if (props.details.maturity_period_type === 'day') {
            setNewDay(props.details.maturity_period * 1)
        } else if (props.details.maturity_period_type === 'week') {
            setNewDay(props.details.maturity_period * 7)
        } else if (props.details.maturity_period_type === 'year') {
            setNewDay(props.details.maturity_period * days_in_a_year)
        }
    };
    const estimate = (si + Number(formAmt)).toFixed(2);
    const conDate = Number(value2);

    function calcDate() {
        if (newDay) {
            const targetDate = new Date(start);
            targetDate.setDate(targetDate.getDate() + (newDay));
            setShowDate((targetDate).toLocaleDateString('en-CA'))
        } else if (props.details.maturity_period_type === 'day' && value2) {
            const targetDate = new Date(start);
            targetDate.setDate(targetDate.getDate() + conDate);
            setShowDate((targetDate).toLocaleDateString('en-CA'))
        };
    };

    // Check amount for user on a range product
    function checkRangeAmount() {
        if (value === 'daily' && props.details.amount_type === 'range' && value2 === '0') {

            let newRange = (save.target_amount) / newDay
            console.log(newRange)
            setRangeAmount((newRange).toFixed(2))
        } else if (save.frequency_type === 'daily' && value === '' && props.details.amount_type === 'range' && value2 === '0') {
            let newRange = (save.target_amount) / newDay
            setRangeAmount((newRange).toFixed(2))
        } else if (value === 'weekly' && props.details.amount_type === 'range' && value2 === '0') {
            let newRange = (save.target_amount) / Math.floor(newDay / 7)
            setRangeAmount(newRange)
        } else if (value === 'monthly' && props.details.amount_type === 'range' && value2 === '0') {
            let newRange = (save.target_amount) / (newDay / 30)
            setRangeAmount((newRange).toFixed(2))
        }
    };


    const valueDisplay = numeral(save.amount).format();
    const valueDisplay2 = props.details.amount === '0.00' && props.details.amount_type !== 'range' && numeral(save.target_amount).format()

    const dest = save.target_amount && props.details.amount === '0.00' && props.details.amount_type !== 'range' ? (save.target_amount).replace(/,/g, "") : '';

    function checkRangeAmount2() {
        if (value === 'daily' && props.details.amount === '0.00' && props.details.amount_type !== 'range' && value2 !== '0') {
            let newRange = (dest) / Number(value2)
            setRangeAmount((newRange).toFixed(2))
        } else if (value === 'weekly' && props.details.amount === '0.00' && props.details.amount_type !== 'range' && value2 !== '0') {
            let newRange = (dest) / Math.floor(Number(value2) / 7)
            setRangeAmount(newRange)
        } else if (value === 'monthly' && props.details.amount === '0.00' && props.details.amount_type !== 'range' && value2 !== '0') {
            let newRange = (dest) / (Number(value2) / 30)
            setRangeAmount((newRange).toFixed(2))
        }
    };

    function submit() {
        if (save.transaction_pin) {
            props.create_savings_plan(save)
        }
    };

    return (
        <>
            <Helmet>
                <title>GetriPay - Fastest growing digital bank with rewards </title>
                <meta name="description" content="Savings" />
                <meta name="keywords" content="savings" />
            </Helmet>
            <TopBar handleToggle={handleToggle} />
            <SideNav toggle={toggle} className={sidenav} hideSidebar={hideSidebar} {...props} />
            <MainContent toggle={toggle}>
                <main className={classes.content}>
                    <Grid container>
                        <Grid item xs={12} md={12} lg={12}>
                            <Container maxWidth="lg" className={classes.container}>
                                <Container className="main-container">
                                    <HeaderBar topic={props.details.name} text={Number(props.balance.available_balance).toLocaleString()} />
                                </Container>
                            </Container>
                        </Grid>

                        <Container >
                            <Container>
                                <Grid container>
                                    <Grid item lg={8}>
                                        <Grid container spacing={3} style={{ alignItems: 'center' }}>

                                            <Grid item lg={6}>
                                                <h4>Customize your Savings Plan</h4>
                                            </Grid>
                                            {props.details.amount_type === 'fixed' ?
                                                <Grid item lg={6}>
                                                    <div style={{ display: 'flex', float: 'right' }}>

                                                    </div>
                                                </Grid>
                                                :
                                                <Grid item lg={6}>
                                                    <div style={{ display: 'flex', float: 'right' }}>
                                                        <p>Automate This Savings?</p>
                                                        <div style={{ marginTop: '10px' }}
                                                            onMouseEnter={handlePopoverOpen}
                                                            onMouseLeave={handlePopoverClose}
                                                            aria-owns={openPop ? 'mouse-over-popover' : undefined}
                                                        >
                                                            <AutomateSwitch
                                                                checked={checked}
                                                                onChange={automateSavings}
                                                                name="checked"

                                                            />
                                                            <Popover
                                                                id="mouse-over-popover"
                                                                className={classes.popover}
                                                                classes={{ paper: classes.paper }}
                                                                open={openPop}
                                                                anchorEl={anchorEl}
                                                                onClose={handlePopoverClose}
                                                                disableRestoreFocus
                                                            >
                                                                <Typography>Your GetriPay account will be <br />debited periodically
                                                                based on <br />the frequency type set by you
                                                                </Typography>
                                                            </Popover>

                                                        </div>
                                                    </div>
                                                </Grid>
                                            }
                                            <Grid item lg={6}>
                                                <span>What are you saving for ? </span>
                                            </Grid>
                                            <Grid item lg={6}>
                                                <div>
                                                    <input
                                                        name='saveName' value={saveName} onChange={handleChangeSaveName}
                                                        type='text' className="input-div" placeholder="Purpose of the savings" />
                                                    <span className={classes.fixed}>For example: Birthday, anniversary, new car, rent</span>
                                                </div>
                                            </Grid>

                                            {props.details && props.details.amount_type !== 'fixed' ?
                                                <>
                                                    <Grid item lg={6}>
                                                        <p>Frequency of savings </p>
                                                        <span className={classes.fixed}>How frequently do you want to add to this savings</span>
                                                    </Grid>
                                                    <Grid item lg={6}>
                                                        <div >
                                                            <FormControl component="fieldset">
                                                                <RadioGroup aria-label="value" name="value" value={value} onChange={handleChange}>
                                                                    <FormControlLabel value="daily" control={<StyledRadio />} label="Daily" />
                                                                    <FormControlLabel value="weekly" control={<StyledRadio />} label="Weekly" />
                                                                    <FormControlLabel value="monthly" control={<StyledRadio />} label="Monthly" />
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </div>
                                                    </Grid>

                                                </>
                                                :
                                                ''
                                            }

                                            {props.details.amount_type !== 'variable' ?
                                                <Grid item lg={6}>
                                                    <p>Length/Period of savings </p>
                                                </Grid>
                                                :
                                                <Grid item lg={6}>
                                                    <p>How long would you like to save for? </p>
                                                </Grid>
                                            }

                                            {props.details.amount_type !== 'variable' && props.details.maturity_period === 0 ?
                                                <Grid item lg={6}>
                                                    <FormControl component="fieldset">
                                                        <RadioGroup aria-label="value2" name="value2" value={value2} onChange={handleChange2}>
                                                            <FormControlLabel value="daily" control={<StyledRadio />} label="3 months" />
                                                            <FormControlLabel value="weekly" control={<StyledRadio />} label="6 months" />
                                                            <FormControlLabel value="monthly" control={<StyledRadio />} label="1 year" />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                                :
                                                props.details.amount_type === 'variable' && props.details.maturity_period === 0 ?
                                                    <Grid item lg={6}>
                                                        <FormControl component="fieldset" >
                                                            <RadioGroup aria-label="value2" name="value2" value={value2} onChange={handleChange2}>
                                                                <Grid container>
                                                                    <Grid item lg={6}>
                                                                        <FormControlLabel value='0' control={<StyledRadio />} label="No maturity date" />
                                                                        <FormControlLabel value='30' control={<StyledRadio />} label="30 days" />
                                                                        <FormControlLabel value='60' control={<StyledRadio />} label="60 days" />
                                                                    </Grid>

                                                                    <Grid item lg={6}>
                                                                        <FormControlLabel value='90' control={<StyledRadio />} label="90 days" />
                                                                        <FormControlLabel value='120' control={<StyledRadio />} label="120 days" />
                                                                        <FormControlLabel value='150' control={<StyledRadio />} label="150 days" />
                                                                        <FormControlLabel value='180' control={<StyledRadio />} label="180 days" />
                                                                    </Grid>
                                                                </Grid>

                                                            </RadioGroup>
                                                        </FormControl>
                                                        <span className={classes.fixed}>NOTE: This is optional </span>
                                                    </Grid>
                                                    :
                                                    <div>
                                                        <span>{props.details.maturity_period}{' '}{props.details.maturity_period_type}s - ({newDay} days)</span>

                                                        <span className={classes.fixed}>NOTE: This period is fixed </span>
                                                    </div>

                                            }




                                            <Grid item lg={6}>
                                                {props.details.amount === '0.00' && props.details.amount_type !== 'range' ?
                                                    <>
                                                        {value2 === '0' ?
                                                            ''
                                                            :
                                                            <p>Total Savings Target</p>
                                                        }

                                                    </>

                                                    :
                                                    ''
                                                }

                                            </Grid>

                                            <Grid item lg={6}>
                                                {props.details.amount === '0.00' && props.details.amount_type !== 'range' ?
                                                    <div >
                                                        {value2 === '0' ?
                                                            ''
                                                            :
                                                            <input
                                                                value={valueDisplay2}
                                                                name='targetAmount' onChange={handleChangeTargetAmount}
                                                                type='text'
                                                                className="input-div"
                                                                placeholder="Please enter your savings target"
                                                            />
                                                        }
                                                    </div>
                                                    :
                                                    ''
                                                }
                                            </Grid>



                                            <Grid item lg={6}>
                                                {props.details.amount !== '0.00' && props.details.amount_type === 'range' ?
                                                    <p>Select  a target amount</p>
                                                    :
                                                    props.details.amount_type === 'fixed' ?
                                                        <div >
                                                            <p>Amount to save </p>
                                                        </div>
                                                        :
                                                        <div >
                                                            <p>Initial amount to save ? </p>
                                                        </div>
                                                }

                                            </Grid>

                                            {props.details.amount === '0.00' && props.details.amount_type !== 'range' ?
                                                <>
                                                    <Grid item lg={6}>
                                                        <div >
                                                            <input
                                                                disabled={value2 !== '0'}
                                                                name='amount' value={valueDisplay} onChange={handleChangeSaveAmount}
                                                                type='text'
                                                                className="input-div"
                                                                placeholder="Amount to save now"
                                                            />
                                                        </div>
                                                    </Grid>
                                                </>
                                                :
                                                props.details.amount_type === 'range' ?
                                                    <>
                                                        <Grid item lg={6}>
                                                            <div >
                                                                <Select
                                                                    onChange={handleChangeTargetAmount}
                                                                    className="input-div"
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    value={targetAmount}
                                                                    name='targetAmount'
                                                                >
                                                                    {buildOptions()}
                                                                </Select>
                                                            </div>
                                                            <div>
                                                                <div style={{ marginTop: '20px' }}>
                                                                    <p style={{ color: '#33cc33', fontWeight: '700' }}>Initial amount to save: NGN{Number(rangeAmount).toLocaleString()} {save.frequency_type}</p>

                                                                </div>
                                                            </div>
                                                        </Grid>
                                                    </>
                                                    :
                                                    <Grid item lg={6}>
                                                        <span>NGN {Number(props.details.amount).toLocaleString()}</span>
                                                        <hr />
                                                        <span className={classes.fixed}>NOTE: This amount is fixed </span>
                                                    </Grid>

                                            }

                                            <Grid item lg={6}>
                                                <p>When will you like to start saving? </p>
                                            </Grid>
                                            <Grid item lg={6}>
                                                <div >
                                                    <input step='1' type='datetime-local' name='start' value={start} onChange={handleChangeStart} />
                                                </div>
                                            </Grid>


                                        </Grid>
                                    </Grid>

                                    <Grid item lg={4} sm={12} xs={12} style={{ marginBottom: "100px" }}>

                                        <div className='preview'>
                                            <Grid container style={{ alignItems: 'center' }}>
                                                <Grid item lg={6}>
                                                    <h5>Preview</h5>
                                                </Grid>
                                                <Grid>
                                                    <p className='back' onClick={history.goBack}>Go back</p>
                                                </Grid>
                                            </Grid>
                                            <div className='sav-section' >
                                                <p style={{ color: '#33cc33', fontSize: "16px", textTransform: "capitalize" }}>{save.name}</p>
                                                <Grid container>
                                                    <Grid item lg={6}>Amount to save now</Grid>
                                                    <Grid item lg={6} className={classes.others}>
                                                        {props.details.amount === '0.00' || props.details.amount_type === 'range' ?
                                                            valueDisplay
                                                            :
                                                            Number(props.details.amount).toLocaleString()
                                                        }
                                                    </Grid>
                                                </Grid>

                                                <hr />
                                                <Grid container>
                                                    <Grid item lg={6}>Start Date</Grid>
                                                    <Grid item lg={6} className={classes.others}>{save.start_date}</Grid>
                                                </Grid>
                                                <hr />
                                                <Grid container>
                                                    <Grid item lg={6}>Maturity Date</Grid>
                                                    <Grid item lg={6} className={classes.others}> {showDate}  </Grid>
                                                </Grid>
                                                <hr />
                                                <Grid container>
                                                    <Grid item lg={6}>Interest Rate</Grid>
                                                    <Grid item lg={6} className={classes.others} >{props.details.interest_rate}% P.A</Grid>
                                                </Grid>
                                                <hr />
                                                <Grid container>
                                                    <Grid item lg={6}>Estimated Returns</Grid>
                                                    <Grid item lg={6} className={classes.others}>NGN {Number(estimate).toLocaleString()}</Grid>
                                                </Grid>
                                                <hr />
                                                {value && value2 && targetAmount && rangeAmount &&
                                                    <p>
                                                        To meet your savings target of
                                                        <span style={{ color: "#0037ba", fontWeight: "900", fontSize: '14px' }}> NGN{Number(dest).toLocaleString()}</span>, you would need to save
                                                        <span style={{ color: "#0037ba", fontWeight: "900", fontSize: '14px' }}>  NGN{Number(rangeAmount).toLocaleString()} {value}</span>,
                                                        for a total of  <span style={{ color: "#0037ba", fontWeight: "900", fontSize: '14px' }}> {value2} </span> days.
                                                    </p>
                                                }

                                                <hr />
                                                <Grid container>
                                                    <Button
                                                        fullWidth
                                                        variant="contained"
                                                        size="small"
                                                        color="primary"
                                                        className="getri-button submit"
                                                        onClick={handleClickOpen}
                                                    >
                                                        Continue
                                                   </Button>
                                                    <ConfirmSaveModal
                                                        amount={props.details.amount === '0.00' || props.details.amount_type === 'range' ?
                                                            valueDisplay
                                                            :
                                                            Number(props.details.amount).toLocaleString()
                                                        }
                                                        save={save}
                                                        data={props.details}
                                                        open={open} close={handleClose}
                                                        value={save.transaction_pin}
                                                        change={handleChangeSave}
                                                        submit={submit}
                                                        loading={props.loading}
                                                        val={value2}
                                                    />

                                                </Grid>
                                            </div>
                                        </div>

                                    </Grid>
                                </Grid>

                            </Container>

                        </Container>
                    </Grid>
                    <BottomNav {...props} />
                    <Footer />
                </main>
            </MainContent>

        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        get_savings_product_detail: (product_id) => dispatch(savings.get_savings_product_detail(product_id)),
        create_savings_plan: (payload) => dispatch(savings.create_savings_plan(payload)),
        get_account_balance: (payload) => dispatch(transactions.get_account_balance(payload)),
    };
};

const mapStateToProps = (state) => {
    return {
        user: state.userDetails.data,
        details: state.getSavingsProductDetail.data,
        data: state.createSavingsPlan.data,
        loading: state.createSavingsPlan.isLoading,
        balance: state.getAccountBalance.data,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewPlan);
