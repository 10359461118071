import { userConstants } from "../actions/types";

export const userActions = {
  uiRequesting,
  uiResponded,
  loginClear,
  loginUserRequest,
  loginUserSuccess,
  loginUserFailure,
  verifyLoginRequest,
  verifyLoginSuccess,
  updateAccountInformation,
  updateUserObject,
  uiAuthenticating,
  uiAuthenticated,
  uiFetching,
  uiFetched,
  uiValidating,
  uiValidated,
  validateReferralRequest,
  validateReferralSuccess,
  validateReferralFailure,
  uiFinalTransferring,
  uiFinalTransferred,
  transferFailure,
  getBillersRequest,
  getBillersSuccess,
  getBillerItemRequest,
  getBillerItemSuccess,
  getBillerItemFailure,
  getBillerProductRequest,
  getBillerProductSuccess,
  getBillerProductFailure,
  validateBillRequest,
  validateBillSuccess,
  validateBillFailure,
  payBillRequest,
  payBillSuccess,
  payBillFailure,
  checkLoanEligibilityRequest,
  checkLoanEligibilitySuccess,
  checkLoanEligibilityFailure,
  calculateLoanRequest,
  calculateLoanSuccess,
  logout,
  getTransactionCountRequest,
  getTransactionCountSuccess,
  getFrequentTransactionRequest,
  getFrequentTransactionSuccess,
  getTransactionLimitRequest,
  getTransactionLimitSuccess,
  getAccountBalanceRequest,
  getAccountBalanceSuccess,
  verifyBvnRequest,
  verifyBvnSuccess,
  verifyBvnFailure,
  verifyBvnOtpRequest,
  verifyBvnOtpSuccess,
  saveBeneficiaryRequest,
  saveBeneficiarySuccess,
  saveBeneficiaryFailure,
  getAllBeneficiaryRequest,
  getAllBeneficiarySuccess,
  deleteBeneficiaryRequest,
  deleteBeneficiarySuccess,
  deleteBeneficiaryFailure,
  getTransactionFeeRequest,
  getTransactionFeeSuccess,
  getLoanHistoryRequest,
  getLoanHistorySuccess,
  clearSuccess,
  clearSuccessTransfer,
  registerBvnRequest,
  registerBvnSuccess,
  registerBvnFailure,
  validateRegisterBvnOtpRequest,
  validateRegisterBvnOtpSuccess,
  validateRegisterBvnOtpFailure,
  validateRegisterBvnRequest,
  validateRegisterBvnSuccess,
  validateRegisterBvnFailure,
  submitSecurityQuestionRequest,
  submitSecurityQuestionSuccess,
  submitSecurityQuestionFailure,
  loginSecurityQuestionRequest,
  loginSecurityQuestionSuccess,
  loginSecurityQuestionFailure,
  getAllNotificationRequest,
  getAllNotificationSuccess,
  notificationToggleRequest,
  notificationToggleSuccess,
  getAccountStatementRequest,
  getAccountStatementSuccess,
  getPhysicalCardRequest,
  getPhysicalCardSuccess,
  getPhysicalCardFailure,
  cardStatusRequest,
  cardStatusSuccess,
  cardStatusFailure,
  upgradeAccountRequest,
  upgradeAccountSuccess,
  upgradeAccountFailure,
  documentStatusRequest,
  documentStatusSuccess,
  documentStatusFailure,
  getVirtualCardRequest,
  getVirtualCardSuccess,
  getVirtualCardFailure,
  getDollarRateRequest,
  getDollarRateSuccess,
  getDollarRateFailure,
  userVirtualCardRequest,
  userVirtualCardSuccess,
  userVirtualCardFailure,
  fundVirtualCardRequest,
  fundVirtualCardSuccess,
  fundVirtualCardFailure,
  withdrawVirtualCardRequest,
  withdrawVirtualCardSuccess,
  withdrawVirtualCardFailure,
  blockVirtualCardRequest,
  blockVirtualCardSuccess,
  blockVirtualCardFailure,
  terminateVirtualCardRequest,
  terminateVirtualCardSuccess,
  terminateVirtualCardFailure,
  virtualCardTransactionsRequest,
  virtualCardTransactionsSuccess,
  virtualCardTransactionsFailure,
  confirmPinRequest,
  confirmPinSuccess,
  confirmPinFailure,
  maskCardPin,
  clearRate,

  loyaltyRequest,
  loyaltySuccess,
  chargebackRequest,
  chargebackSuccess,
  chargebackValueRequest,
  chargebackValueSuccess,
  chargebackConfigRequest,
  chargebackConfigSuccess,

  savingsProductRequest,
  savingsProductSuccess,
  listUserSavingsRequest,
  listUserSavingsSuccess,
  savingsProductDetailRequest,
  savingsProductDetailSuccess,
  createSavingsPlanRequest,
  createSavingsPlanSuccess,
  createSavingsPlanFailure,
  walletBalanceRequest,
  walletBalanceSuccess,
  savingsHistoryRequest,
  savingsHistorySuccess,
  savingsSummaryRequest,
  savingsSummarySuccess,
  fundSavingsRequest,
  fundSavingsSuccess,
  fundSavingsFailure,
  withdrawSavingsRequest,
  withdrawSavingsSuccess,
  withdrawSavingsFailure,
  changeDateRequest,
  changeDateSuccess,
  changeDateFailure,
  showDateRequest,
  showDateSuccess,
  createGroupSavingsRequest,
  createGroupSavingsSuccess,
  createGroupSavingsFailure,
  userGroupSavingsRequest,
  userGroupSavingsSuccess,
  allSavingsGroupRequest,
  allSavingsGroupSuccess,
  savingsGroupDetailRequest,
  savingsGroupDetailSuccess,
  joinGroupSavingsRequest,
  joinGroupSavingsSuccess,
  joinGroupSavingsFailure,
  viewGroupInfoRequest,
  viewGroupInfoSuccess,
  groupWalletRequest,
  groupWalletSuccess,
  inviteToGroupRequest,
  inviteToGroupSuccess,
  getAuthorizeRequest,
  getAuthorizeSuccess,
  savingsNotificationsRequest,
  savingsNotificationsSuccess,
  walletTransactionsRequest,
  walletTransactionsSuccess,
  getInactiveSavingsRequest,
  getInactiveSavingsSuccess,
  availableWalletTranscationsRequest,
  availableWalletTranscationsSuccess,

  getQrMerchantSubmerchantRequest,
  getQrMerchantSubmerchantSuccess,
  createQrMerchantSubmerchantRequest,
  createQrMerchantSubmerchantSuccess,
  createSubmerchantRequest,
  createSubmerchantSuccess,

  validateBulkPaymentRequest,
  validateBulkPaymentSuccess,
  processBulkPaymentRequest,
  processBulkPaymentSuccess,
  getBulkHistoryRequest,
  getBulkHistorySuccess,
  getBulkDetailsRequest,
  getBulkDetailsSuccess,
  switchActiveAccount,

  getNewAccountRequest,
  getNewAccountSuccess,
  submitNewAccountRequest,
  submitNewAccountSuccess,

  getEarningsRequest,
  getEarningsSuccess,
  getInterestHistoryRequest,
  getInterestHistorySuccess,
};

function uiRequesting() {
  return {
    type: userConstants.REQUESTING,
  };
}

function uiResponded(payload) {
  return {
    type: userConstants.RESPONDED,
    payload,
  };
}

function loginUserRequest() {
  return {
    type: userConstants.LOGIN_USER_REQUEST,
  };
}

function loginUserSuccess(payload) {
  return {
    type: userConstants.LOGIN_USER_SUCCESS,
    payload,
  };
}
function loginUserFailure(payload) {
  return {
    type: userConstants.LOGIN_USER_FAILURE,
    payload,
  };
}

function loginClear() {
  return {
    type: userConstants.LOGIN_CLEAR,
  };
}

function verifyLoginRequest() {
  return {
    type: userConstants.VERIFY_LOGIN_REQUEST,
  };
}

function verifyLoginSuccess(payload) {
  return {
    type: userConstants.VERIFY_LOGIN_SUCCESS,
    payload,
  };
}

// UPDATE ACCOUNT INFORMATION
function updateAccountInformation(payload) {
  return {
    type: userConstants.UPDATE_ACCOUNT_INFORMATION,
    payload,
  };
}

// UPDATE USER OBJECT
function updateUserObject(payload) {
  return {
    type: userConstants.UPDATE_USER_OBJECT,
    payload,
  };
}

function uiAuthenticating() {
  return {
    type: userConstants.VERIFYING,
  };
}

function uiAuthenticated() {
  return {
    type: userConstants.VERIFIED,
  };
}

function uiFetching() {
  return {
    type: userConstants.FETCHING,
  };
}

function uiFetched(data) {
  return {
    type: userConstants.FETCHED,
    payload: data,
  };
}

function uiValidating() {
  return {
    type: userConstants.VALIDATING,
  };
}

function uiValidated(data) {
  return {
    type: userConstants.VALIDATED,
    payload: data,
  };
}

// Validate referral code
function validateReferralRequest() {
  return {
    type: userConstants.VALIDATE_REFERRAL_REQUEST,
  };
}

function validateReferralSuccess(data) {
  return {
    type: userConstants.VALIDATE_REFERRAL_SUCCESS,
    payload: data,
  };
}

function validateReferralFailure() {
  return {
    type: userConstants.VALIDATE_REFERRAL_FAILURE,
  };
}

function uiFinalTransferring() {
  return {
    type: userConstants.TRANSFERRING,
  };
}

function uiFinalTransferred(data) {
  return {
    type: userConstants.TRANSFERRED,
    payload: data,
  };
}

function transferFailure(data) {
  return {
    type: userConstants.TRANSFER_FAILURE,
    payload: data,
  };
}

// BILLS
// Get list of all billers
function getBillersRequest() {
  return {
    type: userConstants.GET_BILLERS_REQUEST,
  };
}

function getBillersSuccess(data) {
  return {
    type: userConstants.GET_BILLERS_SUCCESS,
    payload: data,
  };
}

// Get a particular biller item
function getBillerItemRequest() {
  return {
    type: userConstants.GET_BILLER_ITEM_REQUEST,
  };
}

function getBillerItemSuccess(data) {
  return {
    type: userConstants.GET_BILLER_ITEM_SUCCESS,
    payload: data,
  };
}

function getBillerItemFailure() {
  return {
    type: userConstants.GET_BILLER_ITEM_FAILURE,
  };
}

// Get biller products from biller item
function getBillerProductRequest() {
  return {
    type: userConstants.GET_BILLER_PRODUCT_REQUEST,
  };
}

function getBillerProductSuccess(data) {
  return {
    type: userConstants.GET_BILLER_PRODUCT_SUCCESS,
    payload: data,
  };
}

function getBillerProductFailure() {
  return {
    type: userConstants.GET_BILLER_PRODUCT_FAILURE,
  };
}

// Validate bill details
function validateBillRequest() {
  return {
    type: userConstants.VALIDATE_BILL_REQUEST,
  };
}

function validateBillSuccess(data) {
  return {
    type: userConstants.VALIDATE_BILL_SUCCESS,
    payload: data,
  };
}

function validateBillFailure() {
  return {
    type: userConstants.VALIDATE_BILL_FAILURE,
  };
}

// Pay for bill item
function payBillRequest() {
  return {
    type: userConstants.PAY_BILL_REQUEST,
  };
}

function payBillSuccess(data) {
  return {
    type: userConstants.PAY_BILL_SUCCESS,
    payload: data,
  };
}
function payBillFailure() {
  return {
    type: userConstants.PAY_BILL_FAILURE,
    //payload: data,
  };
}

// Check loan Eligibilty
function checkLoanEligibilityRequest() {
  return {
    type: userConstants.CHECK_LOAN_ELIGIBILITY_REQUEST,
  };
}

function checkLoanEligibilitySuccess(data) {
  return {
    type: userConstants.CHECK_LOAN_ELIGIBILITY_SUCCESS,
    payload: data,
  };
}

function checkLoanEligibilityFailure(data) {
  return {
    type: userConstants.CHECK_LOAN_ELIGIBILITY_FAILURE,
    payload: data,
  };
}

// Calculate loan
function calculateLoanRequest() {
  return {
    type: userConstants.CALCULATE_LOAN_REQUEST,
  };
}

function calculateLoanSuccess(data) {
  return {
    type: userConstants.CALCULATE_LOAN_SUCCESS,
    payload: data,
  };
}

// Transaction count
function getTransactionCountRequest() {
  return {
    type: userConstants.GET_TRANS_COUNT_REQUEST,
  };
}

function getTransactionCountSuccess(data) {
  return {
    type: userConstants.GET_TRANS_COUNT_SUCCESS,
    payload: data,
  };
}

// Loans History for user
function getLoanHistoryRequest() {
  return {
    type: userConstants.GET_LOANS_HISTORY_REQUEST,
  };
}

function getLoanHistorySuccess(data) {
  return {
    type: userConstants.GET_LOANS_HISTORY_SUCCESS,
    payload: data,
  };
}

// Frequent Transaction
function getFrequentTransactionRequest() {
  return {
    type: userConstants.GET_FREQUENT_TRANS_REQUEST,
  };
}

function getFrequentTransactionSuccess(data) {
  return {
    type: userConstants.GET_FREQUENT_TRANS_SUCCESS,
    payload: data,
  };
}

// Frequent Transaction
function getTransactionLimitRequest() {
  return {
    type: userConstants.GET_TRANS_LIMIT_REQUEST,
  };
}

function getTransactionLimitSuccess(data) {
  return {
    type: userConstants.GET_TRANS_LIMIT_SUCCESS,
    payload: data,
  };
}

// Transaction Fee
function getTransactionFeeRequest() {
  return {
    type: userConstants.GET_TRANS_FEE_REQUEST,
  };
}

function getTransactionFeeSuccess(data) {
  return {
    type: userConstants.GET_TRANS_FEE_SUCCESS,
    payload: data,
  };
}

// Account Balance
function getAccountBalanceRequest() {
  return {
    type: userConstants.GET_ACCOUNT_BALANCE_REQUEST,
  };
}

function getAccountBalanceSuccess(data) {
  return {
    type: userConstants.GET_ACCOUNT_BALANCE_SUCCESS,
    payload: data,
  };
}

// BVN VALIDATION
function verifyBvnRequest() {
  return {
    type: userConstants.VERIFY_BVN_REQUEST,
  };
}

function verifyBvnSuccess(data) {
  return {
    type: userConstants.VERIFY_BVN_SUCCESS,
    payload: data,
  };
}

function verifyBvnFailure(data) {
  return {
    type: userConstants.VERIFY_BVN_FAILURE,
  };
}

function verifyBvnOtpRequest() {
  return {
    type: userConstants.VERIFY_BVN_OTP_REQUEST,
  };
}

function verifyBvnOtpSuccess(data) {
  return {
    type: userConstants.VERIFY_BVN_OTP_SUCCESS,
    payload: data,
  };
}

function saveBeneficiaryRequest() {
  return {
    type: userConstants.SAVE_BENEFICIARY_REQUEST,
  };
}

function saveBeneficiarySuccess(data) {
  return {
    type: userConstants.SAVE_BENEFICIARY_SUCCESS,
    payload: data,
  };
}
function saveBeneficiaryFailure(data) {
  return {
    type: userConstants.SAVE_BENEFICIARY_FAILURE,
  };
}

function getAllBeneficiaryRequest() {
  return {
    type: userConstants.GET_ALL_BENEFICIARY_REQUEST,
  };
}

function getAllBeneficiarySuccess(data) {
  return {
    type: userConstants.GET_ALL_BENEFICIARY_SUCCESS,
    payload: data,
  };
}

function deleteBeneficiaryRequest() {
  return {
    type: userConstants.DELETE_BENEFICIARY_REQUEST,
  };
}

function deleteBeneficiarySuccess(data) {
  return {
    type: userConstants.DELETE_BENEFICIARY_SUCCESS,
    payload: data,
  };
}

function deleteBeneficiaryFailure() {
  return {
    type: userConstants.DELETE_BENEFICIARY_FAILURE,
  };
}

// LOGOUT
function logout() {
  return {
    type: userConstants.LOGOUT,
  };
}

// clear success box
function clearSuccess() {
  return {
    type: userConstants.CLEAR,
  };
}

function clearSuccessTransfer() {
  return {
    type: userConstants.CLEAR_TRANSFER,
  };
}

// REGISTER WITH  BVN
function registerBvnRequest() {
  return {
    type: userConstants.REGISTER_BVN_REQUEST,
  };
}

function registerBvnSuccess(data) {
  return {
    type: userConstants.REGISTER_BVN_SUCCESS,
    payload: data,
  };
}

function registerBvnFailure() {
  return {
    type: userConstants.REGISTER_BVN_FAILURE,
  };
}

function validateRegisterBvnOtpRequest() {
  return {
    type: userConstants.VALIDATE_REGISTER_BVN_OTP_REQUEST,
  };
}

function validateRegisterBvnOtpSuccess(data) {
  return {
    type: userConstants.VALIDATE_REGISTER_BVN_OTP_SUCCESS,
    payload: data,
  };
}

function validateRegisterBvnOtpFailure() {
  return {
    type: userConstants.VALIDATE_REGISTER_BVN_OTP_FAILURE,
  };
}

function validateRegisterBvnRequest() {
  return {
    type: userConstants.VALIDATE_REGISTER_BVN_REQUEST,
  };
}

function validateRegisterBvnSuccess(data) {
  return {
    type: userConstants.VALIDATE_REGISTER_BVN_SUCCESS,
    payload: data,
  };
}

function validateRegisterBvnFailure() {
  return {
    type: userConstants.VALIDATE_REGISTER_BVN_FAILURE,
  };
}
// REGISTER WITH  BVN

// SUBMIT SECUIRTY QUESTION
function submitSecurityQuestionRequest() {
  return {
    type: userConstants.SUBMIT_SECURITY_QUESTION_REQUEST,
  };
}

function submitSecurityQuestionSuccess(data) {
  return {
    type: userConstants.SUBMIT_SECURITY_QUESTION_SUCCESS,
    payload: data,
  };
}

function submitSecurityQuestionFailure() {
  return {
    type: userConstants.SUBMIT_SECURITY_QUESTION_FAILURE,
  };
}

// SUBMIT SECURITY QUESTION

// LOGIN SECUIRTY QUESTION
function loginSecurityQuestionRequest() {
  return {
    type: userConstants.LOGIN_SECURITY_QUESTION_REQUEST,
  };
}

function loginSecurityQuestionSuccess(data) {
  return {
    type: userConstants.LOGIN_SECURITY_QUESTION_SUCCESS,
    payload: data,
  };
}

function loginSecurityQuestionFailure(data) {
  return {
    type: userConstants.LOGIN_SECURITY_QUESTION_FAILURE,
    payload: data,
  };
}

// LOGIN SECURITY QUESTION

// NOTIFICATION SERVICE
function getAllNotificationRequest() {
  return {
    type: userConstants.GET_ALL_NOTIFICATION_REQUEST,
  };
}

function getAllNotificationSuccess(data) {
  return {
    type: userConstants.GET_ALL_NOTIFICATION_SUCCESS,
    payload: data,
  };
}

function notificationToggleRequest() {
  return {
    type: userConstants.NOTIFICATION_TOGGLE_REQUEST,
  };
}

function notificationToggleSuccess(data) {
  return {
    type: userConstants.NOTIFICATION_TOGGLE_SUCCESS,
    payload: data,
  };
}
// NOTIFICATION SERVICE

// ACCOUNT STATEMENT
function getAccountStatementRequest() {
  return {
    type: userConstants.ACCOUNT_STATEMENT_REQUEST,
  };
}

function getAccountStatementSuccess(data) {
  return {
    type: userConstants.ACCOUNT_STATEMENT_SUCCESS,
    payload: data,
  };
}
// ACCOUNT STATEMENT

// PHYSICAL CARD REQUEST
function getPhysicalCardRequest() {
  return {
    type: userConstants.GET_PHYSICAL_CARD_REQUEST,
  };
}

function getPhysicalCardSuccess(data) {
  return {
    type: userConstants.GET_PHYSICAL_CARD_SUCCESS,
    payload: data,
  };
}

function getPhysicalCardFailure() {
  return {
    type: userConstants.GET_PHYSICAL_CARD_FAILURE,
  };
}

// PHYSICAL CARD REQUEST

// PHYSICAL CARD REQUEST
function cardStatusRequest() {
  return {
    type: userConstants.CARD_STATUS_REQUEST,
  };
}

function cardStatusSuccess(data) {
  return {
    type: userConstants.CARD_STATUS_SUCCESS,
    payload: data,
  };
}

function cardStatusFailure() {
  return {
    type: userConstants.CARD_STATUS_FAILURE,
  };
}

// PHYSICAL CARD REQUEST

//  UPGRADE ACCOUNT
function upgradeAccountRequest() {
  return {
    type: userConstants.UPGRADE_ACCOUNT_REQUEST,
  };
}

function upgradeAccountSuccess(data) {
  return {
    type: userConstants.UPGRADE_ACCOUNT_SUCCESS,
    payload: data,
  };
}

function upgradeAccountFailure() {
  return {
    type: userConstants.UPGRADE_ACCOUNT_FAILURE,
  };
}

// UPGRADE ACCOUNT

//  UPGRADE ACCOUNT
function documentStatusRequest() {
  return {
    type: userConstants.DOCUMENT_STATUS_REQUEST,
  };
}

function documentStatusSuccess(data) {
  return {
    type: userConstants.DOCUMENT_STATUS_SUCCESS,
    payload: data,
  };
}

function documentStatusFailure() {
  return {
    type: userConstants.DOCUMENT_STATUS_FAILURE,
  };
}

// UPGRADE ACCOUNT

// VIRTUAL CARD REQUEST
function getVirtualCardRequest() {
  return {
    type: userConstants.GET_VIRTUAL_CARD_REQUEST,
  };
}

function getVirtualCardSuccess(data) {
  return {
    type: userConstants.GET_VIRTUAL_CARD_SUCCESS,
    payload: data,
  };
}

function getVirtualCardFailure() {
  return {
    type: userConstants.GET_VIRTUAL_CARD_FAILURE,
  };
}

// VIRTUAL CARD REQUEST

// VIRTUAL CARD RATE
function getDollarRateRequest() {
  return {
    type: userConstants.GET_DOLLAR_RATE_REQUEST,
  };
}

function getDollarRateSuccess(data) {
  return {
    type: userConstants.GET_DOLLAR_RATE_SUCCESS,
    payload: data,
  };
}

function getDollarRateFailure() {
  return {
    type: userConstants.GET_DOLLAR_RATE_FAILURE,
  };
}

// VIRTUAL CARD RATE

// VIRTUAL CARD RATE
function userVirtualCardRequest() {
  return {
    type: userConstants.USER_VIRTUAL_CARD_REQUEST,
  };
}

function userVirtualCardSuccess(data) {
  return {
    type: userConstants.USER_VIRTUAL_CARD_SUCCESS,
    payload: data,
  };
}

function userVirtualCardFailure(data) {
  return {
    type: userConstants.USER_VIRTUAL_CARD_FAILURE,
    payload: data,
  };
}

function clearRate() {
  return {
    type: userConstants.CLEAR_RATE,
  };
}

// VIRTUAL CARD RATE

// VIRTUAL CARD FUND
function fundVirtualCardRequest() {
  return {
    type: userConstants.FUND_VIRTUAL_CARD_REQUEST,
  };
}

function fundVirtualCardSuccess(data) {
  return {
    type: userConstants.FUND_VIRTUAL_CARD_SUCCESS,
    payload: data,
  };
}

function fundVirtualCardFailure() {
  return {
    type: userConstants.FUND_VIRTUAL_CARD_FAILURE,
  };
}

// VIRTUAL CARD FUND

// VIRTUAL CARD WITHDRAW
function withdrawVirtualCardRequest() {
  return {
    type: userConstants.WITHDRAW_VIRTUAL_CARD_REQUEST,
  };
}

function withdrawVirtualCardSuccess(data) {
  return {
    type: userConstants.WITHDRAW_VIRTUAL_CARD_SUCCESS,
    payload: data,
  };
}

function withdrawVirtualCardFailure() {
  return {
    type: userConstants.WITHDRAW_VIRTUAL_CARD_FAILURE,
  };
}
// VIRTUAL CARD WITHDRAW

// VIRTUAL CARD BLOCK
function blockVirtualCardRequest() {
  return {
    type: userConstants.BLOCK_VIRTUAL_CARD_REQUEST,
  };
}

function blockVirtualCardSuccess(data) {
  return {
    type: userConstants.BLOCK_VIRTUAL_CARD_SUCCESS,
    payload: data,
  };
}

function blockVirtualCardFailure() {
  return {
    type: userConstants.BLOCK_VIRTUAL_CARD_FAILURE,
  };
}

// VIRTUAL CARD BLOCK

// VIRTUAL CARD TERMINATE
function terminateVirtualCardRequest() {
  return {
    type: userConstants.TERMINATE_VIRTUAL_CARD_REQUEST,
  };
}

function terminateVirtualCardSuccess(data) {
  return {
    type: userConstants.TERMINATE_VIRTUAL_CARD_SUCCESS,
    payload: data,
  };
}

function terminateVirtualCardFailure() {
  return {
    type: userConstants.TERMINATE_VIRTUAL_CARD_FAILURE,
  };
}

// VIRTUAL CARD TERMINATE

// VIRTUAL CARD TRANSACTIONS
function virtualCardTransactionsRequest() {
  return {
    type: userConstants.VIRTUAL_CARD_TRANSACTIONS_REQUEST,
  };
}

function virtualCardTransactionsSuccess(data) {
  return {
    type: userConstants.VIRTUAL_CARD_TRANSACTIONS_SUCCESS,
    payload: data,
  };
}

function virtualCardTransactionsFailure() {
  return {
    type: userConstants.VIRTUAL_CARD_TRANSACTIONS_FAILURE,
  };
}

// VIRTUAL CARD TRANSACTIONS

// CONFIRM TRANSACTION PIN
function confirmPinRequest() {
  return {
    type: userConstants.CONFIRM_PIN_REQUEST,
  };
}

function confirmPinSuccess(data) {
  return {
    type: userConstants.CONFIRM_PIN_SUCCESS,
    payload: data,
  };
}

function confirmPinFailure() {
  return {
    type: userConstants.CONFIRM_PIN_FAILURE,
  };
}

function maskCardPin() {
  return {
    type: userConstants.MASK_CARD_PIN,
  };
}

// VIRTUAL CARD TRANSACTIONS

// LOYALTY//

// Getting a user's loyalty
function loyaltyRequest() {
  return {
    type: userConstants.LOYALTY_REQUEST,
  };
}

function loyaltySuccess(data) {
  return {
    type: userConstants.LOYALTY_SUCCESS,
    payload: data,
  };
}
// Getting a user's loyalty;

// Getting a user's chargeback
function chargebackRequest() {
  return {
    type: userConstants.CHARGEBACK_REQUEST,
  };
}

function chargebackSuccess(data) {
  return {
    type: userConstants.CHARGEBACK_SUCCESS,
    payload: data,
  };
}

function chargebackValueRequest() {
  return {
    type: userConstants.CHARGEBACK_VALUE_REQUEST,
  };
}

function chargebackValueSuccess(data) {
  return {
    type: userConstants.CHARGEBACK_VALUE_SUCCESS,
    payload: data,
  };
}

function chargebackConfigRequest() {
  return {
    type: userConstants.CHARGEBACK_CONFIG_REQUEST,
  };
}

function chargebackConfigSuccess(data) {
  return {
    type: userConstants.CHARGEBACK_CONFIG_SUCCESS,
    payload: data,
  };
}

// Getting a user's chargeback

// -------------------------
// SAVINGS

// GET SAVINGS PRODUCTS
function savingsProductRequest() {
  return {
    type: userConstants.SAVINGS_PRODUCT_REQUEST,
  };
}

function savingsProductSuccess(data) {
  return {
    type: userConstants.SAVINGS_PRODUCT_SUCCESS,
    payload: data,
  };
}

// GET ALL THE SAVINGS A USER HAS
function listUserSavingsRequest() {
  return {
    type: userConstants.LIST_USER_SAVINGS_REQUEST,
  };
}

function listUserSavingsSuccess(data) {
  return {
    type: userConstants.LIST_USER_SAVINGS_SUCCESS,
    payload: data,
  };
}

// GET DETAILS ABOUT A PARTICULAR PRODUCT
function savingsProductDetailRequest() {
  return {
    type: userConstants.SAVINGS_PRODUCT_DETAIL_REQUEST,
  };
}

function savingsProductDetailSuccess(data) {
  return {
    type: userConstants.SAVINGS_PRODUCT_DETAIL_SUCCESS,
    payload: data,
  };
}

// CREATE A USER'S SAVINGS PLAN
function createSavingsPlanRequest() {
  return {
    type: userConstants.CREATE_SAVINGS_PLAN_REQUEST,
  };
}

function createSavingsPlanSuccess(data) {
  return {
    type: userConstants.CREATE_SAVINGS_PLAN_SUCCESS,
    payload: data,
  };
}

function createSavingsPlanFailure() {
  return {
    type: userConstants.CREATE_SAVINGS_PLAN_FAILURE,
  };
}

// GET A USER'S WALLET BALANCE
function walletBalanceRequest() {
  return {
    type: userConstants.WALLET_BALANCE_REQUEST,
  };
}

function walletBalanceSuccess(data) {
  return {
    type: userConstants.WALLET_BALANCE_SUCCESS,
    payload: data,
  };
}

// GET A USER'S WALLET BALANCE
function savingsSummaryRequest() {
  return {
    type: userConstants.SAVINGS_SUMMARY_REQUEST,
  };
}

function savingsSummarySuccess(data) {
  return {
    type: userConstants.SAVINGS_SUMMARY_SUCCESS,
    payload: data,
  };
}

// GET A USER'S WALLET BALANCE
function savingsHistoryRequest() {
  return {
    type: userConstants.SAVINGS_HISTORY_REQUEST,
  };
}

function savingsHistorySuccess(data) {
  return {
    type: userConstants.SAVINGS_HISTORY_SUCCESS,
    payload: data,
  };
}

// FUND A USER'S SAVINGS PLAN
function fundSavingsRequest() {
  return {
    type: userConstants.FUND_SAVINGS_REQUEST,
  };
}

function fundSavingsSuccess(data) {
  return {
    type: userConstants.FUND_SAVINGS_SUCCESS,
    payload: data,
  };
}

function fundSavingsFailure() {
  return {
    type: userConstants.FUND_SAVINGS_FAILURE,
  };
}

// WITHDRAW FROM A USER'S SAVINGS PLAN
function withdrawSavingsRequest() {
  return {
    type: userConstants.WITHDRAW_SAVINGS_REQUEST,
  };
}

function withdrawSavingsSuccess(data) {
  return {
    type: userConstants.WITHDRAW_SAVINGS_SUCCESS,
    payload: data,
  };
}

function withdrawSavingsFailure() {
  return {
    type: userConstants.WITHDRAW_SAVINGS_FAILURE,
  };
}

// Change a user's withdrawal date
function changeDateRequest() {
  return {
    type: userConstants.CHANGE_DATE_REQUEST,
  };
}

function changeDateSuccess(data) {
  return {
    type: userConstants.CHANGE_DATE_SUCCESS,
    payload: data,
  };
}

function changeDateFailure() {
  return {
    type: userConstants.CHANGE_DATE_FAILURE,
  };
}

// Show a user's withdrawal date
function showDateRequest() {
  return {
    type: userConstants.SHOW_DATE_REQUEST,
  };
}

function showDateSuccess(data) {
  return {
    type: userConstants.SHOW_DATE_SUCCESS,
    payload: data,
  };
}

// CREATE A GROUP SAVINGS
function createGroupSavingsRequest() {
  return {
    type: userConstants.CREATE_GROUP_SAVINGS_REQUEST,
  };
}

function createGroupSavingsSuccess(data) {
  return {
    type: userConstants.CREATE_GROUP_SAVINGS_SUCCESS,
    payload: data,
  };
}

function createGroupSavingsFailure() {
  return {
    type: userConstants.CREATE_GROUP_SAVINGS_FAILURE,
  };
}

// GET A USER'S GROUP SAVINGS
function userGroupSavingsRequest() {
  return {
    type: userConstants.USER_GROUP_SAVINGS_REQUEST,
  };
}

function userGroupSavingsSuccess(data) {
  return {
    type: userConstants.USER_GROUP_SAVINGS_SUCCESS,
    payload: data,
  };
}

// GET ALL SAVINGS GROUP
function allSavingsGroupRequest() {
  return {
    type: userConstants.ALL_SAVINGS_GROUP_REQUEST,
  };
}

function allSavingsGroupSuccess(data) {
  return {
    type: userConstants.ALL_SAVINGS_GROUP_SUCCESS,
    payload: data,
  };
}

// GET A PARTICULAR SAVINGS GROUP DETAIL
function savingsGroupDetailRequest() {
  return {
    type: userConstants.SAVINGS_GROUP_DETAIL_REQUEST,
  };
}

function savingsGroupDetailSuccess(data) {
  return {
    type: userConstants.SAVINGS_GROUP_DETAIL_SUCCESS,
    payload: data,
  };
}

// JOIN A GROUP SAVINGS
function joinGroupSavingsRequest() {
  return {
    type: userConstants.JOIN_GROUP_SAVINGS_REQUEST,
  };
}

function joinGroupSavingsSuccess(data) {
  return {
    type: userConstants.JOIN_GROUP_SAVINGS_SUCCESS,
    payload: data,
  };
}

function joinGroupSavingsFailure() {
  return {
    type: userConstants.JOIN_GROUP_SAVINGS_FAILURE,
  };
}

// VIEW GROUP SAVINGS INFO
function viewGroupInfoRequest() {
  return {
    type: userConstants.VIEW_GROUP_INFO_REQUEST,
  };
}

function viewGroupInfoSuccess(data) {
  return {
    type: userConstants.VIEW_GROUP_INFO_SUCCESS,
    payload: data,
  };
}

// GET A GROUP WALLET
function groupWalletRequest() {
  return {
    type: userConstants.GROUP_WALLET_REQUEST,
  };
}

function groupWalletSuccess(data) {
  return {
    type: userConstants.GROUP_WALLET_SUCCESS,
    payload: data,
  };
}

// INVITE TO GROUP
function inviteToGroupRequest() {
  return {
    type: userConstants.INVITE_TO_GROUP_REQUEST,
  };
}

function inviteToGroupSuccess(data) {
  return {
    type: userConstants.INVITE_TO_GROUP_SUCCESS,
    payload: data,
  };
}

// Authorize withdrawal on group savings
function getAuthorizeRequest() {
  return {
    type: userConstants.GET_AUTHORIZE_REQUEST,
  };
}

function getAuthorizeSuccess(data) {
  return {
    type: userConstants.GET_AUTHORIZE_SUCCESS,
    payload: data,
  };
}

// savings notifications settings
function savingsNotificationsRequest() {
  return {
    type: userConstants.SAVINGS_NOTIFICATIONS_REQUEST,
  };
}

function savingsNotificationsSuccess(data) {
  return {
    type: userConstants.SAVINGS_NOTIFICATIONS_SUCCESS,
    payload: data,
  };
}

// Wallet Transactions
function walletTransactionsRequest() {
  return {
    type: userConstants.WALLET_TRANSACTIONS_REQUEST,
  };
}

function walletTransactionsSuccess(data) {
  return {
    type: userConstants.WALLET_TRANSACTIONS_SUCCESS,
    payload: data,
  };
}

function availableWalletTranscationsRequest() {
  return {
    type: userConstants.AVAILABLE_WALLET_TRANSACTIONS_REQUEST,
  };
}

function availableWalletTranscationsSuccess(data) {
  return {
    type: userConstants.AVAILABLE_WALLET_TRANSACTIONS_SUCCESS,
    payload: data,
  };
}

// Getting Inactive User Savings
function getInactiveSavingsRequest() {
  return {
    type: userConstants.GET_INACTIVE_SAVINGS_REQUEST,
  };
}

function getInactiveSavingsSuccess(data) {
  return {
    type: userConstants.GET_INACTIVE_SAVINGS_SUCCESS,
    payload: data,
  };
}

// QR

// Getting QR merchant abd submerchant
function getQrMerchantSubmerchantRequest() {
  return {
    type: userConstants.GET_MERCHANT_SUBMERCHANT_REQUEST,
  };
}

function getQrMerchantSubmerchantSuccess(data) {
  return {
    type: userConstants.GET_MERCHANT_SUBMERCHANT_SUCCESS,
    payload: data,
  };
}

// Creating a new merchant & submerchant
function createQrMerchantSubmerchantRequest() {
  return {
    type: userConstants.CREATE_MERCHANT_SUBMERCHANT_REQUEST,
  };
}

function createQrMerchantSubmerchantSuccess(data) {
  return {
    type: userConstants.CREATE_MERCHANT_SUBMERCHANT_SUCCESS,
    payload: data,
  };
}

// Creating a new submerchant
function createSubmerchantRequest() {
  return {
    type: userConstants.CREATE_SUBMERCHANT_REQUEST,
  };
}

function createSubmerchantSuccess(data) {
  return {
    type: userConstants.CREATE_SUBMERCHANT_SUCCESS,
    payload: data,
  };
}

// BULK PAYMENTS

// Validating bulk payment
function validateBulkPaymentRequest() {
  return {
    type: userConstants.VALIDATE_BULK_PAYMENT_REQUEST,
  };
}

function validateBulkPaymentSuccess(data) {
  return {
    type: userConstants.VALIDATE_BULK_PAYMENT_SUCCESS,
    payload: data,
  };
}

// Validating bulk payment
function processBulkPaymentRequest() {
  return {
    type: userConstants.PROCESS_BULK_PAYMENT_REQUEST,
  };
}

function processBulkPaymentSuccess(data) {
  return {
    type: userConstants.PROCESS_BULK_PAYMENT_SUCCESS,
    payload: data,
  };
}

// Get bulk transaction history
function getBulkHistoryRequest() {
  return {
    type: userConstants.GET_BULK_HISTORY_REQUEST,
  };
}

function getBulkHistorySuccess(data) {
  return {
    type: userConstants.GET_BULK_HISTORY_SUCCESS,
    payload: data,
  };
}

// Get bulk transaction history details
function getBulkDetailsRequest() {
  return {
    type: userConstants.GET_BULK_DETAILS_REQUEST,
  };
}

function getBulkDetailsSuccess(data) {
  return {
    type: userConstants.GET_BULK_DETAILS_SUCCESS,
    payload: data,
  };
}

function switchActiveAccount(data) {
  return {
    type: userConstants.ACTIVE_ACCOUNT,
    payload: data,
  };
}

// Get additional account request
function getNewAccountRequest() {
  return {
    type: userConstants.GET_NEW_ACCOUNT_REQUEST,
  };
}

function getNewAccountSuccess(data) {
  return {
    type: userConstants.GET_NEW_ACCOUNT_SUCCESS,
    payload: data,
  };
}

//Submit additional account request
function submitNewAccountRequest() {
  return {
    type: userConstants.SUBMIT_NEW_ACCOUNT_REQUEST,
  };
}

function submitNewAccountSuccess(data) {
  return {
    type: userConstants.SUBMIT_NEW_ACCOUNT_SUCCESS,
    payload: data,
  };
}

// Interest
function getEarningsRequest() {
  return {
    type: userConstants.GET_EARNINGS_REQUEST,
  };
}

function getEarningsSuccess(data) {
  return {
    type: userConstants.GET_EARNINGS_SUCCESS,
    payload: data,
  };
}

function getInterestHistoryRequest() {
  return {
    type: userConstants.GET_INTEREST_HISTORY_REQUEST,
  };
}

function getInterestHistorySuccess(data) {
  return {
    type: userConstants.GET_INTEREST_HISTORY_SUCCESS,
    payload: data,
  };
}
