import React from 'react';
import {Switch, Route} from 'react-router-dom';
import MainDashboard from './MainDashboard';
import AccountOverview from './UserProfile/AccountOverview';
// import DeveloperDashboard from './DeveloperDashboard/DeveloperDashboard'



export default function DashboardRoot({match}){
    return(
        <div>
            <Switch>
                <Route exact path={`${match.url}/`} component={MainDashboard} />
                <Route path={`${match.url}/account-overview`} component={ AccountOverview} />
                {/* <Route path={`${match.url}/developer-dashboard`} component={ DeveloperDashboard} /> */}
            </Switch>
        </div>

    );
}