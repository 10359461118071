import React, { useState, useEffect } from 'react';
import { Grid} from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

const NotificationSwitch = withStyles((theme) => ({
    switchBase: {
        color: theme.palette.grey[500],
        '&$checked': {
            color: theme.palette.blue,
            '& + $track': {
                opacity: 1,
                backgroundColor: 'blue',
                borderColor: '1.5px solid	#87CEFA',
            },
        },
    },
    track: {
        border: `1.0px solid ${theme.palette.grey[500]}`,
        backgroundColor: theme.palette.common.white,
    },
    checked: {
        color: '#f50057',
    },
}))(Switch);

function Login(props) {
    const [expanded, setExpanded] = useState('panel1');

    const [login, setLogin] = useState({
        sms: props.item ? props.item : false,
        email: props.item2 ? props.item2 : false,
        push: props.item3 ? props.item3 : false,
        inApp: false,
    });

    useEffect(()=>{
        setLogin({
            sms: props.item,
            email: props.item2,
            push: props.item3,
            inApp: false,
        })
    },[props.item, props.item2, props.item3])


    const handleChange6 = (event) => {
        setLogin({ ...login, [event.target.name]: event.target.checked });
    };

    const handleChangen = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <div >
            <Accordion square expanded={expanded === 'panel6'} onChange={handleChangen('panel6')}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                    <Typography>Login</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid component='label' spacing={1}>
                        <div className='for-inline-items switch-grid'>
                            <div >SMS</div>
                            <div >
                                <NotificationSwitch onClick={props.click} checked={login.sms} onChange={handleChange6} name='sms' />
                            </div>
                        </div>
                    </Grid>

                    <Grid component='label' spacing={1}>
                        <div className='for-inline-items switch-grid'>
                            <div >E-mail</div>
                            <div>
                                <NotificationSwitch onClick={props.click2} checked={login.email} onChange={handleChange6} name='email' />
                            </div>
                        </div>
                    </Grid>

                    <Grid component='label' spacing={1}>
                        <div className='for-inline-items switch-grid'>
                            <div >Push</div>
                            <div>
                                <NotificationSwitch onClick={props.click3} checked={login.push} onChange={handleChange6} name='push' />
                            </div>
                        </div>
                    </Grid>

                    <Grid component='label' spacing={1}>
                        <div className='for-inline-items switch-grid'>
                            <div>In-app </div>
                            <div>
                                <NotificationSwitch disabled onClick={props.click4} checked={login.inApp} onChange={handleChange6} name='inApp' />
                            </div>
                        </div>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};


export default Login;
