import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  InputLabel,
  Input,
  MenuItem,
  Select,
} from "@material-ui/core";
import { connect } from "react-redux";
import qr from "../../../../services/qrService";
import { InputGrid } from "../../../../components/Appearance";

function CreateSubMerchantModal(props) {
  const [type, setType] = useState("");
  const [amount, setAmount] = useState("");
  const [info, setInfo] = useState({
    name: "",
    email: "",
    phone_number: "",
  });

  const data = {
    user_id: props.user.id,
    fee: "0",
    mch_no: props.mch_no,
    sub_fixed: type === "fixed" ? "1" : "0",
    sub_amount: type === "fixed" ? amount : "0",
    name: info.name,
    email: info.email,
    phone_number: info.phone_number,
  };

  function handleChange(e) {
    const { name, value } = e.target;
    setInfo((info) => ({ ...info, [name]: value }));
  }

  function submit() {
    props.create_submerchant(data);
  }

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.close}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent>
          <h2>Create a Submerchant</h2>

          <InputGrid>
            <InputLabel htmlFor="name">Submerchant Name</InputLabel>
            <Input
              type="text"
              value={info.name}
              name="name"
              onChange={handleChange}
              style={{ width: "100%" }}
            />
          </InputGrid>

          <InputGrid>
            <InputLabel htmlFor="email">Submerchant E-mail Address</InputLabel>
            <Input
              type="email"
              value={info.email}
              name="email"
              onChange={handleChange}
              style={{ width: "100%" }}
            />
          </InputGrid>

          <InputGrid>
            <InputLabel htmlFor="phone_number">
              Submerchant Phone Number
            </InputLabel>
            <Input
              type="number"
              value={info.phone_number}
              name="phone_number"
              onChange={handleChange}
              style={{ width: "100%" }}
            />
          </InputGrid>

          <InputGrid>
            <InputLabel htmlFor="type">
              Select a submerchant type below
            </InputLabel>
            <Select
              style={{ width: "100%" }}
              value={type}
              name="marital_status"
              onChange={(e) => setType(e.target.value)}
              displayEmpty
            >
              <MenuItem value="fixed">Fixed</MenuItem>
              <MenuItem value="dynamic">Dynamic</MenuItem>
            </Select>
          </InputGrid>
          {type === "fixed" ? (
            <InputGrid>
              <InputLabel htmlFor="marital_status">Enter an amount</InputLabel>
              <Input
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                style={{ width: "100%" }}
              />
            </InputGrid>
          ) : null}

          <div className="merchant-center">
            <Button
              variant="contained"
              color="primary"
              className="merchant-cancel-button"
              onClick={props.close}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="merchant-small-button"
              onClick={submit}
            >
              {props.loading ? (
                <CircularProgress style={{ fontSize: "30px", color: "#fff" }} />
              ) : (
                "Create Submerchant"
              )}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    create_submerchant: (data) => dispatch(qr.create_submerchant(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.userDetails.data,
    loading: state.createSubmerchant.isLoading,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateSubMerchantModal);
