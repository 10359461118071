import { userConstants } from "../actions/types";

const initialState = {
    isLoading: false,
    data: '',
};

function virtualTrans(state = initialState, action) {
    switch (action.type) {
        case userConstants.VIRTUAL_CARD_TRANSACTIONS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case userConstants.VIRTUAL_CARD_TRANSACTIONS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            };
        case userConstants.VIRTUAL_CARD_TRANSACTIONS_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return state;
    }
}

export default virtualTrans;
