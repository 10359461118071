import React, { useEffect, useRef } from "react";
import packageJson from "../package.json";
import ClearCache from './ClearCache';
import SessionPath from "./SessionPath";
import SignUp from "./pages/Auth/Signup";
import Register from "./pages/Auth/Registration";
import Login from "./pages/Auth/Login";
import SetQuestion from "./pages/Auth/SetSecurityQuestion";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import ValidateOtp from "./pages/ForgotPassword/ValidateOTP";
import ChangePassword from "./pages/ForgotPassword/ChangePassword";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FundAccountRoot from "./pages/Dashboard/FundAccount/FundAccountRoot";
import { Router, Switch, Route } from "react-router-dom";
import { history } from "./store/history";
import VerifyRoot from "./pages/Verify/VerifyRoot";
import ResendRoot from "./pages/ResendOTP/ResendRoot";
import RegisterWithBvn from "./pages/Auth/RegisterWithBvn";
import { connect } from "react-redux";
import PrivateRoute from "./PrivateRoute";
import OfflinePage from "./Offline";
import ErrorPage from "./404Page";
require('cross-storage-plus').initializeServer();

// // for firebase
// import { requestFirebaseNotificationPermission } from './firebaseInit'

global.appVersion = packageJson.version;


function App(props) {
  const headerRef = useRef(null);
  useEffect(() => {
    var condition = navigator.onLine ? 'online' : 'offline';
    if (condition === 'online') {

    } else {
      console.log('OFFLINE')
      history.push('/offline')
    }
  }, []);

  // // for firebase
  // requestFirebaseNotificationPermission()
  //   .then((firebaseToken) => {
  //     // eslint-disable-next-line no-console
  //     console.log('firebaseToken', firebaseToken);
  //   })
  //   .catch((err) => {
  //     return err;
  //   });

  return (
    <ClearCache>

      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }

        return (
          <div className="App" ref={headerRef}>
            <Router history={history} >
              <Switch>
                <Route path="/" exact component={Login} />
                <Route path="/register/:code" component={Register} />
                <Route path="/signup/bvn" component={RegisterWithBvn} />
                <Route path="/signup" component={SignUp} />
                <Route path="/security-question" component={SetQuestion} />
                <Route path="/register" component={Register} />
                <Route path="/verify" component={VerifyRoot} />
                <Route path="/resend" component={ResendRoot} />
                <Route path="/forgot-password" component={ForgotPassword} />
                <Route path="/validate" component={ValidateOtp} />
                <Route path="/change-password" component={ChangePassword} />
                <Route path="/fund-account" component={FundAccountRoot} />
                <Route path="/offline" component={OfflinePage} />

                {/* Guarded Route */}
                <PrivateRoute component={SessionPath} data={props} />
                <Route component={ErrorPage} />
              </Switch>
            </Router>
            <ToastContainer />
          </div>
        );
      }}
    </ClearCache>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.userDetails.data
  }
};

export default connect(mapStateToProps, null)(App);
