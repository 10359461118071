import React, { useEffect, useState } from 'react';
import { Grid, Paper, Button } from '@material-ui/core';
import Promotions from './NotificationComponent/Promotions';
import Important from './NotificationComponent/Important';
import Alert from './NotificationComponent/Alert';
import Loan from './NotificationComponent/Loan';
import Otp from './NotificationComponent/Otp';
import Login from './NotificationComponent/Login';
import Switch from '@material-ui/core/Switch';
import { connect } from 'react-redux';
import notifications from '../../services/notificationService';



function NotificationsCard(props) {

	useEffect(() => {
		props.get_notifications();
	}, [])

	return (
		<div >
			<Grid item xs={12} sm={12} md={7} lg={7}>
				<p className='edit-settings'> Notifications</p>
				<Paper className='settingsBox2'>
					<h4 style={{textAlign:'center'}}>How would you like to receive notifications?</h4>
					<div className='settings-input-div'>
						<Promotions
							click={() => props.toggle_promotions_sms()}
							click2={() => props.toggle_promotions_email()}
							click3={() => props.toggle_promotions_push()}
							click4={() => props.toggle_promotions_app()}
							item={props.smsPro}
							item2={props.emailPro}
							item3={props.pushPro}
						/>

						<Alert
							click={() => props.toggle_alert_sms()}
							click2={() => props.toggle_alert_email()}
							click3={() => props.toggle_alert_push()}
							click4={() => props.toggle_alert_app()}
							item={props.smsAlert}
							item2={props.emailAlert}
							item3={props.pushAlert}
						/>

						<Login
							click={() => props.toggle_login_sms()}
							click2={() => props.toggle_login_email()}
							click3={() => props.toggle_login_push()}
							click4={() => props.toggle_login_app()}
							item={props.smsLogin}
							item2={props.emailLogin}
							item3={props.pushLogin}
						/>

						<Otp
							click={() => props.toggle_otp_sms()}
							click2={() => props.toggle_otp_email()}
							click3={() => props.toggle_otp_push()}
							click4={() => props.toggle_otp_app()}
							item={props.smsOtp}
							item2={props.emailOtp}
							item3={props.pushOtp}
						/>

						<Important
							click={() => props.toggle_important_sms()}
							click2={() => props.toggle_important_email()}
							click3={() => props.toggle_important_push()}
							click4={() => props.toggle_important_app()}

						/>

						<Loan
							click={() => props.toggle_loan_sms()}
							click2={() => props.toggle_loan_email()}
							click3={() => props.toggle_loan_push()}
							click4={() => props.toggle_loan_app()}
						/>
					</div>
				</Paper>
			</Grid>
			{/* <Grid item xs={12} sm={12} md={5} lg={5}>
				<Paper className='settingsBox3'>
					<div className='settingsBox3-inner'>
						<p className='card-text-sm'>
							<br />
							Control the way you recieve push notifications and what you get notified about
						</p>
					</div>
				</Paper>
			</Grid> */}
		</div>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		get_notifications: (payload) => dispatch(notifications.get_notifications(payload)),

		toggle_promotions_sms: (payload) => dispatch(notifications.toggle_promotions_sms(payload)),
		toggle_promotions_email: (payload) => dispatch(notifications.toggle_promotions_email(payload)),
		toggle_promotions_push: (payload) => dispatch(notifications.toggle_promotions_push(payload)),
		toggle_promotions_app: (payload) => dispatch(notifications.toggle_promotions_app(payload)),

		toggle_important_sms: (payload) => dispatch(notifications.toggle_important_sms(payload)),
		toggle_important_email: (payload) => dispatch(notifications.toggle_important_email(payload)),
		toggle_important_push: (payload) => dispatch(notifications.toggle_important_push(payload)),
		toggle_important_app: (payload) => dispatch(notifications.toggle_important_app(payload)),

		toggle_alert_sms: (payload) => dispatch(notifications.toggle_alert_sms(payload)),
		toggle_alert_email: (payload) => dispatch(notifications.toggle_alert_email(payload)),
		toggle_alert_push: (payload) => dispatch(notifications.toggle_alert_push(payload)),
		toggle_alert_app: (payload) => dispatch(notifications.toggle_alert_app(payload)),

		toggle_loan_sms: (payload) => dispatch(notifications.toggle_loan_sms(payload)),
		toggle_loan_email: (payload) => dispatch(notifications.toggle_loan_email(payload)),
		toggle_loan_push: (payload) => dispatch(notifications.toggle_loan_push(payload)),
		toggle_loan_app: (payload) => dispatch(notifications.toggle_loan_app(payload)),

		toggle_otp_sms: (payload) => dispatch(notifications.toggle_otp_sms(payload)),
		toggle_otp_email: (payload) => dispatch(notifications.toggle_otp_email(payload)),
		toggle_otp_push: (payload) => dispatch(notifications.toggle_otp_push(payload)),
		toggle_otp_app: (payload) => dispatch(notifications.toggle_otp_app(payload)),

		toggle_login_sms: (payload) => dispatch(notifications.toggle_login_sms(payload)),
		toggle_login_email: (payload) => dispatch(notifications.toggle_login_email(payload)),
		toggle_login_push: (payload) => dispatch(notifications.toggle_login_push(payload)),
		toggle_login_app: (payload) => dispatch(notifications.toggle_login_app(payload)),
	}
}

const mapStateToProps = (state) => {
	return {
		smsPro: state.getAllNotifications.smsPro,
		emailPro: state.getAllNotifications.emailPro,
		pushPro: state.getAllNotifications.pushPro,
		//appPro: state.getAllNotifications.appPro,

		smsAlert: state.getAllNotifications.smsAlert,
		emailAlert: state.getAllNotifications.emailAlert,
		pushAlert: state.getAllNotifications.pushAlert,
		//appPro: state.getAllNotifications.appPro,

		smsOtp: state.getAllNotifications.smsOtp,
		emailOtp: state.getAllNotifications.emailOtp,
		pushOtp: state.getAllNotifications.pushOtp,
		//appOtp: state.getAllNotifications.appOtp,

		smsLogin: state.getAllNotifications.smsLogin,
		emailLogin: state.getAllNotifications.emailLogin,
		pushLogin: state.getAllNotifications.pushLogin,
		//appLogin: state.getAllNotifications.appLogin,
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsCard);
