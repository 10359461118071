import { userConstants } from '../actions/types'

const initialState = {
    isLoading: false, 
}
 
function response(state = initialState, action) {
    switch (action.type) {
        case userConstants.REQUESTING:
            return {
                isLoading: true,
            };
        case userConstants.RESPONDED:
            return {
                isLoading: false,  
            };
        default:
            return state
    }
}

export default response