import React from "react";
import { Grid } from "@material-ui/core";
import jsPDF from "jspdf";

export const SubMerchant = ({ merchant }) => {
  const merc = merchant[0];

  function generatePdf(img) {
    var pdf = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: [84, 40]
    });

    var img = new Image;
   
        pdf.addImage(this, 10, 10);
        pdf.save("test.pdf");

  
    img.crossOrigin = "";  
    img.src = `http://api.test.getripay.com/api/v1/qr/show_qr_image/${img}`;
}

  return (
    <div>
      {merc.submerchant && (
        <Grid container spacing={6}>
          {merc.submerchant.map((res, i) => {
            return (
              <Grid item lg={6}>
                <div className="submerchantDiv">
                  <span>{res.sub_name}</span>
                  <br />
                  <span>{res.sub_mch_no}</span>
                  <br />
                  <span>{new Date(res.created_at).toLocaleString()}</span>
                  <div>
                    <img
                      width="150"
                      src={`http://api.test.getripay.com/api/v1/qr/show_qr_image/${res.emvco_code}`}
                    />
                  </div>

                  <button type="button" 
                 // onClick={()=>generatePdf(res.emvco_code)}
                  >
                    Download QR Code
                  </button>
                </div>
              </Grid>
            );
          })}
        </Grid>
      )}
    </div>
  );
};
