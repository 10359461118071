import React from 'react';
import './pagination.css';
import ReactPaginate from 'react-paginate';
import { ArrowLeft, ArrowRight } from '@material-ui/icons';

const Pagination = ({
  totalItems,
  handlePageClick,
}) => {
  return (
    <div>
      <ReactPaginate
        previousLabel={<ArrowLeft/>}
        nextLabel={<ArrowRight />}
        breakLabel={'...'}
        breakClassName='break'
        pageCount={totalItems}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageClick}
        pageClassName='pagination'
        activeClassName='pageLink2'
        activeLinkClassName='pageLink2'
        previousClassName='arrows'
        nextClassName='arrows'
      />
    </div>
  );
};

export default Pagination;