import React, { useState } from 'react';
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { connect } from 'react-redux';
import savings from '../../../../services/savingsService';


function OverrideModal(props) {

    const [data, setData] = useState({
        user_id: props.user.id,
        group_id: props.id,
        reason: ''
    });

    function handleChange(e) {
        const { name, value } = e.target;
        setData((data) => ({ ...data, [name]: value }));
    }

    function submit() {
        if (data.reason) {
            props.override_withdrawal(data)
        }
    };

    return (
        <div>
            <Dialog
                maxWidth='xs'
                open={props.open}
                onClose={props.close}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle >Withdrawal Authorization Override</DialogTitle>

                <DialogContent>
                    <p>I, <span style={{ color: '#0037ba', textTransform: 'capitalize', fontWeight: '800' }}> {props.user.first_name} {props.user.last_name} </span>
                    hereby consent that any member of this savings group is allowed to withdraw at any time, <span style={{ color: '#0037ba', textTransform: 'capitalize', fontWeight: '800' }}>{props.name}</span> </p>

                    <input type='text' name='reason' value={data.reason} placeholder='Reason' onChange={handleChange} />
                    <Button
                        fullWidth
                        variant="contained"
                        size="small"
                        color="primary"
                        className='getri-button submit'
                        onClick={submit}
                    >
                        {props.loading ?
                            <CircularProgress style={{ fontSize: '30px', color: '#fff' }} />
                            :
                            'Authorize'
                        }

                    </Button>
                </DialogContent>
            </Dialog>
        </div>

    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        override_withdrawal: (data) => dispatch(savings.override_withdrawal(data))
    };
};

const mapStateToProps = (state) => {
    return {
        user: state.userDetails.data,
        loading: state.response.isLoading,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OverrideModal)
