import React, { useEffect, useState } from "react";
import Carousel from 'react-elastic-carousel'
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import security from '../../services/securityServices';
import "../../css/register.css";
import "../../css/otp.css";
import { history } from "../../store/history";
import { connect } from 'react-redux';
import { toast, Flip } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: "#0038ba",
        minHeight: "100vh",
        [theme.breakpoints.down("sm")]: {
            backgroundColor: 'white'
        },
    },
    error: {
        color: 'red',
        fontSize: '13px',
        fontWeight: '500'
    },
    root: {
        padding: theme.spacing(15, 30, 10, 30),
        maxWidth: "100%",
        maxHeight: "100%",
        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(0, 0, 0, 0),
        },
    },
    image: {
        backgroundImage: "url('/images/login-image.png')",
        backgroundRepeat: "no-repeat",
        backgroundColor:
            theme.palette.type === "light"
                ? theme.palette.grey[50]
                : theme.palette.grey[900],
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderBottomLeftRadius: "5%",
        borderTopLeftRadius: "5%",
        maxHeight: "100%",
    },
    paper: {
        margin: theme.spacing(2, 2),
        [theme.breakpoints.down("sm")]: {
            margin: theme.spacing(2, 0),
        },
        display: "flex",
        flexDirection: "column",
    },
    logo: {
        maxWidth: "50%",
        // marginBottom: 10
    },

    pap: {
        backgroundColor: "white",
        borderBottomRightRadius: "5%",
        borderTopRightRadius: "5%",
        [theme.breakpoints.down("sm")]: {
            borderBottomRightRadius: "0%",
            borderTopRightRadius: "0%",
        },
    },
    margin: {
        width: "17vw",
        [theme.breakpoints.down("sm")]: {
            width: 300,
        },
        [theme.breakpoints.down("xs")]: {
            width: 200,
        },
        marginBottom: 1,
    },
    getri: {
        fontWeight: "bold",
    },
    box: {
        border: '2px solid #ccc',
        padding: '10px',
        marginBottom: '10px'
    },
    inputDiv: {
        marginLeft: '40px',
        textAlign: 'center',
        alignItem: 'center',
        width: '800px',
        [theme.breakpoints.down("sm")]: {
            maxWidth: '300px',
        },
    },
    item: {
        fontSize: '15px',
        [theme.breakpoints.down("sm")]: {
            fontSize: '10px',
        },
    }
}));

function SetQuestion(props) {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [question, setQuestion] = useState('a');
    const [question2, setQuestion2] = useState('b');
    const [question3, setQuestion3] = useState('c');
    const [question4, setQuestion4] = useState('d');
    const [question5, setQuestion5] = useState('e');
    const [disable, setDisable] = useState(false);
    const [label, setLabel] = useState('')


    const [answer, setAnswer] = useState('');
    const [answer2, setAnswer2] = useState('');
    const [answer3, setAnswer3] = useState('');
    const [answer4, setAnswer4] = useState('');
    const [answer5, setAnswer5] = useState('');

    const [password, setPassword] = useState('')
    const [open, setOpen] = useState(false);


    const items = [
        {
            question_id: question,
            answer: answer,
            password: password,
        },
        {
            question_id: question2,
            answer: answer2,
            password: password,
        },
        {
            question_id: question3,
            answer: answer3,
            password: password,
        },
        {
            question_id: question4,
            answer: answer4,
            password: password,
        },
        {
            question_id: question5,
            answer: answer5,
            password: password,
        },
    ];

    function handleChange(event) {
        setQuestion(event.target.value)
        setLabel(event.target.key)
    };

    const handleChange2 = (event) => {
        setQuestion2(event.target.value);
    };
    const handleChange3 = (event) => {
        setQuestion3(event.target.value);
    };
    const handleChange4 = (event) => {
        setQuestion4(event.target.value);
    };
    const handleChange5 = (event) => {
        setQuestion5(event.target.value);
    };

    const handleChangeAnswer = (e) => {
        setAnswer(e.target.value);
    };
    const handleChangeAnswer2 = (e) => {
        setAnswer2(e.target.value);
    };
    const handleChangeAnswer3 = (e) => {
        setAnswer3(e.target.value);
    };
    const handleChangeAnswer4 = (e) => {
        setAnswer4(e.target.value);
    };
    const handleChangeAnswer5 = (e) => {
        setAnswer5(e.target.value);
    };

    const handleChangePassword = (e) => {
        setPassword(e.target.value);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        props.get_question();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    function handleSubmit(e) {
        e.preventDefault();
        if (!answer || !answer2 || !answer3 || !answer4 || !answer5) {
            toast.error('Please answer all questions', {
                position: toast.POSITION.TOP_RIGHT,
                transition: Flip
            })
        }
        if (answer && answer2 && answer3 && answer4 && answer5) {
            setIsLoading(true)
            props.submit_question(password, items).then(() => {
                setIsLoading(false)
            })
        }
    }

    useEffect(() => {
        handleSlideChange();
    }, [question, question2, question3, question4, question5])

    function handleSlideChange() {
        if (question === question2 || question === question3 || question === question4 || question === question5) {
            setDisable(true)
        } else if (question2 === question || question2 === question3 || question2 === question4 || question2 === question5) {
            setDisable(true)
        } else if (question3 === question || question3 === question2 || question3 === question4 || question3 === question5) {
            setDisable(true)
        } else if (question4 === question || question4 === question2 || question4 === question3 || question4 === question5) {
            setDisable(true)
        } else if (question5 === question || question5 === question2 || question5 === question3 || question5 === question4) {
            setDisable(true)
        } else {
            setDisable(false)
        }
    }

    // moves the menu below the select input
    const menuProps = {
        list: {
            paddingTop: 0,
            paddingBottom: 0,
            background: 'white',
            "& li.Mui-selected": {
                fontWeight: 700
            }
        },
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
        },
        transformOrigin: {
            vertical: "center",
            horizontal: "center"
        },
        getContentAnchorEl: null
    };

    return (
        <div className={classes.header}>
            <Helmet>
                <title>GetriPay - Set security questions</title>
                <meta name="description" content="set security questions" />
            </Helmet>
            <Grid container component="main" className={classes.root}>
                <Grid item xs={false} sm={4} md={5} className={classes.image} />
                <Grid item xs={12} sm={12} md={7} component={Paper} className={classes.pap}>
                    <div className={classes.paper}>
                        <div>
                            <img src="/images/getripaylogo.png" alt="logo" className={classes.logo} />
                        </div>
                        <div>
                            <p className='typo'>Select at least 5 questions and answer them</p>
                        </div>

                        <form name='form' onSubmit={handleSubmit}>
                            {disable ?
                                <p style={{ color: 'red' }}>
                                    Please select a different question
                                </p>
                                :
                                ''
                            }

                            {label && <p>{label}</p>}
                            <Carousel itemsToShow={1} showArrows={false}>
                                <div style={{ marginTop: '90px' }}>
                                    <Select
                                        style={{ padding: '0px' }}
                                        className={classes.inputDiv}
                                        MenuProps={menuProps}
                                        onClose={handleClose}
                                        onOpen={handleOpen}
                                        value={question}
                                        displayEmpty
                                        name='question'
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={question}>Click to select a question</MenuItem>
                                        {props.securityQ.map((securityQ, i) => (
                                            <MenuItem className={classes.item} key={i} value={securityQ.id} >
                                                {securityQ.question}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <div style={{ marginTop: '30px' }}>
                                        <input
                                            placeholder='Answer to security question one'
                                            name='answer'
                                            value={answer}
                                            onChange={handleChangeAnswer}
                                            type='text'
                                            className={classes.inputDiv}
                                        />
                                    </div>
                                </div>
                                {/* question 2 */}

                                <div style={{ marginTop: '90px' }}>
                                    <Select
                                        MenuProps={menuProps}
                                        className={classes.inputDiv}
                                        onClose={handleClose}
                                        onOpen={handleOpen}
                                        value={question2}
                                        name='question2'
                                        onChange={handleChange2}
                                        placeholder='Select Question'
                                        displayEmpty
                                    >
                                        <MenuItem value={question2}>Click to select a question</MenuItem>
                                        {props.securityQ.map((securityQ, i) => (
                                            <MenuItem className={classes.item} key={i} value={securityQ.id}>
                                                {securityQ.question}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <div style={{ marginTop: '30px' }}>
                                        <input
                                            placeholder='Answer to security question two'
                                            name='answer2'
                                            value={answer2}
                                            onChange={handleChangeAnswer2}
                                            type='text'
                                            className={classes.inputDiv}
                                        />
                                    </div>
                                </div>
                                {/* question 2 */}

                                {/* question 3 */}
                                <div style={{ marginTop: '90px' }}>
                                    <Select
                                        MenuProps={menuProps}
                                        className={classes.inputDiv}
                                        onClose={handleClose}
                                        onOpen={handleOpen}
                                        value={question3}
                                        name='question3'
                                        onChange={handleChange3}
                                        displayEmpty
                                    >
                                        <MenuItem value={question3}>Click to select a question</MenuItem>
                                        {props.securityQ.map((securityQ, i) => (
                                            <MenuItem className={classes.item} key={i} value={securityQ.id}>
                                                {securityQ.question}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <div style={{ marginTop: '30px' }}>
                                        <input
                                            placeholder='Answer to security question three'
                                            name='answer3'
                                            value={answer3}
                                            onChange={handleChangeAnswer3}
                                            type='text'
                                            className={classes.inputDiv}
                                        />
                                    </div>
                                </div>
                                {/* question 3 */}

                                {/* question 4 */}

                                <div style={{ marginTop: '90px' }}>
                                    <Select
                                        MenuProps={menuProps}
                                        className={classes.inputDiv}
                                        onClose={handleClose}
                                        onOpen={handleOpen}
                                        value={question4}
                                        name='question4'
                                        onChange={handleChange4}
                                        displayEmpty
                                    >
                                        <MenuItem value={question4}>Click to select a question</MenuItem>
                                        {props.securityQ.map((securityQ, i) => (
                                            <MenuItem className={classes.item} key={i} value={securityQ.id}>
                                                {securityQ.question}
                                            </MenuItem>

                                        ))}
                                    </Select>

                                    <div style={{ marginTop: '30px' }}>
                                        <input
                                            placeholder='Answer to security question four'
                                            name='answer4'
                                            value={answer4}
                                            onChange={handleChangeAnswer4}
                                            type='text'
                                            className={classes.inputDiv}
                                        />
                                    </div>
                                </div>
                                {/* question 4 */}

                                {/* question 5 */}
                                <div style={{ marginTop: '90px' }}>
                                    <Select
                                        MenuProps={menuProps}
                                        className={classes.inputDiv}
                                        onClose={handleClose}
                                        onOpen={handleOpen}
                                        value={question5}
                                        name='question5'
                                        onChange={handleChange5}
                                        displayEmpty
                                    >
                                        <MenuItem value={question5}>Click to select a question</MenuItem>
                                        {props.securityQ.map((securityQ, i) => (
                                            <MenuItem className={classes.item} key={i} value={securityQ.id}>
                                                {securityQ.question}
                                            </MenuItem>
                                        ))}
                                    </Select>

                                    <div style={{ marginTop: '30px' }}>
                                        <input
                                            placeholder='Answer to security question five'
                                            name='answer5'
                                            value={answer5}
                                            onChange={handleChangeAnswer5}
                                            type='text'
                                            className={classes.inputDiv}
                                        />

                                    </div>

                                    <div style={{ marginTop: '30px' }} >
                                        <input
                                            placeholder='Password'
                                            type='password'
                                            value={password}
                                            className={classes.inputDiv}
                                            onChange={handleChangePassword}
                                        />
                                    </div>
                                </div>
                                {/* question 5 */}
                            </Carousel>

                            <div className='mag' style={{ margin: '10px 10px' }}>
                                <p>Click on the circles below to go back or move forward to the next question</p>
                                <Button fullWidth variant='contained' color='primary' className='submit' type='submit'
                                    disabled={password === '' || answer === '' || answer2 === ''
                                        || answer3 === '' || answer4 === '' || answer5 === '' ||
                                        question === 'a' || question2 === 'b' || question3 === 'c' || question4 === 'd'
                                        || question5 === 'e' || isLoading === true
                                    }
                                >
                                    {isLoading && <CircularProgress className='circle' />}
                                    Save Questions
				                </Button>
                            </div>
                        </form>
                        <p onClick={history.goBack} className='back'>Go Back</p>
                    </div>
                </Grid>
            </Grid>
        </div >
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        get_question: (payload) => dispatch(security.get_question(payload)),
        submit_question: (password, items) => dispatch(security.submit_question(password, items))
    };
};
const mapStateToProps = (state) => {
    return {
        securityQ: state.fetch.data,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SetQuestion);

