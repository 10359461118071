import { userConstants } from "../actions/types";

const initialState = {
    isRegisteringBvn: false,
    data: '',
};

function registerBvn(state = initialState, action) {
    switch (action.type) {
        case userConstants.REGISTER_BVN_REQUEST:
            return {
                ...state,
                isRegisteringBvn: true,
            };
        case userConstants.REGISTER_BVN_SUCCESS:
            return {
                ...state,
                isRegisteringBvn: false,
                data: action.payload,
            };
            case userConstants.REGISTER_BVN_FAILURE:
                return {
                    ...state,
                    isRegisteringBvn: false,
                };
        default:
            return state;
    }
};

export default registerBvn;
