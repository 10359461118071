import React from "react";
import ReactDOM from "react-dom";
import "../css/App.css";

const PrivacyModal = ({ isShowing2, hide }) =>
  isShowing2
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className="modal-overlay" />
          <div
            className="modal-wrapper"
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
          >
            <div className="modal">
              <div className="modal-header">
                <button
                  type="button"
                  className="modal-close-button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={hide}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <h1>Privacy Policy</h1>
              <div>
              <h4>1. Lorem ipsum dolor sit amet</h4>
                Curabitur arcu erat, accumsan id imperdiet et, porttitor at sem.
                Curabitur non nulla sit amet nisl tempus convallis quis ac
                lectus. Vestibulum ac diam sit amet quam vehicula elementum sed
                sit amet dui. Lorem ipsum dolor sit amet, consectetur adipiscing
                elit. Vestibulum ante ipsum primis in faucibus orci luctus et
              </div>

              <div>
              <h4>2. Lorem ipsum dolor sit amet</h4>
                ultrices posuere cubilia Curae; Donec velit neque, auctor sit
                amet aliquam vel, ullamcorper sit amet ligula. Nulla porttitor
                accumsan tincidunt. Vestibulum ac diam sit amet quam vehicula
                elementum sed sit amet dui. Pellentesque in ipsum id orci porta
                dapibus. Proin eget tortor risus. Nulla quis lorem ut libero
                malesuada feugiat.
              </div>
              <div>
              <h4>3. Lorem ipsum dolor sit amet</h4>
                ultrices posuere cubilia Curae; Donec velit neque, auctor sit
                amet aliquam vel, ullamcorper sit amet ligula. Nulla porttitor
                accumsan tincidunt. Vestibulum ac diam sit amet quam vehicula
                elementum sed sit amet dui. Pellentesque in ipsum id orci porta
                dapibus. Proin eget tortor risus. Nulla quis lorem ut libero
                malesuada feugiat.
              </div>
              <div>
              <h4>4. Lorem ipsum dolor sit amet</h4>
                ultrices posuere cubilia Curae; Donec velit neque, auctor sit
                amet aliquam vel, ullamcorper sit amet ligula. Nulla porttitor
                accumsan tincidunt. Vestibulum ac diam sit amet quam vehicula
                elementum sed sit amet dui. Pellentesque in ipsum id orci porta
                dapibus. Proin eget tortor risus. Nulla quis lorem ut libero
                malesuada feugiat.
              </div>
            </div>
          </div>
        </React.Fragment>,
        document.body
      )
    : null;

export default PrivacyModal;
