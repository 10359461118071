import React, { useState, useEffect } from 'react';
import { TextField, Button, Dialog, CircularProgress, DialogContent, DialogActions, DialogContentText, DialogTitle } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import { connect } from "react-redux";
import transfers from "../../../../services/transferService";

function BeneficiaryTab(props) {
    const [transaction_pin, setTransaction_pin] = useState('');
    const [benModal, setBenModal] = useState(false);
    const [deleteId, setDeleteId] = useState(0)

    function showBenModal(id) {
        setBenModal(true, setDeleteId(id));
    };

    function handleChange(e) {
        setTransaction_pin(e.target.value);
    };

    // useEffect(() => {
    //     deleteBeneficiary();
    // }, []); // eslint-disable-line react-hooks/exhaustive-deps

    function deleteBeneficiary() {
        props.delete_beneficiary(deleteId, transaction_pin);
        setTransaction_pin('')

        // var index = props.listBeneficiary.map(x => {
        //     return x.id;
        // }).indexOf(id);

        // setDeleteObj(props.listBeneficiary.splice(index, 1))
        //     console.log(props.listBeneficiary);
    };

    useEffect(() => {
        props.get_beneficiaries();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div >
            <table>
                <tbody>
                    <tr>
                        <th>Account Name</th>
                        <th>Account Number</th>
                        <th>Bank</th>
                        <th>Delete</th>
                    </tr>
                </tbody>

                {props.listBeneficiary ?
                    <tbody>
                        {props.listBeneficiary.map((list, i) => (
                            <tr key={i} >
                                <td>{list.customer_name}</td>
                                <td>{list.account_number}</td>
                                <td>{list.institution_name}</td>
                                <td>
                                    <DeleteIcon style={{ color: 'red', cursor: 'pointer' }}
                                        onClick={() => showBenModal(list.id)}
                                    />
                                </td>
                              
                                    <Dialog
                                        open={benModal === true}
                                        onClose={() => setBenModal(false)}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description">
                                        <DialogTitle id="alert-dialog-title" style={{ color: "red" }}>
                                            DELETE BENEFICIARY
                                        </DialogTitle>

                                        <DialogContent>
                                            <DialogContentText id="alert-d.alog-description">
                                                Please enter your transaction pin
                                        </DialogContentText>
                                            <TextField
                                                onChange={handleChange}
                                                autoFocus
                                                margin="dense"
                                                name='transaction_pin'
                                                value={transaction_pin}
                                                label="Transaction Pin"
                                                type="password"
                                                fullWidth
                                            />
                                        </DialogContent>

                                        <DialogActions>
                                            <Button onClick={() => setBenModal(false)}
                                                variant="contained"
                                                size="small"
                                                style={{
                                                    backgroundColor: "white",
                                                    color: "#0037ba",
                                                    borderRadius: "20px",
                                                    border: "2px solid #0037ba",
                                                    boxShadow: "none",
                                                }}
                                            >
                                                Cancel
                                            </Button>

                                            <Button
                                                variant="contained"
                                                size="small"
                                                style={{
                                                    backgroundColor: "#0037ba",
                                                    color: "white",
                                                    borderRadius: "20px",
                                                    border: "2px solid #0037ba",
                                                    boxShadow: "none",
                                                }}
                                                onClick={() => deleteBeneficiary()}
                                            >
                                                 {props.isDeletingBeneficiary && <CircularProgress className="circle" />}
                                                Delete beneficiary
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                               
                            </tr>
                        ))}
                    </tbody>
                    :
                    ''
                }
            </table>
        </div >
    );
}


const mapStateToProps = (state) => {
    return {
        listBeneficiary: state.getBeneficiary.data,
        isDeletingBeneficiary: state.deleteBeneficiary.isDeletingBeneficiary
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        get_beneficiaries: (payload) => dispatch(transfers.get_beneficiaries(payload)),
        delete_beneficiary: (deleteId, transaction_pin) => dispatch(transfers.delete_beneficiary(deleteId, transaction_pin))
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(BeneficiaryTab);

