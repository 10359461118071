import React from "react";
import { Divider, IconButton, makeStyles, Drawer } from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";
import Pagination from "./Pagination/Pagination";
import TransactionScroll from "../../../../components/Skeleton/TransactionScroll";

const drawerWidth = 500;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  drawerPaper: {
    width: drawerWidth,
    fontSize: "13px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  drawerTitle: {
    fontWeight: "bolder",
    fontSize: "16px",
  },
  small: {
    fontSize: "12px",
    marginTop: theme.spacing(-2),
    marginLeft: "2px",
  },
  ref: {
    marginTop: theme.spacing(-0.5),
    fontSize: "12px",
    marginLeft: "2px",
    fontWeight: "bolder",
  },
  name: {
    fontSize: "11px",
    fontWeight: "bolder",
    marginLeft: "2px",
  },
}));

export default function BulkDrawer({
  batchName,
  batchCode,
  details,
  drawerActive,
  setDrawerActive,
  id,
  bulk_details,
  loading,
}) {
  const classes = useStyles();

  // Change page
  function handlePageClick(data) {
    bulk_details(id, data.selected + 1);
  }
  return (
    <Drawer
      anchor="right"
      open={drawerActive}
      classes={{
        paper: classes.drawerPaper,
      }}
      onClose={() => setDrawerActive(false)}
    >
      <div className={classes.drawerHeader}>
        <p className={classes.drawerTitle}>
          {batchName} - {batchCode}
        </p>
        <IconButton onClick={() => setDrawerActive(false)}>
          <CloseRounded />
        </IconButton>
      </div>
      <Divider />

      <Pagination
        handlePageClick={handlePageClick}
        totalItems={details?.last_page}
      />
      {loading ? (
        <TransactionScroll />
      ) : (
        <div style={{ padding: "20px" }}>
          {details?.data?.map((transaction, i) => {
            return (
              <div key={i} className="history-table2">
                <span
                  className={classes.name}
                  style={{ display: "inline-block" }}
                >
                  {new Date(transaction.created_at).toLocaleString()}
                </span>

                <div style={{ display: "inline-block", float: "right" }}>
                  <span
                    className={
                      transaction?.status === "Un-Verify" ? "deb1" : "deb2"
                    }
                  >
                    {transaction?.status} ||
                    <span
                      className={
                        transaction.transfer_status === "Pending"
                          ? "deb3"
                          : transaction.transfer_status === "completed"
                          ? "deb2"
                          : transaction.transfer_status === "failed"
                          ? "deb1"
                          : "deb3"
                      }
                    >
                      {" "}
                      {transaction?.transfer_status}
                    </span>
                  </span>
                </div>

                <div className="user-inline-items">
                  <div style={{ padding: "none", paddingRight: "10%" }}>
                    <span
                      style={{ marginTop: "20px" }}
                      className="trans-amount"
                    >
                      NGN {Number(transaction.amount).toLocaleString()}
                    </span>
                  </div>

                  <div>
                    <div>
                      <p className={classes.name} style={{ margin: "0px" }}>
                        {transaction?.bank_name}{" "}
                      </p>

                      <div>
                        <p className={classes.name}>
                          {transaction?.account_number}
                        </p>
                        <p className={classes.small}>
                          {transaction?.account_name}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <span className={classes.small}>
                      {new Date(transaction.created_at).toLocaleString()}
                    </span>
                    <p className={classes.ref}>
                      {" "}
                      {transaction?.narration || "No narration"}{" "}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </Drawer>
  );
}
