import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TermsModal from "../../components/TermsModal";
import PrivacyModal from "../../components/PrivacyModal";
import useModal from "../../components/useModal";
import "../../css/register.css";
import "../../css/formfield.css";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import auth from "../../services/authService";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: "#0038ba",
    minHeight: "100vh",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "white",
    },
  },
  root: {
    padding: theme.spacing(5, 25, 5, 25),
    maxWidth: "100%",
    maxHeight: "100%",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0, 0, 0, 0),
    },
  },
  margin: {
    width: "23vw",
    [theme.breakpoints.down("sm")]: {
      width: 300,
    },
    [theme.breakpoints.down("xs")]: {
      width: 200,
    },
    marginBottom: 1,
  },
  image: {
    backgroundImage: "url('/images/login-image.png')",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderBottomLeftRadius: "5%",
    borderTopLeftRadius: "5%",
    maxHeight: "100%",
  },
  paper: {
    margin: theme.spacing(4, 5),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(3, 4),
    },
    display: "flex",
    flexDirection: "column",
  },
  logo: {
    maxWidth: "40%",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  pap: {
    backgroundColor: "white",
    // maxHeight: 900,
    borderBottomRightRadius: "5%",
    borderTopRightRadius: "5%",
    [theme.breakpoints.down("sm")]: {
      borderBottomRightRadius: "0%",
      borderTopRightRadius: "0%",
    },
  },
}));

function Register(props) {
  const classes = useStyles();
  const { isShowing, toggle } = useModal();
  const { isShowing2, toggle2 } = useModal();
  const [checked, setChecked] = useState(false);

  const refLink = props.match.params.code ? props.match.params.code : "";

  const [users, setUsers] = useState({
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    password: "",
    c_password: "",
    phone_number: "",
    parent_id: refLink !== "" ? refLink : "",
  });

  const { first_name, last_name, username, email, phone_number, parent_id } =
    users;
  console.log(parent_id);

  function handleChange(e) {
    const { name, value } = e.target;
    setUsers((users) => ({ ...users, [name]: value }));
  }
  const { register, handleSubmit, errors, watch } = useForm({
    mode: "onBlur",
  });

  const watchedPassword = watch("password", "");

  const handleBoxChange = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    validateReferral();
  }, [parent_id]); // eslint-disable-line react-hooks/exhaustive-deps

  function validateReferral() {
    if (parent_id.length >= 5) {
      props.referralIsUnique(parent_id);
    }
  }

  function onSubmit() {
    if (users && checked) {
      props.register(users);
    }
  }

  return (
    <div className={classes.header}>
      <Helmet>
        <title>GetriPay - Register</title>
        <meta name="description" content="register" />
      </Helmet>
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          component={Paper}
          elevation={0}
          square
          className={classes.pap}
        >
          <div className={classes.paper}>
            <div>
              <img
                src="/images/getripaylogo.png"
                alt="logo"
                className={classes.logo}
              />
            </div>
            <div>
              <p className={"typo"}>Create an account</p>
            </div>

            {/*Start Form */}
            <form
              className={classes.form}
              name="form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div>
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <label>First Name</label>
                    <input
                      type="text"
                      value={first_name}
                      onChange={handleChange}
                      name="first_name"
                      ref={register({
                        required: "Enter your first name",
                      })}
                      style={{ borderColor: errors.first_name && "red" }}
                    />
                    {errors.first_name && (
                      <p className="error">{errors.first_name.message}</p>
                    )}
                  </Grid>
                </Grid>
              </div>

              {/* last name */}
              <div>
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <label>Last Name</label>
                    <input
                      value={last_name}
                      onChange={handleChange}
                      name="last_name"
                      ref={register({
                        required: "Enter your last name",
                      })}
                      style={{ borderColor: errors.last_name && "red" }}
                    />
                    {errors.last_name && (
                      <p className="error">{errors.last_name.message}</p>
                    )}
                  </Grid>
                </Grid>
              </div>

              {/* username */}
              <div>
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <label>Username</label>
                    <input
                      value={username}
                      onChange={handleChange}
                      name="username"
                      ref={register({
                        required: "Enter your username",
                      })}
                      style={{ borderColor: errors.username && "red" }}
                    />
                    {errors.username && (
                      <p className="error">{errors.username.message}</p>
                    )}
                  </Grid>
                </Grid>
              </div>

              {/* email input */}
              <div>
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <label>Email</label>
                    <input
                      value={email}
                      type="email"
                      onChange={handleChange}
                      name="email"
                      ref={register({
                        required: "Enter your e-mail",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: "Enter a valid e-mail address",
                        },
                      })}
                      style={{
                        borderColor: errors.email && "red",
                      }}
                    />
                    {errors.email && (
                      <p className="error">{errors.email.message}</p>
                    )}
                  </Grid>
                </Grid>
              </div>

              {/* password input */}
              <div>
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <label>Password</label>
                    <input
                      type="password"
                      // value={password}
                      onChange={handleChange}
                      name="password"
                      ref={register({
                        required: "Minimum of 6 characters",
                        minLength: 6,
                      })}
                      style={{ borderColor: errors.password && "red" }}
                    />
                    {errors.password && (
                      <p className="error">{errors.password.message}</p>
                    )}
                  </Grid>
                </Grid>
              </div>

              {/* ConfirmPassword Field */}
              <div>
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <label>Confirm Password</label>
                    <input
                      type="password"
                      // value={c_password}
                      onChange={handleChange}
                      name="c_password"
                      ref={register({
                        validate: (value) =>
                          value === watchedPassword ||
                          "The passwords do not match",
                      })}
                      style={{ borderColor: errors.c_password && "red" }}
                    />
                    {errors.c_password && (
                      <p className="error">{errors.c_password.message}</p>
                    )}
                  </Grid>
                </Grid>
              </div>

              {/* PhoneNumber Field*/}
              <div>
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <label>Phone Number</label>
                    <input
                      type="number"
                      value={phone_number}
                      onChange={handleChange}
                      name="phone_number"
                      ref={register({
                        required: "Enter your phone number",
                        minLength: 11,
                        maxLength: 11,
                        pattern: /^[0]\d{10}$/,
                      })}
                      style={{ borderColor: errors.phone_number && "red" }}
                    />
                    {errors.phone_number && (
                      <p className="error">{errors.phone_number.message}</p>
                    )}
                  </Grid>
                </Grid>
              </div>
              {/* Referral Field */}
              <div>
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <label>Referral code(optional)</label>
                    <input
                      type="number"
                      name="parent_id"
                      value={parent_id}
                      disabled={props.isLoading}
                      onChange={handleChange}
                      onBlur={validateReferral}
                    />
                    {props.isAuthenticating ? (
                      <CircularProgress size={14} color="primary" />
                    ) : (
                      ""
                    )}
                    {props.referralStatus === false ? (
                      <p style={{ color: "red", fontSize: "12px" }}>
                        Invalid referral code. Please enter a correct code or
                        proceed with registration
                      </p>
                    ) : props.referralStatus === true ? (
                      <span>
                        <img
                          src="/images/success-icon.svg"
                          style={{ maxWidth: "25px" }}
                          alt="success"
                        />
                      </span>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </div>
              {/* {checked === false?
                  <p>Tick it oo</p>  
                  :
                  ''
                  } */}
              <div>
                <FormControlLabel
                  control={
                    <Checkbox checked={checked} onChange={handleBoxChange} />
                  }
                />
                <span className="agree">
                  I agree with the{" "}
                  <span className="terms" onClick={toggle}>
                    Terms and conditions and Privacy Policy
                  </span>{" "}
                  of
                  <span className="getri"> GetriPay</span>
                  <TermsModal isShowing={isShowing} hide={toggle} />
                  <PrivacyModal isShowing2={isShowing2} hide={toggle2} />
                </span>
              </div>
              {checked === false && (
                <p className="error">
                  You must accept the terms and conditions
                </p>
              )}

              <Grid container>
                <Grid item component={Link} to="/" className="already">
                  Already have an account?{" "}
                  <span className="terms">Login here</span>
                </Grid>
              </Grid>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                className="submit"
                disabled={props.isLoading}
              >
                {props.isLoading && <CircularProgress className="circle" />}
                Continue
              </Button>
            </form>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    register: (payload) => dispatch(auth.register_user(payload)),
    referralIsUnique: (payload) => dispatch(auth.referralIsUnique(payload)),
  };
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.response.isLoading,
    isAuthenticating: state.verify.isAuthenticating,
    referralStatus: state.validateReferral.success,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
