import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TermsModal from "../../components/TermsModal";
import PrivacyModal from "../../components/PrivacyModal";
import useModal from '../../components/useModal';
import "../../css/register.css";
import "../../css/formfield.css";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import auth from "../../services/authService";
import verify from "../../services/verifyService";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Helmet} from 'react-helmet';

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: "#0038ba",
    minHeight: "100vh",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "white",
    },
  },
  root: {
    padding: theme.spacing(10, 25, 5, 25),
    maxWidth: "100%",
    maxHeight: "100%",
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0, 0, 0, 0),
    },
  },
  margin: {
    width: "23vw",
    [theme.breakpoints.down("sm")]: {
      width: 300,
    },
    [theme.breakpoints.down("xs")]: {
      width: 200,
    },
    marginBottom: 1,
  },
  image: {
    backgroundImage: "url('/images/login-image.png')",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderBottomLeftRadius: "5%",
    borderTopLeftRadius: "5%",
    maxHeight: "100%",
  },
  paper: {
    margin: theme.spacing(4, 5),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(3, 4),
    },
    display: "flex",
    flexDirection: "column",
  },
  logo: {
    maxWidth: "40%",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  pap: {
    backgroundColor: "white",
    // maxHeight: 900,
    borderBottomRightRadius: "5%",
    borderTopRightRadius: "5%",
    [theme.breakpoints.down("sm")]: {
      borderBottomRightRadius: "0%",
      borderTopRightRadius: "0%",
    },
  },
}));

function VerifyBvn(props) {
  const classes = useStyles();
  const { isShowing, toggle } = useModal();
  const { isShowing2, toggle2 } = useModal();
  const [disabled, setDisabled] = useState(true);

  const refLink = props.match.params.code

  const [users, setUsers] = useState({
    channel: 'register_bvn_web',
    trans_ref: props.transRef,
    otp_code: "",
    username: "",
    password: "",
    c_password: "",
    parent_id: refLink ? refLink : "",
  });

  console.log(props)

  const { trans_ref, channel, otp_code, username, parent_id } = users;

  function handleChange(e) {
    const { name, value } = e.target;
    setUsers((users) => ({ ...users, [name]: value }));
  }
  const { register, handleSubmit, errors, watch } = useForm({
    mode: "onBlur",
  });

  const watchedPassword = watch("password", "");

  function validateReferral() {
    if (parent_id.length === 5) {
      props.referralIsUnique(parent_id)
    }
  };

  function validateBvnOtp() {
    if (otp_code.length === 6 ) {
      props.verify_register_bvn_otp(trans_ref, otp_code, channel)
    }
  };

  useEffect(() => {
    validateBvnOtp();
  }, [users.otp_code]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    validateReferral();
  }, [parent_id]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.otpSuccess === true) {
      setDisabled(false)
    };
  }, [props.otpSuccess]) // eslint-disable-line react-hooks/exhaustive-deps


  function onSubmit() {
    props.verify_bvn_registration(users);
  }

  return (
    <div className={classes.header}>
      <Helmet>
                <title>GetriPay - Fastest growing digital bank with rewards </title>
                <meta name="description" content="Verify BVN Otp" />
            </Helmet>
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          component={Paper}
          elevation={0}
          square
          className={classes.pap}
        >
          <div className={classes.paper}>
            <div>
              <img
                src="/images/getripaylogo.png"
                alt="logo"
                className={classes.logo}
              />
            </div>
            <div>
              <h1 className='typo'>Enter the otp sent via SMS to complete your registration</h1>
            </div>

            {/*Start Form */}
            <form
              className={classes.form}
              name="form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div>
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <label>OTP </label>
                    <input
                      disabled={props.isVerifyingRegisterBvnOtp}
                      type="text"
                      value={otp_code}
                      onChange={handleChange}
                      //onBlur={validateBvnOtp}
                      name="otp_code"
                      ref={register({
                        required: "Enter the Otp sent to your phone",

                      })}
                      style={{ borderColor: errors.otp_code && "red" }}
                    />
                    {errors.otp_code && <p className="error">{errors.otp_code.message}</p>}
                    {props.isVerifyingRegisterBvnOtp ?
                      <CircularProgress size={14} color="primary" />
                      :
                      ''
                    }
                    {props.otpSuccess === true
                      ?
                      <span >
                        <img src="/images/success-icon.svg" style={{ maxWidth: '25px' }} alt='success' />
                      </span>
                      :
                      props.otpSuccess === false
                        ?
                        <p style={{ color: 'red', fontSize: '12px' }}>
                          Invalid OTP
                     </p>
                        :
                        ''
                    }

                  </Grid>
                </Grid>
              </div>

              {/* username */}
              <div>
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <label>Username</label>
                    <input
                      disabled={disabled}
                      value={username}
                      onChange={handleChange}
                      name="username"
                      ref={register({
                        required: 'Enter your username',
                      })}
                      style={{ borderColor: errors.username && "red" }}
                    />
                    {errors.username && <p className="error">{errors.username.message}</p>}
                  </Grid>
                </Grid>
              </div>

              {/* password input */}
              <div>
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <label>Password</label>
                    <input
                      disabled={disabled}
                      type="password"
                      // value={password}
                      onChange={handleChange}
                      name="password"
                      ref={register({
                        required: 'Minimum of 6 characters',
                        minLength: 6,
                      })}
                      style={{ borderColor: errors.password && "red" }}
                    />
                    {errors.password && <p className="error">{errors.password.message}</p>}
                  </Grid>
                </Grid>
              </div>

              {/* ConfirmPassword Field */}
              <div>
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <label>Confirm Password</label>
                    <input
                      disabled={disabled}
                      type="password"
                      // value={c_password}
                      onChange={handleChange}
                      name="c_password"
                      ref={register({
                        validate: (value) =>
                          value === watchedPassword ||
                          "The passwords do not match",
                      })}
                      style={{ borderColor: errors.c_password && "red" }}
                    />
                    {errors.c_password && (
                      <p className='error'>
                        {errors.c_password.message}
                      </p>
                    )}
                  </Grid>
                </Grid>
              </div>


              {/* Referral Field */}
              <div>
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item>
                    <label>Referral code(optional)</label>
                    <input
                      disabled={disabled || props.isVerifyingRegisterBvn}
                      type="number"
                      name="parent_id"
                      value={parent_id}
                      onChange={handleChange}
                    />
                    {props.isAuthenticating ?
                      <CircularProgress size={14} color="primary" />
                      :
                      ''
                    }
                    {props.referralStatus === false
                      ?
                      <p style={{ color: 'red', fontSize: '12px' }}>
                        Invalid referral code. Please enter a correct code or proceed with registration
                     </p>
                      :
                      props.referralStatus === true
                        ?
                        <span >
                          <img src="/images/success-icon.svg" style={{ maxWidth: '25px' }} alt='success' />
                        </span>
                        :
                        ''
                    }
                  </Grid>
                </Grid>
              </div>

              <div>
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                />
                <span className="agree">
                  I agree with the <span className='terms' onClick={toggle} >Terms and conditions</span> and <span className='terms' onClick={toggle2}>Privacy Policy</span> of
                  <span className="getri"> GetriPay</span>
                  <TermsModal
                    isShowing={isShowing}
                    hide={toggle}
                  />
                  <PrivacyModal
                    isShowing2={isShowing2}
                    hide={toggle2}
                  />
                </span>
              </div>

              <Grid container>
                <Grid item component={Link} to="/" className='already'>
                  Already have an account?  <span className='terms'>Login here</span>
                </Grid>
              </Grid>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                size="large"
                color="primary"
                className="submit"
                disabled={disabled || props.isVerifyingRegisterBvn}
              >
                {props.isVerifyingRegisterBvn && <CircularProgress className="circle" />}
                Continue
              </Button>
            </form>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    verify_bvn_registration: (payload) => dispatch(verify.verify_bvn_registration(payload)),
    referralIsUnique: (payload) => dispatch(auth.referralIsUnique(payload)),
    verify_register_bvn_otp: (trans_ref, otp_code, channel) => dispatch(verify.verify_register_bvn_otp(trans_ref, otp_code, channel))
  };
};

const mapStateToProps = (state) => {
  return {
    isVerifyingRegisterBvn: state.verifyRegisterBvn.isVerifyingRegisterBvn,
    isAuthenticating: state.verify.isAuthenticating,
    referralStatus: state.validateReferral.success,
    transRef: state.registerBvn.data,
    otpSuccess: state.validateRegisterBvnOtp.data,
    isVerifyingRegisterBvnOtp: state.validateRegisterBvnOtp.isVerifyingRegisterBvnOtp
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyBvn);
