import { userConstants } from "../actions/types";

const initialState = {
    isLoading: false,
    data: [],
};

function getPhysicalCard(state = initialState, action) {
    switch (action.type) {
        case userConstants.GET_PHYSICAL_CARD_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case userConstants.GET_PHYSICAL_CARD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            };
        case userConstants.GET_PHYSICAL_CARD_FAILURE:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return state;
    }
}

export default getPhysicalCard;
