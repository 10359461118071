import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid } from "@material-ui/core";
import "../../../css/dashboard.css";
import TopBar from "../../../components/TopBar";
import { MainContent, Footer, HeaderBar } from "../../../components/Appearance";
import SideNav from "../../../components/SideNav";
import BottomNav from "../../../components/bottomNavigation";
import { Helmet } from 'react-helmet';
import { connect } from "react-redux";
import savings from "../../../services/savingsService";
import { history } from "../../../store/history";
import TransactionScroll from "../../../components/Skeleton/TransactionScroll";
import transactions from "../../../services/transactionService";


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        backgroundColor: "white",
    },
    content: {
        flexGrow: 1,
        backgroundColor: "white",
    },
    container: {
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(4),
        backgroundColor: "white",
    },
    large: {
        width: 100,
        height: 100,
    },
    customBadge: {
        color: "white",
        marginRight: '-55px',
        width: '120px'
    },
    txnLogo: {
        height: '25px',
        position: 'absolute',
        marginLeft: '-50px',
        [theme.breakpoints.down("sm")]: {
            position: 'relative',
            marginLeft: '0px',
        },
    },
    small: {
        fontSize: '12px',
        marginTop: theme.spacing(-2),
        marginLeft: '2px',
    },
    ref: {
        marginTop: theme.spacing(-0.5),
        fontSize: '12px',
        marginLeft: '2px',
        fontWeight: 'bolder',
    },
    name: {
        fontSize: '11px',
        fontWeight: 'bolder',
        marginLeft: '2px',
        textTransform: 'capitalize'
    },
}));

function AvailableWalletTransactions(props) {
    const classes = useStyles();
    const [toggle, setToggle] = useState(true);
    const [sidenav, setSidenav] = useState("getri-sidenav");

    const id = props.user.id;
    const wallet_id = props.match.params.slug;

    const handleToggle = () => {
        const mediaScreen = window.matchMedia(
            `(max-width: 700px),
        (min-device-width: 768px)
        and (max-device-width: 1024px)`
        ); //returns true if

        if (mediaScreen.matches) {
            setSidenav("slideOut");
        } else {
            setToggle(!toggle);
        }
    };
    const hideSidebar = () => {
        setSidenav("slideIn");
    };

    useEffect(() => {
        props.get_wallet(id);
        props.get_available_wallet_transactions(wallet_id);
        props.get_account_balance();
    }, []);

    return (
        <>
            <Helmet>
                <title>GetriPay - Fastest growing digital bank with rewards </title>
                <meta name="description" content="Savings" />
                <meta name="keywords" content="savings" />
            </Helmet>
            <TopBar handleToggle={handleToggle} />
            <SideNav toggle={toggle} className={sidenav} hideSidebar={hideSidebar} {...props} />
            <MainContent toggle={toggle}>
                <main className={classes.content}>
                    <Grid container>
                        <Grid item xs={12} md={12} lg={12}>
                            <Container maxWidth="lg" className={classes.container}>
                                <Container className="main-container">
                                    <HeaderBar topic="GetriSave" text={Number(props.balance.available_balance).toLocaleString()} />
                                </Container>
                            </Container>
                        </Grid>

                        <Container>
                            <Grid container>
                                <Grid item lg={3} className='overview-text'>
                                    <span>Wallet Transactions</span>
                                </Grid>
                                <Grid item lg={7} className='overview-text'>
                                    <Grid container style={{ fontSize: '14px' }}>

                                        <Grid item lg={4}>
                                            Wallet number: <br />
                                            {props.wallet ?
                                                <>
                                                    <span style={{ color: '#0037ba' }}>{props.wallet.wallet_number}</span>
                                                </>
                                                :
                                                ''
                                            }
                                        </Grid>
                                        <Grid item lg={4}>
                                            Amount you can withdraw: <br />
                                            {props.wallet ?
                                                <>
                                                    <span style={{ color: '#0037ba' }}>NGN {Number(props.wallet.available_balance).toLocaleString()}</span>
                                                </>
                                                :
                                                ''
                                            }
                                        </Grid>
                                        <Grid item lg={4}>
                                            Getrisave balance:<br />
                                            {props.wallet ?
                                                <>
                                                    <span style={{ color: '#ff0000' }}>NGN {Number(props.wallet.ledger_balance).toLocaleString()}</span>
                                                </>
                                                :
                                                ''
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/* <Grid item lg={3}> */}
                                <Grid item lg={2}>
                                    <span className='back' onClick={history.goBack}>Go back</span>
                                </Grid>
                            </Grid>
                        </Container>

                        <Grid container spacing={3}>

                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                {props.transactions.length === 0 ?
                                    <>
                                        {props.loading ? <TransactionScroll /> :
                                            <h3 style={{ textAlign: 'center', fontWeight: 'bolder' }}>Nothing to see here</h3>
                                        }
                                    </>
                                    :
                                    (<>
                                        {props.transactions.map((data, i) => (
                                            <div key={i} className='history-table2'  >
                                                <span className={data.transaction_type === 'credit' ? 'deb2' : 'deb'} style={{ fontSize: '12px' }}>{data.transaction_type}</span>
                                                <span > - {data.transaction_desc && data.transaction_desc}</span>
                                                <div style={{ display: 'inline-block', float: 'right' }}>
                                                    <span className={data.status === 'success' ? 'card-success-dot' : data.status === 'pending' ? 'card-pending-dot' : data.status === 'failed' ? 'card-dot' : ''}
                                                        style={{ display: 'inline-block', float: 'left' }}></span>
                                                    <span className={data.status === 'success' ? 'deb2' : data.status === 'pending' ? 'deb3' : data.status === 'failed' ? 'deb1' : ''}
                                                        style={{ display: 'inline-block', float: 'right', textTransform: 'uppercase' }}>
                                                        {data.status}
                                                    </span>
                                                </div>

                                                <div className='user-inline-items'>
                                                    <div style={{ padding: 'none', paddingRight: '10%' }}>
                                                        <span style={{ marginTop: '20px' }} className='trans-amount'>
                                                            NGN {' '}   {Number(data.amount).toLocaleString()}
                                                        </span>
                                                    </div>

                                                    <div >
                                                        <div >
                                                            <img alt='logo' src='/images/getriSave_logo.png' className={classes.txnLogo} />
                                                            <div>
                                                                <p className={classes.name}>
                                                                    {data.description}
                                                                </p>
                                                                <p className={classes.small}>{data.recipient_no} </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div >
                                                        <span className={classes.small}>{new Date(data.created_at).toLocaleString()}</span>
                                                        <p className={classes.ref}>REF: {data.trans_ref}</p>
                                                    </div>

                                                </div>
                                            </div>
                                        ))}
                                    </>)

                                }

                            </Grid>
                        </Grid>
                    </Grid>
                    <BottomNav {...props} />
                    {/* <Footer /> */}
                </main>
            </MainContent>

        </>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        get_available_wallet_transactions: (wallet_id) => dispatch(savings.get_available_wallet_transactions(wallet_id)),
        get_wallet: (id) => dispatch(savings.get_wallet(id)),
        get_account_balance: () => dispatch(transactions.get_account_balance()),
    };
};

const mapStateToProps = (state) => {
    return {
        user: state.userDetails.data,
        wallet: state.walletBalance.data,
        transactions: state.availableWalletTransactions.data,
        loading: state.availableWalletTransactions.isLoading,
        balance: state.getAccountBalance.data,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AvailableWalletTransactions);
