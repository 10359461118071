import React, { useState, useEffect } from "react";
import { Container, Grid, Button } from "@material-ui/core";
import { Helmet } from "react-helmet";
import TopBar from "../../../components/TopBar";
import SideNav from "../../../components/SideNav";
import {
  HeaderBar,
  InputGrid,
  MainContent,
} from "../../../components/Appearance";
import { useStyles } from "../QR/components/utils";
import transactions from "../../../services/transactionService";
import { connect, useDispatch } from "react-redux";
import "./bulk.css";
import BackDrop from "../../../components/BackDrop";
import { CSVLink } from "react-csv";
import transfers from "../../../services/transferService";
import bulk from "../../../services/bulkPaymentService";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { userActions } from "../../../actions/creators";
import { setDate } from "date-fns";
import swal from "sweetalert";

const BulkPayment = (props) => {
  const classes = useStyles();
  const [toggle, setToggle] = useState(true);
  const [sidenav, setSidenav] = useState("getri-sidenav");
  const [file, setFile] = useState(null);
  const [disabled, setDisabled] = useState(false);

  const dispatch = useDispatch();

  const [data, setData] = useState({
    user_id: props.user?.id,
    batch_name: "",
    account_number: props.balance.account_number,
    bank_code: "090317",
    bank_name: "GetriPay",
    account_name: props.balance.account_name,
    validation_type: "file",
    transaction_pin: "",
  });

  useEffect(() => {
    setData({
      ...data,
      account_name: props.balance.account_name,
      account_number: props.balance.account_number,
    });
  }, [props.balance]);

  console.log(Number(props.balance.available_balance));
  console.log(props.validateRes?.total_amount);

  const handleToggle = () => {
    const mediaScreen = window.matchMedia(
      `(max-width: 700px),
        (min-device-width: 768px)
        and (max-device-width: 1024px)`
    ); //returns true if

    if (mediaScreen.matches) {
      setSidenav("slideOut");
    } else {
      setToggle(!toggle);
    }
  };
  const hideSidebar = () => {
    setSidenav("slideIn");
  };

  function handleChange(e) {
    const { name, value } = e.target;
    setData((data) => ({ ...data, [name]: value }));
  }

  const codes = props.banks.map((res, i) => {
    return {
      bank: res.InstitutionName,
      code: '""' + res.InstitutionCode + '""',
    };
  });

  function processPayment() {
    if (props.validateRes.batch_code) {
      props.process_payment(
        data.user_id,
        props.validateRes.batch_code,
        data.transaction_pin
      );
    }
  }

  function goBack() {
    dispatch(userActions.validateBulkPaymentSuccess([]));
    setDisabled(false);
  }

  useEffect(() => {
    props.get_account_balance();
    props.get_bank();
  }, []);

  if (
    Number(props.validateRes?.total_amount) >
    Number(props.balance.available_balance)
  ) {
    swal(
      "Oh oh!",
      "Insufficient funds. Kindly fund your account or switch to an account with enough funds",
      "error"
    );
  }

  return (
    <>
      <Helmet>
        <title>GetriPay - Fastest growing digital bank with rewards </title>
        <meta name="description" content="Bulk Payment" />
        <meta name="keywords" content="Bulk Payment" />
      </Helmet>

      <TopBar handleToggle={handleToggle} />
      <SideNav
        toggle={toggle}
        className={sidenav}
        hideSidebar={hideSidebar}
        {...props}
      />
      <MainContent toggle={toggle}>
        {props.loadingValidate || props.loadingProcess ? (
          <div>
            <BackDrop />
          </div>
        ) : null}
        <main className={classes.content}>
          <Grid container>
            <Grid item xs={12} md={12} lg={12}>
              <Container maxWidth="lg" className={classes.container}>
                <Container className="main-container">
                  <HeaderBar
                    topic="Bulk Payments"
                    text={Number(
                      props.balance.available_balance
                    ).toLocaleString()}
                  />
                </Container>

                <Grid container>
                  <Grid item xs={12} md={6} lg={6}>
                    <div className={disabled ? "overlay" : ""}></div>
                    <div className="bulkInfo">
                      <p>
                        You will upload an Excel(.xlsx) file that contains the
                        beneficiaries details. <br /> Click the link below to
                        download the smaple file format.
                      </p>
                      <a
                        href="/images/bulk.xlsx"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Download Sample File Upload Format
                      </a>
                      <CSVLink data={codes} filename={"bank-codes.csv"}>
                        <Button
                          type="primary"
                          style={{
                            marginTop: "-8px",
                            color: "red",
                            fontWeight: "bolder",
                            marginLeft: "10px",
                          }}
                        >
                          Get bank codes
                        </Button>
                      </CSVLink>
                    </div>

                    <div className="bulk-payment-plain">
                      <div className="bulkDiv">
                        <label>General Transaction Description</label>
                        <InputGrid>
                          <input
                            className="input-div"
                            type="text"
                            name="batch_name"
                            placeholder="General transaction description here"
                            value={data.batch_name}
                            onChange={handleChange}
                            autoComplete="off"
                          />
                        </InputGrid>

                        <InputGrid>
                          <label>Transaction Pin</label>
                          <input
                            className="input-div"
                            type="password"
                            name="transaction_pin"
                            placeholder="Enter your transaction pin here"
                            value={data.transaction_pin}
                            onChange={handleChange}
                            maxLength={4}
                            autoComplete="off"
                          />
                        </InputGrid>

                        <InputGrid>
                          <label>Select an excel(.xlsx) file here</label>
                          <input
                            className="input-div"
                            type="file"
                            onChange={(e) => {
                              setFile(e.target.files[0]);
                              props.validate_bulk(data, e.target.files[0]);
                              setDisabled(true);
                            }}
                          />
                        </InputGrid>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={6} lg={6}>
                    <div className="bulk-payment-gray">
                      {disabled && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                        >
                          {" "}
                          <ArrowBack onClick={goBack} />
                          <h6 onClick={goBack}>GO BACK</h6>
                        </div>
                      )}

                      <p>Verified Accounts</p>
                      {props.validateRes?.verify_account?.length !== 0 && (
                        <div style={{ fontSize: "12px" }}>
                          {props.validateRes && (
                            <table>
                              <tr>
                                <th>Account Name</th>
                                <th>Account Number</th>
                                <th>Amount</th>
                                <th>Bank</th>
                              </tr>

                              {props.validateRes?.verify_account?.map(
                                (res, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>{res.account_name}</td>
                                      <td>{res.account_number}</td>
                                      <td>
                                        NGN{" "}
                                        {Number(res.amount).toLocaleString()}{" "}
                                      </td>
                                      <td>{res.bank_name}</td>
                                    </tr>
                                  );
                                }
                              )}
                            </table>
                          )}
                        </div>
                      )}
                      {props.validateRes?.verify_account && (
                        <p style={{ fontSize: "12px" }}>
                          {props.validateRes?.verify_account?.length} Verified
                          Accounts
                        </p>
                      )}
                      {props.validateRes?.total_amount && (
                        <p style={{ fontSize: "12px" }}>
                          Total Amount : NGN{" "}
                          {Number(
                            props.validateRes?.total_amount
                          ).toLocaleString()}
                        </p>
                      )}

                      <p style={{ fontWeight: "bolder", color: "red" }}>
                        Unverified Accounts
                      </p>

                      {props.validateRes?.un_verify_account?.length !== 0 && (
                        <div style={{ fontSize: "12px", color: "red" }}>
                          {props.validateRes && (
                            <table>
                              <tr>
                                <th>Bank</th>
                                <th>Account Number</th>
                                <th>Amount</th>
                              </tr>

                              {props.validateRes?.un_verify_account?.map(
                                (res, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>{res.bank_name}</td>
                                      <td>{res.account_number}</td>
                                      <td>
                                        NGN{" "}
                                        {Number(res.amount).toLocaleString()}{" "}
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </table>
                          )}
                        </div>
                      )}

                      {props.validateRes?.un_verify_account && (
                        <p style={{ color: "red", fontSize: "12px" }}>
                          {props.validateRes?.un_verify_account?.length}{" "}
                          Unverified Accounts
                        </p>
                      )}

                      {props.validateRes?.verify_account ||
                      props.validateRes?.un_verify_account ? (
                        <Button
                          onClick={processPayment}
                          fullWidth
                          variant="contained"
                          size="small"
                          disabled={
                            Number(props.validateRes?.total_amount) >
                            Number(props.balance.available_balance)
                          }
                          className="getri-button"
                          style={{
                            backgroundColor: "#0037ba",
                            color: "#fff",
                          }}
                        >
                          Proceed with payment
                        </Button>
                      ) : null}
                    </div>
                  </Grid>
                </Grid>
              </Container>
            </Grid>
          </Grid>
        </main>
      </MainContent>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_account_balance: (payload) =>
      dispatch(transactions.get_account_balance(payload)),
    get_bank: (payload) => dispatch(transfers.get_bank(payload)),
    validate_bulk: (data, file) => dispatch(bulk.validate_bulk(data, file)),
    process_payment: (user_id, batch_data_id, transaction_pin) =>
      dispatch(bulk.process_payment(user_id, batch_data_id, transaction_pin)),
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.userDetails.data,
    balance: state.getAccountBalance.data,
    banks: state.fetch.data,
    validateRes: state.validateBulkPayment.data,
    loadingValidate: state.validateBulkPayment.isLoading,
    loadingProcess: state.processBulkPayment.isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkPayment);
