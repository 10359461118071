import { userConstants } from '../actions/types'

const initialState = {
    isAuthenticating: false
}
 
function verify(state = initialState, action) {
    switch (action.type) {
        case userConstants.VERIFYING:
            return {
                isAuthenticating: true
            };
        case userConstants.VERIFIED:
            return {
                isAuthenticating: false
            };
        default:
            return state
    }
}

export default verify;