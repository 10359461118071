import React, { useState } from 'react';
import { Button, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { history } from '../../../../store/history';

export default function GroupPlanBox(props) {
    const [show, setShow] = useState(false)
 
    function gotoPlan() {
        if (props.user_kyc < props.kyc_level) {
            setShow(true)
        } else {
            setShow(false)
            history.push(`create-group-savings/${props.id}`)
        }
    };

    function pushtoUpgrade() {
        if (props.user_kyc === 1) {
            history.push('/validate-bvn')
        } else if (props.user_kyc === 2) {
            history.push('/upgrade-account')
        }
    };

    function handleClose() {
        setShow(false)
    };

    return (
        <div onClick={gotoPlan}>
            <div className='start-savings-modal'>
                <img
                    src={props.image_url}
                />
                <p style={{ textTransform: 'uppercase' }}>{props.name}</p>
                <span className='start-savings-modal-text'>{props.description}</span>

            </div>
            <Snackbar open={show} autoHideDuration={6000} onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }
                }
            >
                <Alert severity="error"
                    action={
                        <Button color="inherit" size="small" onClick={pushtoUpgrade}>
                            {props.user_kyc === 1 ?
                                'Link BVN'
                                :
                                props.user_kyc === 2 ?
                                    'Upload identification document'
                                    :
                                    ''
                            }
                        </Button>
                    }
                >
                    {props.user_kyc === 1 ?
                        'You are not eligible for this savings product. Please link your BVN first'
                        :
                        props.user_kyc === 2 ?
                            'You are not eligible for this savings product. Please upload a valid identification document'
                            :
                            ''
                    }

                </Alert>
            </Snackbar>
        </div >
    )
}