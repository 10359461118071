import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import "../../css/register.css";
import "../../css/otp.css";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import forgot from "../../services/forgotService";
import { history } from '../../store/history';
import CircularProgress from '@material-ui/core/CircularProgress';
import resend from '../../services/resendService';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: "#0038ba",
        minHeight: "100vh",
        [theme.breakpoints.down("sm")]: {
            backgroundColor: 'white'
        },
    },
    root: {
        padding: theme.spacing(10, 30, 10, 30),
        maxWidth: "100%",
        maxHeight: "100%",
        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(0, 0, 0, 0),
        },
    },
    image: {
        backgroundImage: "url('/images/login-image.png')",
        backgroundRepeat: "no-repeat",
        backgroundColor:
            theme.palette.type === "light"
                ? theme.palette.grey[50]
                : theme.palette.grey[900],
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderBottomLeftRadius: "5%",
        borderTopLeftRadius: "5%",
        maxHeight: "100%",
    },
    paper: {
        margin: theme.spacing(6, 6),
        display: "flex",
        flexDirection: "column",
    },
    logo: {
        maxWidth: "50%",
        marginBottom: 80
    },
    form: {
        width: "100%",
        marginTop: theme.spacing(1),
    },

    pap: {
        backgroundColor: "white",
        borderBottomRightRadius: "5%",
        borderTopRightRadius: "5%",
        [theme.breakpoints.down("sm")]: {
            borderBottomRightRadius: "0%",
            borderTopRightRadius: "0%",
        },
    },
    margin: {
        width: "17vw",
        [theme.breakpoints.down("sm")]: {
            width: 300,
        },
        [theme.breakpoints.down("xs")]: {
            width: 200,
        },
        marginBottom: 1,
    },
    getri: {
        fontWeight: "bold",
    },

}));



function ValidateOtp(props) {
    const classes = useStyles();

    const [token, setToken] = useState('')
    const [counter, setCounter] = useState(120);

    const [submitted, setSubmitted] = useState(false);

    const email = sessionStorage.getItem('email')

    const padTime = time => {
        return String(time).length === 1 ? `0${time}` : `${time}`;
    };

    const format = time => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes}:${padTime(seconds)}`;
    };


    useEffect(() => {
        let timer;
        if (counter > 0) {
            timer = setTimeout(() => setCounter(c => c - 1), 1000);
        }

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [counter]);

    function resend() {
        props.forgotPassword(email)
    }

    function handleChange(e) {
        setToken(e.target.value)
    }

    function handleSubmit(e) {
        e.preventDefault();

        setSubmitted(true);
        if (token) {
            props.validateOtp(token);
        }
    }

    return (
        <div className={classes.header}>
            <Helmet>
                <title>GetriPay - Fastest growing digital bank with rewards </title>
                <meta name="description" content="forgot passwowrd" />
            </Helmet>
            <Grid container component="main" className={classes.root}>
                <CssBaseline />
                <Grid item xs={false} sm={4} md={7} className={classes.image} />
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={5}
                    component={Paper}
                    elevation={0}
                    square
                    className={classes.pap}
                >
                    <div className={classes.paper}>
                        <div>
                            <img
                                src="images/getripaylogo.png"
                                alt="logo"
                                className={classes.logo}
                            />
                        </div>
                        <div>
                            <p className='typo'>Password reset</p>
                        </div>

                        <div>
                            <p className='digit'>Please enter the OTP sent to your e-mail</p>
                        </div>

                        <form className={classes.form} name="form" onSubmit={handleSubmit}>
                            <div>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>
                                        <label>OTP</label>
                                        <input value={token} type='password' onChange={handleChange} name="phone_number" className={'form-control' + (submitted && !token ? ' is-invalid' : '')} />
                                        {submitted && !token &&
                                            <div className="invalid-feedback">This field is required</div>
                                        }
                                    </Grid>
                                </Grid>
                            </div>

                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                size="large"
                                color="primary"
                                className='submit'
                                disabled={props.isLoading}
                            >
                                {props.isLoading && <CircularProgress className='circle' />}
                                Continue
                            </Button>
                        </form>
                        <Grid container>
                            <Grid item component={Link} to="/register" className='already'>
                                Don't have an account?  <span className='terms'>Create one here</span>
                            </Grid>

                        </Grid>
                        {counter === 0 ?
                            <Grid container>
                                <Grid item className='already' onClick={resend}>
                                    Did not get the SMS?  <span className='terms'>Resend</span>
                                </Grid>
                            </Grid>
                            :
                            <Grid item className='already'>
                                Resend OTP in: <span className='terms'>{format(counter)}</span><span>s</span>
                            </Grid>
                        }

                        <p onClick={history.goBack} className='back'>Go Back</p>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        validateOtp: (token) => dispatch(forgot.validate_otp(token)),
        forgotPassword: (email) => dispatch(forgot.forgot_password(email))
    }
}
const mapStateToProps = (state) => {
    return {
        isLoading: state.response.isLoading
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(ValidateOtp);
