import { userConstants } from "../actions/types";

const initialState = {
  isGettingFrequent: false,
  data: [],
  firstCount: [],
  secondCount: []
};

function frequentTransaction(state = initialState, action) {
  switch (action.type) {
    case userConstants.GET_FREQUENT_TRANS_REQUEST:
      return {
        ...state,
        isGettingFrequent: true,
        firstCount: [],
        secondCount:[]
      };
    case userConstants.GET_FREQUENT_TRANS_SUCCESS:
      return {
         ...state,
        isGettingFrequent: false,
        // data: action.payload,
        firstCount: action?.payload?.mostFrequentTransaction,
        secondCount: action?.payload?.secondMostFrequestTransaction
      };
    default:
      return state;
  }
}

export default frequentTransaction;
