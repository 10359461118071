import { userConstants } from "../actions/types";

const initialState = {
  isLoading: false,
  data: [],
  history:[],
  details:[]
};

export function validateBulkPayment(state = initialState, action) {
  switch (action.type) {
    case userConstants.VALIDATE_BULK_PAYMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case userConstants.VALIDATE_BULK_PAYMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    default:
      return state;
  }
}

export function processBulkPayment(state = initialState, action) {
  switch (action.type) {
    case userConstants.PROCESS_BULK_PAYMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case userConstants.PROCESS_BULK_PAYMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}

export function bulkPaymentHistory(state = initialState, action) {
  switch (action.type) {
    case userConstants.GET_BULK_HISTORY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case userConstants.GET_BULK_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        history: action.payload,
      };
    default:
      return state;
  }
}

export function bulkPaymentDetails(state = initialState, action) {
    switch (action.type) {
      case userConstants.GET_BULK_DETAILS_REQUEST:
        return {
          ...state,
          isLoading: true,
        };
      case userConstants.GET_BULK_DETAILS_SUCCESS:
        return {
          ...state,
          isLoading: false,
          details: action.payload,
        };
      default:
        return state;
    }
  }