import React, { useState } from "react";
import { makeStyles, withStyles, createStyles } from "@material-ui/core/styles";
import { Container, Grid, Paper, Divider, Typography } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import EditProfileCard from "../../components/SettingsCards/EditProfile";
import NotificationsCard from "../../components/SettingsCards/Notifications";
import SecurityCard from "../../components/SettingsCards/Security";
import ReportAProblemCard from "../../components/SettingsCards/Help/ReportAProblem";
//import TermsAndConditions from '../../components/SettingsCards/About/Terms&Conditions';
import TopBar from "../../components/TopBar";
import BottomNav from "../../components/bottomNavigation";
import { MainContent, Footer } from "../../components/Appearance";
import SideNav from "../../components/SideNav";
import Collapse from "@material-ui/core/Collapse";
import "../../css/solomon.css";
import "../../css/settings.css";
import { Helmet } from "react-helmet";
import AccountLevel from "../../components/SettingsCards/AccountLevel";
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";

const drawerWidth = 240;

const useStyles = makeStyles((theme) =>
  createStyles({
    appMenu: {
      width: "100%",
    },
    navList: {
      width: drawerWidth,
    },
    menuItem: {
      width: drawerWidth,
    },
    menuItemIcon: {
      color: "#97c05c",
    },
    content: {
      flexGrow: 1,
      height: "100%",
      width: "auto",
      backgroundColor: "white",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      height: "auto",
      maxWidth: "auto",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  })
);

// tabs starts

const SettingsTabs = withStyles({
  root: {
    borderRight: "0px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: "blue",
  },
})(Tabs);

const SettingsTab = withStyles((theme) => ({
  root: {
    fontFamily: "CarmenSans-Bold",
    fontSize: "13px",
    textTransform: "none",
    maxWidth: "100%",
    padding: "6px 20px",
    alignment: {
      display: "flex",
      flexDirection: "row",
    },
    "&:hover": {
      color: "blue",
      opacity: 1,
    },
    "&$selected": {
      color: "primary",
      backgroundColor: "#E8E8E8",
      borderRight: "5px solid blue",
    },
    "&:focus": {
      color: "blue",
      backgroundColor: "#E8E8E8",
      borderRight: "5px solid blue",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function Settings(props) {
  const classes = useStyles();

  const [toggle, setToggle] = useState(true);
  const [sidenav, setSidenav] = useState("getri-sidenav");
  const match = useRouteMatch();
  const [open] = useState(false);

  const handleToggle = () => {
    const mediaScreen = window.matchMedia(
      `(max-width: 700px),
		  (min-device-width: 768px)
		  and (max-device-width: 1024px)`
    ); //returns true if

    if (mediaScreen.matches) {
      setSidenav("slideOut");
    } else {
      setToggle(!toggle);
    }
  };
  const hideSidebar = () => {
    setSidenav("slideIn");
  };

  // function handleClick(event, newValue) {
  // 	setValue(newValue);
  // 	setOpen(!open);
  // }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  return (
    <>
      <Helmet>
        <title>GetriPay - Fastest growing digital bank with rewards </title>
        <meta name="description" content="Settings" />
        <meta name="keywords" content="settings" />
      </Helmet>

      <TopBar handleToggle={handleToggle} />
      <SideNav
        toggle={toggle}
        className={sidenav}
        hideSidebar={hideSidebar}
        {...props}
      />
      <MainContent toggle={toggle} style={{ height: "100vh" }}>
        <main className={classes.content}>
          <Grid container>
            <Grid item xs={12} md={12} lg={12}>
              <Container maxWidth="lg" className={classes.container}>
                <Grid item xs={12} md={12} lg={12}>
                  <Grid container spacing={3} style={{ marginTop: "40px" }}>
                    <Grid item xs={12} sm={12} md={3} lg={3}>
                      <Paper className="settingsBox1">
                        <SettingsTabs
                          orientation="vertical"
                          variant="scrollable"
                          value={props.location.pathname}
                          aria-label="Vertical tabs example"
                          indicatorColor="primary"
                          textColor="primary"
                          className={classes.tabs}
                        >
                          <SettingsTab
                            label={
                              <>
                                <div>
                                  <img
                                    src="/images/edit-profile-icon-blue.svg"
                                    alt="edit-profile-icon"
                                    style={{
                                      paddingTop: "10px",
                                      marginRight: "10px",
                                      height: "20px",
                                      width: "20px",
                                    }}
                                  />
                                  <span style={{ fontWeight: "bold" }}>
                                    Edit Profile
                                  </span>
                                </div>
                              </>
                            }
                            component={Link}
                            to={`${match.url}`}
                            value={`${match.url}`}
                          />

                          <SettingsTab
                            label={
                              <>
                                <div>
                                  <img
                                    src="/images/help-icon-blue.svg"
                                    alt="help-icon"
                                    style={{
                                      paddingTop: "10px",
                                      marginRight: "10px",
                                      height: "20px",
                                      width: "20px",
                                    }}
                                  />
                                  <span style={{ fontWeight: "bold" }}>
                                    Account Levels
                                  </span>
                                </div>
                              </>
                            }
                            component={Link}
                            to={`${match.url}/account-levels`}
                            value={`${match.url}/account-levels`}
                          />

                          <SettingsTab
                            label={
                              <>
                                <div>
                                  <img
                                    src="/images/notification-icon-blue.svg"
                                    alt="notification-icon"
                                    style={{
                                      paddingTop: "10px",
                                      marginRight: "10px",
                                      height: "20px",
                                      width: "20px",
                                    }}
                                  />
                                  <span style={{ fontWeight: "bold" }}>
                                    Notifications
                                  </span>
                                </div>
                              </>
                            }
                            component={Link}
                            to={`${match.url}/notifications`}
                            value={`${match.url}/notifications`}
                          />
                          <SettingsTab
                            label={
                              <>
                                <div>
                                  <img
                                    src="/images/security-icon-blue.svg"
                                    alt="security-icon"
                                    style={{
                                      paddingTop: "10px",
                                      marginRight: "10px",
                                      height: "20px",
                                      width: "20px",
                                    }}
                                  />
                                  <span style={{ fontWeight: "bold" }}>
                                    Security
                                  </span>
                                </div>
                              </>
                            }
                            component={Link}
                            to={`${match.url}/security`}
                            value={`${match.url}/security`}
                          />
                          <SettingsTab
                            label={
                              <>
                                <div>
                                  <img
                                    src="/images/help-icon-blue.svg"
                                    alt="help-icon"
                                    style={{
                                      paddingTop: "10px",
                                      marginRight: "10px",
                                      height: "20px",
                                      width: "20px",
                                    }}
                                  />
                                  <span style={{ fontWeight: "bold" }}>
                                    Report a problem
                                  </span>
                                </div>
                              </>
                            }
                            component={Link}
                            to={`${match.url}/report-a-problem`}
                            value={`${match.url}/report-a-problem`}
                          />
                        </SettingsTabs>
                      </Paper>
                    </Grid>

                    <Grid item xs={12} sm={12} md={9} lg={9}>
                      <Switch>
                        <Route path={`${match.url}/account-levels`}>
                          <AccountLevel />
                        </Route>
                        <Route path={`${match.url}/notifications`}>
                          <NotificationsCard />
                        </Route>

                        <Route path={`${match.url}/security`}>
                          <SecurityCard />
                        </Route>

                        <Route path={`${match.url}/report-a-problem`}>
                          <ReportAProblemCard />
                        </Route>

                        <Route path={`${match.url}`}>
                          <EditProfileCard />
                        </Route>
                      </Switch>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            </Grid>
          </Grid>
          <BottomNav {...props} />
          <Footer />
        </main>
      </MainContent>
    </>
  );
}
