import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import "../../css/register.css";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import forgot from "../../services/forgotService";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Helmet } from 'react-helmet'

const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: "#0038ba",
        minHeight: "100vh",
        [theme.breakpoints.down("sm")]: {
            backgroundColor: 'white',
        },
    },
    root: {
        padding: theme.spacing(15, 30, 10, 30),
        maxWidth: "100%",
        maxHeight: "100%",
        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(0, 0, 0, 0),
        },
    },
    image: {
        backgroundImage: "url('/images/login-image.png')",
        backgroundRepeat: "no-repeat",
        backgroundColor:
            theme.palette.type === "light"
                ? theme.palette.grey[50]
                : theme.palette.grey[900],
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderBottomLeftRadius: "5%",
        borderTopLeftRadius: "5%",
        maxHeight: "100%",
    },
    paper: {
        margin: theme.spacing(8, 6),
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("md")]: {
            margin: theme.spacing(8, 1),
        },

    },
    logo: {
        maxWidth: "50%",
        marginBottom: 80
    },
    form: {
        width: "100%",
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        borderRadius: 30,
        backgroundColor: "#0036BA",
        fontWeight: "bold",
        padding: 12,
    },
    pap: {
        backgroundColor: "white",
        borderBottomRightRadius: "5%",
        borderTopRightRadius: "5%",
        [theme.breakpoints.down("sm")]: {
            borderBottomRightRadius: "0%",
            borderTopRightRadius: "0%",
        },
    },
    agree: {
        marginLeft: -25,
        fontSize: 15,
    },
    getri: {
        fontWeight: "bold",
    },
}));

function ChangePassword(props) {
    const classes = useStyles();
    const [inputs, setInputs] = useState({
        new_password: '',
        c_new_password: '',
    });
    const [submitted, setSubmitted] = useState(false);
    const { new_password, c_new_password } = inputs;


    function handleChange(e) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    function handleSubmit(e) {
        e.preventDefault();
        setSubmitted(true);
        if (new_password && c_new_password) {
            props.change(new_password, c_new_password)
        }

    }

    return (
        <div className={classes.header}>
            <Helmet>
                <title>GetriPay - Fastest growing digital bank with rewards </title>
                <meta name="description" content="forgot passwowrd" />
            </Helmet>
            <Grid container component="main" className={classes.root}>
                <CssBaseline />
                <Grid item xs={false} sm={4} md={7} className={classes.image} />
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={5}
                    component={Paper}
                    elevation={0}
                    square
                    className={classes.pap}
                >
                    <div className={classes.paper}>
                        <div>
                            <img
                                src="images/getripaylogo.png"
                                alt="logo"
                                className={classes.logo}
                            />
                        </div>
                        <div>
                            <h1 className='typo'>Password Reset</h1>
                        </div>
                        {/*Start Form */}
                        <form className={classes.form} name="form" onSubmit={handleSubmit}>
                            {/* new password*/}
                            <div>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>
                                        <label>New Password</label>
                                        <input type='password' value={new_password} onChange={handleChange} name="new_password" className={'form-control' + (submitted && !new_password ? ' is-invalid' : '')} />
                                        {submitted && !new_password &&
                                            <div className="invalid-feedback">This field is required</div>
                                        }
                                    </Grid>
                                </Grid>
                            </div>
                            {/* confirm new password */}
                            <div>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>
                                        <label>Password</label>
                                        <input type='password' value={c_new_password} onChange={handleChange} name="c_new_password" className={'form-control' + (submitted && !c_new_password ? ' is-invalid' : '')} />
                                        {submitted && !c_new_password &&
                                            <div className="invalid-feedback">Password is required</div>
                                        }
                                    </Grid>
                                </Grid>
                            </div>


                            <Grid container>
                                <Grid item component={Link} to="/" className='already'>
                                    Remember your password?  <span className='terms'>Loginhere</span>
                                </Grid>

                            </Grid>

                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                size="large"
                                color="primary"
                                className='submit'
                                disabled={props.isLoading}
                            >
                                {props.isLoading && <CircularProgress className='circle' />}
                                        Continue
                                </Button>

                        </form>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        change: (new_password, c_new_password) => dispatch(forgot.change_password(new_password, c_new_password))
    }
};

const mapStateToProps = (state) => {
    return {
        isLoading: state.response.isLoading
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
