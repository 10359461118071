import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Grid, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import BackDrop from '../../../components/BackDrop';
import "../../../css/solomon.css";
import "../../../css/dashboard.css";
import { InputGrid } from "../../../components/Appearance";
import axios from 'axios';
import numeral from "numeral";
import config from '../../../config';
import { useFlutterwave } from 'flutterwave-react-v3';
import transactions from "../../../services/transactionService";
const baseUrl = `${config.apiUrl}`;

function FundModal(props) {
    const [amountNew, setAmountNew] = useState(0);
    const [loading, setLoading] = useState(false);
   
    const [getriRef, setGetriRef] = useState('');
    const [confirm, setConfirm] = useState(false);
    // Number formatter
    var valueDisplay = numeral(amountNew).format();

    const newAmt = valueDisplay.replace(/,/g, "");

    const test_key = process.env.REACT_APP_FLUTTERWAVE_PUBLIC_KEY;

    const req_id = Math.floor(Math.random() * 900000000000000000);

    function initiate() {
        axios.post(`${baseUrl}/accounts/${props.balance.account_number}/fund_with_flw/initiate`, first).then((res) => {
            setGetriRef(res.data.data.transaction_ref);
           // setOpen(false);
            setConfirm(true);
        })
    };

    const [first, setFirst] = useState({
        account_number: props.balance.account_number,
        user_id: props.user.id,
        amount: newAmt,
        narration: "Fund account: " + props.balance.account_number,
        request_id: req_id
    });

   

    const [fund] = useState({
        custom_logo: 'http://getripay.lending.ng/images/yellow-logo.svg',
        custom_title: 'Fund Your GetriPay Account: ' + props.balance.account_number,
        account_no: props.balance.account_number,
        key: test_key, // RavePay KEY
        phone: props.user.phone_number,
        firstname: props.user.first_name,
        lastname: props.user.last_name,
        email: props.user.email,
        txref: getriRef
    });

    console.log(fund)

    useEffect(() => {
        props.get_account_balance();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setFirst({
            account_number:props.balance.account_number,
            user_id: props.user.id,
            amount: newAmt,
            narration: "Fund account: " + props.balance.account_number,
            request_id: req_id
        });

    }, [newAmt, first.amount, props]); // eslint-disable-line react-hooks/exhaustive-deps

    const config = {
        public_key: fund.key,
        tx_ref: getriRef,
        amount: newAmt,
        currency: 'NGN',
        payment_options: 'card,mobilemoney,ussd',
        customer: {
            email: fund.email,
            phonenumber: fund.phone,
            name: fund.account_no + ': ' + fund.firstname + ' ' + fund.lastname,
        },
        customizations: {
            title: fund.custom_title,
            description: 'Account Fundinng',
            logo: fund.custom_logo,
        },
    };
   
    const handleFlutterPayment = useFlutterwave(config);

    const close = () => {
        setTimeout(() => {
            document.getElementsByName('checkout')[0].setAttribute('style', 'z-index: -1; opacity: 0')
        }, 1000)
    };

    function handleChange(e) {
        setAmountNew(e.target.value)
    }

    return (
        <div>
            {
                loading
                    ?
                    <BackDrop />
                    :
                    ''
            }
            <div>
                <Dialog
                    open={
                        props.open 
                    }
                 onClose={props.close}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title" style={{ color: "red" }}>
                        INSUFFICIENT FUNDS!!!
                    </DialogTitle>

                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            You can fund your account immediately, and proceed with your transaction
                    </DialogContentText>

                        <InputGrid >
                            <label style={{ marginBottom: '20px', marginTop: '10px' }}>
                                Enter an amount
                            </label>

                            <input
                                // type='number'
                                value={valueDisplay}
                                onChange={handleChange}
                                className="input-div"

                            />
                        </InputGrid>
                        <Grid item xs={12} md={12} lg={12} >
                            <div >
                                <Button
                                    fullWidth
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    className="submit"
                                    onClick={initiate}
                                >
                                    Continue
                                </Button>

                            </div>

                        </Grid>

                    </DialogContent>
                </Dialog>

                <Dialog
                    open={
                        confirm === true ? true
                            :
                            confirm === false ? false
                                :
                                ''
                    }
                    onClose={() => setConfirm(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title" style={{ color: "#0037ba" }}>
                        Account Funding
                                                        </DialogTitle>

                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to fund your Getripay Account with {valueDisplay}?
                                                            </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <div onClick={() => setConfirm(false)}>
                            <button
                                class='submit'
                                onClick={() => {
                                    handleFlutterPayment({
                                        callback: (response) => {
                                            var status = response.status;

                                            close();
                                            if (status == "successful") {

                                                setLoading(true);

                                                axios.post(`${baseUrl}/accounts/${props.balance.account_number}/fund_with_flw`, {
                                                    "account_number": props.balance.account_number,
                                                    "user_id": props.user.id,
                                                    "amount": newAmt,
                                                    "narration": "Fund account: " + props.balance.account_number,
                                                    "trans_ref": getriRef,
                                                    'request_id': first.request_id
                                                })
                                                    .then(function (response) {

                                                        if (response.data.response_code === "00") {
                                                            setLoading(false);
                                                        } else {
                                                        }
                                                        if (response.data.response_code === "00") {
                                                            setFirst(first.request_id = '')
                                                        };
                                                    })
                                                    .catch(function (error) {
                                                        setLoading(false);
                                                    });

                                                //Give Value and return to Success page
                                            } else {

                                            }
                                        },
                                        onClose: () => {
                                            // close();
                                        },
                                    });
                                }}
                            >
                                Fund Getripay Account
                                                                </button>
                        </div>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    );
};


const mapStateToProps = (state) => {
    return {
        user: state.userDetails.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        get_account_balance: (payload) => dispatch(transactions.get_account_balance(payload)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FundModal);
