import React from "react";
import { Grid } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";

export default function TransferCard({ props }) {
  return (
    <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginBottom: "40px" }}>
      <Paper className="dashboardBox1">
        <div className="for-inline-items">
          <div>
            <p className="e-cash"> Transaction Limit </p>
          </div>
        </div>
        <div className="for-inline-items">
          <div className="inner-card">
            <img
              src="/images/warning.jpg"
              alt="logo"
              className="card-icons-2"
            />
            <div className="inner-card-text-bold">
              <span className="inner-text-gray">
                NGN{" "}
                {props?.limit?.limitSpent
                  ? Number(props.limit.limitSpent).toLocaleString()
                  : "0"}
              </span>
              <br />
              <p className="card-text-sm-warning">Daily Limit</p>
              <span className="two">Single Transaction</span>
            </div>
          </div>
          <div className="inner-card">
            <img
              src="/images/warning.jpg"
              alt="logo"
              className="card-icons-2"
            />
            <div className="inner-card-text-bold">
              <span className="inner-text-gray">
                NGN
                {props?.limit?.remainingLimit
                  ? Number(props.limit.remainingLimit).toLocaleString()
                  : "0"}
              </span>
              <br />
              <p className="card-text-sm-warning">Daily Limit</p>
              <span className="two">Total Transaction</span>
            </div>
          </div>
        </div>
      </Paper>
    </Grid>
  );
}
