import { userConstants } from "../actions/types";

const initialState = {
    isVerifyingSecurityLogin: false,
    data: '',
};

function loginSecurityQuestion(state = initialState, action) {
    switch (action.type) {
        case userConstants.LOGIN_SECURITY_QUESTION_REQUEST:
            return {
                ...state,
                isVerifyingSecurityLogin: true,
            };
        case userConstants.LOGIN_SECURITY_QUESTION_SUCCESS:
            return {
                ...state,
                isVerifyingSecurityLogin: false,
            };
            case userConstants.LOGIN_SECURITY_QUESTION_FAILURE:
                return {
                    ...state,
                    isVerifyingSecurityLogin: false,
                    data: action.payload
                };
        default:
            return state;
    }
};

export default loginSecurityQuestion;
