import { userConstants } from "../actions/types";

const initialState = {
  isChecking: false,
  data: [],
  message: null
};

function checkLoanEligibility(state = initialState, action) {
  switch (action.type) {
    case userConstants.CHECK_LOAN_ELIGIBILITY_REQUEST:
      return {
        isChecking: true,
        data: [],
        message: null
      };
    case userConstants.CHECK_LOAN_ELIGIBILITY_SUCCESS:
      return {
        isChecking: false,
        data: action.payload.data,
      };
    case userConstants.CHECK_LOAN_ELIGIBILITY_FAILURE:
      return {
        isChecking: false,
        data: action.payload,
        message:action.payload.success,
      };
    default:
      return state;
  }
}

export default checkLoanEligibility;
