import React, { useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import "../../css/register.css";
import "../../css/otp.css";
import CircularProgress from '@material-ui/core/CircularProgress';
import verify from "../../services/verifyService";
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';


const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: "#0038ba",
        minHeight: "100vh",
    },
    root: {
        padding: theme.spacing(10, 30, 10, 30),
        maxWidth: "100%",
        maxHeight: "100%",
        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(3, 5, 3, 5),
        },
    },

    form: {
        width: "100%",
        marginTop: theme.spacing(1),
    },
}));



function VerifyEmail(props) {

    const register_token = props.match.params.token
    const classes = useStyles();

    // reset login status
    useEffect(() => {
        verifyMail();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    function verifyMail() {
        // if (register_token && emailToken ) {
        props.verifyEmail(register_token);
        // }
    }

    return (
        <div>
            <Helmet>
                <title>GetriPay - Fastest growing digital bank with rewards </title>
                <meta name="description" content="Verify Email" />
            </Helmet>
            <Grid container component="main" className={classes.root}>
                <CssBaseline />
                <form className={classes.form} name="form">
                    <div className={classes.spin}>
                        <CircularProgress />
                    </div>

                    {/* <Grid container>
                                <Grid item className='already'>
                                    <a href='/resend/email' className='terms'>Resend  Verification Link</a>
                                </Grid>
                            </Grid> */}
                </form>
            </Grid>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        verifyEmail: (register_token, emailToken) => dispatch(verify.verify_email(register_token, emailToken))
    }
};

const mapStateToProps = (state) => {
    return {
        isLoading: state.response.isLoading
    }
};



export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);
