import axios from "axios";
import { userActions } from "../actions/creators";
import config from "../config";
import swal from "sweetalert";
import { history } from "../store/history";

const baseUrl = `${config.apiUrl}`;

//To  get a user's loyalty
const qr = {
    get_merchant_and_submerchants: (user_id) => {
        return async (dispatch) => {
            dispatch(userActions.getQrMerchantSubmerchantRequest());
            await axios
                .get(`${baseUrl}/qr/merchants_and_sub_merchants_user/${user_id}`)
                .then((res) => {
                    dispatch(userActions.getQrMerchantSubmerchantSuccess(res.data.data));
                });
        };
    },

    create_merchant_and_submerchants: (data) => {
        return async (dispatch) => {
            dispatch(userActions.createQrMerchantSubmerchantRequest());
            await axios
                .post(`${baseUrl}/qr/create_merchant_sub_merchant`, data)
                .then((res) => {
                    dispatch(userActions.createQrMerchantSubmerchantSuccess(res.data.data));
                    swal("Great!", res.data.response_message, "success");
                    window.location.reload();
                })
                .catch((error) => {
                    dispatch(userActions.createQrMerchantSubmerchantSuccess());
                    swal("Oh oh!", error.response.data.response_message, "error");
                });
        };
    },

    create_submerchant: (data) => {
        return async (dispatch) => {
            dispatch(userActions.createSubmerchantRequest());
            await axios
                .post(`${baseUrl}/qr/sub_merchants`, data)
                .then((res) => {
                    dispatch(userActions.createSubmerchantSuccess(res.data.data));
                    swal("Great!", res.data.response_message, "success");
                    window.location.reload();
                })
                .catch((error) => {
                    dispatch(userActions.createSubmerchantSuccess());
                    swal("Oh oh!", error.response.data.response_message, "error");
                });
        };
    },

};

export default qr;
