import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast, Flip } from 'react-toastify';


function PrivateRoute({ component: Component, data, ...rest }) {
    const storage = localStorage.getItem('token')
    useEffect(() => {
        if (data.user.token == null || storage === null) {
            toast.error("Please log in first", {
                position: toast.POSITION.TOP_RIGHT,
                transition: Flip,
            })
        };
    }, [data.user.token, storage]);

    return (
        <Route {...rest} render={props => {
            if (data.user.token == null || localStorage.getItem('token') === null) {
                sessionStorage.setItem('history', (props.location.pathname))
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
            }
            // authorized so return component
            return <Component {...props} />
        }} />
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.userDetails.data
    }
};

export default connect(mapStateToProps, null)(PrivateRoute);