import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Badge, Typography } from "@material-ui/core";
import "../../css/dashboard.css";
import { connect } from "react-redux";
import WelcomeModal from "../../components/WelcomeModal";
import TopBar from "../../components/TopBar";
import { MainContent, Footer } from "../../components/Appearance";
import SideNav from "../../components/SideNav";
import transactions from "../../services/transactionService";
import AccountInfoCard from "../../components/DashboardCards/AccountInfoCard";
import TransferLimitCard from "../../components/DashboardCards/TransferLimitCard";
import FrequentPaymentsCard from "../../components/DashboardCards/FrequentPaymentsCard";
import TransactionHistoryCard from "../../components/DashboardCards/TransactionHistoryCard";
import LoansCard from "../../components/DashboardCards/LoansCard";
import AccountSetupCard from "../../components/DashboardCards/AccountSetupCard";
import CreditCard from "../../components/DashboardCards/CreditCard";
import VirtualCard from "../../components/DashboardCards/VirtualCard";
import BottomNav from "../../components/bottomNavigation";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { history } from "../../store/history";
import physicalCard from "../../services/physicalCardService";
import { Helmet } from "react-helmet";
import virtualCard from "../../services/virtualCardService";
import LoyaltyCard from "../../components/DashboardCards/LoyaltyCard";
import loyalty from "../../services/loyaltyService";
import EarningsCard from "../../components/DashboardCards/EarningsCard";
import interest from "../../services/interestService";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",

    backgroundColor: "white",
  },
  content: {
    flexGrow: 1,

    backgroundColor: "white",
  },
  container: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(4),

    backgroundColor: "white",
  },
  large: {
    width: 100,
    height: 100,
  },
  customBadge: {
    color: "white",
    marginRight: "-55px",
    width: "120px",
  },
}));

function Dashboard(props) {
  const classes = useStyles();
  const [toggle, setToggle] = useState(true);
  const [sidenav, setSidenav] = useState("getri-sidenav");

  const handleToggle = () => {
    const mediaScreen = window.matchMedia(
      `(max-width: 700px),
        (min-device-width: 768px)
        and (max-device-width: 1024px)`
    ); //returns true if

    if (mediaScreen.matches) {
      setSidenav("slideOut");
    } else {
      setToggle(!toggle);
    }
  };
  const hideSidebar = () => {
    setSidenav("slideIn");
  };

  const id = props.user.id;
  const token = props.user.token;
  const acc = props.balance?.account_number;
  // Dates
  var date = new Date();
  var from = new Date(date.getFullYear(), date.getMonth(), 1);
  var to = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  useEffect(() => {
    props.get_transaction_count();
    props.get_account_balance();
    props.get_frequent_transaction();
    props.get_transaction_limit();
    props.get_loan_history();
    props.get_card_status(id, token);
    props.get_user_virtual_card(id, token);
    props.get_loyalty_points(id);
    props.get_charge_back(id);
    props.get_chargeback_value(id, from, to);
    props.get_chargeback_config();
    props.get_earnings(acc);
    props.get_interest_history(1, acc);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (props.data.success === true) {
    setTimeout(() => {
      props.mask_pin();
    }, 180000);
  }
  // Time out for the virtual masked pan set to 3 minutes

  return (
    <>
      <Helmet>
        <title>GetriPay - Fastest growing digital bank with rewards </title>
        <meta name="description" content="Dashboard" />
        <meta name="keywords" content="dashboard" />
      </Helmet>
      <TopBar handleToggle={handleToggle} />
      <SideNav
        toggle={toggle}
        className={sidenav}
        hideSidebar={hideSidebar}
        {...props}
      />
      <MainContent toggle={toggle}>
        {props.user.bvn_verify === true &&
        props.user.user_profile_complete === true &&
        props.user.set_transaction_pin === true ? (
          ""
        ) : (
          <WelcomeModal />
        )}

        <main className={classes.content}>
          <Grid container>
            <Grid item xs={12} md={12} lg={12}>
              <Container maxWidth="lg" className={classes.container}>
                {/* Body of dashboard */}
                <Grid item xs={12} md={12} lg={12}>
                  <div style={{ display: "flex" }}>
                    <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                      <Badge
                        classes={{ badge: classes.customBadge }}
                        badgeContent={
                          props.user.kyc_level === 1 ? (
                            <span
                              style={{
                                backgroundColor: "ff0000",
                                padding: "3px",
                              }}
                            >
                              {" "}
                              GETRIPAY LITE
                            </span>
                          ) : props.user.kyc_level === 2 ? (
                            <span
                              style={{
                                backgroundColor: "#ffcc00",
                                padding: "3px",
                              }}
                            >
                              {" "}
                              GETRIPAY PLUS
                            </span>
                          ) : props.user.kyc_level === 3 ? (
                            <span
                              style={{
                                backgroundColor: "#33cc33",
                                padding: "3px",
                              }}
                            >
                              {" "}
                              GETRIPAY GOLD
                            </span>
                          ) : (
                            <span
                              style={{
                                backgroundColor: "ff0000",
                                padding: "3px",
                              }}
                            >
                              {" "}
                              GETRIPAY LITE
                            </span>
                          )
                        }
                      >
                        <Typography variant="body1" className="account-title">
                          Welcome, {props.balance.account_name}
                        </Typography>
                      </Badge>
                      <div></div>
                    </div>

                    <AccountCircleIcon
                      className="acc-icon"
                      color="primary"
                      onClick={() =>
                        history.push("/dashboard/account-overview")
                      }
                    />
                  </div>

                  <Grid container spacing={3}>
                    <AccountInfoCard props={props} />
                    <LoyaltyCard props={props} />
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <EarningsCard props={props} />
                    </Grid>
                  </Grid>

                  {/* 2nd row */}
                  <Grid container spacing={3} className="md">
                    <TransferLimitCard props={props} />

                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <VirtualCard props={props} />
                    </Grid>

                    <CreditCard props={props} />
                  </Grid>

                  <Grid container spacing={3} className="md">
                    {props.user.bvn_verify !== true &&
                      props.user.user_profile_complete !== true && (
                        <AccountSetupCard props={props} />
                      )}
                    <FrequentPaymentsCard props={props} />
                    <TransactionHistoryCard props={props} />

                    <LoansCard props={props} />
                  </Grid>
                </Grid>
              </Container>
            </Grid>
          </Grid>
          <BottomNav {...props} />
          <Footer />
        </main>
      </MainContent>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    isFetching: state.fetch.isFetching,
    user: state.userDetails.data,
    report: state.transactionCount.data,
    balance: state.getAccountBalance.data,
    limit: state.transactionLimit.data,
    firstCount: state.frequentTransaction.firstCount,
    secondCount: state.frequentTransaction.secondCount,
    loans: state.fetch.data,
    cardStatus: state.getCardStatus.data,
    userVCard: state.getVirtualCard.userVCard,
    data: state.confirmPin.data,
    loyalty: state.loyaltyReducer.data,
    chargeback: state.chargebackReducer.chargeback,
    chargebackValue: state.chargebackValueReducer.chargebackValue,
    chargebackConfig: state.chargebackConfigReducer.chargebackConfig,
    active: state.activeAccount.data,
    earnings: state.getEarnings.earnings,
    interestHistory: state.getInterestHistory.data,
    interestLoading: state.getInterestHistory.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_transaction_count: (payload) =>
      dispatch(transactions.get_transaction_count(payload)),
    get_account_balance: (payload) =>
      dispatch(transactions.get_account_balance(payload)),
    get_frequent_transaction: (payload) =>
      dispatch(transactions.get_frequent_transaction(payload)),
    get_transaction_limit: (payload) =>
      dispatch(transactions.get_transaction_limit(payload)),
    get_loan_history: (payload) =>
      dispatch(transactions.get_loan_history(payload)),
    get_card_status: (id, token) =>
      dispatch(physicalCard.get_card_status(id, token)),
    get_user_virtual_card: (id, token) =>
      dispatch(virtualCard.get_user_virtual_card(id, token)),
    mask_pin: () => dispatch(virtualCard.mask_pin()),
    clear_rate: () => dispatch(virtualCard.clear_rate()),
    get_loyalty_points: (id) => dispatch(loyalty.get_loyalty_points(id)),
    get_charge_back: (id) => dispatch(loyalty.get_charge_back(id)),
    get_chargeback_value: (id, from, to) =>
      dispatch(loyalty.get_chargeback_value(id, from, to)),
    get_chargeback_config: () => dispatch(loyalty.get_chargeback_config()),
    get_earnings: () => dispatch(interest.get_earnings()),
    get_interest_history: (page) =>
      dispatch(interest.get_interest_history(page)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
