import { userConstants } from "../actions/types";

const initialState = {
    isReqVirCard: false,
    isGettingRate: false,
    isGettingVCard: false,
    isFundingVCard: false,
    isWithdrawingVCard: false,
    isBlockingVCard: false,
    isEndingVCard: false,
    data: [],
    rate: [],
    userVCard: [],
    fund: [],
    withdraw: [],
    block: [],
    end: [],
};

function getVirtualCard(state = initialState, action) {
    switch (action.type) {
        case userConstants.GET_VIRTUAL_CARD_REQUEST:
            return {
                ...state,
                isReqVirCard: true,
            };
        case userConstants.GET_VIRTUAL_CARD_SUCCESS:
            return {
                ...state,
                isReqVirCard: false,
                data: action.payload,
            };
        case userConstants.GET_VIRTUAL_CARD_FAILURE:
            return {
                ...state,
                isReqVirCard: false,
            };

        // GETTING RATE
        case userConstants.GET_DOLLAR_RATE_REQUEST:
            return {
                ...state,
                isGettingRate: true,
            };
        case userConstants.GET_DOLLAR_RATE_SUCCESS:
            return {
                ...state,
                isGettingRate: false,
                rate: action.payload,
            };
        case userConstants.GET_DOLLAR_RATE_FAILURE:
            return {
                ...state,
                isGettingRate: false,
            };
        case userConstants.CLEAR_RATE:
            return {
                ...state,
                rate: [],
            };


        // GETTING A USER'S VIRTUAL CARD
        case userConstants.USER_VIRTUAL_CARD_REQUEST:
            return {
                ...state,
                isGettingVCard: true,
            };
        case userConstants.USER_VIRTUAL_CARD_SUCCESS:
            return {
                ...state,
                isGettingVCard: false,
                userVCard: action.payload,
            };
        case userConstants.USER_VIRTUAL_CARD_FAILURE:
            return {
                ...state,
                isGettingVCard: false,
                userVCard: action.payload,
            };


        // FUND A USER'S VIRTUAL CARD
        case userConstants.FUND_VIRTUAL_CARD_REQUEST:
            return {
                ...state,
                isFundingVCard: true,
            };
        case userConstants.FUND_VIRTUAL_CARD_SUCCESS:
            return {
                ...state,
                isFundingVCard: false,
                fund: action.payload,
            };
        case userConstants.FUND_VIRTUAL_CARD_FAILURE:
            return {
                ...state,
                isFundingVCard: false,
            };

        // WITHDRAW FROM A USER'S VIRTUAL CARD
        case userConstants.WITHDRAW_VIRTUAL_CARD_REQUEST:
            return {
                ...state,
                isWithdrawingVCard: true,
            };
        case userConstants.WITHDRAW_VIRTUAL_CARD_SUCCESS:
            return {
                ...state,
                isWithdrawingVCard: false,
                withdraw: action.payload,
            };
        case userConstants.WITHDRAW_VIRTUAL_CARD_FAILURE:
            return {
                ...state,
                isWithdrawingVCard: false,
            };


        // BLOCK A USER'S VIRTUAL CARD
        case userConstants.BLOCK_VIRTUAL_CARD_REQUEST:
            return {
                ...state,
                isBlockingVCard: true,
            };
        case userConstants.BLOCK_VIRTUAL_CARD_SUCCESS:
            return {
                ...state,
                isBlockingVCard: false,
                block: action.payload,
            };
        case userConstants.BLOCK_VIRTUAL_CARD_FAILURE:
            return {
                ...state,
                isBlockingVCard: false,
            };

        // TERMINATE A USER'S VIRTUAL CARD
        case userConstants.TERMINATE_VIRTUAL_CARD_REQUEST:
            return {
                ...state,
                isEndingVCard: true,
            };
        case userConstants.TERMINATE_VIRTUAL_CARD_SUCCESS:
            return {
                ...state,
                isEndingVCard: false,
                end: action.payload,
            };
        case userConstants.TERMINATE_VIRTUAL_CARD_FAILURE:
            return {
                ...state,
                isEndingVCard: false,
            };
        default:
            return state;
    }
}

export default getVirtualCard;
