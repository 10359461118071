import React from 'react';
import { connect } from 'react-redux';
import GroupModal from './GroupModal';
import JoinGroupModal from './JoinGroupModal';


function JoinSavingsGroupBox(props) {

  return (
    <div className='start-savings start-bg' onClick={props.click}>
      <img src={props.image} />
      <p>{props.title} </p>
      <p style={{ color: 'black' }}>{props.text}</p>
      <JoinGroupModal  open={props.open} close={props.close} {...props}/>
    </div>

  )
}




const mapStateToProps = (state) => {
  return {
    user:state.userDetails.data,
    list: state.allSavingsGroup.data
  };
};


export default connect(mapStateToProps, null)(JoinSavingsGroupBox)