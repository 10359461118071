import React, { useState, useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import "../../css/register.css";
import "../../css/otp.css";
import { connect } from 'react-redux';
import verify from "../../services/verifyService";
import resend from "../../services/resendService";
import { history } from '../../store/history';
import { useForm } from "react-hook-form";
import CircularProgress from '@material-ui/core/CircularProgress';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import {Helmet} from 'react-helmet'


const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: "#0038ba",
        minHeight: "100vh",
        [theme.breakpoints.down("sm")]: {
            backgroundColor: 'white',
        },
    },
    root: {
        padding: theme.spacing(10, 30, 10, 30),
        maxWidth: "100%",
        maxHeight: "100%",
        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(0, 0, 0, 0),
        },
    },
    image: {
        backgroundImage: "url('/images/login-image.png')",
        backgroundRepeat: "no-repeat",
        backgroundColor:
            theme.palette.type === "light"
                ? theme.palette.grey[50]
                : theme.palette.grey[900],
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderBottomLeftRadius: "5%",
        borderTopLeftRadius: "5%",
        maxHeight: "100%",
    },
    paper: {
        margin: theme.spacing(6, 4),
        display: "flex",
        flexDirection: "column",
    },
    logo: {
        maxWidth: "50%",
        marginBottom: 80
    },
    form: {
        width: "100%",
        marginTop: theme.spacing(1),
    },

    pap: {
        backgroundColor: "white",
        borderBottomRightRadius: "5%",
        borderTopRightRadius: "5%",
        [theme.breakpoints.down("sm")]: {
            borderBottomRightRadius: "0%",
            borderTopRightRadius: "0%",
        },
    },
    margin: {
        width: "17vw",
        [theme.breakpoints.down("sm")]: {
            width: 300,
        },
        [theme.breakpoints.down("xs")]: {
            width: 200,
        },
        marginBottom: 1,
    },
    getri: {
        fontWeight: "bold",
    },
    error: {
        color: 'red',
        fontSize: '20px',
        fontWeight: '500'
    },
    eye: {
        position: 'absolute',
        top: '38%',
        right: '-1%',
    }
}));

const eye = <FontAwesomeIcon icon={faEye} />;

function VerifyLogin(props) {
    const classes = useStyles();
    const [token, setToken] = useState('');
    const [error, setError] = useState(false);
    const [disable, setDisable] = useState(false);
    const { register } = useForm();
    const [passwordShown, setPasswordShown] = useState(false);
    const [counter, setCounter] = useState(15);

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    useEffect(() => {
        if (token.length > 6) {
            setError(true);
        } else {
            setError(false);
        }
        disableButton();


        //Countdown Timer 
        const timer =
            counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);

    }, [token, props.isLogging, counter]);

    useEffect(() => {
        validate()
    }, [token]) // eslint-disable-line react-hooks/exhaustive-deps


    function handleChange(e) {
        setToken(e.target.value)
    }

    function inputRegex() {
        token.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
    };

    function disableButton() {
        if (props.isLogging == true) {
            setDisable(true)
        } else {
            setDisable(false)
        }
    };

    function resend() {
        props.resendLoginOtp();
    }

    function validate() {
        if (token.length === 6) {
            props.verifyLogin(token)
        }
    }


    return (
        <div className={classes.header}>
            <Helmet>
                <title>GetriPay - Fastest growing digital bank with rewards </title>
                <meta name="description" content="Verify Login Otp" />
            </Helmet>
            <Grid container component="main" className={classes.root}>
                <CssBaseline />
                <Grid item xs={false} sm={4} md={7} className={classes.image} />
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={5}
                    component={Paper}
                    elevation={0}
                    square
                    className={classes.pap}
                >
                    <div className={classes.paper}>
                        <div>
                            <img
                                src="/images/getripaylogo.png"
                                alt="logo"
                                className={classes.logo}
                            />
                        </div>
                        <div>
                            <p className='typo'>Login</p>
                        </div>

                        <div>
                            <p className='enter'>Enter your verification code</p>
                        </div>

                        <div>
                            <p className='digit'>Enter the six digit code sent to your email or phone number</p>
                        </div>

                        <div className={classes.form} name="form" >
                            <div className='custom-styles'>
                                {error ?
                                    <div className={classes.error}>ERROR!. OTP must be 6 digits</div>
                                    :
                                    ''
                                }
                                <div id="divOuter">
                                    <div id="divInner">
                                        <input id="partitioned"
                                            max="6"
                                            type={passwordShown ? "text" : "password"}
                                            name="token" value={token}
                                            onChange={handleChange}
                                            onBlur={validate}
                                            onInput={inputRegex}
                                            ref={register({ required: "This is required." })}
                                        />
                                        <i className={classes.eye} onClick={togglePasswordVisiblity}>{eye}</i>

                                    </div>
                                </div>
                            </div>

                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                size="large"
                                color="primary"
                                className="submit"
                                disabled={props.isLogging === false}
                            >
                                {props.isLogging && <CircularProgress className="circle" />}
                                        Verify
                            </Button>

                            {counter === 0 ?
                                <Grid container>
                                    <Grid item className='already' onClick={resend}>
                                        Did not get the SMS?  <span className='terms'>Resend</span>
                                    </Grid>
                                </Grid>
                                :
                                <Grid item className='already'>
                                    Resend OTP in: <span className='terms'>{counter}</span><span>s</span>
                                </Grid>
                            }
                        </div>
                        <p onClick={history.goBack} className='back'>Go Back</p>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        verifyLogin: (token) => dispatch(verify.verify_login(token)),
        resendLoginOtp: (payload) => dispatch(resend.resend_login(payload))
    }
};

const mapStateToProps = (state) => {
    return {
        isLogging: state.userDetails.isLogging
    }
};



export default connect(mapStateToProps, mapDispatchToProps)(VerifyLogin);
