import React, { useEffect, useState } from "react";
import { Button, Container, Grid, Select, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import "../../../css/solomon.css";
import numeral from "numeral";
import BackDrop from "../../../components/BackDrop";
import "../../../css/dashboard.css";
import { Link } from "react-router-dom";
import TopBar from "../../../components/TopBar";
import BottomNav from "../../../components/bottomNavigation";
import { MainContent } from "../../../components/Appearance";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SideNav from "../../../components/SideNav";
import {
  HeaderBar,
  CardHeader,
  InputGrid,
  CardBody,
  Footer
} from "../../../components/Appearance";
import loan from "../../../services/loanService";
import transactions from "../../../services/transactionService";
import { Helmet } from 'react-helmet'

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    maxWidth: "100%",
    backgroundColor: "white",
  },
  col: {
    color: "blue",
    fontWeight: 900,
    fontSize: 15,
  },
  content: {
    flexGrow: 1,
    height: "100vh",
  },
  container: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(4),
    height: "auto",
    maxWidth: "auto",
    backgroundColor: "white",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  formCon: {
    width: "50vw",
    marginBottom: 40,
  },
}));

function NewLoan(props) {
  const classes = useStyles();
  const [success, setSuccess] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [sidenav, setSidenav] = useState("getri-sidenav");
  const [display, setDisplay] = useState(false);
  const [tenure, setTenure] = useState("");
  const [continueButton] = useState(true);
  const [loanButton] = useState(true);
  const [amount, setAmount] = useState("");
  const [overlay, setOverlay] = useState(false);

  useEffect(() => {
    props.get_account_balance();
    if (props.loanmsg == true) {
      setSuccess(true);
    } else {
      setSuccess(false);
    }
    if (props.loanerror == false) {
      setDisplay(true);
    }
  }, [props.loanmsg, props.loanerror]);

  const handleToggle = () => {
    const mediaScreen = window.matchMedia(
      `(max-width: 700px),
          (min-device-width: 768px)
          and (max-device-width: 1024px)`
    ); //returns true if

    if (mediaScreen.matches) {
      setSidenav("slideOut");
    } else {
      setToggle(!toggle);
    }
  };
  const hideSidebar = () => {
    setSidenav("slideIn");
  };

  // Number formatter
  var valueDisplay = numeral(amount).format();

  function buildOptions() {
    var arr = [];
    for (let i = 1; i <= props.loanDetails.max_tenure; i++) {
      arr.push(
        <MenuItem key={i} value={i}>
          {i} {props.loanDetails.tenure_type}
        </MenuItem>
      );
    }
    return arr;
  }

  function showSuccessCard() {
    let showCard;
    if (success) {
      showCard = (
        <div className="card" >
          <CardHeader info="Loan eligibility" />
          <ArrowBackIcon onClick={closeOverlay} className='arrow1' />
          <h6 onClick={closeOverlay} className='arrow2' >BACK</h6>
          <div
            className="card-body"
            style={{
              padding: "1em 1em 1em",
              border: "2px solid green",
              borderRadius: "20px",
              backgroundColor: "white",
            }}>
            <div>
              <p>
                You are <span style={{ color: "#33cc33" }}>eligible</span> to
                request for this loan{" "}
              </p>
            </div>
            <hr></hr>
            <h3>Loan Statement</h3>

            <div>
              <p>
                I would like to get a loan of{" "}
                <span className={classes.col}>
                  {Number(amount).toLocaleString()}{" "}
                </span>
                that i will pay back within{" "}
                <span className={classes.col}> {props.payment.tenure} </span>
                at a rate of{" "}
                <span className={classes.col}>
                  {props.payment.loan_product_interest_rate}
                </span>{" "}
                per month.
                <br /> <br />
                TOTAL REPAYMENT: NGN{" "}
                <span className={classes.col}>
                  {Number(props.payment.total_due_amount).toLocaleString()}
                </span>
                <br /> <br />
                This takes effect today{" "}
                <span className={classes.col}>{new Date().toUTCString()}</span>
              </p>
            </div>

            {loanButton ? (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                size="small"
                color="primary"
                className="getri-button submit"
                onClick={handleApply}>
                Submit Application
              </Button>
            ) : (
                <Button
                  fullWidth
                  variant="contained"
                  size="small"
                  color="primary"
                  className="getri-button submit"
                  disabled>
                  Continue
                </Button>
              )}
          </div>
        </div>
      );
    } else {
      showCard = (
        <div
          className="card success-card"
          style={{ display: display ? "block" : "none" }}>
          <CardHeader info="Loan eligibility" />
          <div
            className="card-body"
            style={{
              padding: "1em 1em 1em",
              border: "2px solid red",
              borderRadius: "20px",
              backgroundColor: "white",
            }}>
            <div>
              <img
                src="/images/warning-icon.svg"
                alt="warning "
                style={{ maxWidth: "20px" }}
              />
              <p>
                You are <span style={{ color: "red" }}>ineligible</span> to
                request for this loan{" "}
              </p>
              <p>Complete the following account actions to become eligibile</p>
            </div>
            <hr></hr>
            <h3>Pending account actions</h3>

            <div>
              <Grid
                component={Link}
                to="/settings"
                style={{ cursor: "pointer" }}>
                <h4 style={{ color: "red" }}>Complete your profile</h4>
              </Grid>
              <Grid component={Link} to="/validate-bvn">
                <h4 style={{ color: "red" }}>Link your bvn</h4>
              </Grid>
              <Grid component={Link} to="/settings">
                <h4 style={{ color: "red" }}>Update profile picture</h4>
              </Grid>
            </div>
          </div>
        </div>
      );
    }
    return showCard;
  }

  function handleChange(e) {
    setAmount(e.target.value);
  }

  function handleChangeTenure(e) {
    setTenure(e.target.value);
  }

  function handleOverlay() {
    setOverlay(true);
  }

  const closeOverlay = () => {
    setOverlay(false);
  };

  function handleSubmit() {
    props.check_eligibility(amount);
    if (tenure) {
      props.calculate_payment(amount, tenure);
      setOverlay(false);
    }
  }

  function handleApply() {
    if ((amount, tenure)) {
      props.apply(amount, tenure);
    }
  }

  return (
    <>
      <Helmet>
        <title>GetriPay - Fastest growing digital bank with rewards </title>
        <meta name="description" content="Loan" />
      </Helmet>
      {props.isChecking || props.isCalculating ? (
        <div>
          <BackDrop />
        </div>
      ) : (
          ""
        )}

      <TopBar handleToggle={handleToggle} />
      <SideNav toggle={toggle} className={sidenav} hideSidebar={hideSidebar}  {...props} />
      <MainContent toggle={toggle}>
        <main className="main">
          <Grid style={{ height: "100%" }}>
            <Grid item xs={12} md={12} lg={12} style={{ height: "inherit" }}>
              <Container className="main-container">
                <HeaderBar topic="Request a Loan" text={Number(props.balance.available_balance).toLocaleString()} />
              </Container>
              <Grid container style={{ padding: "1px 0" }}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={6}
                  className='card-container'
                  style={{ height: "100vh" }}>
                  <div className="card" style={{ height: "100%" }}>

                    <CardHeader info="Loan Information" />
                    <CardBody
                      cardstyle={{ padding: "1em 1em 1em", width: "300px" }}
                      className="card2">
                      <form>
                        <InputGrid>
                          <label
                            style={{
                              marginBottom: "20px",
                              display:
                                props.loanmsg === true ? "none" : "block",
                            }}>
                            Please enter an amount
                          </label>

                          <input
                            name="amount"
                            value={valueDisplay}
                            className="input-div"
                            onChange={handleChange}
                            style={{
                              display:
                                props.loanmsg === true ? "none" : "block",
                            }}
                          />
                        </InputGrid>
                        {/* Select for days */}
                        {props.loanDetails.tenure_type === "days" ? (
                          <InputGrid>
                            <label>Select a duration (Days)</label>
                            <Select
                              onClick={handleOverlay}
                              // onKeyDown={closeOverlay}
                              className="input-div"
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={tenure}
                              onChange={handleChangeTenure}>
                              {buildOptions()}
                            </Select>
                          </InputGrid>
                        ) : props.loanDetails.tenure_type === "month" ? (
                          <InputGrid>
                            <label>Select a duration(Months)</label>
                            <Select
                              className="input-div"
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={tenure}
                              onChange={handleChangeTenure}>
                              {buildOptions()}
                            </Select>
                          </InputGrid>
                        ) : props.loanDetails.tenure_type === "year" ? (
                          <InputGrid>
                            <label>Select a duration(Years)</label>
                            <Select
                              className="input-div"
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={tenure}
                              onChange={handleChangeTenure}>
                              <MenuItem value={1}>One year</MenuItem>
                              <MenuItem value={2}>Two years</MenuItem>
                              <MenuItem value={3}>Three years</MenuItem>
                            </Select>
                          </InputGrid>
                        ) : (
                                ""
                              )}

                        <div>
                          <Grid container spacing={1} alignItems="flex-end">
                            <Grid item xs={12} md={6} lg={6}></Grid>
                            <Grid item xs={12} md={12} lg={12}>
                              {continueButton ? (
                                <Button
                                  fullWidth
                                  variant="contained"
                                  size="small"
                                  color="primary"
                                  className="getri-button submit"
                                  onClick={handleSubmit}>
                                  Apply
                                </Button>
                              ) : (
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    className="getri-button submit"
                                    disabled>
                                    Continue
                                  </Button>
                                )}
                            </Grid>
                          </Grid>
                        </div>
                      </form>
                    </CardBody>
                  </div>
                </Grid>
                <div
                  style={{
                    zIndex: overlay ? 1 : "",
                    position: overlay ? "fixed" : "",
                    left: overlay ? 900 : "",
                    top: overlay ? 0 : "",
                    bottom: overlay ? 0 : "",
                    height: overlay ? "100%" : "",
                    width: overlay ? "44%" : "",
                    transition: overlay ? "0.5s ease" : "",
                    background: overlay ? "rgba(0,0,0,0.5)" : "",
                  }}
                  className={overlay ? 'notcard' : ''}
                ></div>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={6}
                  style={{ backgroundColor: "#f6f6f7" }}
                  className="card-container">
                  {showSuccessCard()}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <BottomNav {...props} />
          <Footer />
        </main>
      </MainContent>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    check_eligibility: (amount) => dispatch(loan.check_eligibility(amount)),
    calculate_payment: (amount, tenure) => dispatch(loan.calculate_payment(amount, tenure)),
    apply: (amount, tenure) => dispatch(loan.apply(amount, tenure)),
    get_account_balance: (payload) => dispatch(transactions.get_account_balance(payload)),
  };
};

const mapStateToProps = (state) => {
  return {
    loanerror: state.checkLoanEligibility.message,
    isChecking: state.checkLoanEligibility.isChecking,
    isCalculating: state.calculateLoan.isCalculating,
    loanmsg: state.calculateLoan.message,
    loanDetails: state.checkLoanEligibility.data,
    payment: state.calculateLoan.data,
    balance: state.getAccountBalance.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewLoan);
