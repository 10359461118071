import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import '../../css/register.css';
import '../../css/formfield.css';
import { useForm } from "react-hook-form";
import developer from '../../services/DeveloperServices/developerService';

const useStyles = makeStyles((theme) => ({
	form: {
		marginTop: '30px',
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
}));

function CreateDeveloperAccountCard(props) {
	const classes = useStyles();

	const [user, setUser] = useState({
		userId: props.user.id,
		businessName: '',
		businessSupportEmail: '',
		street: '',
		city: '',
		state: '',
		country: ''
	})

	const { register, handleSubmit } = useForm();

	function handleChange(e) {
		e.preventDefault()
		const { name, value } = e.target;
		setUser(user => ({ ...user, [name]: value }));
	}

	function onSubmit() {
		props.create_dev_account(user);
	}

	return (
		<form className={classes.form} name='form' onSubmit={handleSubmit(onSubmit)}>

			<p style={{ fontSize: '10px', fontWeight: 'bold', marginBottom: '20px' }}>Kindly Enter Your Business Details For Verification</p>

			<div style={{ paddingBottom: '15px' }}>
				<label>* Valid Business Name</label>
				<input disabled={props.data.success === true} type='text' name='businessName' value={user.businessName} className='input-div' onChange={handleChange}
					ref={register({ required: "Business name is required" })}
				/>
			</div>

			<div style={{ paddingBottom: '15px' }}>
				<label>* Valid Business Support Email</label>
				<input disabled={props.data.success === true} type='email' name='businessSupportEmail' value={user.businessSupportEmail} className='input-div' onChange={handleChange} />
			</div>

			<p style={{ fontSize: '10px', fontWeight: 'bold', marginBottom: '20px' }}>Enter Your Business Address Below:</p>

			<div style={{ paddingBottom: '15px' }}>
				<label>* City</label>
				<input disabled={props.data.success === true} type='text' name='city' value={user.city} className='input-div' onChange={handleChange} />
			</div>

			<div style={{ paddingBottom: '15px' }}>
				<label>* State</label>
				<input disabled={props.data.success === true} type='text' name='state' value={user.state} className='input-div' onChange={handleChange} />
			</div>

			<div style={{ paddingBottom: '15px' }}>
				<label>* Country</label>
				<input disabled={props.data.success === true} type='text' name='country' value={user.country} className='input-div' onChange={handleChange} />
			</div>

			<div style={{ paddingBottom: '15px' }}>
				<label>* Address</label>
				<input disabled={props.data.success === true} type='text' name='street' value={user.street} className='input-div' onChange={handleChange} />
			</div>

			<div item xs={12} sm={12} className='mag' style={{ margin: '10px 0' }}>
				<Button disabled={props.data.success === true} variant='contained' className='developer-create-acc-btn'  type="submit">
					Create Developer Account
				</Button>
			</div>
		</form>
	);
}

const mapDispatchToProps = (dispatch) => {
	return {
		create_dev_account: (payload) => dispatch(developer.create_dev_account(payload)),
	};
};

const mapStateToProps = (state) => {
	return {
		user: state.userDetails.data
	};
};


export default connect(mapStateToProps, mapDispatchToProps)(CreateDeveloperAccountCard);
