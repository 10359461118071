import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Slider from 'infinite-react-carousel';
import "../css/dashboard.css";
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid white',
    boxShadow: theme.shadows[5],
    borderRadius: '50px',
    height: 'auto',
    width: '35%',
    padding: theme.spacing(2, 4, 3),
    [theme.breakpoints.down("sm")]: {
      height: 'auto',
      width: '75%',
    },
  },
}));

function WelcomeModal(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <CancelIcon style={{ color: 'red', fontSize: '30', cursor: 'pointer' }} onClick={handleClose} />
            <Slider dots>

              {props.user.user_profile_complete !== true ?
                <div>
                  <img src="/images/profile-icon.svg" alt='profile' className='modal-image' />
                  <h3 className='com'>Complete your profile</h3>
                  <Button
                    component={Link}
                    to='/settings'
                    fullWidth
                    variant="contained"
                    size="small"
                    color="primary"
                    className="getri-button submit">
                    Update Profile
              </Button>
                </div>
                :
                ''
              }


              {props.user.bvn_verify !== true ?
                <div>
                  <img src="/images/bvn-icon.svg" alt='profile' className='modal-image' />
                  <h3 className='com'>Link your BVN</h3>

                  <Button
                    component={Link}
                    to='/validate-bvn'
                    fullWidth
                    variant="contained"
                    size="small"
                    color="primary"
                    className="getri-button submit">
                    Link your BVN
                </Button>
                </div>
                :
                ''
              }

              {props.user.set_transaction_pin !== true ?
                <div>
                  <img src="/images/add-account-icon.svg" alt='profile' className='modal-image' />
                  <h3 className='com'>Set transaction pin </h3>

                  <Button
                    component={Link}
                    to='/settings/security'
                    fullWidth
                    variant="contained"
                    size="small"
                    color="primary"
                    className="getri-button submit">
                    Set transaction pin
              </Button>
                </div>
                :
               ''
              }

            </Slider>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    user: state.userDetails.data
  };
};

export default connect(mapStateToProps, null)(WelcomeModal);