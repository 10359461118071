import React from 'react';
import { Button, Grid, Paper } from '@material-ui/core';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

function AccountLevel(props) {
    return (
        <div className='for-inline-items'>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <p className='edit-settings'> Account Levels </p>
                <Paper className='settingsBox2'>
                    <div className='settings-input-div'>
                        <p style={{ fontSize: '13px' }}> You are currently on

                             {props.user.kyc_level === 1
                                ?
                                <span className='t-c'> GetriPay Lite</span>
                                :
                                props.user.kyc_level === 2
                                    ?
                                    <span className='t-c2'> GetriPay Plus</span>
                                    :
                                    props.user.kyc_level === 3
                                        ?
                                        <span className='t-c3'> GetriPay Gold</span>
                                        :
                                        <span> NO KYC DETECTED</span>
                            }
                        </p>
                        <table className='table' id="t01">
                            <tr className='tr'>
                                <th className='th'>{''}</th>
                                <th className='th t-c'>GetriPay Lite</th>
                                <th className='th t-c2' >
                                    GetriPay Plus
                                    {props.user.bvn_verify === false ?
                                        <Grid component={Link} to='/validate-bvn' style={{ padding: '0px', margin: '0px', color: 'red', cursor: 'pointer', fontSize: '12px' }}> (Upgrade)</Grid>
                                        :
                                        ''
                                    }
                                </th>

                                <th className='th t-c3'>GetriPay Gold
                                {props.user.bvn_verify === true ?
                                        <Grid component={Link} to='/upgrade-account' style={{ padding: '0px', margin: '0px', color: 'red', cursor: 'pointer', fontSize: '12px' }}> (Upgrade)</Grid>
                                        :
                                        ''
                                    }
                                </th>
                            </tr>
                            <tr className='tr'>
                                <td className='td'>Can fund with FlutterWave ?</td>
                                <td className='td'>No</td>
                                <td className='td'>No</td>
                                <td className='td'>Yes</td>
                            </tr>
                            <tr className='tr'>
                                <td className='td'>Max. amount you can receive </td>
                                <td className='td'> NGN 50,000</td>
                                <td className='td'>NGN 50,000</td>
                                <td className='td'>Unlimited</td>
                            </tr>
                            <tr className='tr'>
                                <td className='td'>Max. amount for one transfer </td>
                                <td className='td'>NGN 10,000</td>
                                <td className='td'>NGN 50,000</td>
                                <td className='td'> NGN 1,000,000</td>
                            </tr>
                            <tr className='tr'>
                                <td className='td'> Can transfer to other banks ?</td>
                                <td className='td'>No</td>
                                <td className='td'>No</td>
                                <td className='td'> Yes</td>
                            </tr>
                            <tr className='tr'>
                                <td className='td'>Max. bill purchase </td>
                                <td className='td'>NGN 10,000</td>
                                <td className='td'> Unlimited</td>
                                <td className='td'> Unlimited</td>
                            </tr>
                            <tr className='tr'>
                                <td className='td'>Can request for physical card ? </td>
                                <td className='td'>No</td>
                                <td className='td'> Yes</td>
                                <td className='td'> Yes</td>
                            </tr>
                            <tr className='tr'>
                                <td className='td'>Can request for virtual card ? </td>
                                <td className='td'>No</td>
                                <td className='td'> No</td>
                                <td className='td'> Yes</td>
                            </tr>
                            <tr className='tr'>
                                <td className='td'>Max account balance </td>
                                <td className='td'>NGN 30,000</td>
                                <td className='td'> NGN 300,000</td>
                                <td className='td'> Unlimited</td>
                            </tr>
                        </table>
                        {props.user.bvn_verify === false ?
                            <Grid component={Link} to='/validate-bvn'>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    className='getri-button submit'
                                >
                                    Link BVN
                                </Button>
                            </Grid>
                            :
                            ''
                        }

                        {props.user.bvn_verify === true ?
                            <Grid component={Link} to='/upgrade-account'>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    className='getri-button submit'
                                >
                                    Upload Document
                                </Button>
                            </Grid>
                            :
                            ''
                        }
                    </div>
                </Paper>
            </Grid>

        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        user: state.userDetails.data
    }
}
export default connect(mapStateToProps, null)(AccountLevel);
