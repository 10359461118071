import React from 'react';
import {Switch, Route} from 'react-router-dom';
import FundAccount from './FundAccount';


export default function FundAccountRoot({match}){
    return(
        <div>
            <Switch>
                <Route exact path={`${match.url}/`} component={FundAccount} />
            </Switch>
        </div>

    );
}