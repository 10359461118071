import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Menu, MenuItem } from "@material-ui/core";
import "../../../css/dashboard.css";
import TopBar from "../../../components/TopBar";
import { MainContent, Footer, HeaderBar } from "../../../components/Appearance";
import SideNav from "../../../components/SideNav";
import BottomNav from "../../../components/bottomNavigation";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import savings from "../../../services/savingsService";
import { history } from "../../../store/history";
import transactions from "../../../services/transactionService";
import { AutomateSwitch } from "./NewPlan";
import TransactionScroll from "../../../components/Skeleton/TransactionScroll";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "white",
  },
  content: {
    flexGrow: 1,
    backgroundColor: "white",
  },
  container: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(4),
    backgroundColor: "white",
  },
  large: {
    width: 100,
    height: 100,
  },
  customBadge: {
    color: "white",
    marginRight: "-55px",
    width: "120px",
  },
  txnLogo: {
    height: "25px",
    position: "absolute",
    marginLeft: "-50px",
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      marginLeft: "0px",
    },
  },
  small: {
    fontSize: "12px",
    marginTop: theme.spacing(-2),
    marginLeft: "2px",
  },
  ref: {
    marginTop: theme.spacing(-0.5),
    fontSize: "12px",
    marginLeft: "2px",
    fontWeight: "bolder",
  },
  name: {
    fontSize: "11px",
    fontWeight: "bolder",
    marginLeft: "2px",
    textTransform: "capitalize",
  },
}));

function SavingsHistory(props) {
  const classes = useStyles();
  const [sort, setSort] = useState(null);
  const [sortLowest, setSortLowest] = useState(null);
  const [sortCredit, setSortCredit] = useState(null);
  const [toggle, setToggle] = useState(true);
  const [sidenav, setSidenav] = useState("getri-sidenav");
  const auto =
    props.saveSummary[0] && props.saveSummary[0].auto_save === 0
      ? "true"
      : "false";
  const [checked, setChecked] = useState(false);
  const acc = checked === false ? "enable" : "disable";

  const [data, setData] = useState({
    user_id: props.user.id,
    savings_id: save_id,
    action: acc,
  });
  const id = props.user.id;
  const save_id = props.match.params.slug;

  const handleToggle = () => {
    const mediaScreen = window.matchMedia(
      `(max-width: 700px),
        (min-device-width: 768px)
        and (max-device-width: 1024px)`
    ); //returns true if

    if (mediaScreen.matches) {
      setSidenav("slideOut");
    } else {
      setToggle(!toggle);
    }
  };
  const hideSidebar = () => {
    setSidenav("slideIn");
  };

  // const handleClick = (event) => {
  //     setSort(event.currentTarget);
  // };

  // const handleClose = () => {
  //     setSort(null);
  // };

  // // Sort by credit
  // const sortByCredit = () => {
  //     var result = props.saveHistory.filter(obj => {
  //         return obj.transaction_type === 'credit'
  //     })
  //     props.savings_history_sort(result)
  // };

  // // Sort by debit
  // const sortByDebit = () => {
  //     var result = props.saveHistory.filter(obj => {
  //         return obj.transaction_type === 'debit'
  //     })
  //     props.savings_history_sort(result)
  // };

  useEffect(() => {
    props.get_wallet(id);
    props.get_savings_summary(save_id);
    props.get_savings_history(save_id);
    props.get_account_balance();
  }, []);

  useEffect(() => {
    setChecked(auto === "false" ? true : false);
  }, [auto]);

  useEffect(() => {
    setData({
      user_id: props.user.id,
      savings_id: save_id,
      action: acc,
    });
  }, [acc]);

  const automateSavings = (event) => {
    setChecked((prev) => !prev);
    props.toggle_autosave(data).then(() => {
      props.get_savings_summary(save_id);
    });
  };

  return (
    <>
      <Helmet>
        <title>GetriPay - Fastest growing digital bank with rewards </title>
        <meta name="description" content="Savings" />
        <meta name="keywords" content="savings" />
      </Helmet>
      <TopBar handleToggle={handleToggle} />
      <SideNav
        toggle={toggle}
        className={sidenav}
        hideSidebar={hideSidebar}
        {...props}
      />
      <MainContent toggle={toggle}>
        <main className={classes.content}>
          <Grid container>
            <Grid item xs={12} md={12} lg={12}>
              <Container maxWidth="lg" className={classes.container}>
                <Container className="main-container">
                  <HeaderBar
                    topic="GetriSave"
                    text={Number(
                      props.balance.available_balance
                    ).toLocaleString()}
                  />
                </Container>
              </Container>
            </Grid>
            <Container>
              <Container>
                <Grid container>
                  <Grid item lg={3} className="overview-text">
                    <span>{props.saveSummary.name}</span>
                  </Grid>
                  <Grid item lg={7} className="overview-text">
                    <Grid container style={{ fontSize: "14px" }}>
                      <Grid item lg={4}>
                        Wallet number: <br />
                        {props.wallet ? (
                          <>
                            <span style={{ color: "#0037ba" }}>
                              {props.wallet.wallet_number}
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid
                        item
                        lg={4}
                        component={Link}
                        to={`/savings/available-wallet-transactions/${props.wallet.id}`}
                      >
                        Amount you can withdraw: <br />
                        {props.wallet ? (
                          <>
                            <span style={{ color: "#0037ba" }}>
                              NGN{" "}
                              {Number(
                                props.wallet.available_balance
                              ).toLocaleString()}
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid
                        component={Link}
                        to={`/savings/wallet-transactions/${props.wallet.id}`}
                        item
                        lg={4}
                        style={{ cursor: "pointer" }}
                      >
                        Getrisave balance:
                        <br />
                        {props.wallet ? (
                          <>
                            <span style={{ color: "#ff0000" }}>
                              NGN{" "}
                              {Number(
                                props.wallet.ledger_balance
                              ).toLocaleString()}
                            </span>
                          </>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* <Grid item lg={3}> */}
                  {/* <span className='arrange2' onClick={handleClick}>
                                            <img src='/images/sort-icon.svg' className='filter-icon' alt='sort' />
                                            <span className={classes.text}>Filter by</span>
                                        </span>
                                        <Menu anchorEl={sort} keepMounted open={Boolean(sort)} onClose={handleClose}>
                                            <MenuItem onClick={sortByCredit}>Filter by Credit</MenuItem>
                                            <MenuItem onClick={sortByDebit}>Filter by Debit</MenuItem>
                                        </Menu> */}
                  {/* </Grid> */}
                  <Grid item lg={2}>
                    <span className="back" onClick={history.goBack}>
                      Go back
                    </span>
                  </Grid>
                </Grid>
              </Container>

              <Container>
                <Grid container spacing={3}>
                  <Grid
                    item
                    lg={5}
                    md={12}
                    sm={12}
                    xs={12}
                    className="group-history-box"
                  >
                    {props.saveSummary[0] && (
                      <div
                        style={{
                          fontSize: "13px",
                          textTransform: "capitalize",
                        }}
                      >
                        <Grid container>
                          <Grid item lg={6}>
                            <img
                              src={
                                props.saveSummary[0].savings_product.image_url
                              }
                              width="100"
                              height="70"
                            />
                          </Grid>

                          {props.saveSummary[0].savings_product.amount_type !==
                            "fixed" &&
                            props.saveSummary[0].status !== "in-active" && (
                              <Grid item lg={6}>
                                <AutomateSwitch
                                  checked={auto === "false" ? true : false}
                                  name="checked"
                                  onChange={automateSavings}
                                />
                                <span>
                                  Turn {auto === "false" ? "off" : "on"}{" "}
                                  auto-save
                                </span>
                              </Grid>
                            )}
                        </Grid>
                        <div>
                          {props.saveSummary[0].available_for_withdraw === 0 ? (
                            <p style={{ color: "red", textAlign: "center" }}>
                              You cannot withdraw from this savings yet
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <table>
                          <tr>
                            <td style={{ fontWeight: "bolder" }}>
                              Savings Name
                            </td>
                            <td>{props.saveSummary[0].name}</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: "bolder" }}>Auto save</td>
                            <td>
                              {props.saveSummary[0] &&
                              props.saveSummary[0].auto_save === 0
                                ? "false"
                                : "true"}
                            </td>
                          </tr>

                          <tr>
                            <td style={{ fontWeight: "bolder" }}>Start Date</td>
                            <td>{props.saveSummary[0].start_date}</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: "bolder" }}>
                              Maturity Date
                            </td>
                            <td>{props.saveSummary[0].maturity_date}</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: "bolder" }}>
                              Amount saved so far
                            </td>
                            <td>
                              NGN{" "}
                              {Number(
                                props.saveSummary[0].amount_save
                              ).toLocaleString()}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: "bolder" }}>
                              Total amount saved + interest{" "}
                            </td>
                            <td>
                              NGN{" "}
                              {Number(
                                props.saveSummary[0].total_interest_amount_save
                              ).toLocaleString()}
                            </td>
                          </tr>

                          <tr>
                            <td style={{ fontWeight: "bolder" }}>
                              Amount withdrawn
                            </td>
                            <td>
                              NGN{" "}
                              {Number(
                                props.saveSummary[0].amount_withdraw
                              ).toLocaleString()}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: "bolder" }}>
                              Balance left on savings{" "}
                            </td>
                            <td>
                              NGN{" "}
                              {Number(
                                props.saveSummary[0].amount_left
                              ).toLocaleString()}
                            </td>
                          </tr>

                          <tr>
                            <td style={{ fontWeight: "bolder" }}>
                              Balance left + interest{" "}
                            </td>
                            <td>
                              NGN{" "}
                              {Number(
                                props.saveSummary[0]
                                  .interest_remain_amount_save_remain
                              ).toLocaleString()}
                            </td>
                          </tr>

                          <tr>
                            <td style={{ fontWeight: "bolder" }}>
                              Target amount
                            </td>
                            <td>
                              NGN{" "}
                              {Number(
                                props.saveSummary[0].target_amount
                              ).toLocaleString()}
                            </td>
                          </tr>

                          <tr>
                            <td style={{ fontWeight: "bolder" }}>
                              Interest accrued
                            </td>
                            <td>NGN {props.saveSummary[0].total_interest}</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: "bolder" }}>
                              Interest paid out
                            </td>
                            <td>NGN{props.saveSummary[0].interest_payout}</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: "bolder" }}>
                              Interest Rate
                            </td>
                            <td>{props.saveSummary[0].interest_rate} %</td>
                          </tr>

                          <tr>
                            <td style={{ fontWeight: "bolder" }}>Status</td>
                            <td>{props.saveSummary[0].status}</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: "bolder" }}>
                              Frequency Type
                            </td>
                            <td>{props.saveSummary[0].frequency_type}</td>
                          </tr>

                          <tr>
                            <td style={{ fontWeight: "bolder" }}>
                              Savings Type
                            </td>
                            <td>
                              {props.saveSummary[0].savings_product.amount_type}
                            </td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: "bolder" }}>
                              Savings Product Name
                            </td>
                            <td>{props.saveSummary[0].savings_product.name}</td>
                          </tr>
                        </table>
                      </div>
                    )}

                    <br />
                  </Grid>

                  <Grid item lg={7} md={12} sm={12} xs={12}>
                    {props.loading || props.saveHistory.length === 0 ? (
                      <>
                        <TransactionScroll />
                        <p>No savings history yet</p>
                      </>
                    ) : (
                      <>
                        {props.saveHistory.map((data, i) => (
                          <div key={i} className="history-table2">
                            <span
                              className={
                                data.transaction_type === "credit"
                                  ? "deb2"
                                  : "deb"
                              }
                              style={{ fontSize: "12px" }}
                            >
                              {data.transaction_type}
                            </span>
                            <span>
                              {" "}
                              - {data.transaction_desc && data.transaction_desc}
                            </span>
                            <div
                              style={{
                                display: "inline-block",
                                float: "right",
                              }}
                            >
                              <span
                                className={
                                  data.status === "success"
                                    ? "card-success-dot"
                                    : data.status === "pending"
                                    ? "card-pending-dot"
                                    : data.status === "failed"
                                    ? "card-dot"
                                    : ""
                                }
                                style={{
                                  display: "inline-block",
                                  float: "left",
                                }}
                              ></span>
                              <span
                                className={
                                  data.status === "success"
                                    ? "deb2"
                                    : data.status === "pending"
                                    ? "deb3"
                                    : data.status === "failed"
                                    ? "deb1"
                                    : ""
                                }
                                style={{
                                  display: "inline-block",
                                  float: "right",
                                  textTransform: "uppercase",
                                }}
                              >
                                {data.status}
                              </span>
                            </div>

                            <div className="user-inline-items">
                              <div
                                style={{ padding: "none", paddingRight: "10%" }}
                              >
                                <span
                                  style={{ marginTop: "20px" }}
                                  className="trans-amount"
                                >
                                  NGN {Number(data.amount).toLocaleString()}
                                </span>
                              </div>

                              <div>
                                <div>
                                  <img
                                    alt="logo"
                                    src="/images/getriSave_logo.png"
                                    className={classes.txnLogo}
                                  />
                                  <div>
                                    <p className={classes.name}>
                                      {data.description}
                                    </p>
                                    <p className={classes.small}>
                                      {data.recipient_no}{" "}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div>
                                <span className={classes.small}>
                                  {" "}
                                  {new Date(data.created_at).toUTCString()}
                                </span>
                                <p className={classes.ref}>
                                  REF: {data.trans_ref}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </Grid>
                </Grid>
              </Container>
            </Container>
          </Grid>
          <BottomNav {...props} />
          {/* <Footer /> */}
        </main>
      </MainContent>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    get_wallet: (id) => dispatch(savings.get_wallet(id)),
    get_savings_summary: (save_id) =>
      dispatch(savings.get_savings_summary(save_id)),
    get_savings_history: (save_id) =>
      dispatch(savings.get_savings_history(save_id)),
    savings_history_sort: (result) =>
      dispatch(savings.savings_history_sort(result)),
    get_account_balance: (payload) =>
      dispatch(transactions.get_account_balance(payload)),
    toggle_autosave: (data) => dispatch(savings.toggle_autosave(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.userDetails.data,
    saveSummary: state.savingsSummary.data,
    wallet: state.walletBalance.data,
    saveHistory: state.savingsHistory.data,
    loading: state.savingsHistory.isLoading,
    balance: state.getAccountBalance.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SavingsHistory);
