import { userConstants } from "../actions/types";

const initialState = {
  isGettingFee: false,
  data: [],
};

function transactionFee(state = initialState, action) {
  switch (action.type) {
    case userConstants.GET_TRANS_FEE_REQUEST:
      return {
        ...state,
        isGettingFee: true,
      };
    case userConstants.GET_TRANS_FEE_SUCCESS:
      return {
        ...state,
        isGettingFee: false,
        data: action.payload,
      };
    default:
      return state;
  }
}

export default transactionFee;
