import React from 'react';
import {Switch, Route} from 'react-router-dom';
import VerifyEmail from './VerifyEmail';
import VerifyPhone from './VerifyPhone';
import VerifyLogin from './VerifyLogin';
import VerifyBvn from './VerifyBvn';
import VerifySecurityQuestion from './VerifySecurityQuestion';


export default function VerifyRoot({match}){
    return(
        <div>
            <Switch>
                <Route path={`${match.url}/email/:token`} component={VerifyEmail} />
                <Route path={`${match.url}/phone`} component={VerifyPhone} />
                <Route path={`${match.url}/login`} component={VerifyLogin} />
                <Route path={`${match.url}/bvn`} component={VerifyBvn} />
                <Route path={`${match.url}/security-question`} component={VerifySecurityQuestion} />
            </Switch>
        </div>

    );
}