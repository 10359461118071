import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import { Link } from 'react-router-dom';

function TransactionHistoryCard({ props }) {
    return (
        <Grid item xs={12} sm={12} md={4} lg={4}>
            <Paper className='dashboardBox1'>
                <div>
                    <div className='for-inline-items'>
                        <div >
                            <Grid component={Link} to='/dashboard/account-overview' className='e-cash'>Transaction History </Grid>
                        </div>

                    </div>

                    <div className='for-inline-items'>
                        <div className='inner-card' >
                            <img src="images/history-icon.svg" alt="logo" className='card-icons-2' />
                            <div className='inner-card-text-bold'>
                                <span className='inner-text-gray'>{props?.report?.monthlyCount}</span>
                                <br />
                                <span className='three'>Monthly </span>
                                <br />
                                <p className='card-text-sm'>{new Date().toLocaleString('default', { month: 'long', year: "numeric" })}</p>
                            </div>
                        </div>
                        <div className='inner-card' >
                            <img src="images/history-icon.svg" alt="logo" className='card-icons-2' />
                            <div className='inner-card-text-bold'>
                                <span className='inner-text-gray'>{props?.report?.dailyCount}</span>
                                <br />
                                <span className='three'>Daily</span>
                                <br />
                                <p className='card-text-sm'>{new Date().toLocaleString('default', { day: '2-digit', month: 'long', year: "numeric" })}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Paper>
        </Grid>


    )
}

export default TransactionHistoryCard