import React from 'react';
import { Dialog, DialogContent, Grid, } from '@material-ui/core';

export default function ReceiptModal(props) {
    return (
        <>
            <Dialog
                open={props.openMod}
                onClose={props.closeMod}
                aria-labelledby="form-dialog-title">
                <DialogContent>
                    <Grid container style={{ width: "25em" }}>
                        <Grid item xs={12} md={12} lg={12}>
                            <div>
                                <div className="reciept_head">
                                    <span>Transaction Reciept</span>
                                </div>
                                <div className="reciept_date">
                                    <span>{(new Date).toLocaleDateString()}</span>
                                </div>
                                <div className="reciept_status">
                                    <span>SUCCESS</span>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <div style={{ paddingTop: "10px" }}>
                                <span style={{ backgroundColor: "#eee", fontSize: "10px" }}>
                                    TRANSACTION TYPE
                                </span>
                                <div className="reciept_type">
                                    <span>BILL PAYMENT</span>
                                </div>
                            </div>
                            <div style={{ paddingTop: "10px" }}>
                                <span style={{ backgroundColor: "#eee", fontSize: "10px" }}>
                                    TRANSACTION REFERENCE
                                </span>
                                <div className="trans_id">
                                    <span>
                                        {props.reference}
                                    </span>
                                </div>
                            </div>

                            {props.pin ?
                                <div style={{ paddingTop: "10px" }}>
                                    <span style={{ backgroundColor: "#eee", fontSize: "13px" }}>
                                        TOKEN /PIN
                                </span>

                                    <div className="trans_id">
                                        <span> Hi pin will be here{props.pin}</span>
                                    </div>
                                </div>
                                :
                                ''
                            }


                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <div className="box-holder">
                                <div className="funding_acct">
                                    <div className="from_topic">
                                        <span>Debited Account</span>
                                    </div>
                                    <div className="from_body">
                                        <img
                                            alt="logo"
                                            src="/images/yellow-logo.svg"
                                            style={{ width: "1.8em", height: "1.8em" }}
                                        />
                                        <div className="bank_name">
                                            <span>Getripay</span>
                                        </div>
                                        <div className="acct_num">
                                            <span>
                                                {props.account}
                                            </span>
                                        </div>
                                        <div className="owner_name">
                                            <span>
                                                {props.accountName}
                                            </span>
                                        </div>
                                        <div className="status sender">
                                            <span>SENT</span>
                                            <span>
                                                NGN {props.amount}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="dir_arrow">
                                    <div className="arrow">
                                        <span>{">"}</span>
                                    </div>
                                </div>
                                <div className="recieving_acct">
                                    <div className="to_topic">
                                        <span>Receipient</span>
                                    </div>
                                    <div className="from_body">
                                        <img
                                            alt="logo"
                                            src={props.img}
                                            style={{
                                                width: "1.8em",
                                                height: "1.8em",
                                            }}
                                        />
                                        <div className="bank_name">
                                            <span>
                                                {props.institution}
                                            </span>
                                        </div>
                                        <div className="acct_num">
                                            <span>
                                                {props.customer}
                                            </span>
                                        </div>
                                        <div className="status reciever">
                                            <span>RECIEVED</span>
                                            <span>
                                                NGN {props.amount}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}