import React, {useState} from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import '../css/navbar.css'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1,
    maxWidth:'92%',
    left: 240,
    [theme.breakpoints.down("md")]: {
      left: 0,
      top: 72,
      maxWidth:'100%',
  },
  },
}));

export default  function Loader() {
  const classes = useStyles();
  const [show] = useState(true)
  const [open, setOpen] = useState(show);

  const handleClose = () => {
    setOpen(show);
  };
 
  return (
    <div>
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <div className='backdrop'></div>
        <p style={{fontWeight:'bolder', fontSize:'20px'}}>Please wait ....</p>
      </Backdrop>
    </div>
  );
}

