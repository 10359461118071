import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import security from "../../services/securityServices";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: "30px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

function SecurityQuestion(props) {
  const classes = useStyles();
  const [question_id, setQuestion] = useState("");
  const [open, setOpen] = useState(false);

  const handleChange = (event) => {
    setQuestion(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    props.get_question();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function handleSubmit(e) {
    e.preventDefault();
  }

  return (
    <form className={classes.form} name="form" onSubmit={handleSubmit}>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">
          Select Security Question
        </InputLabel>
        <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          className="input-div"
          style={{
            maxWidth: "360px",
            minWidth: "360px",
            borderBottom: "1px solid lightgray",
            width: "100%",
            marginBottom: "8px",
          }}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={question_id}
          name={question_id}
          onChange={handleChange}
          displayEmpty
        >
          {props?.securityQ &&
            props?.securityQ.map((securityQ, i) => (
              <MenuItem key={i} value={securityQ.id}>
                {securityQ.question}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <div>
        <label>Answer to security question</label>
        <input
          type="text"
          className="input-div"
          style={{
            border: "none",
            borderBottom: "1px solid lightgray",
            width: "100%",
          }}
        />
      </div>

      <div item xs={12} sm={12} className="mag" style={{ margin: "10px 0" }}>
        <Button variant="contained" color="primary" className="settings-button">
          Save Changes
        </Button>
      </div>
    </form>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    get_question: (payload) => dispatch(security.get_question(payload)),
  };
};
const mapStateToProps = (state) => {
  return {
    securityQ: state.fetch.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SecurityQuestion);
