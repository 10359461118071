import React, { useEffect, useState } from "react";
import { history } from "./store/history";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import verify from './services/verifyService';
import { connect } from 'react-redux';
const session_start = process.env.REACT_APP_SESSION_START
const session_end = process.env.REACT_APP_SESSION_END

const SessionTimeout = (props) => {
  const [idleModal, setIdleModal] = useState(false);
  const signoutTime = session_end; //60 seconds
  const warningTime = session_start; //30 seconds
  const [counter] = useState(30);
  let warnTimeout;
  let logoutTimeout;

  const warn = () => {
    setIdleModal(true);
  };

  const logout = () => {
    history.push('/')
    props.logout();
  };

  const setTimeouts = () => {
    warnTimeout = setTimeout(warn, warningTime);
    logoutTimeout = setTimeout(logout, signoutTime);
  };

  const clearTimeouts = () => {
    if (warnTimeout) clearTimeout(warnTimeout);
    if (logoutTimeout) clearTimeout(logoutTimeout);
  };

  useEffect(() => {
    const events = [
      "mousemove",
      "click",
      "keypress",
      "mouseleave",
      "mouseenter",
      "mouseover",
      "load",
      "mousemove",
      "mousedown",
      "scroll",
    ];

    const resetTimeout = () => {
      clearTimeouts();
      setTimeouts();
    };

    for (let i in events) {
      window.addEventListener(events[i], resetTimeout);
    }

    setTimeouts();
    //time();

    return () => {
      for (let i in events) {
        window.removeEventListener(events[i], resetTimeout);
        clearTimeouts();
      }
    };
  }, [counter]);


  return (
    <Dialog
      open={idleModal}
      onClose={() => setIdleModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title" style={{ color: "red" }}>
        Session expiration warning
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Your session will expire in {counter} seconds, due to inactivity. Do
          you want to continue this session?
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={logout}
          style={{
            backgroundColor: "white",
            color: "#0037ba",
            width: "50%",
            height: "30px",
            marginBottom: "10px",
            borderRadius: "20px",
            border: "2px solid #0037ba",
            boxShadow: "none",
          }}>
          Logout
        </Button>
        <Button
          variant="contained"
          size="small"
          style={{
            backgroundColor: "#0037ba",
            color: "white",
            width: "50%",
            height: "30px",
            marginBottom: "10px",
            borderRadius: "20px",
            border: "2px solid #0037ba",
            boxShadow: "none",
          }}
          onClick={() => setIdleModal(false)}>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(verify.logout()),
  };
};

export default connect(null, mapDispatchToProps)(SessionTimeout);
