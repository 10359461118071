import axios from "axios";
import { userActions } from "../actions/creators";
import config from "../config";
import headers from "../store/authHeader";
import { toast, Flip } from "react-toastify";
import swal from "sweetalert";

const baseUrl = `${config.apiUrl}`;

const transfers = {
  // Get listing of all banks
  get_bank: () => {
    return async (dispatch) => {
      dispatch(userActions.uiFetching());
      await axios.get(`${baseUrl}/transfers/listBanks`).then((response) => {
        const bankObj = response.data.data;
        let bankDetails = [];
        bankObj.forEach(function (arrayItem) {
          bankDetails.push(arrayItem);
        });
        dispatch(userActions.uiFetched(bankDetails));
      });
    };
  },

  // Validate account number entered by user
  validate_bank: (payload) => {
    return async (dispatch) => {
      dispatch(userActions.uiValidating());
      await axios
        .post(`${baseUrl}/transfers/name_enquiry`, payload)
        .then((res) => {
          dispatch(userActions.uiValidated(res.data));
        })
        .catch((error) => {
          dispatch(userActions.uiValidated(error));
        });
    };
  },

  // Transfer funds
  final_transfer: (payload) => {
    const new_amount = payload.amount.replace(/,/g, "");
    const data = {
      demo_mode: payload.demo_mode,
      user_id: payload.user_id,
      request_id: payload.request_id,
      dest_bank_code: payload.dest_bank_code,
      dest_account_no: payload.dest_account_no,
      dest_account_name: payload.dest_account_name,
      bvn: payload.bvn,
      source_account_no: payload.source_account_no,
      source_account_name: payload.source_account_name,
      session_id: payload.session_id,
      amount: new_amount,
      narration: payload.narration,
      pin: payload.pin,
    };
    return async (dispatch) => {
      dispatch(userActions.uiFinalTransferring());

      await axios
        .post(`${baseUrl}/transfers/post_single_transfer`, data)
        .then((res) => {
          dispatch(userActions.uiFinalTransferred(res.data));
          if (res.data.response_code === "00") {
            dispatch(
              userActions.uiFinalTransferred({
                res_code: res.data.response_code,
                res_data: res.data,
              })
            );
          }
        })
        .catch((err) => {
          const error = err.response.data.response_message;
          swal({
            title: "Oops!",
            text: error,
            icon: "error",
            button: "Oh Oh!",
          });
          dispatch(userActions.transferFailure());
        });
    };
  },

  save_beneficiary: (dest_account_no, dest_bank_code, dest_account_name, bankName, pin) => {
    return async dispatch => {
      const data = {
        user_id: localStorage.getItem("user_id"),
        account_number: dest_account_no,
        institution_code: dest_bank_code,
        customer_name: dest_account_name,
        institution_name: bankName,
        transaction_pin: pin
      }
      dispatch(userActions.saveBeneficiaryRequest());
      await axios.post(`${baseUrl}/user/user_beneficiaries`, data, { "headers": headers })
        .then((res) => {
          if (res.data.success === true) {
            toast.success("Beneficiary saved successfully", {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
          }
          dispatch(userActions.saveBeneficiarySuccess(res.data.success));
        }).catch((error) => {
          dispatch(userActions.saveBeneficiaryFailure());
          if (error.response.data.response_code === "USM019") {
            toast.error("Incorrect pin", {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            })
          }
        });
    }
  },

  // Get all beneficiaries for a particular user
  get_beneficiaries: () => {
    return async (dispatch) => {
      dispatch(userActions.getAllBeneficiaryRequest());
      const id = localStorage.getItem('user_id')
      await axios.get(`${baseUrl}/user/user_beneficiaries/beneficiary_for_user/${id}`, { "headers": headers })
        .then((res) => {
          dispatch(userActions.getAllBeneficiarySuccess(res.data.data));
        });
    }
  },

  delete_beneficiary: (deleteId, transaction_pin) => {
    return async (dispatch) => {
      dispatch(userActions.deleteBeneficiaryRequest());

      const user_id = localStorage.getItem('user_id');

      await axios.delete(`${baseUrl}/user/user_beneficiaries/${deleteId}/${user_id}/${transaction_pin}`, { "headers": headers })
        .then((res) => {
          dispatch(userActions.deleteBeneficiarySuccess(res.data.Response_message));
          if (res.data.success === true) {
            toast.success("Beneficiary deleted successfully", {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
            window.location.reload(true);
          }
        }).catch((error) => {
          dispatch(userActions.deleteBeneficiaryFailure());
          if (error.response.data.response_code === "USM019") {
            toast.error("Incorrect pin", {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            })
          }
        });
    }
  },
  clear_success: () => {
    return async dispatch => {
      dispatch(userActions.clearSuccessTransfer());
    }
  }
};

export default transfers;
