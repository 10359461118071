import { userConstants } from "../actions/types";

const initialState = {
  isVerifyingBvnOtp: false,
  data: null,
};

function verifyBvnOtp(state = initialState, action) {
  switch (action.type) {
    case userConstants.c:
      return {
        ...state,
        isVerifyingBvnOtp: true,
      };
    case userConstants.VERIFY_BVN_OTP_SUCCESS:
      return {
        ...state,
        isVerifyingBvnOtp: false,
        data: action.payload,
      };
    default:
      return state;
  }
}

export default verifyBvnOtp;
