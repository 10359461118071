import { userConstants } from "../actions/types";

const initialState = {
  isLoading: false,
  data: "",
  chargeback: "",
  chargebackConfig: [],
  chargebackValue: "",
};

export function loyaltyReducer(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOYALTY_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case userConstants.LOYALTY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    default:
      return state;
  }
}

export function chargebackReducer(state = initialState, action) {
  switch (action.type) {
    case userConstants.CHARGEBACK_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case userConstants.CHARGEBACK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        chargeback: action.payload,
      };
    default:
      return state;
  }
}

export function chargebackValueReducer(state = initialState, action) {
  switch (action.type) {
    case userConstants.CHARGEBACK_VALUE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case userConstants.CHARGEBACK_VALUE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        chargebackValue: action.payload,
      };
    default:
      return state;
  }
}

export function chargebackConfigReducer(state = initialState, action) {
  switch (action.type) {
    case userConstants.CHARGEBACK_CONFIG_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case userConstants.CHARGEBACK_CONFIG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        chargebackConfig: action.payload,
      };
    default:
      return state;
  }
}
