import React from 'react';
import {Switch, Route} from 'react-router-dom';
import ResendEmail from './ResendEmail';
import ResendLoginOTP from './ResendLoginOTP';
import ResendPhone from './ResendPhone';

export default function ResendRoot({match}){
    return(
        <div>
            <Switch>
                <Route path={`${match.url}/email`} component={ResendEmail} />
                <Route path={`${match.url}/login-otp`} component={ResendLoginOTP} />
                <Route path={`${match.url}/phone`} component={ResendPhone} />
            </Switch>
        </div>

    );
}
