import { developerConstants } from '../../actions/DeveloperActions/types'

const initialState = {
  isLoading: false,
  data: [],
};

function uploadDoc(state = initialState, action) {
  switch (action.type) {
    case developerConstants.UPLOAD_DOCUMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case developerConstants.UPLOAD_DOCUMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
      case developerConstants.UPLOAD_DOCUMENT_FAILURE:
        return {
          ...state,
          isLoading: false,
        };
    default:
      return state;
  }
}

export default uploadDoc;
