import { userConstants } from "../actions/types";

const initialState = {
  isCalculating: false,
  data: [],
  message: null
};

function calculateLoan(state = initialState, action) {
  switch (action.type) {
    case userConstants.CALCULATE_LOAN_REQUEST:
      return {
        isCalculating: true,
        data: [],
        message: null
      };
    case userConstants.CALCULATE_LOAN_SUCCESS:
      return {
        isCalculating: false,
        data: action.payload.data,
        message: action.payload.success,
      };
    default:
      return state;
  }
}

export default calculateLoan;
