import axios from "axios";
import config from "../config";
import { userActions } from "../actions/creators.js";
import { toast, Flip } from "react-toastify";
import swal from "sweetalert";

const baseUrl = `${config.apiUrl}`;

const virtualCard = {
  // User's request for a physical card
  request_virtual_card: (payload, token) => {
    return async (dispatch) => {
      dispatch(userActions.getVirtualCardRequest());

      await axios
        .post(`${baseUrl}/cards/virtual_cards`, payload, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.success === true) {
            dispatch(userActions.getVirtualCardSuccess(res.data));
            swal({
              title: "Success!",
              text: "Virtual Card Created Successfully",
              icon: "success",
              button: "Okay!",
            });
          }
        })
        .catch((error) => {
          dispatch(userActions.getVirtualCardFailure());
          if (error.response.data.response_code === "CRD027") {
            swal({
              title: "Oops!",
              text: "You already have an active virtual card",
              icon: "error",
              button: "Okay!",
            });
          } else if (error.response.data.response_code === "CRD025") {
            swal({
              title: "Oops!",
              text: "You cannot request for card at this moment. Please upgrade your account",
              icon: "error",
              button: "Okay!",
            });
          } else {
            toast.error("ERROR", {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
          }
        });
    };
  },

  get_rate: (payload, token) => {
    return async (dispatch) => {
      dispatch(userActions.getDollarRateRequest());

      await axios
        .post(`${baseUrl}/cards/current_rates`, payload, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          dispatch(userActions.getDollarRateSuccess(res.data));
        })
        .catch((error) => {
          dispatch(userActions.getDollarRateFailure());
          if (error.response.data.response_code === "CRD037") {
            toast.error("Something went wrong when getting currency rate", {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
          } else {
            toast.error("ERROR", {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
          }
        });
    };
  },

  get_user_virtual_card: (id, token) => {
    return async (dispatch) => {
      dispatch(userActions.userVirtualCardRequest());

      await axios
        .get(`${baseUrl}/cards/get_user_virtual_card/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          dispatch(userActions.userVirtualCardSuccess(res.data));
        })
        .catch((error) => {
          dispatch(userActions.userVirtualCardFailure(error?.response?.data));
        });
    };
  },

  fund_virtual_card: (payload, token) => {
    return async (dispatch) => {
      dispatch(userActions.fundVirtualCardRequest());

      await axios
        .post(`${baseUrl}/cards/fund/virtual_cards`, payload, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          dispatch(userActions.fundVirtualCardSuccess(res.data));
          toast.success("Virtual Card funded successfully", {
            position: toast.POSITION.TOP_RIGHT,
            transition: Flip,
          });
        })
        .catch((error) => {
          dispatch(userActions.fundVirtualCardFailure());
          if (error.response.data.response_code === "CRD036") {
            swal({
              title: "Oops!",
              text: "Transaction limit exceeded. Please upgrade your account",
              icon: "error",
              button: "Okay!",
            });
          } else if (error.response.data.response_code === "CRD038") {
            swal({
              title: "Oops!",
              text: "An error occurred. Please try again later",
              icon: "error",
              button: "Okay!",
            });
          } else {
            toast.error("ERROR", {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
          }
        });
    };
  },

  withdraw_virtual_card: (payload, token) => {
    return async (dispatch) => {
      dispatch(userActions.withdrawVirtualCardRequest());

      await axios
        .post(`${baseUrl}/cards/withdraw/virtual_cards`, payload, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          dispatch(userActions.withdrawVirtualCardSuccess(res.data));
          toast.success("Funds Withdrawn Successfully", {
            position: toast.POSITION.TOP_RIGHT,
            transition: Flip,
          });
        })
        .catch((error) => {
          dispatch(userActions.withdrawVirtualCardFailure());
          if (error.response.data.response_code === "CRD030") {
            swal({
              title: "Oops!",
              text: "Withdrawal Frequency exceeded. Please try again later.",
              icon: "error",
              button: "Okay!",
            });
          } else if (error.response.data.response_code === "CRD029") {
            swal({
              title: "Oops!",
              text: "Something went wrong when getting currency rate",
              icon: "error",
              button: "Okay!",
            });
          } else {
            toast.error("ERROR", {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
          }
        });
    };
  },

  block_virtual_card: (payload, token) => {
    return async (dispatch) => {
      dispatch(userActions.blockVirtualCardRequest());

      await axios
        .post(`${baseUrl}/cards/block_user_virtual_card`, payload, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          dispatch(userActions.blockVirtualCardSuccess(res.data));
          swal({
            title: "Success",
            text: "Virtual Card blocked successfully!.",
            icon: "success",
            button: "Okay!",
          });
        })
        .catch((error) => {
          dispatch(userActions.blockVirtualCardFailure(error.response.data));
          toast.error(error.response.data.response_message, {
            position: toast.POSITION.TOP_RIGHT,
            transition: Flip,
          });
        });
    };
  },

  terminate_virtual_card: (payload, token) => {
    return async (dispatch) => {
      dispatch(userActions.terminateVirtualCardRequest());

      await axios
        .post(`${baseUrl}/cards/terminate_user_virtual_card`, payload, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          dispatch(userActions.terminateVirtualCardSuccess(res.data));
          swal({
            title: "Success",
            text: "Virtual Card terminated successfully!.",
            icon: "success",
            button: "Okay!",
          });
        })
        .catch((error) => {
          dispatch(
            userActions.terminateVirtualCardFailure(error.response.data)
          );
          toast.error(error.response.data.response_message, {
            position: toast.POSITION.TOP_RIGHT,
            transition: Flip,
          });
        });
    };
  },

  virtual_card_transactions: (id, card_id, token) => {
    return async (dispatch) => {
      dispatch(userActions.virtualCardTransactionsRequest());
      await axios
        .get(
          `${baseUrl}/cards/get_user_virtual_card_transaction/${id}/${card_id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          dispatch(userActions.virtualCardTransactionsSuccess(res.data.data));
        })
        .catch((error) => {
          dispatch(userActions.virtualCardTransactionsFailure());
        });
    };
  },

  confirm_trans_pin: (user_id, transaction_pin, token) => {
    return async (dispatch) => {
      dispatch(userActions.confirmPinRequest());
      await axios
        .post(
          `${baseUrl}/user/users/u/confirm-transaction-pin`,
          { user_id, transaction_pin },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          dispatch(userActions.confirmPinSuccess(res.data));
        })
        .catch((error) => {
          dispatch(userActions.confirmPinFailure());
        });
    };
  },

  mask_pin: () => {
    return async (dispatch) => {
      dispatch(userActions.maskCardPin());
    };
  },

  clear_rate: () => {
    return async (dispatch) => {
      dispatch(userActions.clearRate());
    };
  },
};

export default virtualCard;
