import axios from "axios";
import { userActions } from "../actions/creators";
import config from "../config";
import headers from "../store/authHeader";
import swal from "sweetalert";
import { toast, Flip } from "react-toastify";

const baseUrl = `${config.apiUrl}`;

const bills = {
  // Get listing of all billers
  get_billers: () => {
    return async (dispatch) => {
      dispatch(userActions.getBillersRequest());
      await axios.get(`${baseUrl}/biller/billers_category`).then((res) => {
        dispatch(userActions.getBillersSuccess(res.data.data));
      });
    };
  },

  get_biller_item: (category_id) => {
    return async (dispatch) => {
      dispatch(userActions.getBillerItemRequest());
      await axios
        .get(`${baseUrl}/biller/billers_category/${category_id}`)
        .then((res) => {
          dispatch(userActions.getBillerItemSuccess(res.data.data));
        });
    };
  },

  get_biller_product: (id) => {
    return async (dispatch) => {
      dispatch(userActions.getBillerProductRequest());
      await axios
        .get(`${baseUrl}/biller/payment_item/${id}`)
        .then((res) => {
          dispatch(userActions.getBillerProductSuccess(res.data.data));
        })
        .catch((error) => {
          dispatch(userActions.getBillerProductFailure());
        });
    };
  },

  // Individual biller item
  // AIRTIME (category id is 3)
  get_airtime_item: () => {
    return async (dispatch) => {
      dispatch(userActions.getBillerItemRequest());
      await axios
        .get(`${baseUrl}/biller/billers_category/3`)
        .then((res) => {
          dispatch(userActions.getBillerItemSuccess(res.data.data));
        })
        .catch((error) => {
          dispatch(userActions.getBillerItemFailure());
        });
    };
  },

  // MOBILE DATA (category id is 4)
  get_data_item: () => {
    return async (dispatch) => {
      dispatch(userActions.getBillerItemRequest());
      await axios
        .get(`${baseUrl}/biller/billers_category/4`)
        .then((res) => {
          dispatch(userActions.getBillerItemSuccess(res.data.data));
        })
        .catch((error) => {
          dispatch(userActions.getBillerItemFailure());
        });
    };
  },

  // CABLE TV (category id is 2)
  get_cable_item: () => {
    return async (dispatch) => {
      dispatch(userActions.getBillerItemRequest());
      await axios
        .get(`${baseUrl}/biller/billers_category/2`)
        .then((res) => {
          dispatch(userActions.getBillerItemSuccess(res.data.data));
        })
        .catch((error) => {
          dispatch(userActions.getBillerItemFailure());
        });
    };
  },

  // UTILITIES (category id is 1)
  get_utility_item: () => {
    return async (dispatch) => {
      dispatch(userActions.getBillerItemRequest());
      await axios
        .get(`${baseUrl}/biller/billers_category/1`)
        .then((res) => {
          dispatch(userActions.getBillerItemSuccess(res.data.data));
        })
        .catch((error) => {
          dispatch(userActions.getBillerItemFailure());
        });
    };
  },

  validate_bill: (customerId, paymentCode) => {
    return async (dispatch) => {
      dispatch(userActions.validateBillRequest());
      await axios
        .post(
          `${baseUrl}/biller/validate_customer`,
          { customerId, paymentCode },
          { headers: headers }
        )
        .then((res) => {
          dispatch(userActions.validateBillSuccess(res.data.Customers[0]));
          if (res.data.Customers[0].responseCode !== "90000") {
            swal({
              title: "C'mon!",
              text: customerId + ":" + " " + "This is invalid",
              icon: "error",
              button: "Oh Oh!",
            });
          }
        })
        .catch((error) => {
          dispatch(userActions.validateBillFailure());
        });
    };
  },

  make_payment: (payload) => {
    const newAmt = payload.amount.replace(/,/g, "");
    const data = {
      paymentCode: payload.paymentCode,
      customerId: payload.customerId,
      amount: newAmt,
      userId: payload.userId,
      account: payload.account,
      institutionName: payload.institutionName,
      logo: payload.logo,
      pin: payload.pin,
      recipientName: payload.recipientName,
    };
    return async (dispatch) => {
      dispatch(userActions.payBillRequest());
      await axios
        .post(`${baseUrl}/biller/make_payment`, data)
        .then((res) => {
          if (res.data.data.responseCodeGrouping == "SUCCESSFUL") {
            dispatch(userActions.payBillSuccess(res.data));
          } else if (res.data.data.error.responseCodeGrouping == "PENDING") {
            dispatch(userActions.payBillSuccess(res.data));
            toast.warning("PENDING", {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
          } else if (res.data.data.error.responseCodeGrouping == "FAILED") {
            dispatch(userActions.payBillSuccess(res.data));
            toast.error("ERROR", {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
          }
        })
        .catch((error) => {
          dispatch(userActions.payBillFailure());
        });
    };
  },

  clear_success: () => {
    return async (dispatch) => {
      dispatch(userActions.clearSuccess());
    };
  },

  get_airtime_data_fee: () => {
    return async (dispatch) => {
      dispatch(userActions.getTransactionFeeRequest());
      const userId = localStorage.getItem("user_id");
      await axios
        .post(`${baseUrl}/transaction/get_fee`, {
          userId: userId,
          serviceCode: "SER_019_AT",
          transactionCategory: "TC_001_BP",
        })
        .then((res) => {
          dispatch(
            userActions.getTransactionFeeSuccess(res.data?.data?.transactionFee)
          );
        });
    };
  },

  get_other_fee: () => {
    return async (dispatch) => {
      dispatch(userActions.getTransactionFeeRequest());
      const userId = localStorage.getItem("user_id");
      await axios
        .post(`${baseUrl}/transaction/get_fee`, {
          userId: userId,
          serviceCode: "SER_020_OB",
          transactionCategory: "TC_001_BP",
        })
        .then((res) => {
          dispatch(
            userActions.getTransactionFeeSuccess(res.data.data.transactionFee)
          );
        });
    };
  },
};

export default bills;
