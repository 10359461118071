import React from 'react';
import { Dialog, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import PlanBox from './PlanBox';


export default function SavingsModal(props) {
  return (
    <Dialog
      fullWidth
      maxWidth='lg'
      open={props.open}
      onClose={props.close}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle >Select a Savings Plan</DialogTitle>
      <DialogContent>

        {props.plans ?
          <>
            {props.plans.length === 0 ?
              <p>No savings products yet</p>
              :
              <Grid container spacing={3}>
                <>
                  {props.plans.map((newPlan, i) => (
                    <Grid item lg={4} sm={12} md={6} xs={12} key={i}>
                      <PlanBox {...newPlan} user_kyc={props.user.kyc_level} />
                    </Grid>
                  ))}
                </>

              </Grid>
            }


          </>
          :
          <p>No savings products yet</p>
        }


      </DialogContent>
    </Dialog>
  );
}
