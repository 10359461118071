import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, MenuItem, Select, TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import savings from '../../../../services/savingsService';


function ChangeDateModal(props) {
    let dayInterval;
    const [withdrawal_date, setWithdrawalDate] = useState('');

    const user_id = props.user.id;

    function handleChange(e) {
        setWithdrawalDate(e.target.value);
    }

    function submit() {
        if (withdrawal_date) {
            props.change_date(user_id, withdrawal_date)
        }
    };

    if (props.date && props.date.interval_type === 'month') {
        dayInterval = (1000 * 60 * 60 * 24) * 30 * props.date.interval; // month
    } else if (props.date && props.date.interval_type === 'days') {
        dayInterval = (1000 * 60 * 60 * 24) * props.date.interval; // days
    } else if (props.date && props.date.interval_type === 'year') {
        dayInterval = (1000 * 60 * 60 * 24) * 365 * props.date.interval; // year
    };

    function getDates(startDate, interval) {
        const duration = startDate;
        const steps = duration / interval;
        return Array.from({ length: steps + 1 }, (v, i) => new Date(startDate.valueOf() + (interval * i)));
    }
    const startDate =props.date &&  new Date(props.date.withdrawal_date);

    const result = getDates(startDate, dayInterval).slice(1, 10)

    return (
        <div>
            <Dialog open={props.open} onClose={props.close}>
                <DialogTitle>Click on the dropdown below to select a date</DialogTitle>
                <DialogContent>
                    <Select
                        style={{ width: '100%' }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name='withdrawal_date' onChange={handleChange} value={withdrawal_date}
                    >
                        {result.map((date) => {
                            return <MenuItem style={{ borderBottom: '1px solid black' }} value={new Date(date).toLocaleDateString('en-CA')}>{date.toDateString()}</MenuItem>;
                        })}
                    </Select>

                    <Button
                        fullWidth
                        variant="contained"
                        size="small"
                        color="primary"
                        className='getri-button submit'
                        onClick={submit}
                    >
                        {props.loading ?
                            <CircularProgress style={{ fontSize: '30px', color: '#fff' }} />
                            :
                            'Change Withdrawal Date'
                        }

                    </Button>
                </DialogContent>
            </Dialog>
        </div>

    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        change_date: (user_id, withdrawal_date) => dispatch(savings.change_date(user_id, withdrawal_date))
    };
};

const mapStateToProps = (state) => {
    return {
        user: state.userDetails.data,
        loading: state.changeWithdrawalDate.isLoading,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeDateModal)
