import React from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Paper, Grid } from "@material-ui/core";

export default function AccountCard({ props }) {
  return (
    <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginBottom: "40px" }}>
      <Paper className="dashboardBox1">
        <div className="for-inline-items">
          <div>
            <p className="card-badge-new deb1">Savings ACCOUNT No:</p>
            <div>
              <p className="card-text-blue-large">
                {props.balance.account_number}
              </p>
            </div>
          </div>
          <div>
            <p className="e-cash">Account Info</p>
          </div>
        </div>
        <div className="for-inline-items">
          <div>
            <div>
              <p className="card-badge-blue e-cash">BALANCE</p>
            </div>
            <div>
              <p className="card-text-money two">
                NGN {Number(props.balance.available_balance).toLocaleString()}
              </p>
            </div>
          </div>
          <div></div>
        </div>
        <div className="for-inline-items">
          <div>
            <div>
              <p className="card-badge-blue e-cash">LEDGER BALANCE</p>
            </div>
            <div>
              <p className="card-text-money-sm">
                NGN {Number(props.balance.available_balance).toLocaleString()}
              </p>
            </div>
          </div>
          <div></div>
        </div>

        <br />

        <div className="for-inline-items">
          <Link to="/fund-account">
            <Button variant="contained" color="primary" className="loan-button">
              Fund Account
            </Button>
          </Link>
          <div className="user-badge">
            <div>
              <p className="card-badge">ACCOUNT NAME</p>
            </div>
            <div>
              <p className="card-active-light">
                {props.balance.account_name}
              </p>
            </div>
          </div>
        </div>
      </Paper>
    </Grid>
  );
}
