import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Airtime from './Airtime';
import MobileData from './MobileData';
import CableTv from './CableTv';
import Utilities from './Utilities';
import PayBills from './PayBills';

export default function BillPaymentRoot({ match }) {
    return (
        <div>
            <Switch>
                <Route exact path={`${match.url}/`} />
                <Route path={`${match.url}/airtime`} component={Airtime} />
                <Route path={`${match.url}/data`} component={MobileData} />
                <Route path={`${match.url}/cable-tv`} component={CableTv} />
                <Route path={`${match.url}/utilities`} component={Utilities} />
                <Route path={`${match.url}/pay-bills/:slug/:logo/:tag/:cat/:name`} component={PayBills} />
            </Switch>
        </div>

    );
}