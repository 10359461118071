// import React, { useEffect, useState } from 'react';
// import './css/dashboard.css'

// function OfflinePage() {


//     function detect() {
//         var condition = navigator.onLine ? 'online' : 'offline';
//         if (condition === 'online') {
//             console.log('ONLINE');
//             fetch('https://www.google.com/', { // Check for internet connectivity
//                 mode: 'no-cors',
//             })
//                 .then(() => {
//                     console.log('CONNECTED TO INTERNET');
//                 }).catch(() => {
//                     console.log('INTERNET CONNECTIVITY ISSUE');
//                 })

//         } else {
//             console.log('OFFLINE')
//         }
//     }

//     useEffect(() => {
//         detect();
//     }, [])
//     return (
//         <div className='offline'>
//             <div >
//                 <img
//                     src="images/getripaylogo.png"
//                     alt="logo"
//                 />
//             </div>
//             <p >So, there's good news and bad news</p>
//         </div>
//     )
// }

// export default OfflinePage;


import React, { useRef, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import OfflineHeader from './components/OfflineHeader';

function OfflinePage() {

    const [activeColor, setActiveColor] = useState('')

    const toggleColor = color => () => {
        if (activeColor !== color) {
            setActiveColor(color);

        }
    };

    const canvasRef = useRef(null)
    const contextRef = useRef(null)
    const [isDrawing, setIsDrawing] = useState(false);

    useEffect(() => {
        const canvas = canvasRef.current;
        canvas.width = 3000;
        canvas.height = 700;
        canvas.style.width = `${100}%`;
        canvas.style.height = 700;

        const context = canvas.getContext("2d")
        context.scale(2, 2)
        context.lineCap = "round"
        context.strokeStyle = activeColor
        context.lineWidth = 2
        contextRef.current = context;
    }, [activeColor])

    const startDrawing = ({ nativeEvent }) => {
        const { offsetX, offsetY } = nativeEvent;
        contextRef.current.beginPath()
        contextRef.current.moveTo(offsetX, offsetY)
        setIsDrawing(true)
    }

    const finishDrawing = () => {
        contextRef.current.closePath()
        setIsDrawing(false)
    }

    const draw = ({ nativeEvent }) => {
        if (!isDrawing) {
            return
        }
        const { offsetX, offsetY } = nativeEvent;
        contextRef.current.lineTo(offsetX, offsetY)
        contextRef.current.stroke()
    }

    return (
        <div className='offline'>
            <OfflineHeader />

            <div >
                <div >
                    <img
                        src="images/getripaylogo.png"
                        alt="logo"
                        className="heartbeat"
                    />
                </div>
                <h3>So, there's good news and bad news</h3>
                <p>Bad news: It looks like you're offline </p>
                <p> Good news: You can draw a picture on this page while you wait to get it back</p>


                <Grid container spacing={0}>

                    <Grid item lg={4}></Grid>

                    <Grid item lg={4}>
                        <p>Pick a color below and start drawing</p>
                        <Grid container className='color-container'>
                            <Grid item xs={2} sm={2} md={2} lg={2}>
                                <div className='offline-red' onClick={toggleColor("red")} ></div>
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} lg={2}>
                                <div className='offline-blue' onClick={toggleColor("blue")} ></div>
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} lg={2}>
                                <div className='offline-yellow' onClick={toggleColor("yellow")}></div>
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} lg={2}>
                                <div className='offline-green' onClick={toggleColor("green")}></div>
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} lg={2}>
                                <div className='offline-purple' onClick={toggleColor("purple")}></div>
                            </Grid>
                            <Grid item xs={2} sm={2} md={2} lg={2}>
                                <div className='offline-orange' onClick={toggleColor("orange")}></div>
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item lg={4}></Grid>
                </Grid>
            </div>

            <canvas
                onMouseDown={startDrawing}
                onMouseUp={finishDrawing}
                onMouseMove={draw}
                ref={canvasRef}

            />
        </div>


    );
}

export default OfflinePage;