import React, { useState } from "react";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import IconButton from '@material-ui/core/IconButton';
import verify from '../services/verifyService';
import { connect } from "react-redux";
import { history } from "../store/history";
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import axios from 'axios';
import config from '../config';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import AccountSelect from "./AccountSelect";



function TopBar(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  let [list, setList] = useState([]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    history.push("/")
    props.logout();
  };

  const baseUrl = `${config.apiUrl}`;

  const [selectedValue, setSelectedValue] = useState(null);
  const [value, setValue] = useState(null)

  // handle input change event
  const handleInputChange = value => {
    setValue(value);
  };
  // handle selection
  const handleChange = value => {
    setSelectedValue(value);
  }
  let one = `${baseUrl}/biller/billers_category/3`
  let two = `${baseUrl}/biller/billers_category/4`
  let three = `${baseUrl}/biller/billers_category/2`
  let four = `${baseUrl}/biller/billers_category/1`

  function loadBills() {
    axios.all([axios.get(one), axios.get(two), axios.get(three), axios.get(four)])
      .then((res) => {
        const responseOne = res[0]
        const responseTwo = res[1]
        const responseThree = res[2]
        const responseFour = res[3]

        if (responseOne.data.data != undefined) {
          let items = responseOne.data.data.map((res, i) => { return { id: i, label: res.biller_name, image: res.logo_url, ref: res.biller_ref, tag: res.label_name, cat: res.biller_category, name: res.biller_name } })
          let items2 = responseTwo.data.data.map((res, i) => { return { id: i, label: res.biller_name, image: res.logo_url, ref: res.biller_ref, tag: res.label_name, cat: res.biller_category, name: res.biller_name } })
          let items3 = responseThree.data.data.map((res, i) => { return { id: i, label: res.biller_name, image: res.logo_url, ref: res.biller_ref, tag: res.label_name, cat: res.biller_category, name: res.biller_name } })
          let items4 = responseFour.data.data.map((res, i) => { return { id: i, label: res.biller_name, image: res.logo_url, ref: res.biller_ref, tag: res.label_name, cat: res.biller_category, name: res.biller_name } })
          let children = items.concat(items2, items3, items4);
          setList(list = children)
        }
      });
  }

  const options = list;

  const loadOptions = inputValue => {
    loadBills();
    return new Promise((resolve, reject) => {
      // using setTimeout to emulate a call to server
      setTimeout(() => {
        resolve(filter(inputValue));
      }, 1000);
    });
  };

  const filter = inputValue =>
    options.filter(option =>
      option.label.toLowerCase().includes(inputValue.toLowerCase())
    );

  const Option = props => {
    const { data } = props;

    return (
      <components.Option {...props}>
        <div>
          <img style={{ borderRadius: '50px', maxWidth: '32px' }} onClick={props.innerProps.onClick = () => {
            history.push(`/bills/pay-bills/${props.data.ref}/${(props.data.image).replace('https://www.quickteller.com/images/Downloaded/', '')}/${props.data.tag}/${props.data.cat}/${props.data.name}`);
          }
          } name={data.label} src={props.data.image} />
          <span style={{ fontSize: '15px' }}>{data.label}</span>
        </div>

      </components.Option>

    );
  };

  return (
    <>
      <header className="getri-topbar">
        <nav className="getri-nav">
          <div className="hamburger">
            <div className="total-bar" onClick={props.handleToggle}>
              <img src="/images/hamburger-menu.svg" alt="logo" className='bar1' />
            </div>
          </div>
          <div className="getri-brand" id="getripay">
            <div className="brand-details">
              <div className="brand-logo">
                <div className="getri-logo">
                  <img src="/images/getripaylogo.png" alt="logo" />
                </div>
              </div>
            </div>
          </div>
          <div className="searchBar-div">
            <div className="search"

            >
              <AsyncSelect
                components={{ Option }}
                cacheOptions
                placeholder={"Search for bills...."}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    "&:hover": { borderColor: "#ccc" },
                    border: "2px solid #ccc",
                    borderRadius: "50px",
                    boxShadow: "none",
                  }),
                }}
                loadOptions={loadOptions}
                onInputChange={handleInputChange}
                onChange={handleChange}
              />

            </div>
          </div>
          <div className="right-nav">
            <div className="nav-list">
              <ul className='group'>
                <li style={{ cursor: 'pointer' }}>
                  <Grid className="bar-icon" component={Link} to='/dashboard/account-overview'>
                    <AccountCircleIcon color="action" />
                  </Grid>
                  <Link to='/dashboard/account-overview'>
                    <span style={{ fontSize: '12px' }} >My Profile</span>
                  </Link>

                </li>
              </ul>

              <div className="profile">
                <AccountSelect />

              </div>

              <div className='nextgrid'>
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <MoreVertIcon className='menu-height' alt='menu' />
                </IconButton>
                <Menu
                  style={{ marginTop: '50px' }}
                  id="customized-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem component={Link} to='/settings' >
                    <ListItemIcon >
                      <Grid className="bar-icon" >
                        <img src="/images/settings-icon.svg" alt="logo" />
                      </Grid>
                    </ListItemIcon>
                    <ListItemText primary="Settings" />
                  </MenuItem>

                  <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                      <Grid className='bar-icon'>
                        <ExitToAppIcon />
                      </Grid>
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.userDetails.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(verify.logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
