import axios from "axios";
import { userActions } from "../actions/creators";
import config from "../config";

const baseUrl = `${config.apiUrl}`;

const interest = {
  get_earnings: (acc) => {
    const account_no = acc ? acc : localStorage.getItem("account");
    return async (dispatch) => {
      dispatch(userActions.getEarningsRequest());
      await axios
        .get(`${baseUrl}/interests/interest/earnings/user/${account_no}`)
        .then((res) => {
          dispatch(userActions.getEarningsSuccess(res.data.data));
        })
        .catch(() => {
          dispatch(userActions.getEarningsSuccess());
        });
    };
  },

  get_interest_history: (page, acc) => {
    const account_no = acc ? acc : localStorage.getItem("account");
    return async (dispatch) => {
      dispatch(userActions.getInterestHistoryRequest());
      await axios
        .get(
          `${baseUrl}/interests/interest/history/user/${account_no}?page=${page}`
        )
        .then((res) => {
          dispatch(userActions.getInterestHistorySuccess(res.data.data));
        })
        .catch((res) => {
          dispatch(userActions.getInterestHistorySuccess());
        });
    };
  },
};

export default interest;
