import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    backgroundColor: '#eee',
    color: 'white',
    [theme.breakpoints.up("sm")]: {
      display: 'none'
    }
  },
}));

export default function BottomNav(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  return (
    <div >
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        showLabels
        className={classes.root}
      >
        {props.history.location.pathname === "/transfer" ?
          <BottomNavigationAction label="Transfer" component={Link} to='/transfer' icon={<img style={{ maxWidth: '20px' }} src="/images/g_send_money_coloured.svg" />} alt="" />
          :
          <BottomNavigationAction label="Transfer" component={Link} to='/transfer' icon={<img style={{ maxWidth: '20px' }} src="/images/transfer-icon.svg" />} alt="" />
        }

        {props.history.location.pathname === "/bills/airtime" ?
          <BottomNavigationAction label="Buy Airtime" component={Link} to='/bills/airtime' icon={<img style={{ maxWidth: '20px' }} src="/images/airtime-colored.svg" alt="airtime" />} />
          :
          <BottomNavigationAction label="Buy Airtime" component={Link} to='/bills/airtime' icon={<img style={{ maxWidth: '20px' }} src="/images/g_airtime_gray.svg" alt="airtime" />} />
        }

        {props.history.location.pathname === "/bills/data" ?
          <BottomNavigationAction label="Buy Data" component={Link} to='/bills/data' icon={<img style={{ maxWidth: '20px' }} src="/images/mobile-data-colored.svg" alt="data" />} />
          :
          <BottomNavigationAction label="Buy Data" component={Link} to='/bills/data' icon={<img style={{ maxWidth: '20px' }} src="/images/mobile-data.svg" alt="data" />} />
        }

        {props.history.location.pathname === "/loan" ?
          <BottomNavigationAction label="Loan" component={Link} to='/loan' icon={<img style={{ maxWidth: '20px' }} src="/images/g_loans_coloured.svg" alt="loan" />} />
          :
          <BottomNavigationAction label="Loan" component={Link} to='/loan' icon={<img style={{ maxWidth: '20px' }} src="/images/loan-icon.svg" alt="loan" />} />
        }
      </BottomNavigation>
    </div>

  );
}
