import React, { useEffect, useState } from "react";
import { TextField, Button, Container, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import BackDrop from "../../components/BackDrop";
import { DebounceInput } from "react-debounce-input";
import "../../css/solomon.css";
import bvnValidation from "../../services/bvnService";
import transactions from "../../services/transactionService";
import updateUser from "../../services/userObjectUpdate";
import TopBar from "../../components/TopBar";
import SideNav from "../../components/SideNav";
import BottomNav from "../../components/bottomNavigation";
import {
  HeaderBar,
  CardHeader,
  InputGrid,
  CardBody,
  MainContent,
  Footer,
} from "../../components/Appearance";
import { Helmet } from "react-helmet";

function ValidateBvn(props) {
  const [toggle, setToggle] = useState(true);
  const [sidenav, setSidenav] = useState("getri-sidenav");
  const [success, setSuccess] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [inputdisabled, setInputdisabled] = useState(true);
  const [validBvn, setValidBvn] = useState("");
  const [overlay, setOverlay] = useState(false);
  const [validate, setValidate] = useState({
    trans_ref: "",
    bvn_number: "",
    otp_code: "",
    user_id: "",
  });

  const [newBvn, setNewBvn] = useState({
    user_id: "",
    bvn_token: "",
    dob: "",
  });

  const handleToggle = () => {
    const mediaScreen = window.matchMedia(
      `(max-width: 700px),
        (min-device-width: 768px)
        and (max-device-width: 1024px)`
    ); //returns true if

    if (mediaScreen.matches) {
      setSidenav("slideOut");
      setToggle(true);
    } else {
      setToggle(!toggle);
    }
  };
  const hideSidebar = () => {
    setSidenav("slideIn");
  };

  const validateForm = () => {
    if (validBvn > 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  useEffect(() => {
    if (props.bvnstatus !== "") {
      setSuccess(true);
    } else {
      setSuccess(false);
    }
    validateForm();

    setValidate({
      trans_ref: props.bvnstatus.trans_ref,
      bvn_number: validBvn,
      otp_code: "",
      user_id: props.user.id,
    });

    setNewBvn({
      user_id: props.user.id,
      bvn_token: props.bvnstatus.data ? props.bvnstatus.data.bvn_token : "",
      dob: "",
    });
  }, [validBvn, props]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    props.get_account_balance();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function handleChangeValidateBvn(e) {
    setValidBvn(e.target.value);
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setValidate((validate) => ({ ...validate, [name]: value }));
  }

  function handleChangeNew(e) {
    const { name, value } = e.target;
    setNewBvn((newBvn) => ({ ...newBvn, [name]: value }));
  }

  const token = props.user.token;

  function validateBvn() {
    // if (validBvn.length === 10) {
    props.verify_bvn(validBvn, token);
    setOverlay(true);
    // }
  }

  function handleSubmit() {
    if (
      validate.bvn_number &&
      validate.otp_code &&
      props.bvnstatus.response_code !== "USM023"
    ) {
      setDisabled(false);
      props.verify_bvn_otp(validate, token).then(() => {
        props.update_user_object();
      });
    } else if (
      validate.bvn_number &&
      props.bvnstatus.response_code === "USM023" &&
      newBvn.bvn_token &&
      newBvn.dob
    ) {
      setDisabled(false);
      props.verify_bvn_dob(newBvn, token).then(() => {
        props.update_user_object();
      });
    }
  }

  function showSuccessCard() {
    let showCard;
    if (success) {
      showCard = (
        <div className="card">
          <CardHeader
            info={
              props.bvnstatus.data.message
                ? props.bvnstatus.data.message
                : "Please enter your date of birth in the format YY-MM-DD"
            }
          />
          <div className="wrapper">
            <CardBody className="card2" cardstyle={{ padding: "1em 1em 1em" }}>
              <form>
                <InputGrid>
                  <label>BVN</label>

                  <input
                    name="validBVN"
                    value={validBvn}
                    onChange={handleChangeValidateBvn}
                    disabled
                    className="input-div"
                  />
                </InputGrid>
                {props.bvnstatus.response_code === "USM023" ? (
                  <InputGrid>
                    <input
                      onChange={handleChangeNew}
                      name="dob"
                      value={newBvn.dob}
                      className="input-div"
                      required
                      type="date"
                    />
                  </InputGrid>
                ) : (
                  <InputGrid>
                    <TextField
                      onChange={handleChange}
                      name="otp_code"
                      value={validate.otp_code}
                      className="input-div"
                      required
                      label="Please enter the otp sent to your phone"
                      type="password"
                    />
                  </InputGrid>
                )}

                <Button
                  onClick={handleSubmit}
                  fullWidth
                  variant="contained"
                  size="small"
                  color="primary"
                  className="getri-button submit"
                >
                  Submit
                </Button>
              </form>
            </CardBody>
          </div>
        </div>
      );
    }
    return showCard;
  }

  return (
    <>
      <Helmet>
        <title>GetriPay - Fastest growing digital bank with rewards </title>
        <meta name="description" content="Link BVN" />
      </Helmet>
      <TopBar handleToggle={handleToggle} />
      <SideNav
        toggle={toggle}
        className={sidenav}
        hideSidebar={hideSidebar}
        {...props}
      />
      <MainContent toggle={toggle}>
        {props.isVerifyingBvn || props.isVerifyingBvnOtp ? (
          <div>
            <BackDrop />
          </div>
        ) : (
          ""
        )}
        <main className="main">
          <Grid style={{ height: "100%" }}>
            <Grid item xs={12} md={12} lg={12} style={{ height: "inherit" }}>
              <Container className="main-container">
                <HeaderBar
                  topic="BVN VALIDATION"
                  text={Number(
                    props.balance.available_balance
                  ).toLocaleString()}
                />
              </Container>
              <Grid container style={{ height: "100%" }}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={6}
                  className={!overlay ? "card-container" : "notcard"}
                  style={{ height: "100vh" }}
                >
                  <div className="card">
                    <div className={!overlay ? "" : "overlay"}></div>
                    <CardHeader info="BVN Information" />
                    <CardBody
                      cardstyle={{
                        padding: "1em 1em 1em",
                        width: "300px",
                      }}
                      className="card2"
                    >
                      <form style={{ marginTop: "40px" }}>
                        <InputGrid>
                          <DebounceInput
                            onChange={handleChangeValidateBvn}
                            className="input-div"
                            disabled={!inputdisabled}
                            type="number"
                            name="validBvn"
                            value={validBvn}
                            // onChange= {validateBvn}
                            placeholder="Please enter your bvn"
                          />
                        </InputGrid>

                        <div>
                          <Grid container spacing={1} alignItems="flex-end">
                            <Grid item xs={12} md={6} lg={6}></Grid>
                            <Grid item xs={12} md={12} lg={12}>
                              <Button
                                onClick={validateBvn}
                                // onClick={handleClickOpen}
                                fullWidth
                                variant="contained"
                                size="small"
                                // disabled={!disabled}
                                className="getri-button"
                                style={{
                                  backgroundColor: !disabled
                                    ? "#0037ba"
                                    : "#0037ba",
                                  color: "#fff",
                                }}
                              >
                                Continue
                              </Button>
                            </Grid>
                          </Grid>
                        </div>
                      </form>
                    </CardBody>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={6}
                  style={{ backgroundColor: "#f6f6f7" }}
                  className="card-container"
                >
                  {showSuccessCard()}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <BottomNav {...props} />

          <Footer />
        </main>
      </MainContent>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    verify_bvn: (validBvn, token) =>
      dispatch(bvnValidation.verify_bvn(validBvn, token)),
    verify_bvn_otp: (newBvn, token) =>
      dispatch(bvnValidation.verify_bvn_otp(newBvn, token)),
    verify_bvn_dob: (validate, token) =>
      dispatch(bvnValidation.verify_bvn_dob(validate, token)),
    update_user_object: (payload) =>
      dispatch(updateUser.update_user_object(payload)),
    get_account_balance: (payload) =>
      dispatch(transactions.get_account_balance(payload)),
  };
};

const mapStateToProps = (state) => {
  return {
    bvnstatus: state.verifyBvn.data,
    user: state.userDetails.data,
    isVerifyingBvn: state.verifyBvn.isVerifyingBvn,
    isVerifyingBvnOtp: state.verifyBvnOtp.isVerifyingBvnOtp,
    balance: state.getAccountBalance.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ValidateBvn);
