import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import "../../css/register.css";
import "../../css/otp.css";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import resend from "../../services/resendService";
import { history } from '../../store/history';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Helmet} from 'react-helmet';

const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: "#0038ba",
        minHeight: "100vh",
        [theme.breakpoints.down("sm")]: {
            backgroundColor: 'white'
        },
    },
    root: {
        padding: theme.spacing(10, 30, 10, 30),
        maxWidth: "100%",
        maxHeight: "100%",
        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(0, 0, 0, 0),
        },
    },
    image: {
        backgroundImage: "url('/images/login-image.png')",
        backgroundRepeat: "no-repeat",
        backgroundColor:
            theme.palette.type === "light"
                ? theme.palette.grey[50]
                : theme.palette.grey[900],
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderBottomLeftRadius: "5%",
        borderTopLeftRadius: "5%",
        maxHeight: "100%",
    },
    paper: {
        margin: theme.spacing(6, 4),
        display: "flex",
        flexDirection: "column",
    },
    logo: {
        maxWidth: "50%",
        marginBottom: 80
    },
    form: {
        width: "100%",
        marginTop: theme.spacing(1),
    },

    pap: {
        backgroundColor: "white",
        borderBottomRightRadius: "5%",
        borderTopRightRadius: "5%",
        [theme.breakpoints.down("sm")]: {
            borderBottomRightRadius: "0%",
            borderTopRightRadius: "0%",
        },
    },
    margin: {
        width: "17vw",
        [theme.breakpoints.down("sm")]: {
            width: 300,
        },
        [theme.breakpoints.down("xs")]: {
            width: 200,
        },
        marginBottom: 1,
    },
    getri: {
        fontWeight: "bold",
    },

}));



function ResendLoginOtp(props) {
    const classes = useStyles();

    const [phone_number, setPhoneNumber] = useState('')

    const [submitted, setSubmitted] = useState(false);

    function handleChange(e) {
        setPhoneNumber(e.target.value)
    }

    function handleSubmit(e) {
        e.preventDefault();

        setSubmitted(true);
        if (phone_number) {
            props.resendLoginOtp(phone_number);
        }
    }

    return (
        <div className={classes.header}>
            <Helmet>
                <title>GetriPay - Fastest growing digital bank with rewards </title>
                <meta name="description" content="resend login OTP" />
            </Helmet>
            <Grid container component="main" className={classes.root}>
                <CssBaseline />
                <Grid item xs={false} sm={4} md={7} className={classes.image} />
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={5}
                    component={Paper}
                    elevation={0}
                    square
                    className={classes.pap}
                >
                    <div className={classes.paper}>
                        <div>
                            <img
                                src="images/getripaylogo.png"
                                alt="logo"
                                className={classes.logo}
                            />
                        </div>
                        <div>
                            <p className='typo'>Login</p>
                        </div>

                        <div>
                            <p className='digit'>Please re-enter your phone number</p>
                        </div>

                        <form className={classes.form} name="form" onSubmit={handleSubmit}>
                            <div>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>
                                        <label>Phone Number</label>
                                        <input value={phone_number} type='number' onChange={handleChange} name="phone_number" className={'form-control' + (submitted && !phone_number ? ' is-invalid' : '')} />
                                        {submitted && !phone_number &&
                                            <div className="invalid-feedback">This field is required</div>
                                        }
                                    </Grid>
                                </Grid>
                            </div>

                            <Grid container>
                                <Grid item component={Link} to="/register" className='already'>
                                    Don't have an account?  <span className='terms'>Create one here</span>
                                </Grid>
                            </Grid>

                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                size="large"
                                color="primary"
                                className='submit'
                                disabled={props.isLoading}
                            >
                                {props.isLoading && <CircularProgress className='circle' />}
                                    Continue
                            </Button>

                        </form>
                        <p onClick={history.goBack} className='back'>Go Back</p>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        resendLoginOtp: (phone_number) => dispatch(resend.resend_login(phone_number))
    }
};

const mapStateToProps = (state) => {
    return {
        isLoading: state.response.isLoading
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ResendLoginOtp);
