import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import "../../../../css/UserProfile.css";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(0),
      width: "100%",
      height: theme.spacing(7),
      borderBottom: "1px solid #ccc",
    },
  },
  nameColor: {
    color: "#33cc33",
    fontSize: "15px",
  },
  ProfileNav: {
    marginLeft: "4%",
    PaddingBotton: "5%",
  },
}));

export default function Variants({ props }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper>
        <Grid container className={classes.ProfileNav}>
          <Grid item xs>
            <h1 style={{ margin: "1%" }} className="account-title">
              {" "}
              User Profile{" "}
              <span className={classes.nameColor}>
                {props.balance.account_name}
              </span>
            </h1>
          </Grid>
          {/* <Grid item xs>
          <h2 style={{marginLeft: '35%'}} className='rec'> Home > Dashboard > User Profile </h2>
          </Grid> */}
        </Grid>
      </Paper>
    </div>
  );
}
