import React, { useState } from 'react';
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import savings from '../../../../services/savingsService';


function FundSavingsModal(props) {
  
    const [data, setData] = useState({
        user_id: props.user.id,
        amount: '',
        savings_id: props.id ? props.id : '',
        transaction_pin: '',
        trans_ref: `${props.user.id}` + Math.floor(Math.random() * 900000000000000000),
        account_no: props?.balance?.account_number,
    });
   

    function handleChange(e) {
        const { name, value } = e.target;
        setData((data) => ({ ...data, [name]: value }));
    }

    function submit() {
        if (data.amount && data.transaction_pin) {
            props.fund_savings(data)
        }
    }

    return (
        <div>
            <Dialog
                maxWidth='xs'
                open={props.open}
                onClose={props.close}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle >Topup Wallet</DialogTitle>
                <DialogContent>

                    <TextField
                        autoFocus
                        name='amount'
                        onChange={handleChange}
                        value={data.amount}
                        margin="dense"
                        label="Please enter an amount"
                        type="number"
                    />

                    <TextField
                        autoFocus
                        name='transaction_pin'
                        onChange={handleChange}
                        value={data.transaction_pin}
                        margin="dense"
                        label="Please enter your transction pin"
                        type="password"
                    />

                    <Button
                        fullWidth
                        variant="contained"
                        size="small"
                        color="primary"
                        className='getri-button submit'
                        onClick={submit}
                    >
                        {props.loading ?
                            <CircularProgress style={{ fontSize: '30px', color: '#fff' }} />
                            :
                            '  Add fund to savings'
                        }

                    </Button>
                </DialogContent>
            </Dialog>
        </div>

    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        fund_savings: (data) => dispatch(savings.fund_savings(data))
    };
};

const mapStateToProps = (state) => {
    return {
        user: state.userDetails.data,
        loading: state.fundSavings.isLoading,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FundSavingsModal)
