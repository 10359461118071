import React from 'react';
import { Grid, Paper, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { InputGrid } from '../../Appearance';

function ReportAProblemCard({ props }) {
	return (
		<div className='for-inline-items'>
			<Grid item xs={12} sm={12} md={12} lg={12}>
				<p className='edit-settings'> Report a Problem </p>
					<Paper className='settingsBox2'>
						<div className='settings-input-div'>
							<InputGrid>
								<label>Select Problem Category</label>
                <select  
                style={{border: 'none', borderBottom: '1px solid lightgray', width: '100%'}}
									name='dest_account_name'
									// onChange={handleChange}
									// value={dest_account_name}
									// className='settings-input'
								/>
							</InputGrid>

							<InputGrid>
								<label>Brief Title of Problem</label>
								<input style={{border: 'none', borderBottom: '1px solid lightgray', width: '100%'}}
									name='dest_account_name'
									// onChange={handleChange}
									// value={dest_account_name}
									// className='input-div'
								/>
							</InputGrid>

							<InputGrid>
								<label style={{marginBottom: '10px'}}>Describe Your Problem in Detail</label>
                <textarea style={{ border: '1px solid lightgray', width: '100%', borderRadius: '5px'}}
                  // onSelect={{ border: '2px solid blue' }}
									name='dest_account_name'
									// onChange={handleChange}
									// value={dest_account_name}
                  // className='input-div'
                  rows='12'
								/>
							</InputGrid>

							<div >
								<Link to='/loan'>
									<Button variant='contained' color='primary' className='settings-textarea-button'>
										Save Changes
									</Button>
								</Link>
							</div>
						</div>
					</Paper>
			</Grid>
			
		</div>
	);
}

export default ReportAProblemCard;
