import { components } from "react-select";
import React, { useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { userActions } from "../actions/creators";

function AccountSelect(props) {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState(null);
  const active = useSelector((state) => state.activeAccount.data);
  const accountOptions = props?.user?.bank_account.map((accNum, i) => ({
    label: accNum.account_number,
    value: accNum.account_number,
    name: accNum.account_name,
    key: i,
  }));

  function handleChangeAccNum(selectedOption) {
    setSelectedOption(selectedOption);
    localStorage.setItem("account", selectedOption.value);
    dispatch(
      userActions.switchActiveAccount({
        value: selectedOption.value,
        label: selectedOption.label,
        name: selectedOption.name,
      })
    );

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  const Option = (props) => {
    const { data } = props;

    return (
      <components.Option {...props}>
        <div
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        >
          <img
            style={{ borderRadius: "40px", maxWidth: "40px" }}
            src="/images/yellow-logo.svg"
          />
          <div>
            <span style={{ fontSize: "15px", marginLeft: "10px" }}>
              {data.label}
            </span>
            <p style={{ fontSize: "12px", marginLeft: "10px" }}> {data.name}</p>
          </div>
        </div>
      </components.Option>
    );
  };

  return (
    <Select
      components={{ Option }}
      defaultValue={{
        label: active.label,
        value: active.value,
        name: active.name,
      }}
      options={accountOptions}
      onChange={handleChangeAccNum}
      styles={{
        option: (provided, state) => ({
          ...provided,
          color: "black",
          fontWeight: 900,
        }),
        singleValue: (provided) => ({
          ...provided,
          color: "black",
          marginLeft: "30px",
          fontWeight: 900,
          fontSize: "14px",
        }),
        control: (base, state) => ({
          ...base,
          "&:hover": { borderColor: "#ccc" },
          border: "2px solid #ccc",
          borderRadius: "50px",
          boxShadow: "none",
        }),
      }}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.userDetails.data,
  };
};

export default connect(mapStateToProps, null)(AccountSelect);
