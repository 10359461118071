import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import "../../css/register.css";
import "../../css/otp.css";
import { connect } from 'react-redux';
import forgot from "../../services/forgotService";
import { history } from '../../store/history';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Helmet } from 'react-helmet'


const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: "#0038ba",
        minHeight: "100vh",
        [theme.breakpoints.down("sm")]: {
            backgroundColor: 'white',
        },
    },
    root: {
        padding: theme.spacing(10, 30, 10, 30),
        maxWidth: "100%",
        maxHeight: "100%",
        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(0, 0, 0, 0),
        },
    },
    image: {
        backgroundImage: "url('/images/login-image.png')",
        backgroundRepeat: "no-repeat",
        backgroundColor:
            theme.palette.type === "light"
                ? theme.palette.grey[50]
                : theme.palette.grey[900],
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderBottomLeftRadius: "5%",
        borderTopLeftRadius: "5%",
        maxHeight: "100%",
    },
    paper: {
        margin: theme.spacing(6, 6),
        display: "flex",
        flexDirection: "column",
    },
    logo: {
        maxWidth: "50%",
        marginBottom: 80
    },
    form: {
        width: "100%",
        marginTop: theme.spacing(1),
    },

    pap: {
        backgroundColor: "white",
        borderBottomRightRadius: "5%",
        borderTopRightRadius: "5%",
        [theme.breakpoints.down("sm")]: {
            borderBottomRightRadius: "0%",
            borderTopRightRadius: "0%",
        },
    },
    margin: {
        width: "17vw",
        [theme.breakpoints.down("sm")]: {
            width: 300,
        },
        [theme.breakpoints.down("xs")]: {
            width: 200,
        },
        marginBottom: 1,
    },
    getri: {
        fontWeight: "bold",
    },

}));



function ForgotPassword(props) {
    const classes = useStyles();
    const [email, setEmail] = useState();
    const [submitted, setSubmitted] = useState(false);


    function handleChange(e) {
        setEmail(e.target.value)
    }

    function handleSubmit(e) {
        e.preventDefault();
        setSubmitted(true);
        if (email) {
            sessionStorage.setItem('email', (email))
            props.forgotPassword(email)
        }
    }

    return (
        <div className={classes.header}>
            <Helmet>
                <title>GetriPay - Fastest growing digital bank with rewards </title>
                <meta name="description" content="forgot passwowrd" />
            </Helmet>
            <Grid container component="main" className={classes.root}>
                <CssBaseline />
                <Grid item xs={false} sm={4} md={7} className={classes.image} />
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={5}
                    component={Paper}
                    elevation={0}
                    square
                    className={classes.pap}
                >
                    <div className={classes.paper}>
                        <div>
                            <img
                                src="images/getripaylogo.png"
                                alt="logo"
                                className={classes.logo}
                            />
                        </div>
                        <div>
                            <p className='typo'>Password reset</p>
                        </div>

                        {/* <div>
                            <p className='enter'>Enter your verification code</p>
                        </div> */}

                        <div>
                            <p className='digit'>Enter your email/Phone Number/ Username</p>
                        </div>

                        <form className={classes.form} name="form" onSubmit={handleSubmit}>
                            <div className='custom-styles'>
                                <input name="token" type='text' value={email} onChange={handleChange} className={'form-control' + (submitted && !email ? ' is-invalid' : '')} />
                                {submitted && !email &&
                                    <div className="invalid-feedback">Please fill the form</div>
                                }
                            </div>

                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                size="large"
                                color="primary"
                                className='submit'
                                disabled={props.isLoading}
                            >
                                {props.isLoading && <CircularProgress className='circle' />}
                                    Continue
                            </Button>
                        </form>
                        <p onClick={history.goBack} className='back'>Go Back</p>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        forgotPassword: (email) => dispatch(forgot.forgot_password(email))
    }
};

const mapStateToProps = (state) => {
    return {
        isLoading: state.response.isLoading
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
