import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Button,
  Menu,
  MenuItem,
  ListItemText,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import VirtualCardModal from "../VirtualCardModals/virtualCardRequestModal";
import WithdrawVirtual from "../VirtualCardModals/WithdrawVirtual";
import FundVirtual from "../VirtualCardModals/FundVirtual";
import BlockVirtual from "../VirtualCardModals/BlockVirtual";
import TerminateVirtual from "../VirtualCardModals/TerminateVirtual";
import { Link } from "react-router-dom";
import { history } from "../../store/history";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import ConfirmPinModal from "../VirtualCardModals/ConfirmPinModal";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const eye = <FontAwesomeIcon icon={faEye} />;

function VirtualCard({ props }) {
  const [open, setOpen] = useState(false);
  const [fundVirtual, setFundVirtual] = useState(false);
  const [withdrawVirtual, setWithdrawVirtual] = useState(false);
  const [blockVirtual, setBlockVirtual] = useState(false);
  const [terminateVirtual, setTerminateVirtual] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [virtualState, setVirtualState] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showAlert, setShowAlert] = useState(false);

  function toggleVirtual() {
    if (props?.userVCard?.success === false) {
      setVirtualState(true);
    } else if (props?.userVCard?.success === true) {
      setVirtualState(false);
    }
  }

  const toggleMask = () => {
    setConfirm(true);
  };

  const cardDeets = props?.userVCard?.data ? props?.userVCard?.data : [];

  useEffect(() => {
    toggleVirtual();
  }, [props.userVCard]);

  function handleClose() {
    props.clear_rate();
    setOpen(false);
  }
  function handleCloseFund() {
    props.clear_rate();
    setFundVirtual(false);
  }
  function handleCloseWithdraw() {
    props.clear_rate();
    setWithdrawVirtual(false);
  }
  function handleCloseBlock() {
    props.clear_rate();
    setBlockVirtual(false);
  }
  function handleCloseTerminate() {
    props.clear_rate();
    setTerminateVirtual(false);
  }
  function handleCloseConfirm() {
    setConfirm(false);
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseIcon = () => {
    setAnchorEl(null);
  };

  function openCreate() {
    if (props.user.kyc_level !== 3) {
      setShowAlert(true);
    } else {
      setOpen(true);
    }
  }

  function closeAlert() {
    setShowAlert(false);
  }

  function pushtoUpgrade() {
    if (props.user.kyc_level === 1) {
      history.push("/validate-bvn");
    } else if (props.user.kyc_level === 2) {
      history.push("/upgrade-account");
    }
  }

  const newPan = cardDeets.card_pan
    ? cardDeets.card_pan.match(/.{1,4}/g).join("\xa0\xa0\xa0\xa0\xa0")
    : "";
  const newMask = cardDeets.masked_pan
    ? cardDeets.masked_pan.match(/.{1,4}/g).join("\xa0\xa0\xa0\xa0\xa0")
    : "";

  return (
    <div>
      {virtualState ? (
        <Paper className="dashboardBox1">
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className="mag">
              <img
                src="/images/g_add_card_coloured.svg"
                alt="virtual-card"
                className="virtual-img"
              />
            </div>
            <div className="mag">
              <p className="card-text-sm">
                You do not have a virtual <br /> card yet
              </p>
            </div>
            <div className="mag2">
              <Button
                onClick={openCreate}
                variant="contained"
                color="primary"
                style={{
                  backgroundColor: "#0037ba",
                  borderRadius: "500px",
                  textTransform: "capitalize",
                  color: "white",
                  fontSize: "10px",
                }}
              >
                Create Virtual Card
              </Button>
            </div>
            <VirtualCardModal
              close={handleClose}
              open={open}
              details={props.user}
            />
            {showAlert && (
              <Snackbar
                open={showAlert}
                autoHideDuration={6000}
                onClose={closeAlert}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Alert
                  severity="error"
                  action={
                    <Button
                      color="inherit"
                      size="small"
                      onClick={pushtoUpgrade}
                    >
                      {props.user.kyc_level === 1
                        ? "Link BVN"
                        : props.user.kyc_level === 2
                        ? "Upload identification document"
                        : ""}
                    </Button>
                  }
                >
                  {props.user.kyc_level === 1
                    ? "Please validate your BVN first"
                    : props.user.kyc_level === 2
                    ? "Please upload a valid identification document"
                    : ""}
                </Alert>
              </Snackbar>
            )}
          </Grid>
        </Paper>
      ) : (
        <Paper className="dashboardBoxVirtual">
          <div>
            <div className="for-inline-items">
              <div>
                <p className="card-text-money">
                  $ {cardDeets.amount ? cardDeets.amount : 0}
                </p>
              </div>
              <div>
                <Button
                  style={{
                    backgroundColor: "#0037ba",
                    borderRadius: "300px",
                    color: "white",
                    fontSize: "10px",
                  }}
                  onClick={handleClick}
                  className="menu-height"
                  alt="menu"
                >
                  Manage Card
                </Button>

                <Menu
                  // style={{ marginTop: '50px' }}
                  id="customized-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleCloseIcon}
                >
                  <MenuItem onClick={() => setFundVirtual(true)}>
                    <ListItemText primary="Fund Card" />
                  </MenuItem>

                  <MenuItem onClick={() => setWithdrawVirtual(true)}>
                    <ListItemText primary="Withdraw" />
                  </MenuItem>

                  <MenuItem onClick={() => setBlockVirtual(true)}>
                    <ListItemText primary="Block Card" />
                  </MenuItem>

                  <MenuItem onClick={() => setTerminateVirtual(true)}>
                    <ListItemText primary="Terminate Card" />
                  </MenuItem>

                  <MenuItem
                    component={Link}
                    to="/dashboard/account-overview/cards"
                  >
                    <ListItemText primary="Card Transactions" />
                  </MenuItem>
                </Menu>
              </div>
            </div>
            <div className="for-inline-items">
              <div>
                <p className="card-text-sm">
                  {cardDeets.expiration
                    ? new Date(cardDeets.expiration).toLocaleDateString()
                    : ""}
                </p>
              </div>
              <div>
                {cardDeets.status_name === "active" ? (
                  <p className="card-active">Active</p>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="dashboard-card-no">
              {props.data.success === true ? (
                <span className="card-text-money2">{newPan}</span>
              ) : (
                <span className="card-text-money2">{newMask}</span>
              )}
              <i
                style={{ float: "right", cursor: "pointer" }}
                onClick={toggleMask}
              >
                {eye}
              </i>
            </div>

            <div className="for-inline-items">
              <img
                src="/images/mastercard.png"
                alt="logo"
                className="dashboard-ma2"
              />
              <div>
                <p className="card-text-sm">{cardDeets.name_on_card} </p>
              </div>
              <div>
                {props.data.success === true ? (
                  <p className="card-text-money">{cardDeets.cvv}</p>
                ) : (
                  <p className="card-text-money"> *** </p>
                )}
              </div>
            </div>
          </div>
          <FundVirtual
            close={handleCloseFund}
            open={fundVirtual}
            details={props.user}
            balance={props.balance}
            card={cardDeets}
          />
          <WithdrawVirtual
            close={handleCloseWithdraw}
            open={withdrawVirtual}
            balance={props.balance}
            details={props.user}
            card={cardDeets}
          />
          <BlockVirtual
            close={handleCloseBlock}
            open={blockVirtual}
            balance={props.balance}
            details={props.user}
            card={cardDeets}
          />
          <TerminateVirtual
            close={handleCloseTerminate}
            open={terminateVirtual}
            balance={props.balance}
            details={props.user}
            card={cardDeets}
          />
          <ConfirmPinModal
            close={handleCloseConfirm}
            open={confirm}
            details={props.user}
          />
        </Paper>
      )}
    </div>
  );
}

export default VirtualCard;
