import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import "../../css/register.css";
import "../../css/otp.css";
import { Link } from 'react-router-dom';
import { history } from '../../store/history';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: "#0038ba",
        minHeight: "100vh",
        [theme.breakpoints.down("sm")]: {
            backgroundColor: 'white'
        },
    },
    root: {
        padding: theme.spacing(15, 30, 10, 30),
        maxWidth: "100%",
        maxHeight: "100%",
        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(0, 0, 0, 0),
        },
    },
    image: {
        backgroundImage: "url('/images/login-image.png')",
        backgroundRepeat: "no-repeat",
        backgroundColor:
            theme.palette.type === "light"
                ? theme.palette.grey[50]
                : theme.palette.grey[900],
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderBottomLeftRadius: "5%",
        borderTopLeftRadius: "5%",
        maxHeight: "100%",
    },
    paper: {
        margin: theme.spacing(6, 4),
        display: "flex",
        flexDirection: "column",
    },
    logo: {
        maxWidth: "50%",
        marginBottom: 80
    },
    form: {
        width: "100%",
        marginTop: theme.spacing(1),
    },

    pap: {
        backgroundColor: "white",
        borderBottomRightRadius: "5%",
        borderTopRightRadius: "5%",
        [theme.breakpoints.down("sm")]: {
            borderBottomRightRadius: "0%",
            borderTopRightRadius: "0%",
        },
    },
    margin: {
        width: "17vw",
        [theme.breakpoints.down("sm")]: {
            width: 300,
        },
        [theme.breakpoints.down("xs")]: {
            width: 200,
        },
        marginBottom: 1,
    },
    getri: {
        fontWeight: "bold",
    },

}));

export default function SignUp(props) {
    const classes = useStyles();

    return (
        <div className={classes.header}>
            <Grid container component="main" className={classes.root}>
                <CssBaseline />
                <Grid item xs={false} sm={4} md={7} className={classes.image} />
                <Grid item xs={12} sm={12} md={5} component={Paper} elevation={0} square className={classes.pap}>
                    <div className={classes.paper}>
                        <div>
                            <img
                                src="/images/getripaylogo.png"
                                alt="logo"
                                className={classes.logo}
                            />
                        </div>
                        <div>
                            <p className='typo'>Welcome to GetriPay</p>
                        </div>

                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Button
                                    component={Link}
                                    to='/signup/bvn'
                                    fullWidth
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    className='submit'
                                >
                                    SIGNUP WITH BVN
                            </Button>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Button
                                    component={Link}
                                    to='/register'
                                    fullWidth
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    className='submit'
                                >
                                    SIGNUP WITHOUT BVN
                            </Button>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item component={Link} to="/" className='already'>
                                Already have an acoount?  <span className='terms'>Login here</span>
                            </Grid>
                        </Grid>

                        <p onClick={history.goBack} className='back'>Go Back</p>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

