export const userConstants = {
  REQUESTING: "REQUESTING",
  RESPONDED: "RESPONDED",

  LOGIN_USER_REQUEST: "LOGIN_USER_REQUEST",
  LOGIN_USER_SUCCESS: "LOGIN_USER_SUCCESS",
  LOGIN_USER_FAILURE: "LOGIN_USER_FAILURE",
  LOGIN_CLEAR: "LOGIN_CLEAR",

  VERIFY_LOGIN_REQUEST: "VERIFY_LOGIN_REQUEST",
  VERIFY_LOGIN_SUCCESS: "VERIFY_LOGIN_SUCCESS",

  VERIFYING: "VERIFYING",
  VERIFIED: "VERIFIED",

  FETCHING: "FETCHING",
  FETCHED: "FETCHED",

  VALIDATING: "VALIDATING",
  VALIDATED: "VALIDATED",

  VALIDATE_REFERRAL_REQUEST: "VALIDATE_REFERRAL_REQUEST",
  VALIDATE_REFERRAL_SUCCESS: "VALIDATE_REFERRAL_SUCCESS",
  VALIDATE_REFERRAL_FAILURE: "VALIDATE_REFERRAL_FAILURE",

  TRANSFERRING: "TRANSFERRING",
  TRANSFERRED: "TRANSFERRED",
  TRANSFER_FAILURE: "TRANSFER_FAILURE",

  GET_BILLERS_REQUEST: "GET_BILLERS_REQUEST",
  GET_BILLERS_SUCCESS: "GET_BILLERS_SUCCESS",

  GET_BILLER_ITEM_REQUEST: "GET_BILLER_ITEM_REQUEST",
  GET_BILLER_ITEM_SUCCESS: "GET_BILLER_ITEM_SUCCESS",
  GET_BILLER_ITEM_FAILURE: "GET_BILLER_ITEM_FAILURE",

  GET_BILLER_PRODUCT_REQUEST: "GET_BILLER_PRODUCT_REQUEST",
  GET_BILLER_PRODUCT_SUCCESS: "GET_BILLER_PRODUCT_SUCCESS",
  GET_BILLER_PRODUCT_FAILURE: "GET_BILLER_PRODUCT_FAILURE",

  PAY_BILL_REQUEST: "PAY_BILL_REQUEST",
  PAY_BILL_SUCCESS: "PAY_BILL_SUCCESS",
  PAY_BILL_FAILURE: "PAY_BILL_FAILURE",

  VALIDATE_BILL_REQUEST: "VALIDATE_BILL_REQUEST",
  VALIDATE_BILL_SUCCESS: "VALIDATE_BILL_SUCCESS",
  VALIDATE_BILL_FAILURE: "VALIDATE_BILL_FAILURE",

  UPDATE_ACCOUNT_INFORMATION: "UPDATE_ACCOUNT_INFORMATION",

  UPDATE_USER_OBJECT: "UPDATE_USER_OBJECT",

  CHECK_LOAN_ELIGIBILITY_REQUEST: "CHECK_LOAN_ELIGIBILITY_REQUEST",
  CHECK_LOAN_ELIGIBILITY_SUCCESS: "CHECK_LOAN_ELIGIBILITY_SUCCESS",
  CHECK_LOAN_ELIGIBILITY_FAILURE: "CHECK_LOAN_ELIGIBILITY_FAILURE",

  CALCULATE_LOAN_REQUEST: "CALCULATE_LOAN_REQUEST",
  CALCULATE_LOAN_SUCCESS: "CALCULATE_LOAN_SUCCESS",

  LOGOUT: "LOGOUT",

  GET_TRANS_COUNT_REQUEST: "GET_TRANS_COUNT_REQUEST",
  GET_TRANS_COUNT_SUCCESS: "GET_TRANS_COUNT_SUCCESS",

  GET_FREQUENT_TRANS_REQUEST: "GET_FREQUENT_TRANS_REQUEST",
  GET_FREQUENT_TRANS_SUCCESS: "GET_FREQUENT_TRANS_SUCCESS",

  GET_LOANS_HISTORY_REQUEST: "GET_LOANS_HISTORY_REQUEST",
  GET_LOANS_HISTORY_SUCCESS: "GET_LOANS_HISTORY_SUCCESS",

  GET_TRANS_LIMIT_REQUEST: "GET_TRANS_LIMIT_REQUEST",
  GET_TRANS_LIMIT_SUCCESS: "GET_TRANS_LIMIT_SUCCESS",

  GET_ACCOUNT_BALANCE_REQUEST: "GET_ACCOUNT_BALANCE_REQUEST",
  GET_ACCOUNT_BALANCE_SUCCESS: "GET_ACCOUNT_BALANCE_SUCCESS",

  VERIFY_BVN_REQUEST: "VERIFY_BVN_REQUEST",
  VERIFY_BVN_SUCCESS: "VERIFY_BVN_SUCCESS",
  VERIFY_BVN_FAILURE: "VERIFY_BVN_FAILURE",

  VERIFY_BVN_OTP_REQUEST: "VERIFY_BVN_OTP_REQUEST",
  VERIFY_BVN_OTP_SUCCESS: "VERIFY_BVN_OTP_SUCCESS",

  SAVE_BENEFICIARY_REQUEST: "SAVE_BENEFICIARY_REQUEST",
  SAVE_BENEFICIARY_SUCCESS: "SAVE_BENEFICIARY_SUCCESS",
  SAVE_BENEFICIARY_FAILURE: "SAVE_BENEFICIARY_FAILURE",

  GET_ALL_BENEFICIARY_REQUEST: "GET_ALL_BENEFICIARY_REQUEST",
  GET_ALL_BENEFICIARY_SUCCESS: "GET_ALL_BENEFICIARY_SUCCESS",

  DELETE_BENEFICIARY_REQUEST: "DELETE_BENEFICIARY_REQUEST",
  DELETE_BENEFICIARY_SUCCESS: "DELETE_BENEFICIARY_SUCCESS",
  DELETE_BENEFICIARY_FAILURE: "DELETE_BENEFICIARY_FAILURE",

  GET_TRANS_FEE_REQUEST: "GET_TRANS_FEE_REQUEST",
  GET_TRANS_FEE_SUCCESS: "GET_TRANS_FEE_SUCCESS",

  CLEAR: "CLEAR",
  CLEAR_TRANSFER: "CLEAR_TRANSFER",

  REGISTER_BVN_REQUEST: "REGISTER_BVN_REQUEST",
  REGISTER_BVN_SUCCESS: "REGISTER_BVN_SUCCESS",
  REGISTER_BVN_FAILURE: "REGISTER_BVN_FAILURE",

  VALIDATE_REGISTER_BVN_OTP_REQUEST: "VALIDATE_REGISTER_BVN_OTP_REQUEST",
  VALIDATE_REGISTER_BVN_OTP_SUCCESS: "VALIDATE_REGISTER_BVN_OTP_SUCCESS",
  VALIDATE_REGISTER_BVN_OTP_FAILURE: "VALIDATE_REGISTER_BVN_OTP_FAILURE",

  VALIDATE_REGISTER_BVN_REQUEST: "VALIDATE_REGISTER_BVN_REQUEST",
  VALIDATE_REGISTER_BVN_SUCCESS: "VALIDATE_REGISTER_BVN_SUCCESS",
  VALIDATE_REGISTER_BVN_FAILURE: "VALIDATE_REGISTER_BVN_FAILURE",

  SUBMIT_SECURITY_QUESTION_REQUEST: "SUBMIT_SECURITY_QUESTION_REQUEST",
  SUBMIT_SECURITY_QUESTION_SUCCESS: "SUBMIT_SECURITY_QUESTION_SUCCESS",
  SUBMIT_SECURITY_QUESTION_FAILURE: "SUBMIT_SECURITY_QUESTION_FAILURE",

  LOGIN_SECURITY_QUESTION_REQUEST: "LOGIN_SECURITY_QUESTION_REQUEST",
  LOGIN_SECURITY_QUESTION_SUCCESS: "LOGIN_SECURITY_QUESTION_SUCCESS",
  LOGIN_SECURITY_QUESTION_FAILURE: "LOGIN_SECURITY_QUESTION_FAILURE",

  GET_ALL_NOTIFICATION_REQUEST: "GET_ALL_NOTIFICATION_REQUEST",
  GET_ALL_NOTIFICATION_SUCCESS: "GET_ALL_NOTIFICATION_SUCCESS",

  NOTIFICATION_TOGGLE_REQUEST: "NOTIFICATION_TOGGLE_REQUEST",
  NOTIFICATION_TOGGLE_SUCCESS: "NOTIFICATION_TOGGLE_SUCCESS",

  ACCOUNT_STATEMENT_REQUEST: "ACCOUNT_STATEMENT_REQUEST",
  ACCOUNT_STATEMENT_SUCCESS: "ACCOUNT_STATEMENT_SUCCESS",

  GET_PHYSICAL_CARD_REQUEST: "GET_PHYSICAL_CARD_REQUEST",
  GET_PHYSICAL_CARD_SUCCESS: "GET_PHYSICAL_CARD_SUCCESS",
  GET_PHYSICAL_CARD_FAILURE: "GET_PHYSICAL_CARD_FAILURE",

  CARD_STATUS_REQUEST: "CARD_STATUS_REQUEST",
  CARD_STATUS_SUCCESS: "CARD_STATUS_SUCCESS",
  CARD_STATUS_FAILURE: "CARD_STATUS_FAILURE",

  UPGRADE_ACCOUNT_REQUEST: "UPGRADE_ACCOUNT_REQUEST",
  UPGRADE_ACCOUNT_SUCCESS: "UPGRADE_ACCOUNT_SUCCESS",
  UPGRADE_ACCOUNT_FAILURE: "UPGRADE_ACCOUNT_FAILURE",

  DOCUMENT_STATUS_REQUEST: "DOCUMENT_STATUS_REQUEST",
  DOCUMENT_STATUS_SUCCESS: "DOCUMENT_STATUS_SUCCESS",
  DOCUMENT_STATUS_FAILURE: "DOCUMENT_STATUS_FAILURE",

  GET_VIRTUAL_CARD_REQUEST: "GET_VIRTUAL_CARD_REQUEST",
  GET_VIRTUAL_CARD_SUCCESS: "GET_VIRTUAL_CARD_SUCCESS",
  GET_VIRTUAL_CARD_FAILURE: "GET_VIRTUAL_CARD_FAILURE",

  GET_DOLLAR_RATE_REQUEST: "GET_DOLLAR_RATE_REQUEST",
  GET_DOLLAR_RATE_SUCCESS: "GET_DOLLAR_RATE_SUCCESS",
  GET_DOLLAR_RATE_FAILURE: "GET_DOLLAR_RATE_FAILURE",

  USER_VIRTUAL_CARD_REQUEST: "USER_VIRTUAL_CARD_REQUEST",
  USER_VIRTUAL_CARD_SUCCESS: "USER_VIRTUAL_CARD_SUCCESS",
  USER_VIRTUAL_CARD_FAILURE: "USER_VIRTUAL_CARD_FAILURE",

  FUND_VIRTUAL_CARD_REQUEST: "FUND_VIRTUAL_CARD_REQUEST",
  FUND_VIRTUAL_CARD_SUCCESS: "FUND_VIRTUAL_CARD_SUCCESS",
  FUND_VIRTUAL_CARD_FAILURE: "FUND_VIRTUAL_CARD_FAILURE",

  CLEAR_RATE: "CLEAR_RATE",

  WITHDRAW_VIRTUAL_CARD_REQUEST: "WITHDRAW_VIRTUAL_CARD_REQUEST",
  WITHDRAW_VIRTUAL_CARD_SUCCESS: "WITHDRAW_VIRTUAL_CARD_SUCCESS",
  WITHDRAW_VIRTUAL_CARD_FAILURE: "WITHDRAW_VIRTUAL_CARD_FAILURE",

  BLOCK_VIRTUAL_CARD_REQUEST: "BLOCK_VIRTUAL_CARD_REQUEST",
  BLOCK_VIRTUAL_CARD_SUCCESS: "BLOCK_VIRTUAL_CARD_SUCCESS",
  BLOCK_VIRTUAL_CARD_FAILURE: "BLOCK_VIRTUAL_CARD_FAILURE",

  TERMINATE_VIRTUAL_CARD_REQUEST: "TERMINATE_VIRTUAL_CARD_REQUEST",
  TERMINATE_VIRTUAL_CARD_SUCCESS: "TERMINATE_VIRTUAL_CARD_SUCCESS",
  TERMINATE_VIRTUAL_CARD_FAILURE: "TERMINATE_VIRTUAL_CARD_FAILURE",

  VIRTUAL_CARD_TRANSACTIONS_REQUEST: "VIRTUAL_CARD_TRANSACTIONS_REQUEST",
  VIRTUAL_CARD_TRANSACTIONS_SUCCESS: "VIRTUAL_CARD_TRANSACTIONS_SUCCESS",
  VIRTUAL_CARD_TRANSACTIONS_FAILURE: "VIRTUAL_CARD_TRANSACTIONS_FAILURE",

  CONFIRM_PIN_REQUEST: "CONFIRM_PIN_REQUEST",
  CONFIRM_PIN_SUCCESS: "CONFIRM_PIN_SUCCESS",
  CONFIRM_PIN_FAILURE: "CONFIRM_PIN_FAILURE",
  MASK_CARD_PIN: "MASK_CARD_PIN",

  LOYALTY_REQUEST: "LOYALTY_REQUEST",
  LOYALTY_SUCCESS: "LOYALTY_SUCCESS",

  CHARGEBACK_REQUEST: "CHARGEBACK_REQUEST",
  CHARGEBACK_SUCCESS: "CHARGEBACK_SUCCESS",
  CHARGEBACK_VALUE_REQUEST: "CHARGEBACK_VALUE_REQUEST",
  CHARGEBACK_VALUE_SUCCESS: "CHARGEBACK_VALUE_SUCCESS",
  CHARGEBACK_CONFIG_REQUEST: "CHARGEBACK_CONFIG_REQUEST",
  CHARGEBACK_CONFIG_SUCCESS: "CHARGEBACK_CONFIG_SUCCESS",

  SAVINGS_PRODUCT_REQUEST: "SAVINGS_PRODUCT_REQUEST",
  SAVINGS_PRODUCT_SUCCESS: "SAVINGS_PRODUCT_SUCCESS",

  LIST_USER_SAVINGS_REQUEST: "LIST_USER_SAVINGS_REQUEST",
  LIST_USER_SAVINGS_SUCCESS: "LIST_USER_SAVINGS_SUCCESS",

  SAVINGS_PRODUCT_DETAIL_REQUEST: "SAVINGS_PRODUCT_DETAIL_REQUEST",
  SAVINGS_PRODUCT_DETAIL_SUCCESS: "SAVINGS_PRODUCT_DETAIL_SUCCESS",

  CREATE_SAVINGS_PLAN_REQUEST: "CREATE_SAVINGS_PLAN_REQUEST",
  CREATE_SAVINGS_PLAN_SUCCESS: "CREATE_SAVINGS_PLAN_SUCCESS",
  CREATE_SAVINGS_PLAN_FAILURE: "CREATE_SAVINGS_PLAN_FAILURE",

  WALLET_BALANCE_REQUEST: "WALLET_BALANCE_REQUEST",
  WALLET_BALANCE_SUCCESS: "WALLET_BALANCE_SUCCESS",

  SAVINGS_SUMMARY_REQUEST: "SAVINGS_SUMMARY_REQUEST",
  SAVINGS_SUMMARY_SUCCESS: "SAVINGS_SUMMARY_SUCCESS",

  SAVINGS_HISTORY_REQUEST: "SAVINGS_HISTORY_REQUEST",
  SAVINGS_HISTORY_SUCCESS: "SAVINGS_HISTORY_SUCCESS",

  FUND_SAVINGS_REQUEST: "FUND_SAVINGS_REQUEST",
  FUND_SAVINGS_SUCCESS: "FUND_SAVINGS_SUCCESS",
  FUND_SAVINGS_FAILURE: "FUND_SAVINGS_FAILURE",

  WITHDRAW_SAVINGS_REQUEST: "WITHDRAW_SAVINGS_REQUEST",
  WITHDRAW_SAVINGS_SUCCESS: "WITHDRAW_SAVINGS_SUCCESS",
  WITHDRAW_SAVINGS_FAILURE: "WITHDRAW_SAVINGS_FAILURE",

  CHANGE_DATE_REQUEST: "CHANGE_DATE_REQUEST",
  CHANGE_DATE_SUCCESS: "CHANGE_DATE_SUCCESS",
  CHANGE_DATE_FAILURE: "CHANGE_DATE_FAILURE",

  SHOW_DATE_REQUEST: "SHOW_DATE_REQUEST",
  SHOW_DATE_SUCCESS: "SHOW_DATE_SUCCESS",

  CREATE_GROUP_SAVINGS_REQUEST: "CREATE_GROUP_SAVINGS_REQUEST",
  CREATE_GROUP_SAVINGS_SUCCESS: "CREATE_GROUP_SAVINGS_SUCCESS",
  CREATE_GROUP_SAVINGS_FAILURE: "CREATE_GROUP_SAVINGS_FAILURE",

  USER_GROUP_SAVINGS_REQUEST: "USER_GROUP_SAVINGS_REQUEST",
  USER_GROUP_SAVINGS_SUCCESS: "USER_GROUP_SAVINGS_SUCCESS",

  ALL_SAVINGS_GROUP_REQUEST: "ALL_SAVINGS_GROUP_REQUEST",
  ALL_SAVINGS_GROUP_SUCCESS: "ALL_SAVINGS_GROUP_SUCCESS",

  SAVINGS_GROUP_DETAIL_REQUEST: "SAVINGS_GROUP_DETAIL_REQUEST",
  SAVINGS_GROUP_DETAIL_SUCCESS: "SAVINGS_GROUP_DETAIL_SUCCESS",

  JOIN_GROUP_SAVINGS_REQUEST: "JOIN_GROUP_SAVINGS_REQUEST",
  JOIN_GROUP_SAVINGS_SUCCESS: "JOIN_GROUP_SAVINGS_SUCCESS",
  JOIN_GROUP_SAVINGS_FAILURE: "JOIN_GROUP_SAVINGS_FAILURE",

  VIEW_GROUP_INFO_REQUEST: "VIEW_GROUP_INFO_REQUEST",
  VIEW_GROUP_INFO_SUCCESS: "VIEW_GROUP_INFO_SUCCESS",

  GROUP_WALLET_REQUEST: "GROUP_WALLET_REQUEST",
  GROUP_WALLET_SUCCESS: "GROUP_WALLET_SUCCESS",

  INVITE_TO_GROUP_REQUEST: "INVITE_TO_GROUP_REQUEST",
  INVITE_TO_GROUP_SUCCESS: "INVITE_TO_GROUP_SUCCESS",

  GET_AUTHORIZE_REQUEST: "GET_AUTHORIZE_REQUEST",
  GET_AUTHORIZE_SUCCESS: "GET_AUTHORIZE_SUCCESS",

  SAVINGS_NOTIFICATIONS_REQUEST: "SAVINGS_NOTIFICATIONS_REQUEST",
  SAVINGS_NOTIFICATIONS_SUCCESS: "SAVINGS_NOTIFICATIONS_SUCCESS",

  WALLET_TRANSACTIONS_REQUEST: "WALLET_TRANSACTIONS_REQUEST",
  WALLET_TRANSACTIONS_SUCCESS: "WALLET_TRANSACTIONS_SUCCESS",

  AVAILABLE_WALLET_TRANSACTIONS_REQUEST:
    "AVAILABLE_WALLET_TRANSACTIONS_REQUEST",
  AVAILABLE_WALLET_TRANSACTIONS_SUCCESS:
    "AVAILABLE_WALLET_TRANSACTIONS_SUCCESS",

  GET_INACTIVE_SAVINGS_REQUEST: "GET_INACTIVE_SAVINGS_REQUEST",
  GET_INACTIVE_SAVINGS_SUCCESS: "GET_INACTIVE_SAVINGS_SUCCESS",

  GET_MERCHANT_SUBMERCHANT_REQUEST: "GET_MERCHANT_SUBMERCHANT_REQUEST",
  GET_MERCHANT_SUBMERCHANT_SUCCESS: "GET_MERCHANT_SUBMERCHANT_SUCCESS",

  CREATE_MERCHANT_SUBMERCHANT_REQUEST: "CREATE_MERCHANT_SUBMERCHANT_REQUEST",
  CREATE_MERCHANT_SUBMERCHANT_SUCCESS: "CREATE_MERCHANT_SUBMERCHANT_SUCCESS",

  CREATE_SUBMERCHANT_REQUEST: "CREATE_SUBMERCHANT_REQUEST",
  CREATE_SUBMERCHANT_SUCCESS: "CREATE_SUBMERCHANT_SUCCESS",

  VALIDATE_BULK_PAYMENT_REQUEST: "VALIDATE_BULK_PAYMENT_REQUEST",
  VALIDATE_BULK_PAYMENT_SUCCESS: "VALIDATE_BULK_PAYMENT_SUCCESS",

  PROCESS_BULK_PAYMENT_REQUEST: "PROCESS_BULK_PAYMENT_REQUEST",
  PROCESS_BULK_PAYMENT_SUCCESS: "PROCESS_BULK_PAYMENT_SUCCESS",

  GET_BULK_HISTORY_REQUEST: "GET_BULK_HISTORY_REQUEST",
  GET_BULK_HISTORY_SUCCESS: "GET_BULK_HISTORY_SUCCESS",
  GET_BULK_DETAILS_REQUEST: "GET_BULK_DETAILS_REQUEST",
  GET_BULK_DETAILS_SUCCESS: "GET_BULK_DETAILS_SUCCESS",

  ACTIVE_ACCOUNT: "ACTIVE_ACCOUNT",

  GET_NEW_ACCOUNT_REQUEST: "GET_NEW_ACCOUNT_REQUEST",
  GET_NEW_ACCOUNT_SUCCESS: "GET_NEW_ACCOUNT_SUCCESS",

  SUBMIT_NEW_ACCOUNT_REQUEST: "SUBMIT_NEW_ACCOUNT_REQUEST",
  SUBMIT_NEW_ACCOUNT_SUCCESS: "SUBMIT_NEW_ACCOUNT_SUCCESS",

  GET_EARNINGS_REQUEST: "GET_EARNINGS_REQUEST",
  GET_EARNINGS_SUCCESS: "GET_EARNINGS_SUCCESS",
  GET_INTEREST_HISTORY_REQUEST: "GET_INTEREST_HISTORY_REQUEST",
  GET_INTEREST_HISTORY_SUCCESS: "GET_INTEREST_HISTORY_SUCCESS",
};
