import { userConstants } from '../actions/types'

const initialState = {
    isVerifying: false,
    data: [],
}
 
function verifyLogin(state = initialState, action) {
    switch (action.type) {
        case userConstants.VERIFY_LOGIN_REQUEST:
            return {
               ...state,
                isVerifying: true,
                isLogging: true,
                data:[]
            };

        case userConstants.VERIFY_LOGIN_SUCCESS:
            return {
                 ...state,
                isVerifying: false,
                isLogging: false,
                data: action.payload, 
            };

        case userConstants.UPDATE_ACCOUNT_INFORMATION:
            return {
                ...state,
                isVerifying: false,
                data: action.payload, 
        };
        case userConstants.UPDATE_USER_OBJECT:
            return {
                ...state,
                isVerifying: false,
                data: action.payload, 
        };
            case userConstants.LOGOUT:
            return {
                ...state,
                isVerifying: false,
                data: [], 
            };
        default:
            return state
    }
}

export default verifyLogin;