import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import virtualCard from '../../services/virtualCardService';
import transactions from '../../services/transactionService';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});

function VirtualCardModal(props) {
 
    const [price, setPrice] = useState(0);

    const user = {
        amount: price,
        user_id: props.details.id,
        currency: 'USD',
        trans_ref: `${props.details.id}` + Math.floor(Math.random() * 900000000000000000),
        account_no: props.balance.account_number,
    };

    const rateRequest = {
        amount: price,
        type: 'buying',
        currency: 'USD',
        user_kyc_level: props.details.kyc_level
    }
    const token = props.details.token;
    const id = props.details.id

    function handleChange(e) {
        setPrice(e.target.value)
    }

    // GET VIRTUAL CARD RATE
    function getRate() {
        if (price) {
            props.get_rate(rateRequest, token)
        }
    }

    // SUMBIT VIRTUAL CARD REQUEST
    function submit() {
        if (user && token && props.details.kyc_level !== 1 && props.details.kyc_level !== 2) {
            props.request_virtual_card(user, token)
                .then(() => {
                    props.clear_rate();
                    props.get_account_balance();
                    props.get_user_virtual_card(id, token)
                });
        }
    };

    return (
        <div>
            <Dialog
                open={
                    props.data.success === true ? false
                        :
                        props.open === true ? true
                            :
                            false
                }
                TransitionComponent={Transition}
                keepMounted
                onClose={props.close}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <Typography variant="p" style={{ padding: '20px' }}>
                    Enter the amount in dollar you would like to fund your virtual card with
                 </Typography>

                <DialogContent dividers style={{ backgroundColor: '#eee' }}>
                    <Typography variant='span' gutterBottom className='request-modal-title'>
                        Account Name:  {''}
                    </Typography>
                    <Typography variant='span' className='request-modal-text'>
                        {props?.balance?.account_name}
                    </Typography>
                    <br /><br />

                    <TextField
                        autoFocus
                        onChange={handleChange}
                        value={price}
                        margin="dense"
                        label="Amount in dollar"
                        type="text"
                    />
                    <Button variant="contained" color="primary" className='virtual-rate-button' onClick={getRate} >
                        {props.isGettingRate ?
                            <CircularProgress style={{ fontSize: '30px', color: '#fff' }} />
                            :
                            'Calculate'
                        }
                    </Button>
                    <br />
                    <Typography variant='span' className='request-modal-text' style={{ color: 'red', fontSize: '11px' }}>
                        Amount must be greater than $1
                    </Typography>
                    <br /><br />
                    {props.rate.data
                        ?
                        <Typography variant='p' >
                            {/* You're buying $1 at the rate of <span style={{ color: 'black', fontSize: '14px', fontWeight: '900' }}>NGN {props.rate.data.$1}</span>
                            <br />
                            You will be charged  <span style={{ color: 'black', fontSize: '14px', fontWeight: '900' }}> NGN {(props.rate.data.rates).toLocaleString()}</span> from your GetriPay Account */}
                         <span style={{ color: 'black', fontSize: '14px', fontWeight: '900' }}> {props.rate.response_message}</span>
                        <br /><br />
                        Processing fee: <span style={{ color: 'black', fontSize: '14px', fontWeight: '900' }}> NGN {(props.rate.data.card_request_fees).toLocaleString()}</span>
                        </Typography>
                        :
                        ''
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.close} color="primary" className='request-modal-button-cancel'>
                        CANCEL
                    </Button>
                    <Button disabled={props.rate.response_message == undefined} color='primary' variant="contained" size="small" onClick={submit} className='request-modal-button'>
                        {props.isReqVirCard ?
                            <CircularProgress style={{ fontSize: '30px', color: '#fff' }} />
                            :
                            'Create Virtual Card'
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        request_virtual_card: (user, token) => dispatch(virtualCard.request_virtual_card(user, token)),
        get_rate: (payload, token) => dispatch(virtualCard.get_rate(payload, token)),
        get_user_virtual_card: (id, token) => dispatch(virtualCard.get_user_virtual_card(id, token)),
        clear_rate:()=>dispatch(virtualCard.clear_rate()),
        get_account_balance: (payload) => dispatch(transactions.get_account_balance(payload)),
    };
};
const mapStateToProps = (state) => {
    return {
        isReqVirCard: state.getVirtualCard.isReqVirCard,
        isGettingRate: state.getVirtualCard.isGettingRate,
        rate: state.getVirtualCard.rate,
        data: state.getVirtualCard.data,
        balance:state.getAccountBalance.data
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VirtualCardModal);