import React from "react";
import { Grid, Paper } from "@material-ui/core";

function TransferLimitCard({ props }) {
  return (
    <Grid item xs={12} sm={12} md={4} lg={4}>
      {/* account details section */}
      <Paper className="dashboardBox1">
        <div>
          <div className="for-inline-items">
            <p className="e-cash"> Transaction Limit </p>
          </div>

          <div className="for-inline-items">
            <div className="inner-card">
              <img
                src="images/warning.jpg"
                alt="logo"
                className="card-icons-2"
              />
              <div className="inner-card-text-bold">
                <span className="inner-text-gray">
                  NGN{" "}
                  {props?.limit?.limitSpent
                    ? Number(props.limit.limitSpent).toLocaleString()
                    : "0"}
                </span>

                <p className="card-text-sm-warning">Daily Limit Spent</p>
                <span className="two">Single Transaction</span>
              </div>
            </div>
            <div className="inner-card">
              <img
                src="images/warning.jpg"
                alt="logo"
                className="card-icons-2"
              />
              <div className="inner-card-text-bold">
                <span className="inner-text-gray">
                  NGN{" "}
                  {props?.limit?.remainingLimit
                    ? Number(props.limit.remainingLimit).toLocaleString()
                    : "0"}
                </span>

                <p className="card-text-sm-warning">Daily Limit Left</p>
                <span className="two">Total Transaction</span>
              </div>
            </div>
          </div>
        </div>
      </Paper>
    </Grid>
  );
}

export default TransferLimitCard;
