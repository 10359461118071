import { userConstants } from '../actions/types'

const initialState = {
    isValidatingBill: false,
    data: [],
}

function validateBill(state = initialState, action) {
    switch (action.type) {
        case userConstants.VALIDATE_BILL_REQUEST:
            return {
                ...state,
                isValidatingBill: true,

            };
        case userConstants.VALIDATE_BILL_SUCCESS:
            return {
                ...state,
                isValidatingBill: false,
                data: action.payload,
            };
        case userConstants.VALIDATE_BILL_FAILURE:
            return {
                ...state,
                isValidatingBill: false,
                success:false,
                data: [],
            };
        default:
            return state
    }
}

export default validateBill;