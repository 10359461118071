import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { connect } from "react-redux";
import virtualCard from "../../services/virtualCardService";
import transactions from "../../services/transactionService";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

function TerminateVirtual(props) {
  const [user, setUser] = useState({
    user_id: props.details.id,
    card_id: props.card.id,
    currency: "USD",
    trans_ref:
      `${props.details.id}` + Math.floor(Math.random() * 900000000000000000),
    account_no: props.balance.account_number,
  });

  useEffect(() => {
    setUser({
      ...user,
      account_no: props.balance.account_number,
    });
  }, [props]);

  const token = props.details.token;
  const id = props.details.id;

  // SUMBIT VIRTUAL CARD REQUEST
  function submit() {
    if (user && token) {
      props.terminate_virtual_card(user, token).then(() => {
        props.clear_rate();
        props.get_account_balance();
        props.get_user_virtual_card(id, token);
      });
    }
  }

  return (
    <div>
      <Dialog
        open={
          props.end.success === true
            ? false
            : props.open === true
            ? true
            : false
        }
        TransitionComponent={Transition}
        keepMounted
        onClose={props.close}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Typography variant="p" style={{ padding: "20px", color: "red" }}>
          Are you sure you want to terminate your Virtual Card?.
          <br />
          <br />
          Please note that this action cannot be undone. Your available funds in
          dollar will be credited to your GetriPay account.
        </Typography>

        <DialogContent dividers style={{ backgroundColor: "#eee" }}>
          <Typography
            variant="span"
            gutterBottom
            className="request-modal-title"
          >
            Available Dollar Balance: {""}
          </Typography>
          <Typography variant="span" className="request-modal-text">
            $ {Number(props.card.amount).toLocaleString()}
          </Typography>
          <br />
          <br />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={props.close}
            color="primary"
            className="request-modal-button-cancel"
          >
            CANCEL
          </Button>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={submit}
            className="request-modal-button"
          >
            {props.isEndingVCard ? (
              <CircularProgress style={{ fontSize: "30px", color: "#fff" }} />
            ) : (
              "Terminate Virtual Card"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    terminate_virtual_card: (user, token) =>
      dispatch(virtualCard.terminate_virtual_card(user, token)),
    get_user_virtual_card: (id, token) =>
      dispatch(virtualCard.get_user_virtual_card(id, token)),
    clear_rate: () => dispatch(virtualCard.clear_rate()),
    get_account_balance: (payload) =>
      dispatch(transactions.get_account_balance(payload)),
  };
};
const mapStateToProps = (state) => {
  return {
    isEndingVCard: state.getVirtualCard.isEndingVCard,
    end: state.getVirtualCard.end,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TerminateVirtual);
