import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import savings from '../../../../services/savingsService';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { IconButton, Menu, MenuItem, Grid, Popover, Typography } from '@material-ui/core';
import { ListItemText } from '@material-ui/core';
import FundSavingsModal from './FundSavingsModal';
import WithdrawSavingsModal from './WithdrawSavingsModal';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';

function SavingsBox(props) {
    const id = props.id
    const [anchorEl, setAnchorEl] = useState(null);
    const [showPop, setShowPop] = useState(null);
    const [open, setOpen] = useState(false);
    const [withdraw, setWithdraw] = useState(false);

    const handleOpenModal = () => {
        setOpen(true);
    };

    const handleCloseModal = () => {
        setOpen(false);
    };

    const handleOpenWithdraw = () => {
        setWithdraw(true);
    };

    const handleCloseWithdraw = () => {
        setWithdraw(false);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlePopoverOpen = (event) => {
        setShowPop(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setShowPop(null);
    };

    const openPop = Boolean(showPop);

    return (
        <>
            <div className='savings-box'>
                <Grid container spacing={5} style={{alignItems:'center'}}
                // component={Link} to={`history/${id}`}
                //  onClick={()=>history.push(`history/${id}`)}
                >

                    <Grid item lg={7} sm={7} xs={7}>
                        <p style={{ margin: '0px', textTransform: "capitalize" }} className='savings-title'>{props.name}</p>
                        <p style={{ fontSize: "12px", margin: '0px' }}>{props.savings_product ? props.savings_product.name : ''}</p>
                    </Grid>
                    <Grid item lg={1} sm={1} xs={1}>
                        {props.available_for_withdraw === 1 ?
                            <div
                                onMouseEnter={handlePopoverOpen}
                                onMouseLeave={handlePopoverClose}
                                aria-owns={openPop ? 'mouse-over-popover' : undefined}
                            >
                                <LockOpenIcon style={{color:'green'}} />
                                <Popover
                                    id="mouse-over-popover"
                                    open={openPop}
                                    anchorEl={showPop}
                                    onClose={handlePopoverClose}
                                    disableRestoreFocus
                                >
                                    <Typography style={{ padding: '10px' }}>You can withdraw from this savings</Typography>
                                </Popover>

                            </div>
                            :
                            <div
                                onMouseEnter={handlePopoverOpen}
                                onMouseLeave={handlePopoverClose}
                                aria-owns={openPop ? 'mouse-over-popover' : undefined}
                            >
                                <LockIcon style={{color:'red'}} />
                                <Popover

                                    id="mouse-over-popover"
                                    open={openPop}
                                    anchorEl={showPop}
                                    onClose={handlePopoverClose}
                                    disableRestoreFocus
                                >
                                    <Typography style={{ padding: '10px' }}>You cannot withdraw from this savings</Typography>
                                </Popover>

                            </div>
                        }
                    </Grid>

                    <Grid item lg={3} sm={3} xs={3}>
                        <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onMouseOver={handleClick}
                        >
                            <MoreVertIcon className='menu-height' alt='menu' />
                        </IconButton>
                        <Menu
                    
                            id="customized-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem component={Link} to={`history/${id}`} >
                                <ListItemText primary="Saving history" />
                            </MenuItem>

                            {props.savings_product && props.status !== 'in-active' ?
                                <>
                                    {props.savings_product.amount_type === 'fixed' ?
                                        ''
                                        :
                                        <MenuItem onClick={handleOpenModal}>
                                            <ListItemText primary="Top up savings" />
                                        </MenuItem>
                                    }
                                </>
                                :
                                ''
                            }
                            {props.status !== 'in-active' ?
                                <MenuItem onClick={handleOpenWithdraw}>
                                    <ListItemText primary="Withdraw from savings" />
                                </MenuItem>
                                :
                                ''
                            }


                        </Menu>
                    </Grid>
                    <FundSavingsModal open={open} close={handleCloseModal} id={props.id} balance={props.balance} />
                    <WithdrawSavingsModal open={withdraw} close={handleCloseWithdraw} id={props.id} balance={props.balance}/>

                </Grid>

                <div className='savings-section'>
                    <div className='inner-card savings-section-1'>

                        {props.savings_product ?
                            <>
                                <img src={props.savings_product.image_url}
                                />
                            </>
                            :
                            <img src='/images/target_savings.png' />
                        }

                        <span className='prog-text'>PROGRESS</span>
                        <span className='prog-amount'>NGN {Number(props.amount_save).toLocaleString()}</span>
                        <div>
                            <span className='prog-text'>INTEREST ACCRUED</span>
                            <span className='prog-amount'>NGN{props.total_interest}</span>
                        </div>
                    </div>
                    <div className='inner-card savings-section-1'>
                        {props.savings_product ?
                            <>
                                <img src={props.savings_product.image_url}
                                />
                            </>
                            :
                            <img src='/images/target_savings.png' />
                        }
                        <span className='target-text'>TARGET</span>
                        <span className='prog-amount'>NGN {Number(props.target_amount).toLocaleString()}</span>
                        <span className='save-time-left'>{new Date(props.maturity_date).toDateString()} </span>
                        <span className='save-interest'>{props.interest_rate}% Interest Rate</span>
                    </div>
                </div>

            </div>
        </>

    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        get_savings_summary: (id) => dispatch(savings.get_savings_summary(id))
    };
};

const mapStateToProps = (state) => {
    return {
        data: state.savingsSummary.data,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SavingsBox);