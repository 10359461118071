import axios from 'axios';
import { userActions } from "../actions/creators";
import config from '../config';

const baseUrl = `${config.apiUrl}`;

//To  upgrade account
const upgradeKyc = {
    upgrade_to_kyc_3: (document, images, token) => {
        return async dispatch => {
            const formData = new FormData();
            formData.append('images', images);
            formData.append('user_id', document.user_id);
            formData.append('kyc_level_name', document.kyc_level_name);
            formData.append('kyc_level_id', document.kyc_level_id);
            formData.append('name', document.name);
            formData.append('identification_number', document.identification_number);
            formData.append('type', document.type);

            dispatch(userActions.upgradeAccountRequest());
            await axios.post(`${baseUrl}/user/user_documents`, formData, {
                "headers": {
                    "Content-Type": 'application/json',
                    "Authorization": `Bearer ${token}`
                }
            }).then(res => {
                dispatch(userActions.upgradeAccountSuccess(res.data.success));
            }).catch(error => {
                dispatch(userActions.upgradeAccountFailure());
            });
        };
    },

    check_kyc_requirements: () => {
        return async dispatch => {
            dispatch(userActions.uiRequesting());
            const token = localStorage.getItem('token')
            await axios.get(`${baseUrl}/user/check_requirement_for_kyc_level/3`, {
                "headers": {
                    "Content-Type": 'application/json',
                    "Authorization": `Bearer ${token}`
                }
            })
                .then(res => {

                })
                .catch(error => {

                })

        }
    },

    document_status: (token) => {
        return async dispatch => {
            dispatch(userActions.documentStatusRequest());

            const id = localStorage.getItem('user_id');

            await axios.get(`${baseUrl}/user/my_own/user_documents/${id}`, {
                "headers": {
                    "Content-Type": 'application/json',
                    "Authorization": `Bearer ${token}`
                }
            })
                .then(res => {
                    dispatch(userActions.documentStatusSuccess(res.data.data));
                })
                .catch(error => {
                    dispatch(userActions.documentStatusFailure());
                })
        }
    },
};

export default upgradeKyc;