import { userConstants } from "../actions/types";

const initialState = {
  isLoading: false,
  data: [],
};

function walletBalance(state = initialState, action) {
  switch (action.type) {
    case userConstants.WALLET_BALANCE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case userConstants.WALLET_BALANCE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    default:
      return state;
  }
}

export default walletBalance;
