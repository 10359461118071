import { userConstants } from '../actions/types'

const initialState = {
    isLoading: false,
    data: [],
}
 
function getSavingsProductDetail(state = initialState, action) {
    switch (action.type) {
        case userConstants.SAVINGS_PRODUCT_DETAIL_REQUEST:
            return {
                ...state,
                isLoading: true,
            
            };
        case userConstants.SAVINGS_PRODUCT_DETAIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload, 
            };
        default:
            return state
    }
}

export default getSavingsProductDetail;