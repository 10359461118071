import axios from 'axios';
import { toast, Flip } from 'react-toastify';
import config from '../config';
import { userActions } from "../actions/creators.js";
import { history } from "../store/history"

const baseUrl = `${config.apiUrl}`;

const resend = {

    // Resend OTP to Phone 

    resend_phone: () => {
        return async dispatch => {
            dispatch(userActions.uiRequesting());

            const register_token = localStorage.getItem('register_token')

            await axios.get(`${baseUrl}/user/users/resendotp/${register_token}/phone`, { register_token })
                .then(res => {
                    dispatch(userActions.uiResponded());

                    localStorage.setItem('register_token', (res.data.data.register_token));
                    if (res.data.response_message === 'New Phone token Generated successful') {
                        toast.success("An OTP has been sent to you via Email and SMS", {
                            position: toast.POSITION.TOP_RIGHT,
                            transition: Flip,
                            autoClose: 5000
                        });
                        window.location.reload(true)
                    }

                    // history.push('/verify/phone');
                })
                .catch(error => {
                    dispatch(userActions.uiResponded());

                    if (error.response.data.response_message === "Not Exist") {
                        toast.error('This phone number does not exist, please register', {
                            position: toast.POSITION.TOP_RIGHT,
                            transition: Flip,
                        })
                        history.push("/register");
                    }
                })
        }
    },

    // Resend link to Email
    resend_email: (email) => {
        return async dispatch => {
            dispatch(userActions.uiRequesting());

            const register_token = localStorage.getItem('register_token')

            await axios.get(`${baseUrl}/user/users/resendotp/${register_token}/email`, { register_token })
                .then(res => {
                    dispatch(userActions.uiResponded());

                    localStorage.setItem('register_token', (res.data.data.register_token));
                    if (res.data.response_message === 'New Email token Generated successful') {
                        toast.success("Verification link has been re-sent to your email", {
                            position: toast.POSITION.TOP_RIGHT,
                            transition: Flip,
                            autoClose: 5000
                        })
                        history.push('/')
                    }
                })
                .catch(error => {
                    dispatch(userActions.uiResponded());

                    if (error.response.data.response_message === "Not exist") {
                        toast.error('This email does not exist, please register', {
                            position: toast.POSITION.TOP_RIGHT,
                            transition: Flip,
                        })
                        history.push("/register")
                    }
                })
        }
    },


    // Resend Login OTP
    resend_login: () => {
        return async dispatch => {
            dispatch(userActions.uiRequesting());

            const register_token = localStorage.getItem('register_token')
            await axios.get(`${baseUrl}/user/users/login/resendotp/${register_token}/phone`, { register_token })
                .then(res => {
                    dispatch(userActions.uiResponded());
                    if (res.data.response_code === 'USM014') {
                        toast.success("A new OTP has been sent to you via Email and SMS", {
                            position: toast.POSITION.TOP_RIGHT,
                            transition: Flip,
                            autoClose: 5000
                        })
                    } else if (res.data.data === "Token Expired") {
                        toast.error('Please login again', {
                            position: toast.POSITION.TOP_RIGHT,
                            transition: Flip,
                        });
                        history.push('/');
                    }
                })
                .catch(error => {

                    dispatch(userActions.uiResponded());

                    if (error.response.data.response_message === "Not Exist") {
                        toast.error('This phone number does not exist, please register', {
                            position: toast.POSITION.TOP_RIGHT,
                            transition: Flip,
                        })
                        history.push('/register');
                    }
                })
        }
    },
};

export default resend;

