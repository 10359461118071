import React, { useEffect, useState } from 'react';
import { TextField, Button, IconButton } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	input: {
		marginLeft: theme.spacing(1),
		flex: 1,
		width: '70%',
	},
	iconButton: {
		padding: 10,
	},
	devButton: {
		fontSize: '10px',
		color: 'primary',
		fontWeight: '600',
		backgroundColor: '#ffcc00',
		borderRadius: '50px',
		width: '110px',
	},
	section: {
		textAlign: 'left',
		margin: '20px',
	},
}));

export default function DeveloperKeys(props) {
	const classes = useStyles();

	const [keys, setKeys] = useState({
		pubTest: props.devKey.testPublicKey,
		secTest: props.devKey.testSecreteKey,
		pubLive: props.devKey.livePublicKey,
		secLive: props.devKey.liveSecreteKey,
	});

	function handleChange(e) {
		const { name, value } = e.target;
		setKeys((keys) => ({ ...keys, [name]: value }));
	}

	const copyPubTest = () => {
		navigator.clipboard.writeText(keys.pubTest);
		alert(keys.pubTest);
	};

	const copySecTest = () => {
		navigator.clipboard.writeText(keys.secTest);
		alert(keys.secTest);
	};

	const copyPubLive = () => {
		navigator.clipboard.writeText(keys.pubLive);
		alert(keys.pubLive);
	};

	const copySecLive = () => {
		navigator.clipboard.writeText(keys.secLive);
		alert(keys.secLive);
	};

	return (
		<div style={{ marginTop: '30px' }}>
			{props.data.success === true ? (
				<div>
					<p style={{ fontSize: '12px', fontWeight: 'bold', marginTop: '20px' }}>Developer Test Keys</p>

					<Grid
						style={{
							width: '100%',
							padding: '10px',
							marginRight: '10px',
							border: '2px solid #F0F0F0',
							borderRadius: '4px',
						}}
					>
						<TextField
							onChange={handleChange}
							name='pubTest'
							value={keys.pubTest}
							disabled
							variant='outlined'
							placeholder='Public test key will be here'
							className={classes.input}
						/>
						<IconButton className={classes.iconButton} aria-label='copy'>
							<Button className={classes.devButton} onClick={copyPubTest}>
								{' '}
								COPY
							</Button>
						</IconButton>

						<div style={{ marginTop: '10px' }}>
							<TextField
								onChange={handleChange}
								name='secTest'
								value={keys.secTest}
								disabled
								variant='outlined'
								placeholder='Secret test key will be here'
								className={classes.input}
							/>
							<IconButton className={classes.iconButton} aria-label='copy'>
								<Button className={classes.devButton} onClick={copySecTest}>
									{' '}
									COPY
								</Button>
							</IconButton>
						</div>
					</Grid>

					<p style={{ fontSize: '12px', fontWeight: 'bold', marginTop: '20px' }}>Developer Live Keys</p>

					<Grid
						style={{
							width: '100%',
							padding: '10px',
							marginRight: '10px',
							border: '2px solid #F0F0F0',
							borderRadius: '4px',
						}}
					>
						<TextField
							onChange={handleChange}
							name='secLive'
							value={keys.secLive}
							variant='outlined'
							disabled
							placeholder='Secret live key will be here'
							className={classes.input}
						/>
						<IconButton className={classes.iconButton} aria-label='copy'>
							<Button className={classes.devButton} onClick={copySecLive}>
								{' '}
								COPY
							</Button>
						</IconButton>

						<TextField
							onChange={handleChange}
							name='pubLive'
							value={keys.pubLive}
							variant='outlined'
							disabled
							placeholder='Public live key will be here'
							className={classes.input}
							style={{ marginTop: '10px' }}
						/>
						<IconButton className={classes.iconButton} style={{ marginTop: '20px' }} aria-label='copy'>
							<Button className={classes.devButton} onClick={copyPubLive}>
								{' '}
								COPY
							</Button>
						</IconButton>
					</Grid>
				</div>
			) : (
				<div style={{ textAlign: 'center', height: '100vh', marginTop: '50px' }}>
					<h4>You do not have a GetriPay developer account yet. Please visit the first tab to create an account.</h4>
				</div>
			)}
		</div>
	);
}
