import axios from 'axios';
import { toast, Flip } from 'react-toastify';
import config from '../config';
import { userActions } from "../actions/creators.js";
import { history } from "../store/history";
import headers from '../store/authHeader';

const baseUrl = `${config.apiUrl}`;

const forgot = {
    // Forgot password
    forgot_password: (email) => {
        return async dispatch => {

            dispatch(userActions.uiRequesting());

            await axios.get(`${baseUrl}/user/users/password/reset/token/${email}`, {email})
                .then(res => {     
                    dispatch(userActions.uiResponded());

                    localStorage.setItem('register_token', (res.data.data.register_token));
                    localStorage.setItem('user_id', (res.data.data.user_id));
                   
                    if (res.data.success === true) {
                        toast.success( "Please check your mail to reset your password", {
                            position: toast.POSITION.TOP_RIGHT,
                            transition: Flip,
                        })
                        history.push('/validate')
                    }
                })
                .catch(error => {
                    dispatch(userActions.uiResponded());

                    if (error.response.data.success === false) {
                        toast.error('You are not registered, Please register', {
                            position: toast.POSITION.TOP_RIGHT,
                            transition: Flip
                        })
                    } 
                    // history.push('/register')
                })
        }
    },

    // Validate otp sent to users email
    validate_otp: (token) => {

        const register_token = localStorage.getItem('register_token');

        return async dispatch => {

            dispatch(userActions.uiRequesting());

            await axios.get(`${baseUrl}/user/users/${register_token}/password/token/${token}/verify` )
                .then(res => {
            
                    dispatch(userActions.uiResponded());

                    if (res.data.success === true) {
                        toast.success( "Successful, Enter your new password", {
                            position: toast.POSITION.TOP_RIGHT,
                            transition: Flip,
                        })
                        history.push('/change-password')
                    }
                })
                .catch(error => {
                   dispatch(userActions.uiResponded());

                    if (error.response.data.response_message === 'Token is not Valid') {
                        toast.error('Invalid Token', {
                            position: toast.POSITION.TOP_RIGHT,
                            transition: Flip
                        })
                    } 
                    else if (error.response.data.response_message === "Token is used") {
                        toast.error('This code has been used', {
                            position: toast.POSITION.TOP_RIGHT,
                            transition: Flip
                        })
                        history.push('/forgot-password')
                    }
                })
        }
    },


    // Enter new password
    change_password: (new_password, c_new_password) => {

        const user_id = localStorage.getItem('user_id');

        return async dispatch => {

            dispatch(userActions.uiRequesting());

            await axios.post(`${baseUrl}/user/users/password/change`, { new_password, c_new_password, user_id } )
                .then(res => {
                   
                    dispatch(userActions.uiResponded());

                    if (res.data.success === true) {
                        toast.success( "Password changed successfully", {
                            position: toast.POSITION.TOP_RIGHT,
                            transition: Flip,
                        })
                        history.push('/')
                    }
                })
                .catch(error => {
                    
                   dispatch(userActions.uiResponded());

                    if (error.response.data.success === false) {
                        toast.error('ERROR', {
                            position: toast.POSITION.TOP_RIGHT,
                            transition: Flip
                        })
                    } 
                    // history.push('/register')
                })
        }
    },

    // Reset Password
    reset_password: (old_password, new_password, c_new_password) => {

        const user_id = localStorage.getItem('user_id')

        return async dispatch => {
        
            dispatch (userActions.uiRequesting());
            await axios.post(`${baseUrl}/user/users/password/reset/${user_id}`, {old_password, new_password, c_new_password}, {"headers": headers})
            .then(res=>{
                toast.success( "Your password has been updated successfully", {
                    position: toast.POSITION.TOP_RIGHT,
                    transition: Flip,
                })
            })
            .catch(error=>{
                toast.error( "Your Old password is not correct!", {
                    position: toast.POSITION.TOP_RIGHT,
                    transition: Flip,
                })
               
            })
        }
    }    
};

export default forgot;

