import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Avatar, Container, Snackbar } from "@material-ui/core";
import TopBar from "../../../components/TopBar";
import { MainContent, HeaderBar, Footer } from "../../../components/Appearance";
import SideNav from "../../../components/SideNav";
import { connect } from "react-redux";
import BackDrop from '../../../components/BackDrop';
import '../../../css/dashboard.css';
import bills from "../../../services/billPaymentService";
import transactions from "../../../services/transactionService";
import { Alert } from '@material-ui/lab';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import BottomNav from "../../../components/bottomNavigation";
import { Helmet } from 'react-helmet'

const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    large: {
        width: theme.spacing(13),
        height: theme.spacing(13),
        [theme.breakpoints.down("sm")]: {
            width: theme.spacing(8),
            height: theme.spacing(8),
        },
    },
    merchant: {
        fontSize: 13,
        textAlign: 'center',
        fontWeight: '900',
        [theme.breakpoints.down("sm")]: {
            fontSize: 10
        },
    },
    catdesc: {
        fontSize: 11,
        fontWeight: 'bolder',
        textAlign: 'center',
        color: '#0037ba',
        [theme.breakpoints.down("sm")]: {
            fontSize: 9
        },
    },
    bill: {
        padding: 30,
        margin: 40,
        "&:hover": {
            backgroundColor: 'rgb(151, 173, 225)'
        },
        [theme.breakpoints.down("sm")]: {
            margin: 15,
        },
        [theme.breakpoints.down("xs")]: {
            margin: 11,
            padding: 10
        },
        textAlign: '-webkit-center',
        cursor: 'pointer'
    },
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

function Airtime(props) {
    const classes = useStyles();
    const [toggle, setToggle] = useState(true);
    const [sidenav, setSidenav] = useState("getri-sidenav");
    const [setOpen] = useState(true);

    const handleToggle = () => {
        const mediaScreen = window.matchMedia(
            `(max-width: 700px),
        (min-device-width: 768px)
        and (max-device-width: 1024px)`
        ); //returns true if

        if (mediaScreen.matches) {
            setSidenav("slideOut");
        } else {
            setToggle(!toggle);
        }
    };
    const hideSidebar = () => {
        setSidenav("slideIn");
    };

    useEffect(() => {
        props.get_airtime_item();
        props.get_account_balance();
    }, [props.status]) // eslint-disable-line react-hooks/exhaustive-deps

    function refresh() {
        window.location.reload(true)
        return false;
    }


    const handleClose = () => {
        setOpen(false);
    };


    return (
        <>
            <Helmet>
                <title>GetriPay - Fastest growing digital bank with rewards </title>
                <meta name="description" content="Buy Airtime" />
            </Helmet>
            {
                props.isGettingBiller || props.isGettingItem || props.isGettingProduct
                    ?
                    <BackDrop />
                    :
                    ''
            }

            <TopBar handleToggle={handleToggle} />
            <SideNav toggle={toggle} className={sidenav} hideSidebar={hideSidebar}  {...props} />
            <MainContent toggle={toggle}>
                <main className="main">
                    <Grid style={{ height: "100%" }}>
                        <Grid item xs={12} md={12} lg={12} style={{ height: "inherit" }}>
                            <Container className="main-container" style={{ paddingLeft: "50px" }}>
                                <HeaderBar topic="Buy Airtime" text={Number(props.balance.available_balance).toLocaleString()} />
                            </Container>
                            <Grid container style={{ padding: "1px 0", height: '100%', backgroundColor: 'white' }}>
                                {props.billerItem.map((billerItem, i) => (
                                    <Grid item xs={5} md={5} lg={3} sm={5} key={i} className={classes.bill} component={Link} to={`pay-bills/${billerItem.biller_ref}/${(billerItem.logo_url).replace('https://www.quickteller.com/images/Downloaded/', '')}/${billerItem.label_name}/${billerItem.biller_category}/${billerItem.biller_name}`}>
                                        <Avatar alt="Biller" src={billerItem.logo_url} className={classes.large} />
                                        <p className={classes.merchant}>{billerItem.biller_name}</p>
                                        <span className={classes.catdesc}>{billerItem.biller_category_name}</span>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                        {props.status === false
                            ?
                            <div className={classes.root}>
                                <Snackbar open={props.status === false}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    <Alert variant="filled" severity="error"
                                        action={
                                            <Button color="inherit" size="small" onClick={refresh}>
                                                REFRESH
                                        </Button>
                                        }
                                    >
                                        We could not process this request at this moment, please try again later.
                                    <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            className={classes.close}
                                            onClick={handleClose}
                                        >
                                            <CloseIcon fontSize="small" />
                                        </IconButton>
                                    </Alert>
                                </Snackbar>

                            </div>
                            :
                            ''
                        }
                    </Grid>
                    <BottomNav {...props} />
                    <Footer />
                </main>
            </MainContent>
        </>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        get_airtime_item: (payload) => dispatch(bills.get_airtime_item(payload)),
        get_account_balance: (payload) => dispatch(transactions.get_account_balance(payload)),
    };
};

const mapStateToProps = (state) => {
    return {
        billerItem: state.getBillerItem.data,
        isGettingItem: state.getBillerItem.isGettingItem,
        status: state.getBillerItem.success,
        balance: state.getAccountBalance.data,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Airtime);
