import React from 'react';


function NoSavings(props) {

  return (
    <div className='no-savings' >
      <img src={props.image} />
      <p>{props.title} </p>
      <p >{props.text}</p>
    </div>

  )
}


const mapStateToProps = (state) => {
  return {
    plans: state.getSavingsProduct.data,
  };
};


export default NoSavings;