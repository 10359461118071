import axios from 'axios';
import { userActions } from "../actions/creators";
import config from '../config';

const baseUrl=`${config.apiUrl}`;

//To  update to user object
const updateUser = {
    update_user_object: () => {
        return async dispatch => {
            const id=localStorage.getItem('user_id');
            await axios.get(`${baseUrl}/user/users-profile/${id}`).then(res => {
                    dispatch(userActions.updateUserObject(res.data.data));
                   // window.location.reload(true);
                })
        }
    }
}

export default updateUser;