import React from "react";
import { Divider, IconButton, makeStyles, Drawer } from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";
import Pagination from "../../pages/Dashboard/UserProfile/components/Pagination/Pagination";
import TransactionScroll from "../Skeleton/TransactionScroll";

const drawerWidth = 500;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  drawerPaper: {
    width: drawerWidth,
    fontSize: "13px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  drawerTitle: {
    fontWeight: "bolder",
    fontSize: "16px",
  },
  small: {
    fontSize: "12px",
    marginTop: theme.spacing(-2),
    marginLeft: "2px",
  },
  ref: {
    marginTop: theme.spacing(-0.5),
    fontSize: "12px",
    marginLeft: "2px",
    fontWeight: "bolder",
  },
  name: {
    fontSize: "11px",
    fontWeight: "bolder",
    marginLeft: "2px",
  },
}));

export default function InterestDrawer({
  details,
  drawerActive,
  setDrawerActive,
  loading,
  account,
  get_interest_history,
}) {
  const classes = useStyles();
  // Change page
  function handlePageClick(data) {
    get_interest_history(data.selected + 1);
  }
  return (
    <Drawer
      anchor="right"
      open={drawerActive}
      classes={{
        paper: classes.drawerPaper,
      }}
      onClose={() => setDrawerActive(false)}
    >
      <div className={classes.drawerHeader}>
        <p className={classes.drawerTitle}>Interest History for {account}</p>
        <IconButton onClick={() => setDrawerActive(false)}>
          <CloseRounded />
        </IconButton>
      </div>
      <Divider />

      <Pagination
        handlePageClick={handlePageClick}
        totalItems={details?.meta?.last_page}
      />
      {loading ? (
        <TransactionScroll />
      ) : (
        <div style={{ padding: "20px" }}>
          {details?.data &&
            details?.data.map((transaction, i) => {
              return (
                <div key={i} className="history-table2">
                  <span
                    className={classes.name}
                    style={{ display: "inline-block" }}
                  >
                    {new Date(transaction.createdAt).toLocaleString()}
                  </span>

                  <div className="user-inline-items">
                    <div style={{ padding: "none", paddingRight: "10%" }}>
                      <span
                        style={{ marginTop: "20px" }}
                        className="trans-amount"
                      >
                        NGN{" "}
                        {Number(transaction.interest_recieved).toLocaleString()}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </Drawer>
  );
}
