import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Menu,
  MenuItem,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { connect } from "react-redux";
import "../../../../css/dashboard.css";
import transactions from "../../../../services/transactionService";
import NewPdf from "./new";
import "bootstrap-daterangepicker/daterangepicker.css";
import "react-dates/initialize";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "date-fns";
import LoadingOverlay from "react-loading-overlay";
import Pagination from "./Pagination/Pagination";
import config from "../../../../config";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  large: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  txnLogo: {
    height: "25px",
    position: "absolute",
    marginLeft: "-50px",
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      marginLeft: "0px",
    },
  },
  small: {
    fontSize: "12px",
    marginTop: theme.spacing(-2),
    marginLeft: "2px",
  },
  ref: {
    marginTop: theme.spacing(-0.5),
    fontSize: "12px",
    marginLeft: "2px",
    fontWeight: "bolder",
  },
  name: {
    fontSize: "11px",
    fontWeight: "bolder",
    marginLeft: "2px",
  },
  amount: {
    fontWeight: "700",
  },
  success: {
    color: "#00e600",
    fontSize: "12px",
    textAlign: "right",
    fontWeight: "500",
  },
  fee: {
    color: "red",
    textAlign: "right",
    fontWeight: "500",
  },
  text: {
    fontSize: "13px",
  },
}));

function HistoryTab(props) {
  const classes = useStyles();
  const baseUrl = `${config.apiUrl}`;

  const [sort, setSort] = useState(null);
  const [sortHighest, setSortHighest] = useState(null);
  const [sortLowest, setSortLowest] = useState(null);
  const [sortOldest, setSortOldest] = useState(null);
  const [sortRecent, setSortRecent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  // Change page
  function handlePageClick(data) {
    props.get_transactions(data.selected + 1);
    setCurrentPage(data.selected);
  }

  async function downloadStatement() {
    const response = await fetch(`${baseUrl}/user_account/statement`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        account_number: localStorage.getItem("account"),
        start_date: start,
        end_date: end,
        return_pdf: true,
      }),
    });

    response.blob().then((blob) => {
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = url;
      a.download = "account_statement.pdf";
      document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
      a.click();
      a.remove(); //afterwards we remove the element again
    });
  }

  const handleRange = (event, picker) => {
    props.get_transactions_filter(picker.startDate, picker.endDate);
    props.get_account_statement(picker.startDate, picker.endDate);
    setStart(picker.startDate);
    setEnd(picker.endDate);
  };

  const handleClick = (event) => {
    setSort(event.currentTarget);
  };

  const handleClose = () => {
    setSort(null);
  };

  // Sort by highest amount
  const sortByHighestAmount = () => {
    setSortHighest(
      props.transaction.sort(
        (a, b) => b.transactionAmount - a.transactionAmount
      )
    );
  };

  // Sort by lowest amount
  const sortByLowestAmount = () => {
    setSortLowest(
      props.transaction.sort(
        (a, b) => a.transactionAmount - b.transactionAmount
      )
    );
  };

  // Sort by oldest date
  const sortByOldestDate = () => {
    setSortOldest(
      props.transaction.sort((a, b) =>
        a.transactionDate > b.transactionDate
          ? 1
          : a.transactionDate < b.transactionDate
          ? -1
          : 0
      )
    );
  };

  // Sort by most recent date
  const sortByRecentDate = () => {
    setSortRecent(
      props.transaction.sort((a, b) =>
        a.transactionDate < b.transactionDate
          ? 1
          : a.transactionDate > b.transactionDate
          ? -1
          : 0
      )
    );
  };

  useEffect(() => {
    props.get_transactions(currentPage);
  }, []);

  return (
    <div className={classes.root}>
      <div>
        <Grid container className="history" spacing={0}>
          <Grid item md={3} lg={3}>
            <h4>Transaction History</h4>
          </Grid>
          <Grid item md={2}>
            <DateRangePicker onApply={handleRange} className={classes.picker}>
              <Button
                style={{ width: "100%", height: "35px", marginBottom: "10px" }}
                fullWidth
                variant="contained"
                size="small"
                color="primary"
                className="loan-button"
              >
                Date Filter
              </Button>
            </DateRangePicker>
          </Grid>
          <Grid item md={2} lg={2}>
            {props.statement !== undefined ? (
              <div>
                <Button
                  onClick={downloadStatement}
                  style={{
                    width: "100%",
                    height: "35px",
                    marginBottom: "10px",
                  }}
                  fullWidth
                  variant="contained"
                  size="small"
                  color="primary"
                  className="new-btn"
                >
                  Download Statement!
                </Button>
              </div>
            ) : (
              ""
            )}
          </Grid>
          <Grid container className="item4">
            <Grid item sm={3} md={6} lg={6}>
              <span className="arrange2">
                <img
                  src="/images/filter-icon.svg"
                  className="filter-icon"
                  alt="filter"
                />
                <span className={classes.text}>All transactions</span>
              </span>
            </Grid>

            <Grid item sm={3} md={6} lg={6}>
              <span className="arrange2" onClick={handleClick}>
                <img
                  src="/images/sort-icon.svg"
                  className="filter-icon"
                  alt="sort"
                />
                <span className={classes.text}>Sort by</span>
              </span>
              <Menu
                anchorEl={sort}
                keepMounted
                open={Boolean(sort)}
                onClose={handleClose}
              >
                <MenuItem onClick={sortByLowestAmount}>Lowest Amount</MenuItem>
                <MenuItem onClick={sortByHighestAmount}>
                  Highest Amount
                </MenuItem>
                <MenuItem onClick={sortByOldestDate}>
                  Oldest Transaction
                </MenuItem>
                <MenuItem onClick={sortByRecentDate}>
                  Most Recent Transaction
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Grid>
        <div style={{ textAlign: "right" }}>
          <Pagination
            handlePageClick={handlePageClick}
            totalItems={localStorage.getItem("totalTxn")}
          />
        </div>
        {props?.isFetching ? (
          <LoadingOverlay
            className="his-over"
            active={props.isFetching}
            spinner
            text="Please wait, while we fetch your transactions .....😜"
          >
            {""}
          </LoadingOverlay>
        ) : (
          <>
            {props.transaction &&
              props?.transaction.map((transaction, i) => (
                <div
                  key={i}
                  className={
                    transaction.transactionStatus === "TS_COMPLETED" ||
                    transaction.transactionStatus === "TS_PAID" ||
                    transaction.transactionStatus === "WAIVED"
                      ? "history-table2"
                      : transaction.transactionStatus === "TS_FAILED"
                      ? "history-table"
                      : transaction.transactionStatus === "TS_PENDING"
                      ? "history-table3"
                      : ""
                  }
                >
                  {transaction.transactionType === "debit" ||
                  transaction.transactionType === "DEBIT" ? (
                    <span className="deb1" style={{ display: "inline-block" }}>
                      {transaction.transactionType}
                    </span>
                  ) : (
                    <span className="deb2">{transaction.transactionType} </span>
                  )}

                  {transaction.transactionStatus === "TS_COMPLETED" ? (
                    <div style={{ display: "inline-block", float: "right" }}>
                      <p
                        className="card-success-dot"
                        style={{ display: "inline-block", float: "left" }}
                      ></p>
                      <span
                        className="deb2"
                        style={{ display: "inline-block", float: "right" }}
                      >
                        PAID
                      </span>
                    </div>
                  ) : transaction.transactionStatus === "TS_PENDING" ? (
                    <div style={{ display: "inline-block", float: "right" }}>
                      <p
                        className="card-pending-dot"
                        style={{ display: "inline-block", float: "left" }}
                      ></p>
                      <span
                        className="deb3"
                        style={{ display: "inline-block", float: "right" }}
                      >
                        PENDING
                      </span>
                    </div>
                  ) : transaction.transactionStatus === "TS_FAILED" ? (
                    <div style={{ display: "inline-block", float: "right" }}>
                      <span
                        className="card-dot"
                        style={{ display: "inline-block", float: "left" }}
                      ></span>
                      <span
                        className="deb1"
                        style={{ display: "inline-block", float: "right" }}
                      >
                        FAILED
                      </span>
                    </div>
                  ) : transaction.transactionStatus === "TS_PAID" ? (
                    <div style={{ display: "inline-block", float: "right" }}>
                      <span
                        className="card-success-dot"
                        style={{ display: "inline-block", float: "left" }}
                      ></span>
                      <span
                        className="deb2"
                        style={{ display: "inline-block", float: "right" }}
                      >
                        PAID
                      </span>
                    </div>
                  ) : transaction.transactionStatus === "WAIVED" ? (
                    <div style={{ display: "inline-block", float: "right" }}>
                      <span
                        className="card-success-dot"
                        style={{ display: "inline-block", float: "left" }}
                      ></span>
                      <span
                        className="deb2"
                        style={{ display: "inline-block", float: "right" }}
                      >
                        FEE WAIVED
                      </span>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="user-inline-items">
                    <div style={{ padding: "none", paddingRight: "10%" }}>
                      <span
                        style={{ marginTop: "20px" }}
                        className="trans-amount"
                      >
                        {transaction.transactionStatus !== "WAIVED" ? (
                          <>
                            NGN{" "}
                            {transaction.transactionType === "debit" ||
                            transaction.transactionType === "DEBIT" ? (
                              <span
                                className="deb1"
                                style={{ fontWeight: "900", fontSize: "20px" }}
                              >
                                -
                              </span>
                            ) : (
                              ""
                            )}
                            {Number(
                              transaction.transactionAmount
                            ).toLocaleString()}
                          </>
                        ) : (
                          <span
                            style={{
                              color: "grey",
                              fontWeight: "900",
                              fontSize: "17px",
                            }}
                          >
                            NGN -
                            {Number(
                              transaction.transactionAmount
                            ).toLocaleString()}
                          </span>
                        )}
                      </span>
                    </div>

                    <div>
                      <div>
                        <p className={classes.name} style={{ margin: "0px" }}>
                          {transaction.narration}{" "}
                        </p>
                        <img
                          alt="logo"
                          src={`${transaction.recipientInstitutionLogoUrl}`}
                          className={classes.txnLogo}
                        />
                        <div>
                          <p className={classes.name}>
                            {transaction.recipientInstitutionName} |{" "}
                            {transaction.recipientNumber}
                          </p>
                          <p className={classes.small}>
                            {transaction.recipientName}{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <span className={classes.small}>
                        {new Date(transaction.transactionDate).toLocaleString()}
                      </span>
                      <p className={classes.ref}>
                        {" "}
                        {transaction.transactionRef}
                      </p>
                    </div>
                  </div>

                  {(transaction.transactionStatus !== "TS_PENDING" &&
                    transaction.transactionStatus !== "TS_FAILED" &&
                    transaction.serviceCode === "SER_002_TB") ||
                  transaction.serviceCode === "SER_005_BP" ||
                  transaction.serviceCode === "SER_020_OB" ||
                  transaction.serviceCode === "SER_019_AT" ||
                  transaction.serviceCode === "SER_001_TP" ? (
                    <NewPdf data={transaction} />
                  ) : (
                    ""
                  )}
                </div>
              ))}
          </>
        )}

        <div>
          {loading ? (
            <CircularProgress style={{ fontSize: "30px", color: "#0037ba" }} />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    transaction: state.fetch.data,
    isFetching: state.fetch.isFetching,
    statement: state.accountStatement.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_transactions_filter: (fromDateString, toDateString) =>
      dispatch(
        transactions.get_transactions_filter(fromDateString, toDateString)
      ),
    get_account_statement: (startDate, endDate) =>
      dispatch(transactions.get_account_statement(startDate, endDate)),
    get_transactions: (pageNumber) =>
      dispatch(transactions.get_transactions(pageNumber)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HistoryTab);
