import axios from 'axios';
import config from '../config';
import { userActions } from "../actions/creators.js";
import headers from '../store/authHeader'
import swal from "sweetalert";
import { history } from "../store/history";

const baseUrl = `${config.apiUrl}`;

const loan = {
    // Check loan eligibility for a particular user
    check_eligibility: (payload) => {
        const user_id = localStorage.getItem('user_id');

        const amount = payload.replace(/,/g, "");
       

        return async dispatch => {
            dispatch(userActions.checkLoanEligibilityRequest());

            await axios.post(`${baseUrl}/loan/loans/check-if-user-can-apply`, { user_id, amount }, { "headers": headers })
                .then(res => {
                    dispatch(userActions.checkLoanEligibilitySuccess(res.data));
                })
                .catch(error => {

                    dispatch(userActions.checkLoanEligibilityFailure(error.response.data));
                });
        }
    },

    //  // Calculate payment
    calculate_payment: (newAmount, tenure) => {
        const user_id = localStorage.getItem('user_id');
        const account_number = localStorage.getItem('account');

        const amount = newAmount.replace(/,/g, "");

        return async dispatch => {
            dispatch(userActions.calculateLoanRequest());

            axios.post(`${baseUrl}/loan/loans/calculate`, { user_id, amount, account_number, tenure }, { "headers": headers })
                .then(res => {

                    dispatch(userActions.calculateLoanSuccess(res.data));
                })
                .catch(error => {

                    //  dispatch(userActions.checkLoanEligibilityFailure(error.response.data));
                });
        }
    },

    //   Apply
    apply: (newAmount, tenure) => {
        const user_id = localStorage.getItem('user_id');
        const account_number = localStorage.getItem('account');
        const amount = newAmount.replace(/,/g, "");
        return async dispatch => {
            //  dispatch(userActions.checkLoanEligibilityRequest());
            

            axios.post(`${baseUrl}/loan/loans`, { user_id, amount, account_number, tenure, reason: 'loan' }, { "headers": headers })
                .then(res => {
                    dispatch(userActions.uiFetched(res.data.data));
                    swal("Great!", "Loan application successful. Please check your email for further details!", "success");
                    history.push("/dashboard")
                })
                .catch(error => {

                    const errorMessage = error.response.data.response_message
                    if (error.response.data.response_error === 'Invalid Tenure') {
                        swal({
                            title: "Oops!",
                            text: errorMessage,
                            icon: "error",
                            button: "Oh Oh!",
                        });
                    } else if(error.response.data.response_code === 'US035'){
                        swal({
                            title: "Oops!",
                            text: 'This is a first time loan. Please apply for this loan on our mobile application. Thank you!',
                            icon: "error",
                            button: "Okay!",
                        });
                    }
                    //  dispatch(userActions.checkLoanEligibilityFailure(error.response.data));
                });
        }
    },
};

export default loan;

