import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Container,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@material-ui/core";
import { history } from "../../../store/history";
import TopBar from "../../../components/TopBar";
import {
  MainContent,
  SmallCardBills,
  Footer,
  CardHeaderNew,
} from "../../../components/Appearance";
import SideNav from "../../../components/SideNav";
import { connect } from "react-redux";
import BottomNav from "../../../components/bottomNavigation";
import Select from "react-select";
import numeral from "numeral";
import BackDrop from "../../../components/BackDrop";
import "../../../css/solomon.css";
import "../../../css/dashboard.css";
import bills from "../../../services/billPaymentService";
import transactions from "../../../services/transactionService";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  HeaderBar,
  CardHeader,
  InputGrid,
  CardBody,
  BillsSuccessCard,
} from "../../../components/Appearance";
import ReceiptModal from "./receiptModal";
import { Helmet } from "react-helmet";

function PayBills(props) {
  const [toggle, setToggle] = useState(true);
  const [sidenav, setSidenav] = useState("getri-sidenav");
  const [selectedOption, setSelectedOption] = useState(null);
  let [selectAmount, setSelectAmount] = useState("");
  let [selectCode, setSelectCode] = useState("");
  let [name, setName] = useState("");
  let [categoryid, setCategoryid] = useState("");
  let [inputAmount, setInputAmount] = useState("");
  const [newAmt, setnewAmt] = useState("");
  const [success, setSuccess] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [inputdisabled, setInputdisabled] = useState(true);
  const [display, setDisplay] = useState(false);
  const [overlay, setOverlay] = useState(false);
  const [receipt, setReceipt] = useState(false);

  const id = props.match.params.slug;

  const pic = props.match.params.logo;

  const label = props.match.params.tag;

  const categoryname = props.match.params.cat;

  const itemName = props.match.params.name;

  const url = "https://www.quickteller.com/images/Downloaded/";
  const [completeUrl] = useState(url + pic);

  const [bills, setBills] = useState({
    paymentCode: "",
    customerId: "",
    amount: "",
    userId: props.user.id,
    account: props?.balance?.account_number,
    institutionName: "",
    logo: completeUrl,
    pin: "",
    recipientName: "",
  });
  const {
    paymentCode,
    customerId,
    amount,
    userId,
    account,
    institutionName,
    logo,
    pin,
  } = bills;

  useEffect(() => {
    setBills({
      paymentCode: selectCode,
      customerId: bills.customerId,
      amount: selectAmount ? `${selectAmount}` : newAmt,
      userId: props.user.id,
      account: props?.balance?.account_number,
      institutionName: itemName + "-" + " " + name,
      logo: completeUrl,
      pin: "",
      recipientName: props.customerDetails.fullName
        ? props.customerDetails.fullName
        : bills.customerId,
    });
  }, [props, selectAmount, selectCode, name, customerId, newAmt]);

  const handleToggle = () => {
    const mediaScreen = window.matchMedia(
      `(max-width: 700px),
      (min-device-width: 768px)
      and (max-device-width: 1024px)`
    ); //returns true if

    if (mediaScreen.matches) {
      setSidenav("slideOut");
    } else {
      setToggle(!toggle);
    }
  };
  const hideSidebar = () => {
    setSidenav("slideIn");
  };

  useEffect(() => {
    props.get_biller_product(id);
    props.get_account_balance();
    if (categoryname === "cat003" || categoryname === "cat004") {
      props.get_airtime_data_fee();
    } else {
      props.get_other_fee();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const options = props.billerProduct.map((billerProduct, i) => ({
    label: billerProduct.payment_item_name,
    value: billerProduct.amount,
    code: billerProduct.payment_code,
    name: billerProduct.payment_item_name,
    categoryid: billerProduct.biler_ref,
    inputAmount: billerProduct.amount,
    key: i,
  }));

  // Number formatter
  var valueDisplay = numeral(amount).format();
  const newPrice = valueDisplay.replace(/,/g, "");
  // var valDis = numeral(newAmt).format();

  function handleChangeAmount(selectedOption) {
    setSelectedOption(selectedOption);
    setSelectAmount((selectAmount = selectedOption.value));
    setSelectCode((selectCode = selectedOption.code));
    setName((name = selectedOption.name));
    setCategoryid((categoryid = selectedOption.categoryid));
    setInputAmount((inputAmount = selectedOption.inputAmount));
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setBills((bills) => ({ ...bills, [name]: value }));
  }

  function handleNewChange(e) {
    setnewAmt(e.target.value);
  }

  const validateForm = () => {
    if (bills.customerId === 6 || inputdisabled != true) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };

  const validateBill = () => {
    if (categoryname !== "cat003" && categoryname !== "cat004") {
      props.validate_bill(customerId, paymentCode);
    }
  };

  let msg = props.purchasemsg ? props.purchasemsg.response_code : "";

  function changeSuccess() {
    if (msg == "00") {
      setSuccess(true);
      setOverlay(false);
    } else {
      setSuccess(false);
    }
  }
  useEffect(() => {
    changeSuccess();
    setDisplay(true);
    validateForm();
  }, [props.purchasemsg, selectAmount]); // eslint-disable-line react-hooks/exhaustive-deps

  function handleOverlay() {
    if (customerId && paymentCode) {
      setOverlay(true);
    }
  }

  function closeOverlay() {
    setOverlay(false);
  }

  function closeReceipt() {
    setReceipt(false);
  }

  function handleSubmit() {
    setDisabled(false);
    if (
      paymentCode &&
      customerId &&
      amount &&
      userId &&
      account &&
      institutionName
    ) {
      props.make_payment(bills);
    }
  }

  const result = props.fee? Number(newPrice) +  Number(props.fee) : Number(newPrice) ;
  const displayFee = props.fee ? props.fee : "0";

  const pass = props.purchasemsg
    ? props.purchasemsg.data.responseCodeGrouping
    : "";

  function showSuccessCard() {
    let showCard;
    if (success) {
      showCard = (
        <BillsSuccessCard
          openReceipt={() => setReceipt(true)}
          topic={props.purchasemsg ? pass : ""}
          data={props?.purchasemsg?.data}
        />
      );
    } else {
      showCard = (
        <div className="card" style={{ display: display ? "block" : "none" }}>
          <CardHeader info="Make Payment" />
          <div className="wrapper">
            <ArrowBackIcon onClick={closeOverlay} className="arrow1" />
            <h6 onClick={closeOverlay} className="arrow2">
              BACK
            </h6>
            <CardBody className="card1">
              <SmallCardBills
                img={completeUrl}
                amount={newPrice + " " + "+" + " " + displayFee}
                item1={name}
                item2={
                  props.customerDetails.fullName
                    ? props.customerDetails.fullName
                    : bills.customerId
                }
              />
            </CardBody>
          </div>
          <div className="wrapper">
            <CardBody className="card2" cardstyle={{ padding: "1em 1em 1em" }}>
              <form>
                <InputGrid>
                  <Select
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        "&:hover": { borderColor: "black" },
                        border: "none",
                        borderRadius: "none",
                        borderBottom: "1px solid black",
                        boxShadow: "none",
                      }),
                    }}
                    placeholder="Select Account"
                    value={selectedOption}
                    options={options}
                    isDisabled={true}
                  />
                </InputGrid>
                <InputGrid>
                  <label>Amount</label>

                  <input
                    type="text"
                    name="amount"
                    value={result}
                    onChange={handleChange}
                    disabled
                    className="input-div"
                  />
                </InputGrid>

                <InputGrid>
                  {categoryid === "2" ? (
                    <label style={{ marginBottom: "20px", marginTop: "10px" }}>
                      Smart Card Number
                    </label>
                  ) : categoryid === "1" ? (
                    <label style={{ marginBottom: "20px", marginTop: "10px" }}>
                      Metre Number
                    </label>
                  ) : categoryid === "3" || categoryid === "4" ? (
                    <label style={{ marginBottom: "20px", marginTop: "10px" }}>
                      Phone Number
                    </label>
                  ) : (
                    <label style={{ marginBottom: "20px", marginTop: "10px" }}>
                      Customer ID
                    </label>
                  )}

                  <input
                    name="customerId"
                    value={customerId}
                    onChange={handleChange}
                    disabled
                    className="input-div"
                  />
                </InputGrid>

                {pin.length > 4 ? (
                  <p style={{ color: "red", fontSize: "12px" }}>
                    Pin must be 4 digits
                  </p>
                ) : (
                  ""
                )}
                <InputGrid>
                  <TextField
                    onChange={handleChange}
                    name="pin"
                    disabled={!overlay}
                    value={pin}
                    className="input-div"
                    required
                    label="Please enter your 4 digit pin"
                    type="password"
                  />
                </InputGrid>
                <Button
                  onClick={handleSubmit}
                  fullWidth
                  variant="contained"
                  size="small"
                  color="primary"
                  className="getri-button"
                  disabled={!overlay}
                  style={{
                    backgroundColor: !overlay ? "" : "#0037ba",
                    color: "#fff",
                  }}
                >
                  Pay
                </Button>
              </form>
            </CardBody>
          </div>
        </div>
      );
    }
    return showCard;
  }

  return (
    <>
      <Helmet>
        <title>GetriPay - Fastest growing digital bank with rewards </title>
        <meta name="description" content="Pay for bills" />
      </Helmet>
      {props.isGettingProduct || props.isPaying || props.isValidatingBill ? (
        <BackDrop />
      ) : (
        ""
      )}
      <ReceiptModal
        openMod={receipt}
        reference={
          props.purchasemsg ? props.purchasemsg.data.transactionRef : ""
        }
        pin={props.purchasemsg ? props.purchasemsg.data.miscData : ""}
        //subPin = {props.purchasemsg ? props.purchasemsg.data.rechargePin : ''}
        closeMod={() => {
          closeReceipt();
        }}
        customer={bills.customerId}
        img={completeUrl}
        amount={bills.amount}
        institution={itemName}
        account={bills.account}
        accountName={props.balance.account_name}
      />
      <TopBar handleToggle={handleToggle} />
      <SideNav
        toggle={toggle}
        className={sidenav}
        hideSidebar={hideSidebar}
        {...props}
      />
      <MainContent toggle={toggle}>
        <main className="main">
          <Grid style={{ height: "100%" }}>
            <Grid item xs={12} md={12} lg={12} style={{ height: "inherit" }}>
              <Container className="main-container">
                <HeaderBar
                  topic={`PayBills - ${itemName}`}
                  text={Number(
                    props.balance.available_balance
                  ).toLocaleString()}
                />
              </Container>
              <Grid container style={{ padding: "1px 0" }}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={6}
                  className={!overlay ? "card-container" : "notcard"}
                  style={{ height: "100vh" }}
                >
                  <div className="card" style={{ height: "100%" }}>
                    <div className={!overlay ? "" : "overlay"}></div>
                    <CardHeaderNew
                      info={
                        props.user.kyc_level === 1
                          ? "Max purchase - NGN10,000"
                          : props.user.kyc_level === 2
                          ? "Max purchase - Unlimited"
                          : "Max purchase - Unlimited"
                      }
                    />
                    <CardBody
                      cardstyle={{ padding: "1em 1em 1em", width: "300px" }}
                      className="card2"
                    >
                      <form>
                        <InputGrid>
                          <Select
                            styles={{
                              control: (base, state) => ({
                                ...base,
                                "&:hover": { borderColor: "black" },
                                border: "none",
                                borderRadius: "none",
                                borderBottom: "1px solid black",
                                boxShadow: "none",
                              }),
                            }}
                            name="amount"
                            placeholder="Select Option"
                            value={selectedOption}
                            onChange={handleChangeAmount}
                            options={options}
                            isDisabled={!inputdisabled}
                          />
                        </InputGrid>

                        <InputGrid>
                          {label === "Customer A" ? (
                            <label
                              style={{
                                marginBottom: "20px",
                                marginTop: "10px",
                              }}
                            >
                              Customer A/C No
                            </label>
                          ) : (
                            <label
                              style={{
                                marginBottom: "20px",
                                marginTop: "10px",
                              }}
                            >
                              {label}
                            </label>
                          )}

                          <input
                            // onKeyDown={validateBill}
                            onBlur={validateBill}
                            disabled={!inputdisabled}
                            name="customerId"
                            value={customerId}
                            onChange={handleChange}
                            className="input-div"
                          />
                        </InputGrid>

                        {inputAmount === 0 ? (
                          <InputGrid>
                            <div>
                              <label
                                style={{
                                  marginBottom: "20px",
                                  marginTop: "10px",
                                }}
                              >
                                Please enter an amount
                              </label>

                              <input
                                type="text"
                                placeholder="Amount"
                                // disabled={!inputdisabled || props.isGettingFee || props.isValidatingBill}
                                name="newAmt"
                                value={valueDisplay}
                                onChange={handleNewChange}
                                className="input-div"
                              />
                            </div>
                          </InputGrid>
                        ) : (
                          ""
                        )}

                        <Grid container spacing={1} alignItems="flex-end">
                          <Grid item xs={12} md={6} lg={6}></Grid>
                          <Grid item xs={12} md={12} lg={12}>
                            <Button
                              onClick={handleOverlay}
                              fullWidth
                              variant="contained"
                              size="small"
                              color="primary"
                              className="getri-button submit"
                              disabled={overlay === true}
                            >
                              Continue
                            </Button>
                          </Grid>
                        </Grid>
                      </form>
                    </CardBody>
                  </div>
                </Grid>
                <Grid
                  className="card-container"
                  item
                  xs={12}
                  md={6}
                  lg={6}
                  style={{ backgroundColor: "#f6f6f7" }}
                >
                  {showSuccessCard()}
                </Grid>
              </Grid>
            </Grid>
            <div>
              <Dialog
                open={props.user.transaction_pin === null}
                // onClose={() => setIdleModal(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title" style={{ color: "red" }}>
                  TRANSACTION PIN
                </DialogTitle>

                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Set transcation pin before proceeding with your transaction
                  </DialogContentText>
                </DialogContent>

                <DialogActions>
                  <Button
                    variant="contained"
                    size="small"
                    style={{
                      backgroundColor: "#0037ba",
                      color: "white",
                      width: "20%",
                      height: "30px",
                      marginBottom: "10px",
                      borderRadius: "20px",
                      border: "2px solid #0037ba",
                      boxShadow: "none",
                    }}
                    onClick={() => history.push("/settings")}
                  >
                    Set Pin
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </Grid>
          <BottomNav {...props} />
          <Footer />
        </main>
      </MainContent>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    get_biller_product: (id) => dispatch(bills.get_biller_product(id)),
    make_payment: (payload) => dispatch(bills.make_payment(payload)),
    validate_bill: (customerId, paymentCode) =>
      dispatch(bills.validate_bill(customerId, paymentCode)),
    get_account_balance: (payload) =>
      dispatch(transactions.get_account_balance(payload)),
    clear: () => dispatch(bills.clear_success()),
    get_airtime_data_fee: () => dispatch(bills.get_airtime_data_fee()),
    get_other_fee: () => dispatch(bills.get_other_fee()),
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.userDetails.data,
    purchasemsg: state.payBill.data,
    isGettingProduct: state.getBillerProduct.isGettingProduct,
    isPaying: state.payBill.isPaying,
    billerProduct: state.getBillerProduct.data,
    isValidatingBill: state.validateBill.isValidatingBill,
    customerDetails: state.validateBill.data,
    validatefailure: state.validateBill.success,
    purchasefailure: state.payBill.success,
    productfailure: state.getBillerProduct.success,
    billerItem: state.getBillerItem.data,
    balance: state.getAccountBalance.data,
    fee: state.transactionFee.data,
    isGettingFee: state.transactionFee.isGettingFee,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PayBills);
