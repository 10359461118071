import { userConstants } from "../actions/types";

const initialState = {
    isVerifyingBvn: false,
    data: '',
};

function verifyBvn(state = initialState, action) {
    switch (action.type) {
        case userConstants.VERIFY_BVN_REQUEST:
            return {
                ...state,
                isVerifyingBvn: true,
            };
        case userConstants.VERIFY_BVN_SUCCESS:
            return {
                ...state,
                isVerifyingBvn: false,
                data: action.payload, 
            };
        case userConstants.VERIFY_BVN_FAILURE:
            return {
                ...state,
                isVerifyingBvn: false,
            };
        default:
            return state;
    }
}

export default verifyBvn;
