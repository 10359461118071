import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Container, Grid, Tabs, Tab, Button } from "@material-ui/core";
import "../../../css/navbar.css";
import "../../../css/UserProfile.css";
import { connect } from "react-redux";
import TopBar from "../../../components/TopBar";
import { MainContent, Footer } from "../../../components/Appearance";
import SideNav from "../../../components/SideNav";
import BottomNav from "../../../components/bottomNavigation";
import "../../../css/dashboard.css";
import transactions from "../../../services/transactionService";
import ProfileNav from "./components/ProfileNav";
import AccountCard from "./components/AccountCard";
import PhysicalCard from "./components/PhysicalCard";
import VirtualCard from "../../../components/DashboardCards/VirtualCard";
import LoanCard from "./components/LoanCard";
import TransactionHistoryCard from "./components/TransactionHistoryCard";
import TransferCard from "./components/TransferCard";
import ProfileDetails from "./components/ProfileDetails";
import HistoryTab from "./components/HistoryTab";
import LoanHistoryTab from "./components/LoanHistoryTab";
import BeneficiaryTab from "./components/BeneficiaryTab";
import physicalCard from "../../../services/physicalCardService";
import { Helmet } from "react-helmet";
import upgradeKyc from "../../../services/upgradeAccountService";
import virtualCard from "../../../services/virtualCardService";
import VirtualTransactions from "./components/vCardTransTab";
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";
import BulkHistoryTab from "./components/BulkHistoryTab";
import newAccounts from "../../../services/newAccountService";
import interest from "../../../services/interestService";
import EarningsCard from "../../../components/DashboardCards/EarningsCard";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    height: "100vh",
    width: "100%",
    backgroundColor: "white",
  },
  container: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(4),
    height: "auto",
    width: "100vw",
    backgroundColor: "white",
  },
  large: {
    width: 120,
    height: 120,
  },
  tabContainer: {
    borderRadius: "5px",
    border: "2px solid #F0F0F0",
    marginLeft: "10px",
    marginRight: "10px",
  },
  cardSpace: {
    marginTop: "9%",
  },
}));

const AccountOverViewTabs = withStyles({
  root: {
    backgroundColor: "white",
  },
  indicator: {
    backgroundColor: "#00e600",
  },
})(Tabs);

const AccountOverViewTab = withStyles((theme) => ({
  root: {
    fontFamily: "CarmenSans-Bold",
    backgroundColor: theme.palette.background.paper,
    fontSize: "13px",
    fontWeight: "600",
    textTransform: "none",
    maxWidth: "80%",
    padding: "6px 10px",
    textAlign: "-webkit-center",
    flexDirection: "container",
    justifyContent: "space-around",
    "&:hover": {
      color: "black",
      opacity: 1,
    },
    "&$selected": {
      color: "black",
      fontWeight: "bold",
      borderBottom: "4px solid #00e600",
      textAlign: "-webkit-center",
    },
    "&:focus": {
      color: "black",
      fontWeight: "bold",
      borderBottom: "4px solid #00e600",
      textAlign: "-webkit-center",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

function Dashboard(props) {
  const classes = useStyles();
  const [toggle, setToggle] = useState(true);
  const [sidenav, setSidenav] = useState("getri-sidenav");
  const [showTran, setShowTran] = useState(false);
  const [showBox, setShowBox] = useState(true);

  function show() {
    setShowBox(false);
    setShowTran(true);
  }

  function dontShow() {
    setShowBox(true);
    setShowTran(false);
  }

  const handleToggle = () => {
    const mediaScreen = window.matchMedia(
      `(max-width: 700px),
        (min-device-width: 768px)
        and (max-device-width: 1024px)`
    ); //returns true if

    if (mediaScreen.matches) {
      setSidenav("slideOut");
    } else {
      setToggle(!toggle);
    }
  };
  const hideSidebar = () => {
    setSidenav("slideIn");
  };

  const id = props.user.id;
  const token = props.user.token;
  const acc = props.balance?.account_number;

  const card_id = props?.userVCard?.data ? props?.userVCard?.data?.id : "";
  const match = useRouteMatch();

  useEffect(() => {
    props.get_loan_history();
    props.get_transaction_count();
    props.get_account_balance();
    props.get_transaction_limit();
    props.document_status(token);
    props.get_user_virtual_card(id, token);
    props.get_new_accounts(id);
    props.get_earnings(acc);
    props.get_interest_history(1, acc);
    //	props.get_card_status(id, token)
  }, [card_id]); // eslint-disable-line react-hooks/exhaustive-deps

  if (props.data.success === true) {
    setTimeout(() => {
      props.mask_pin();
    }, 180000);
  }
  // Time out for the virtual masked pan set to 3 minutes

  useEffect(() => {
    props.virtual_card_transactions(id, card_id, token);
  }, [card_id]);

  return (
    <>
      <Helmet>
        <title>GetriPay - Fastest growing digital bank with rewards </title>
        <meta name="description" content="Account overview" />
        <meta name="keywords" content="profile, account overview" />
      </Helmet>
      <TopBar handleToggle={handleToggle} />
      <SideNav
        toggle={toggle}
        className={sidenav}
        hideSidebar={hideSidebar}
        {...props}
      />
      <MainContent toggle={toggle}>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Grid container>
            <Grid item xs={12} md={12} lg={12}>
              <Container maxWidth="lg" className={classes.container}>
                {/* Body of dashboard */}
                <Grid item xs={12} md={12} lg={12}>
                  <ProfileNav props={props} />
                  {/* 1st row */}
                  <Grid container spacing={3} className="mb">
                    <Grid item xs>
                      <ProfileDetails props={props} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={9}>
                      {/* <div > */}
                      <div position="static" className={classes.tabContainer}>
                        <AccountOverViewTabs
                          value={props.location.pathname}
                          textColor="primary"
                          variant="scrollable"
                          scrollButtons="auto"
                          aria-label="scrollable auto tabs example"
                          className="tabsOverview"
                        >
                          <AccountOverViewTab
                            component={Link}
                            to={`${match.url}`}
                            value={`${match.url}`}
                            label="Account Summary"
                          />
                          <AccountOverViewTab
                            component={Link}
                            to={`${match.url}/history`}
                            value={`${match.url}/history`}
                            label="History"
                          />
                          <AccountOverViewTab
                            component={Link}
                            to={`${match.url}/bulk-transactions`}
                            value={`${match.url}/bulk-transactions`}
                            label="Bulk Transactions"
                          />
                          <AccountOverViewTab
                            component={Link}
                            to={`${match.url}/beneficiaries`}
                            value={`${match.url}/beneficiaries`}
                            label="Beneficiaries"
                          />

                          <AccountOverViewTab
                            component={Link}
                            to={`${match.url}/cards`}
                            value={`${match.url}/cards`}
                            label="Cards"
                          />
                          <AccountOverViewTab
                            component={Link}
                            to={`${match.url}/loans`}
                            value={`${match.url}/loans`}
                            label="Loans"
                          />
                        </AccountOverViewTabs>
                      </div>

                      <Grid container spacing={0}>
                        <Switch>
                          <Route path={`${match.url}/history`}>
                            <div>
                              <HistoryTab props={props} />
                            </div>
                          </Route>

                          <Route
                            path={`${match.url}/bulk-transactions`}
                            render={() => (
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <div>
                                  <h2 className="history">
                                    Bulk Transactions history
                                  </h2>
                                  <BulkHistoryTab props={props} />
                                </div>
                              </Grid>
                            )}
                          />
                          <Route
                            path={`${match.url}/beneficiaries`}
                            render={() => (
                              <Grid item xs={12} sm={12} md={12} lg={12}>
                                <div>
                                  <h2 className="history">Beneficiaries</h2>
                                  <BeneficiaryTab props={props} />
                                </div>
                              </Grid>
                            )}
                          />

                          <Route path={`${match.url}/loans`}>
                            <div>
                              <LoanHistoryTab props={props} />
                            </div>
                          </Route>

                          <Route path={`${match.url}/cards`}>
                            <div style={{ width: "100%" }}>
                              <h2 className="history">Cards</h2>
                              <Button
                                variant="outlined"
                                color="primary"
                                style={{
                                  display: showTran ? "none" : "",
                                  marginBottom: "10px",
                                }}
                                onClick={show}
                              >
                                SHOW VIRTUAL CARD TRANSACTIONS
                              </Button>

                              <Grid
                                container
                                spacing={3}
                                style={{ display: showTran ? "none" : "" }}
                              >
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                  <VirtualCard props={props} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                  <PhysicalCard props={props} />
                                </Grid>
                              </Grid>
                              <VirtualTransactions
                                {...props.vCardTrans}
                                loading={props.isLoading}
                                show={showBox}
                                dont={dontShow}
                              />
                            </div>
                          </Route>
                          <Route path={`${match.url}`}>
                            <div>
                              <h2>Account Summary</h2>
                              <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                  <AccountCard props={props} />
                                  <TransactionHistoryCard props={props} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                  <div style={{ marginBottom: "30px" }}>
                                    <EarningsCard props={props} />
                                  </div>

                                  <TransferCard props={props} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6}>
                                  <LoanCard props={props} />
                                </Grid>
                              </Grid>
                            </div>
                          </Route>
                        </Switch>

                        {/* <Grid item xs={12} sm={12} md={6} lg={6}>
												<TabPanel value={value} index={2}>
													<h2 className='history'>Accounts</h2>
													<AccountCard props={props} />
												</TabPanel>
											</Grid> */}
                      </Grid>

                      {/* </div> */}
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            </Grid>
          </Grid>
          <BottomNav {...props} />
          <Footer />
        </main>
      </MainContent>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.userDetails.data,
    transaction: state.fetch.data,
    report: state.transactionCount.data,
    balance: state.getAccountBalance.data,
    limit: state.transactionLimit.data,
    loans: state.getLoanHistory.data,
    docList: state.upgradeAccount.status,
    cardStatus: state.getCardStatus.data,
    userVCard: state.getVirtualCard.userVCard,
    vCardTrans: state.virtualTrans.data,
    isLoading: state.virtualTrans.isLoading,
    data: state.confirmPin.data,
    accountRequest: state.getNewAccount.data,
    earnings: state.getEarnings.earnings,
    interestHistory: state.getInterestHistory.data,
    interestLoading: state.getInterestHistory.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_loan_history: (payload) =>
      dispatch(transactions.get_loan_history(payload)),
    get_transaction_count: (payload) =>
      dispatch(transactions.get_transaction_count(payload)),
    get_account_balance: (payload) =>
      dispatch(transactions.get_account_balance(payload)),
    get_transaction_limit: (payload) =>
      dispatch(transactions.get_transaction_limit(payload)),
    document_status: (token) => dispatch(upgradeKyc.document_status(token)),
    get_card_status: (id, token) =>
      dispatch(physicalCard.get_card_status(id, token)),
    get_user_virtual_card: (id, token) =>
      dispatch(virtualCard.get_user_virtual_card(id, token)),
    virtual_card_transactions: (id, card_id, token) =>
      dispatch(virtualCard.virtual_card_transactions(id, card_id, token)),
    mask_pin: () => dispatch(virtualCard.mask_pin()),
    clear_rate: () => dispatch(virtualCard.clear_rate()),
    get_new_accounts: (id) => dispatch(newAccounts.get_new_accounts(id)),
    get_earnings: () => dispatch(interest.get_earnings()),
    get_interest_history: (page) =>
      dispatch(interest.get_interest_history(page)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
