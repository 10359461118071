import { userConstants } from '../actions/types'

const initialState = {
    isGettingNotifications: false,
    data: '',
}
 
function getAllNotifications(state = initialState, action) {
    switch (action.type) {
        case userConstants.GET_ALL_NOTIFICATION_REQUEST:
            return {
                ...state,
                isGettingNotifications: true,
            
            };
        case userConstants.GET_ALL_NOTIFICATION_SUCCESS:
            return {
                ...state,
                isGettingNotifications: false,
                data: action.payload, 
                
                smsPro: action.payload.sms.PROMOTIONS,
                emailPro: action.payload.email.PROMOTIONS,
                pushPro: action.payload.push.PROMOTIONS,
                //appPro: action.payload.app.PROMOTIONS,

                smsAlert: action.payload.sms.ALERT,
                emailAlert: action.payload.email.ALERT,
                pushAlert: action.payload.push.ALERT,
                //appAlert: action.payload.app.ALERT,

                smsOtp: action.payload.sms.OTP,
                emailOtp: action.payload.email.OTP,
                pushOtp: action.payload.push.OTP,
                //appOtp: action.payload.app.OTP,

                smsLogin: action.payload.sms.LOGIN,
                emailLogin: action.payload.email.LOGIN,
                pushLogin: action.payload.push.LOGIN,
                //appLogin: action.payload.app.LOGIN

            };
        default:
            return state
    }
}

export default getAllNotifications;