import axios from 'axios';
import { userActions } from "../actions/creators";
import config from '../config';
import headers from '../store/authHeader';
import { history } from '../store/history'
import swal from "sweetalert";
import { toast, Flip } from 'react-toastify';

const baseUrl = `${config.apiUrl}`;

const bvnValidation = {
    // to submit a user's bvn
    verify_bvn: (validBvn, token) => {
        return async dispatch => {
            dispatch(userActions.verifyBvnRequest());

            const bvn_number = validBvn
            const user_id = localStorage.getItem('user_id');

            await axios.post(`${baseUrl}/user/validate-bvn`, { user_id, bvn_number }, {
                "headers": {
                    "Content-Type": 'application/json',
                    "Authorization": `Bearer ${token}`
                }
            })
                .then((res) => {
                    if (res.data.response_code === 'USM032') {
                        swal({
                            title: "Oh Oh!",
                            text: 'Your BVN has already been validated',
                            icon: "warning",
                            button: "Great!",
                        })
                        dispatch(userActions.verifyBvnFailure());
                        history.push('/dashboard')
                    } else if (res.data.response_code === 'USM039') {
                        swal({
                            title: "Oh Oh!",
                            text: 'BVN Validation Successful',
                            icon: "success",
                            button: "Great!",
                        })
                        dispatch(userActions.verifyBvnFailure());
                        history.push('/dashboard')
                    } else if (res.data.response_code === 'USM023') {
                        dispatch(userActions.verifyBvnSuccess(res.data));
                    } else {
                        dispatch(userActions.verifyBvnSuccess(res.data));
                    }

                })
                .catch((err) => {
                    dispatch(userActions.verifyBvnFailure());
                    toast.error("BVN validation failed. Please try again later", {
                        position: toast.POSITION.TOP_RIGHT,
                        transition: Flip,
                    })
                });
        }
    },

    verify_bvn_otp: (validate, token) => {
        return async dispatch => {

            dispatch(userActions.verifyBvnOtpRequest());

            await axios.post(`${baseUrl}/user/validate-bvn-otp`, validate, {
                "headers": {
                    "Content-Type": 'application/json',
                    "Authorization": `Bearer ${token}`
                }
            })
                .then((res) => {

                    swal({
                        title: "Yaay!",
                        text: 'BVN Linked successfully ',
                        icon: "success",
                        button: "Nice!",
                    });
                    dispatch(userActions.verifyBvnOtpSuccess(res.data.data));
                })
                .catch((err) => {
                    swal({
                        title: "Oops!",
                        text: 'An error occured',
                        icon: "error",
                        button: "Oh Oh!",
                    });
                });
        }
    },

    verify_bvn_dob: (validate, token) => {
        return async dispatch => {

            dispatch(userActions.verifyBvnOtpRequest());

            await axios.post(`${baseUrl}/user/verify-bvn-date-of-birth`, validate, {
                "headers": {
                    "Content-Type": 'application/json',
                    "Authorization": `Bearer ${token}`
                }
            })
                .then((res) => {
                    const message = res.data.response_message;
                    swal({
                        title: "Great!",
                        text: message,
                        icon: "success",
                        button: "Okay!",
                    });
                    dispatch(userActions.verifyBvnOtpSuccess(res.data.data));
                    history.push('/dashboard')
                })
                .catch((err) => {
                    if (err.response.data.response_code === 'USM029') {
                        swal({
                            title: "Oops!",
                            text: 'Incorrect date of birth',
                            icon: "error",
                            button: "Oh Oh!",
                        });
                    } else {
                        swal({
                            title: "Oops!",
                            text: 'An error occured',
                            icon: "error",
                            button: "Oh Oh!",
                        });
                    }

                });
        }
    },
}

export default bvnValidation;