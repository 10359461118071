import React from "react";
import packageJson from "../../package.json";
import { Link } from "react-router-dom";
import { Grid, Button } from "@material-ui/core";
import { useSelector } from "react-redux";

function refresh() {
  window.location.reload(true);
  return false;
}

export const HeaderBar = (props) => {
  const active = useSelector((state) => state.activeAccount.data);
  return (
    <div className="header">
      <Grid container>
        <Grid item xs={6} md={6} lg={6}>
          <div className="topic">
            <h4>{props.topic}</h4>
          </div>
        </Grid>
        <Grid item xs={6} md={6} lg={6}>
          <div className="menuList">
            <div>
              <span>
                <span>Available Balance: </span>
                <span
                  style={{
                    fontWeight: "bolder",
                    color: "red",
                    marginRight: "10px",
                    borderRight: "2px solid gray",
                  }}
                >
                  NGN {props.text}{" "}
                </span>
              </span>

              <span>
                <Button
                  style={{
                    width: "70%",
                    height: "35px",
                    marginBottom: "10px",
                    display: "inline",
                    marginLeft: "10px",
                  }}
                  component={Link}
                  to="/fund-account"
                  fullWidth
                  variant="contained"
                  size="small"
                  color="primary"
                  className="loan-button"
                >
                  Fund Account
                </Button>
              </span>

              <p style={{ fontSize: "12px", fontWeight: "bolder" }}>
                Account Name: {active?.name}
              </p>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
export const CardHeader = (props) => (
  <div className="card-header">
    <h4>{props.info}</h4>
  </div>
);

export const CardHeaderNew = (props) => (
  <div className="card-header-new">
    <h4>{props.info}</h4>
  </div>
);

export const CardBody = (props) => (
  <div style={props.cardstyle} className={props.className}>
    <div className="input-holder">{props.children}</div>
  </div>
);

export const InputGrid = (props) => (
  <div className="form-card">
    <Grid container spacing={1} alignItems="flex-end">
      <Grid item xs={12} md={12} lg={12} onChange={props.onchange}>
        {props.children}
      </Grid>
    </Grid>
  </div>
);

export const SmallCard = (props) => (
  <Grid container>
    <Grid item xs={12} md={3} lg={3}>
      <div>
        <span>SENDING</span>
      </div>
      <div className="send-amount">{`NGN ${props.amount}`}</div>
    </Grid>

    <Grid item xs={12} md={4} lg={4}>
      <img alt="Bank" src={props.img} className="network" />
    </Grid>

    <Grid item xs={12} md={4} lg={4}>
      <div>
        <div>{props.item1}</div>
        <span>{props.item2}</span>
      </div>
    </Grid>
  </Grid>
);

export const SmallCardBills = (props) => (
  <Grid container spacing={3}>
    <Grid item xs={12} md={3} lg={3}>
      <div>
        <span>SENDING</span>
      </div>
      <div className="send-amount">{`NGN ${props.amount}`}</div>
    </Grid>

    <Grid item xs={12} md={4} lg={4}>
      <img alt="Biller" src={props.img} className="network" />
    </Grid>

    <Grid item xs={12} md={4} lg={4}>
      <div>
        <div>{props.item1}</div>
        <span>{props.item2}</span>
      </div>
    </Grid>
  </Grid>
);

export const MainContent = (props) => (
  <div
    id="getri-main"
    style={{ width: props.toggle ? "83vw" : "95vw", marginBottom: "100px" }}
  >
    <div className="getri-container">{props.children}</div>
  </div>
);

export const Footer = () => (
  <div className="foot">
    <p style={{ textAlign: "center" }}>
      &copy; 2021 <span>GetriPay</span>. Powered by PatrickGold MFBank{" "}
      <span style={{ fontSize: "10px" }}> V {packageJson.version}</span>
    </p>
  </div>
);

export const SuccessCard = (props) => (
  <div className="card success-card">
    <CardBody className="card2 transfer-success">
      <Grid container className="success-wrapper">
        <Grid item xs={12} md={12} lg={12}>
          <div className="trans-text">
            <img src="/images/success-icon.svg" alt="success" />
          </div>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <div className="trans-text">
            <span>
              Transaction <br /> Successful!
            </span>
          </div>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <div className="trans-text">
            <div className="btn1">
              <Button
                onClick={refresh}
                fullWidth
                variant="contained"
                size="small"
                color="primary"
                className="getri-button submit"
              >
                Perform Another Transaction
              </Button>
            </div>
            <div className="btn2">
              <Button
                component={Link}
                to="/dashboard/account-overview"
                fullWidth
                variant="contained"
                size="small"
                color="primary"
                className="getri-button submit"
              >
                View Account Balance
              </Button>
            </div>
            <div className="btn1">
              <Button
                onClick={props.openReceipt}
                fullWidth
                variant="contained"
                size="small"
                color="primary"
                className="getri-button submit"
              >
                View Receipt
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </CardBody>
  </div>
);

export const BillsSuccessCard = (props) => (
  <div className="card success-card">
    <CardBody className="card2 transfer-success">
      <Grid container className="success-wrapper">
        <Grid item xs={12} md={12} lg={12}>
          {props.topic === "SUCCESSFUL" ? (
            <div className="trans-text">
              <img src="/images/success-icon.svg" alt="success" />
            </div>
          ) : props.topic === "FAILED" ? (
            <div className="trans-text">
              <span>
                Bill Purchase <br /> Failed!
              </span>
            </div>
          ) : (
            <div className="trans-text">
              <img
                src="/images/warning.jpg"
                alt="pending"
                style={{ maxWidth: "60px" }}
              />
            </div>
          )}
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          {props.topic === "SUCCESSFUL" ? (
            <div className="trans-text">
              <span>
                Bill Purchase <br /> Successful!
              </span>
            </div>
          ) : props.topic === "FAILED" ? (
            <div className="trans-text">
              <span>
                Bill Purchase <br /> Failed!
              </span>
            </div>
          ) : (
            <div className="trans-text">
              <span>
                Bill Purchase <br /> Pending!
              </span>
            </div>
          )}

          {props.data?.transaction?.chargeBackData?.chargeBack && (
            <div className="trans-text" style={{ marginTop: "10px" }}>
              <small>
                You have received{" "}
                <strong className="green">
                  &#8358; {props.data?.transaction?.chargeBackData?.chargeBack}{" "}
                </strong>{" "}
                as
                {
                  props.data?.transaction?.chargeBackData?.chargeBackNarration
                }{" "}
                on{" "}
                <strong className="green">
                  &#8358; {" "}
                  {props.data?.transaction?.chargeBackData?.transactionAmount}
                </strong>
              </small>
            </div>
          )}
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <div className="trans-text">
            <div className="btn1">
              <Button
                onClick={refresh}
                fullWidth
                variant="contained"
                size="small"
                color="primary"
                className="getri-button submit"
              >
                Buy Again
              </Button>
            </div>
            <div className="btn2">
              <Button
                component={Link}
                to="/dashboard/account-overview"
                fullWidth
                variant="contained"
                size="small"
                color="primary"
                className="getri-button submit"
              >
                View Account Balance
              </Button>
            </div>
            <div className="btn1">
              <Button
                onClick={props.openReceipt}
                fullWidth
                variant="contained"
                size="small"
                color="primary"
                className="getri-button submit"
              >
                View Receipt
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </CardBody>
  </div>
);

export const DocSuccessCard = (props) => (
  <div className="card success-card">
    <CardBody className="card2 transfer-success">
      <Grid container className="success-wrapper">
        <Grid item xs={12} md={12} lg={12}>
          <div className="trans-text">
            <img src="/images/success-icon.svg" alt="success" />
          </div>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <div className="trans-text">
            <span>
              Document Upload <br /> Successful!
            </span>
            <p style={{ color: "green" }}>Please await verification!!!</p>
          </div>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <div className="trans-text">
            <div className="btn2">
              <Button
                component={Link}
                to="/dashboard"
                fullWidth
                variant="contained"
                size="small"
                color="primary"
                className="getri-button submit"
              >
                Go to Dashboard
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </CardBody>
  </div>
);
