import React from 'react';
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, TextField } from '@material-ui/core';


export default function ConfirmSaveModal(props) {
    return (
        <Dialog
            maxWidth='xs'
            open={props.open}
            onClose={props.close}
            aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle >Confirmation</DialogTitle>
            <DialogContent>
                <h3 style={{ color: 'red' }}>
                    Kindly ensure that you adhere to the plan set, as you will be charged 20% of the accrued sum if you do not follow the plan accordingly.
                </h3>
                <span style={{ fontSize: "14px", color: '#0037ba', marginBottom: '10px' }}>
                    I hereby confirm that I would like to create a savings plan, {props.save.name}, and save NGN{props.amount} {props.save.frequency_type} at an interest rate of
                    {props.data.interest_rate}%/annum that will mature on {props.save.maturity_date}
                </span>

                <TextField
                    inputProps={{ maxLength: 4 }}
                    autoFocus
                    name='transaction_pin'
                    onChange={props.change}
                    value={props.value}
                    margin="dense"
                    label="Please enter your transction pin"
                    type="password"
                />

                <Button
                    fullWidth
                    variant="contained"
                    size="small"
                    color="primary"
                    className='getri-button submit'
                    onClick={props.submit}
                >
                    {props.loading ?
                        <CircularProgress style={{ fontSize: '30px', color: '#fff' }} />
                        :
                        'Create savings plan'
                    }

                </Button>
            </DialogContent>
        </Dialog>
    );
}
