import axios from "axios";
import { userActions } from "../actions/creators";
import config from "../config";
import swal from "sweetalert";
import { history } from "../store/history";

const baseUrl = `${config.apiUrl}`;

const newAccounts = {
  get_new_accounts: (user_id) => {
    return async (dispatch) => {
      dispatch(userActions.getNewAccountRequest());
      await axios
        .get(`${baseUrl}/user/user/request_additional_accounts/${user_id}`)
        .then((res) => {
          dispatch(userActions.getNewAccountSuccess(res.data.data));
        });
    };
  },

  submit_new_account: (data) => {
    return async (dispatch) => {
      dispatch(userActions.submitNewAccountRequest());
      await axios
        .post(`${baseUrl}/user/request_additional_accounts`, data)
        .then((res) => {
          dispatch(
            userActions.submitNewAccountSuccess(res.data.data)
          );
          swal("Great!", "Your request has been received and will be approved soon", "success");

          setTimeout(() => {
            window.location.reload();
          }, 5000);
        })
        .catch((error) => {
          dispatch(userActions.submitNewAccountSuccess());
          swal("Oh oh!", error.response.data.response_message, "error");
        });
    };
  },
};

export default newAccounts;
