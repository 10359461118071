import React from 'react';
import { Dialog, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';



export default function JoinGroupModal(props) {


    return (
        <Dialog
            fullWidth
            maxWidth='lg'
            open={props.open}
            onClose={props.close}
            aria-labelledby="max-width-dialog-title"
        >
            <DialogTitle >Select a group to begin</DialogTitle>
            <DialogContent>

                {props.list ?
                    <>
                        {props.list.length === 0 ?
                            <p>No open groups yet</p>
                            :
                            <Grid container spacing={3}>
                                <>
                                    {props.list.map((data, i) => (
                                        <Grid item lg={4} sm={12} md={6} xs={12} key={i} component={Link} to={`join-group-savings/${data.slug}/${data.id}`}>
                                            <div className='start-savings-modal' >
                                                <p style={{ textTransform: 'uppercase' }}>{data.name}</p>
                                                <span className='start-savings-modal-text'>{data.description}</span>
                                                <p style={{color:'#33cc33'}}>Group Target: NGN {Number(data.maximum_amount).toLocaleString()}</p>
                                                <p>Participants joined: {data.user_join} of {data.maximum_participants}</p>
                                                <p style={{color:'#33cc33'}}>Group progress: NGN {Number(data.wallet.ledger_balance).toLocaleString()} of NGN {Number(data.maximum_amount).toLocaleString()}</p>
                                            </div>
                                        </Grid>
                                    ))}
                                </>

                            </Grid>
                        }


                    </>
                    :
                    <p>No open groups yet</p>
                }


            </DialogContent>
        </Dialog>
    );
}
