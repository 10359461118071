import axios from "axios";
import config from "../config";
import { userActions } from "../actions/creators.js";
import { toast, Flip } from "react-toastify";
import swal from "sweetalert";

const baseUrl = `${config.apiUrl}`;

const physicalCard = {
  // User's request for a physical card
  request_physical_card: (payload, token) => {
    return async (dispatch) => {
      dispatch(userActions.getPhysicalCardRequest());

      await axios
        .post(`${baseUrl}/cards/card_requests`, payload, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.response_code === "CRD003") {
            dispatch(userActions.getPhysicalCardSuccess(res.data));
            toast.success(
              "Card request successful, please check your email for further details",
              {
                position: toast.POSITION.TOP_RIGHT,
                transition: Flip,
              }
            );

            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        })
        .catch((error) => {
          dispatch(userActions.getPhysicalCardFailure());

          swal({
            title: "Oops!",
            text:
              error.response.data.response_message ||
              "Something went wrong. Please try again later",
            icon: "error",
            button: "Okay!",
          });
        });
    };
  },

  // User's request for a physical card
  get_card_status: (id, token) => {
    return async (dispatch) => {
      dispatch(userActions.cardStatusRequest());

      await axios
        .get(`${baseUrl}/cards/card_requests/user/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          dispatch(userActions.cardStatusSuccess(res.data.data));
        })
        .catch((error) => {
          dispatch(userActions.cardStatusFailure());
        });
    };
  },
};

export default physicalCard;
