import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Container,
  Grid,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
} from "@material-ui/core";
import TopBar from "../../../components/TopBar";
import { MainContent } from "../../../components/Appearance";
import SideNav from "../../../components/SideNav";
import BackDrop from "../../../components/BackDrop";
import "../../../css/solomon.css";
import "../../../css/dashboard.css";
import BottomNav from "../../../components/bottomNavigation";
import { connect } from "react-redux";
import transactions from "../../../services/transactionService";
import {
  HeaderBar,
  CardHeader,
  InputGrid,
  CardBody,
  Footer,
} from "../../../components/Appearance";
import axios from "axios";
import numeral from "numeral";
import config from "../../../config";
import { useFlutterwave } from "flutterwave-react-v3";
import { Helmet } from "react-helmet";
import swal from "sweetalert";

const baseUrl = `${config.apiUrl}`;

function FundAccount(props) {
  const [toggle, setToggle] = useState(true);
  const [sidenav, setSidenav] = useState("getri-sidenav");
  const [amountNew, setAmountNew] = useState(0);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [display, setDisplay] = useState(false);
  const [getriRef, setGetriRef] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);

  const handleToggle = () => {
    const mediaScreen = window.matchMedia(
      `(max-width: 700px),
      (min-device-width: 768px)
      and (max-device-width: 1024px)`
    ); //returns true if

    if (mediaScreen.matches) {
      setSidenav("slideOut");
    } else {
      setToggle(!toggle);
    }
  };

  // Number formatter
  var valueDisplay = numeral(amountNew).format();

  const test_key = process.env.REACT_APP_FLUTTERWAVE_PUBLIC_KEY;
  const newAmt = valueDisplay.replace(/,/g, "");

  const req_id = Math.floor(Math.random() * 900000000000000000);

  const [first, setFirst] = useState({
    account_number: props.balance.account_number,
    user_id: props.user.id,
    amount: newAmt,
    narration: "Fund account: " + props.balance.account_number,
    request_id: req_id,
  });

  function initiate() {
    if (first.amount >= 100) {
      axios
        .post(
          `${baseUrl}/accounts/${props.balance.account_number}/fund_with_flw/initiate`,
          first
        )
        .then((res) => {
          setGetriRef(res.data.data.transaction_ref);
          setConfirm(true);
        })
        .catch((error) => {
          if (error.response.data.response_code === "ACM007") {
            swal("Error!", error.response.data.response_message, "error");
          }
        });
    }
  }
  const [fund, setFund] = useState({
    custom_logo: "http://getripay.lending.ng/images/yellow-logo.svg",
    custom_title: "Fund Your GetriPay Account: " + props.balance.account_number,
    account_no: props.balance.account_number,
    key: test_key, // RavePay PUBLIC KEY
    phone: props.user.phone_number,
    firstname: props.user.first_name,
    lastname: props.user.last_name,
    email: props.user.email,
    txref: getriRef,
  });

  useEffect(() => {
    setFund({
      ...fund,
      custom_title:
        "Fund Your GetriPay Account: " + props.balance.account_number,
      account_no: props.balance.account_number,
    });
  }, [props]);

  useEffect(() => {
    props.get_account_balance();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setFirst({
      account_number: props.balance.account_number,
      user_id: props.user.id,
      amount: newAmt,
      narration: "Fund account: " + props.balance.account_number,
      request_id: req_id,
    });
    if (first.amount < 100) {
      setErrorMsg(true);
    } else {
      setErrorMsg(false);
    }
  }, [newAmt, first.amount, props]); // eslint-disable-line react-hooks/exhaustive-deps

  const hideSidebar = () => {
    setSidenav("slideIn");
  };

  function refresh() {
    window.location.reload(true);
    return false;
  }
  function showSuccessCard() {
    let showCard;
    if (success) {
      showCard = (
        <div className="card success-card">
          <CardBody className="card2 transfer-success">
            <Grid container className="success-wrapper">
              <Grid item xs={12} md={12} lg={12}>
                <div className="trans-text">
                  <img src="images/success-icon.svg" alt="success" />
                </div>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <div className="trans-text">
                  <span>
                    Account Funding <br /> Successful!
                  </span>
                </div>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <div className="trans-text">
                  <div className="btn1">
                    <Button
                      onClick={refresh}
                      fullWidth
                      variant="contained"
                      size="small"
                      color="primary"
                      className="getri-button submit"
                    >
                      Fund account again
                    </Button>
                  </div>
                  <div className="btn2">
                    <Button
                      component={Link}
                      to="/dashboard/account-overview"
                      fullWidth
                      variant="contained"
                      size="small"
                      color="primary"
                      className="getri-button submit"
                    >
                      View account balance
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </CardBody>
        </div>
      );
    } else {
      showCard = (
        <div className="card">
          <CardBody
            className="card-body"
            cardstyle={{ padding: "1em 1em 1em", borderColor: "#33cc33" }}
          ></CardBody>
        </div>
      );
    }
    return showCard;
  }

  const config = {
    public_key: fund.key,
    tx_ref: getriRef,
    amount: newAmt,
    currency: "NGN",
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email: fund.email,
      phonenumber: fund.phone,
      name: fund.account_no + ": " + fund.firstname + " " + fund.lastname,
    },
    customizations: {
      title: fund.custom_title,
      description: "Account Fundinng",
      logo: fund.custom_logo,
    },
  };

  const handleFlutterPayment = useFlutterwave(config);

  const close = () => {
    setTimeout(() => {
      document
        .getElementsByName("checkout")[0]
        .setAttribute("style", "z-index: -1; opacity: 0");
    }, 1000);
  };
  function handleChange(e) {
    setAmountNew(e.target.value);
  }

  return (
    <>
      <Helmet>
        <title>GetriPay - Fastest growing digital bank with rewards </title>
        <meta name="description" content="Fund Account" />
      </Helmet>
      <TopBar handleToggle={handleToggle} />
      <SideNav
        toggle={toggle}
        className={sidenav}
        hideSidebar={hideSidebar}
        {...props}
      />
      <MainContent toggle={toggle}>
        {loading ? <BackDrop /> : ""}
        <main className="main">
          <Grid style={{ height: "100%" }}>
            <Grid item xs={12} md={12} lg={12} style={{ height: "inherit" }}>
              <Container className="main-container">
                <HeaderBar
                  topic="Fund Account"
                  text={Number(
                    props.balance.available_balance
                  ).toLocaleString()}
                />
              </Container>
              <Grid container style={{ padding: "1px 0" }}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={6}
                  className="card-container"
                  style={{
                    backgroundColor: success ? "#f6f6f7" : "white",
                    height: "100vh",
                  }}
                >
                  <div
                    className="card"
                    style={{ display: success ? "none" : "block" }}
                  >
                    <CardHeader info="Funding of account" />
                    {errorMsg && (
                      <p style={{ color: "red", fontSize: "14px" }}>
                        Please enter an amout greater than or equal to NGN100
                      </p>
                    )}
                    <CardBody
                      cardstyle={{ padding: "1em 1em 1em 1em", width: "300px" }}
                      className="card2"
                    >
                      <InputGrid>
                        <label
                          style={{ marginBottom: "20px", marginTop: "10px" }}
                        >
                          Enter an amount
                        </label>
                        <input
                          //type='number'
                          value={valueDisplay}
                          onChange={handleChange}
                          className="input-div"
                        />
                      </InputGrid>

                      <div>
                        <Grid container spacing={1} alignItems="flex-end">
                          <Grid item xs={12} md={12} lg={12}>
                            <div>
                              <Button
                                fullWidth
                                variant="contained"
                                size="large"
                                color="primary"
                                className="submit"
                                onClick={initiate}
                              >
                                Continue
                              </Button>
                            </div>
                          </Grid>
                          <Dialog
                            open={
                              confirm === true
                                ? true
                                : confirm === false
                                ? false
                                : ""
                            }
                            onClose={() => setConfirm(false)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogTitle
                              id="alert-dialog-title"
                              style={{ color: "#0037ba" }}
                            >
                              Account Funding
                            </DialogTitle>

                            <DialogContent>
                              <DialogContentText id="alert-dialog-description">
                                Are you sure you want to fund your Getripay
                                Account with {valueDisplay}?
                              </DialogContentText>
                            </DialogContent>

                            <DialogActions>
                              <div onClick={() => setConfirm(false)}>
                                <button
                                  className="submit"
                                  onClick={() => {
                                    handleFlutterPayment({
                                      callback: (response) => {
                                        var status = response.status;

                                        close();
                                        if (status == "successful") {
                                          setLoading(true);

                                          axios
                                            .post(
                                              `${baseUrl}/accounts/${props.balance.account_number}/fund_with_flw`,
                                              {
                                                account_number:
                                                  props.balance.account_number,
                                                user_id: props.user.id,
                                                amount: newAmt,
                                                narration:
                                                  "Fund account: " +
                                                  props.balance.account_number,
                                                trans_ref: getriRef,
                                                request_id: first.request_id,
                                              }
                                            )
                                            .then(function (response) {
                                              if (
                                                response.data.response_code ===
                                                "00"
                                              ) {
                                                setSuccess(true);
                                                setLoading(false);
                                                props.get_account_balance();
                                              } else {
                                                setSuccess(false);
                                              }
                                              if (
                                                response.data.response_code ===
                                                "00"
                                              ) {
                                                setDisplay(true);
                                                setFirst(
                                                  (first.request_id = "")
                                                );
                                              }
                                            })
                                            .catch(function (error) {
                                              setLoading(false);
                                            });

                                          //Give Value and return to Success page
                                        } else {
                                        }
                                      },
                                      onClose: () => {
                                        // close();
                                      },
                                    });
                                  }}
                                >
                                  Fund Getripay Account
                                </button>
                              </div>
                            </DialogActions>
                          </Dialog>
                        </Grid>
                      </div>
                    </CardBody>
                    <div
                      className="card2"
                      style={{
                        marginTop: "30px",
                        padding: "1em 1em 1em",
                        border: "2px solid green",
                        borderRadius: "20px",
                        backgroundColor: "white",
                      }}
                    >
                      <div>
                        <p>
                          Other means of{" "}
                          <span style={{ color: "#33cc33" }}>funding</span> your{" "}
                          <span
                            style={{ color: "#33cc33", fontWeight: "bolder" }}
                          >
                            GETRIPAY
                          </span>{" "}
                          account
                        </p>
                      </div>
                      <hr></hr>
                      <div style={{ fontSize: "14px" }}>
                        <p>
                          Bank transfer from any nigerian bank to your{" "}
                          <span style={{ fontWeight: "bolder" }}>
                            {" "}
                            GETRIPAY
                          </span>{" "}
                          account -{" "}
                          <span style={{ color: "#33cc33" }}>
                            {props.balance.account_number}
                          </span>
                        </p>
                        <ul>
                          <li>
                            Select PATRICKGOLD MFBANK from the bank list in your
                            bank app
                          </li>
                          <li>
                            Account number should be{" "}
                            <span style={{ color: "#33cc33" }}>
                              {props.balance.account_number}
                            </span>
                          </li>
                        </ul>
                        <div>
                          <p>Other options -USSD</p>
                          <a
                            href={`tel:*737*2*${props.balance.account_number}*${newAmt}#`}
                          >
                            GTBANK: Dial *737*2*
                            <span style={{ color: "#33cc33" }}>
                              {props.balance.account_number}
                            </span>
                            *{newAmt}#
                          </a>
                          <br />
                          <br />

                          <a
                            href={`tel:*901*${newAmt}*${props.balance.account_number}#`}
                          >
                            ACCESS BANK: Dial *901*{newAmt}*
                            <span style={{ color: "#33cc33" }}>
                              {props.balance.account_number}
                            </span>
                            #
                          </a>
                          <br />
                          <br />

                          <a
                            href={`tel:*894*${newAmt}*${props.balance.account_number}#`}
                          >
                            FIRST BANK: Dial *894*{newAmt}*
                            <span style={{ color: "#33cc33" }}>
                              {props.balance.account_number}
                            </span>
                            #
                          </a>
                          <br />
                          <br />

                          <a
                            href={`tel:*833*${newAmt}*${props.balance.account_number}#`}
                          >
                            POLARIS BANK: Dial *833*{newAmt}*
                            <span style={{ color: "#33cc33" }}>
                              {props.balance.account_number}
                            </span>
                            #
                          </a>
                          <br />
                          <br />

                          <a
                            href={`tel:*966*${newAmt}*${props.balance.account_number}#`}
                          >
                            ZENITH BANK: Dial *966*{newAmt}*
                            <span style={{ color: "#33cc33" }}>
                              {props.balance.account_number}
                            </span>
                            #
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={6}
                  style={{
                    backgroundColor: success ? "white" : "#f6f6f7",
                    display: display ? "grid" : "none",
                  }}
                  className="card-container"
                >
                  {showSuccessCard()}
                </Grid>
              </Grid>
            </Grid>
            <BottomNav {...props} />
            <Footer />
          </Grid>
        </main>
      </MainContent>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.userDetails.data,
    balance: state.getAccountBalance.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_account_balance: (payload) =>
      dispatch(transactions.get_account_balance(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FundAccount);
