import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import "../../css/register.css";
import "../../css/otp.css";
import { connect } from 'react-redux';
import resend from "../../services/resendService";
import verify from "../../services/verifyService";
import { history } from '../../store/history';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Helmet } from 'react-helmet'


const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: "#0038ba",
        minHeight: "100vh",
        [theme.breakpoints.down("sm")]: {
            backgroundColor: 'white'
        },
    },
    root: {
        padding: theme.spacing(10, 30, 10, 30),
        maxWidth: "100%",
        maxHeight: "100%",
        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(0, 0, 0, 0),
        },
    },
    image: {
        backgroundImage: "url('/images/login-image.png')",
        backgroundRepeat: "no-repeat",
        backgroundColor:
            theme.palette.type === "light"
                ? theme.palette.grey[50]
                : theme.palette.grey[900],
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderBottomLeftRadius: "5%",
        borderTopLeftRadius: "5%",
        maxHeight: "100%",
    },
    paper: {
        margin: theme.spacing(6, 4),
        display: "flex",
        flexDirection: "column",
    },
    logo: {
        maxWidth: "50%",
        marginBottom: 80
    },
    form: {
        width: "100%",
        marginTop: theme.spacing(1),
    },

    pap: {
        backgroundColor: "white",
        borderBottomRightRadius: "5%",
        borderTopRightRadius: "5%",
        [theme.breakpoints.down("sm")]: {
            borderBottomRightRadius: "0%",
            borderTopRightRadius: "0%",
        },
    },
    margin: {
        width: "17vw",
        [theme.breakpoints.down("sm")]: {
            width: 300,
        },
        [theme.breakpoints.down("xs")]: {
            width: 200,
        },
        marginBottom: 1,
    },
    getri: {
        fontWeight: "bold",
    },

}));

const padTime = time => {
    return String(time).length === 1 ? `0${time}` : `${time}`;
  };
  
  const format = time => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${padTime(seconds)}`;
  };

function VerifyPhone(props) {
    const classes = useStyles();
    const [inputs, setInputs] = useState({
        token: '',
        register_token: localStorage.getItem('register_token'),
    });
    const [submitted, setSubmitted] = useState(false);
    const [counter, setCounter] = useState(120);
    const { token, register_token } = inputs;

    function handleChange(e) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    };

    function resend() {
        props.resendPhone();
    };

    function resendEmail() {
        props.resendEmail();
    };

    
    function handleSubmit(e) {
        e.preventDefault();

        setSubmitted(true);
        if (inputs.token && inputs.register_token) {
            props.verifyPhone(token, register_token);
        }
    };

    React.useEffect(() => {
        let timer;
        if (counter > 0) {
          timer = setTimeout(() => setCounter(c => c - 1), 1000);
        }
    
        return () => {
          if (timer) {
            clearTimeout(timer);
          }
        };
      }, [counter]);

    return (
        <div className={classes.header}>
            <Helmet>
                <title>GetriPay - Fastest growing digital bank with rewards </title>
                <meta name="description" content="Verify Registration" />
            </Helmet>
            <Grid container component="main" className={classes.root}>
                <CssBaseline />
                <Grid item xs={false} sm={4} md={7} className={classes.image} />
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={5}
                    component={Paper}
                    elevation={0}
                    square
                    className={classes.pap}
                >
                    <div className={classes.paper}>
                        <div>
                            <img
                                src="/images/getripaylogo.png"
                                alt="logo"
                                className={classes.logo}
                            />
                        </div>
                        <div>
                            <p className='typo'>Create an account</p>
                        </div>

                        <div>
                            <p className='enter'>Enter your verification code</p>
                        </div>

                        <div>
                            <p className='digit2'>Please check your email to confirm your email address!</p>
                        </div>

                        <div>
                            <p className='digit'>Enter the six digit code sent to your email/phone</p>
                        </div>

                        <form className={classes.form} name="form" onSubmit={handleSubmit}>
                            <div className='custom-styles'>
                                <input name="token" type='password' value={inputs.token} onChange={handleChange} className={'form-control' + (submitted && !inputs.token ? ' is-invalid' : '')} />
                                {submitted && !inputs.token &&
                                    <div className="invalid-feedback">Enter your 6 digits token</div>
                                }
                            </div>

                            <Grid container>
                                {counter === 0 ?
                                    <Grid item className='already'>
                                        <span>Did not receive the text message? </span>

                                        <span className='terms' onClick={resend}> Resend SMS</span>

                                    </Grid>
                                    :
                                    <div className='already' style={{display:"block"}}>
                                        Resend SMS OTP in: <span className='terms'>{format(counter)}</span><span>s</span>
                                    </div>
                                }
                                {counter === 0 ?
                                    <Grid item className='already' >
                                        <span>Did not receive the email? </span>

                                        <span className='terms' onClick={resendEmail}> Resend E-mail</span>

                                    </Grid>
                                    :
                                    <div className='already' style={{display:"block", marginRight:"40px"}}>
                                        Resend Email OTP in: <span className='terms'>{format(counter)}</span><span>s</span>
                                    </div>
                                }
                            </Grid>

                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                size="large"
                                color="primary"
                                className='submit'
                                disabled={props.isLoading}
                            >
                                {props.isLoading && <CircularProgress className='circle' />}
                                    Continue
                            </Button>
                        </form>
                        <p onClick={history.goBack} className='back'>Go Back</p>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        verifyPhone: (token, register_token) => dispatch(verify.verify_phone(token, register_token)),
        resendPhone: (payload) => dispatch(resend.resend_phone(payload)),
        resendEmail: (payload) => dispatch(resend.resend_email(payload))
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.response.isLoading
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyPhone);
