import React, { useState } from 'react';
import { Button, CircularProgress, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import savings from '../../../../services/savingsService';


function NotificationSettingsTab(props) {
    const [data, setData] = useState({
        user_id: props.user.id,
        frequency: '',
        frequency_type: '',
        notification_type: 'savings',
    });

    console.log(data)
    function handleChange(e) {
        const { name, value } = e.target;
        setData((data) => ({ ...data, [name]: value }));
    }

    function submit() {
        if (data.frequency && data.frequency_type) {
            props.savings_notifications(data)
        }
    }

    return (
        <div className='savings-notifications'>

            <p>Configure your notification settings</p>

            <InputLabel id="demo-simple-select-label">Frequency</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={data.frequency}
                name='frequency'
                onChange={handleChange}
                style={{ width: '100%', marginBottom: '50px' }}
            >
                <MenuItem value={1}>One</MenuItem>
                <MenuItem value={2}>Two</MenuItem>
                <MenuItem value={3}>Three</MenuItem>
                <MenuItem value={4}>Four</MenuItem>
                <MenuItem value={5}>Five</MenuItem>
                <MenuItem value={6}>Six</MenuItem>
            </Select>

            <InputLabel id="demo-simple-select-label">Frequency Type</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={data.frequency_type}
                onChange={handleChange}
                style={{ width: '100%' }}
                name='frequency_type'
            >
                <MenuItem value='daily'>Daily</MenuItem>
                <MenuItem value='weekly'>Weekly</MenuItem>
                <MenuItem value='monthly'>Monthly</MenuItem>
                <MenuItem value='quarterly'>Quarterly</MenuItem>
                <MenuItem value='yearly'>Yearly</MenuItem>
            </Select>
            <p>
                You want to receive savings notifications every {data.frequency} {data.frequency_type}
            </p>

            <Button
                fullWidth
                variant="contained"
                size="small"
                color="primary"
                className='getri-button submit'
                onClick={submit}
            >
                {props.loading ?
                    <CircularProgress style={{ fontSize: '30px', color: '#fff' }} />
                    :
                    'Submit'
                }

            </Button>
        </div>

    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        savings_notifications: (data) => dispatch(savings.savings_notifications(data))
    };
};

const mapStateToProps = (state) => {
    return {
        user: state.userDetails.data,
        loading: state.savingsNotifications.isLoading,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationSettingsTab)
