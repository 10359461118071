import { userConstants } from '../actions/types'

const initialState = {
    isGettingLoanHistory: false,
    data: [],
}
 
function getLoanHistory(state = initialState, action) {
    switch (action.type) {
        case userConstants.GET_LOANS_HISTORY_REQUEST:
            return {
                ...state,
                isGettingLoanHistory: true,
            
            };
        case userConstants.GET_LOANS_HISTORY_SUCCESS:
            return {
                ...state,
                isGettingLoanHistory: false,
                data: action.payload, 
            };
        default:
            return state
    }
}

export default getLoanHistory;