import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import virtualCard from '../../services/virtualCardService';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});

function ConfirmPinModal(props) {
    const [transaction_pin, setTransacitonPin] = useState('');

    const token = props.details.token;
    const user_id = props.details.id

    function handleChange(e) {
        setTransacitonPin(e.target.value)
    }

    function submit() {

        if (transaction_pin) {
            props.confirm_trans_pin(user_id, transaction_pin, token )
        };
    };

    return (
        <div>
            <Dialog
                open={
                    props.data.success === true || props.data === 'a' ? false
                        :
                        props.open === true ? true 
                            :
                            false
                }
                TransitionComponent={Transition}
                keepMounted
                onClose={props.close}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <Typography variant="p" style={{ padding: '20px' }}>
                   Please enter your transaction pin
                 </Typography>

                <DialogContent dividers style={{ backgroundColor: '#eee' }}>

                    <TextField
                        autoFocus
                        onChange={handleChange}
                        value={transaction_pin}
                        margin="dense"
                        label="Transaction Pin"
                        type="password"
                    />
                   
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.close} color="primary" className='request-modal-button-cancel'>
                        CANCEL
                    </Button>
                    <Button color='primary' variant="contained" size="small" onClick={submit} className='request-modal-button'>
                        {props.isLoading ?
                            <CircularProgress style={{ fontSize: '30px', color: '#fff' }} />
                            :
                            'Confirm Pin'
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        confirm_trans_pin: (user_id, transaction_pin, token ) => dispatch(virtualCard.confirm_trans_pin(user_id, transaction_pin, token)),
    };
};
const mapStateToProps = (state) => {
    return {
        isLoading: state.confirmPin.isLoading,
        data:state.confirmPin.data
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPinModal);