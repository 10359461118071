import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import savings from '../../../../services/savingsService';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { IconButton, Menu, MenuItem, Grid } from '@material-ui/core';
import { ListItemText } from '@material-ui/core';
import FundSavingsModal from './FundSavingsModal';
import WithdrawSavingsModal from './WithdrawSavingsModal';
import ChangeDateModal from './ChangeDateModal';
import InviteGroupModal from './InviteModal';
import AuthorizeModal from './AuthorizeModal';
import OverrideModal from './OverrideModal';


function GroupBox(props) {
    const id = props.id
    const group_id = props.group ? props.group.id : ''
    const wallet_id = props.user_wallet_id;

    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [openInvite, setOpenInvite] = useState(false);
    const [withdraw, setWithdraw] = useState(false);
    const [changeDate, setChangeDate] = useState(false)
    const [copy, setCopy] = useState('');
    const [authorize, setAuthorize] = useState(false);
    const [override, setOverride] = useState(false)

    const handleOpenModal = () => {
        setOpen(true);
    };

    const handleCloseModal = () => {
        setOpen(false);
    };

    const handleOpenInvite = () => {
        setOpenInvite(true);
    };

    const handleCloseInvite = () => {
        setOpenInvite(false);
    };


    const handleOpenAuthorize = () => {
        setAuthorize(true);
    };

    const handleCloseAuthorize = () => {
        setAuthorize(false);
    };


    const handleOpenOverride = () => {
        setOverride(true);
    };

    const handleCloseOverride = () => {
        setOverride(false);
    };

    const handleOpenWithdraw = () => {
        setWithdraw(true);
    };

    const handleCloseWithdraw = () => {
        setWithdraw(false);
    };

    const handleChangeDate = () => {
        setChangeDate(true);
    };

    const handleCloseDate = () => {
        setChangeDate(false);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    function shareLink(slug, id) {
        const url = `http://localhost:3000/savings/join-group-savings/${slug}/${id}`;
        navigator.clipboard.writeText(url);
        setCopy('Copied!');
        alert('copied')
    }

    return (
        <>
            {props.group &&
                <div className='savings-box' >
                    <Grid container spacing={5}>
                        <Grid item lg={7} sm={7} xs={7}>
                            <p className='savings-title'>{props.name}</p>
                        </Grid>

                        {props.group !== null &&
                            <Grid item lg={3} sm={3} xs={3}>
                                {
                                    <p style={{ textTransform: 'capitalize', color: 'red' }} className='prog-text2'>{props.group.group_type} group</p>
                                }
                            </Grid>

                        }

                        <Grid item lg={2} sm={2} xs={2}>
                            <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onMouseOver={handleClick}
                            >
                                <MoreVertIcon className='menu-height' alt='menu' />
                            </IconButton>
                            <Menu
                                style={{ marginTop: '50px' }}
                                id="customized-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem component={Link} to={`history-group/${id}/${group_id}/${wallet_id}`} >
                                    <ListItemText primary="Saving history" />
                                </MenuItem>

                                <MenuItem onClick={() => shareLink(props.group.slug, props.group.id)} >
                                    <ListItemText primary="Share group link" />
                                </MenuItem>

                                <MenuItem onClick={handleOpenInvite} >
                                    <ListItemText primary="Invite others to group" />
                                </MenuItem>
                                {props.group !== null && props.group.group_type === 'close' &&

                                    <MenuItem onClick={handleOpenAuthorize} >
                                        <ListItemText primary="Authorize withdrawal" />
                                    </MenuItem>
                                }

                                {props.group !== null && props.group.creator_id === props.user_id &&

                                    <MenuItem onClick={handleOpenOverride} >
                                        <ListItemText primary="Override withdrawal settings" />
                                    </MenuItem>
                                }
                                {props.savings_product ?
                                    <>
                                        {props.savings_product.amount_type === 'fixed' ?
                                            ''
                                            :
                                            <MenuItem onClick={handleOpenModal}>
                                                <ListItemText primary="Top up savings" />
                                            </MenuItem>
                                        }
                                    </>
                                    :
                                    ''
                                }

                                <MenuItem onClick={handleOpenWithdraw}>
                                    <ListItemText primary="Withdraw from savings" />
                                </MenuItem>
                                {props.savings_product ?
                                    <>
                                        {props.savings_product.amount_type === 'variable' ?
                                            <MenuItem onClick={handleChangeDate}>
                                                <ListItemText primary="Change withdrawal date" />
                                            </MenuItem>
                                            :
                                            ''
                                        }
                                    </>
                                    :
                                    ''
                                }
                            </Menu>
                        </Grid>
                        <FundSavingsModal open={open} close={handleCloseModal} id={props.id} />
                        <WithdrawSavingsModal open={withdraw} close={handleCloseWithdraw} id={props.id} />
                        <ChangeDateModal open={changeDate} close={handleCloseDate} />
                        <InviteGroupModal open={openInvite} close={handleCloseInvite} id={group_id} />
                        <AuthorizeModal open={authorize} close={handleCloseAuthorize} id={group_id} name={props.name} />
                        <OverrideModal open={override} close={handleCloseOverride} id={group_id} name={props.name} />
                    </Grid>

                    <div className='savings-section'>
                        <div className='inner-card savings-section-1'>

                            {props.savings_product ?
                                <>
                                    <img src={props.savings_product.amount_type === 'fixed' ?
                                        '/images/fixed_depo.png' :
                                        props.savings_product.amount_type === 'range' ?
                                            '/images/save_flexi.png'
                                            :
                                            '/images/target_savings.png'
                                    }
                                    />
                                </>
                                :
                                <img src='/images/target_savings.png' />
                            }

                            <span className='prog-text2' style={{ marginTop: '10px' }}>YOUR SAVINGS</span>
                            <span className='prog-amount'>NGN{Number(props.amount_save).toLocaleString()}</span>
                            <span className='prog-text2 ' style={{ marginTop: '10px' }}>INTEREST ACCRUED</span>
                            <span className='prog-amount'>NGN{props.total_interest}</span>
                        </div>
                        <div className='inner-card savings-section-1'>
                            {props.savings_product ?
                                <>
                                    <img src={props.savings_product.amount_type === 'fixed' ?
                                        '/images/fixed_depo.png' :
                                        props.savings_product.amount_type === 'range' ?
                                            '/images/save_flexi.png'
                                            :
                                            '/images/target_savings.png'
                                    }
                                    />
                                </>
                                :
                                <img src='/images/target_savings.png' />
                            }
                            <span className='target-text'>GROUP TARGET</span>
                            {props.group && <span className='prog-amount'>NGN {Number(props.group.maximum_amount).toLocaleString()}</span>}

                            <span className='save-time-left'>{new Date(props.maturity_date).toDateString()} </span>
                            <span className='save-interest'>{props.interest_rate}% Interest Rate</span>
                        </div>

                        <div className='inner-card savings-section-1'>
                            {props.savings_product ?
                                <>
                                    <img src={props.savings_product.amount_type === 'fixed' ?
                                        '/images/fixed_depo.png' :
                                        props.savings_product.amount_type === 'range' ?
                                            '/images/save_flexi.png'
                                            :
                                            '/images/target_savings.png'
                                    }
                                    />
                                </>
                                :
                                <img src='/images/target_savings.png' />
                            }
                            <span className='target-text'>GROUP PROGRESS</span>
                            <span className='prog-amount'>NGN {Number(props.wallet.ledger_balance).toLocaleString()}</span>

                            <span className='save-time-left'>View more details</span>
                        </div>
                    </div>

                </div>
            }

        </>

    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        get_savings_summary: (id) => dispatch(savings.get_savings_summary(id))
    };
};

const mapStateToProps = (state) => {
    return {
        data: state.savingsSummary.data,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupBox);