import React from "react";
import { Paper, Grid } from "@material-ui/core";

export default function TransactionHistoryCard({ props }) {
  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <Paper className="dashboardBox1">
        <div className="for-inline-items">
          <div>
            <p className="e-cash"> Transaction History </p>
          </div>
        </div>

        <div className="for-inline-items">
          <div className="inner-card">
            <img
              src="/images/history-icon.svg"
              alt="logo"
              className="card-icons-2"
            />
            <div className="inner-card-text-bold">
              <span className="inner-text-gray">
                {props?.report?.monthlyCount??"-"}
              </span>
              <br />
              <span className="three">Monthly transaction</span>
              <br />
              <p className="card-text-sm">
                {new Date().toLocaleString("default", {
                  month: "long",
                  year: "numeric",
                })}
              </p>
            </div>
          </div>
          <div className="inner-card">
            <img
              src="/images/history-icon.svg"
              alt="logo"
              className="card-icons-2"
            />
            <div className="inner-card-text-bold">
              <span className="inner-text-gray">{props?.report?.dailyCount}</span>
              <br />
              <span className="three">Daily transaction </span>
              {/* <br /> */}
              <p className="card-text-sm">
                {new Date().toLocaleString("default", {
                  day: "2-digit",
                  month: "long",
                  year: "numeric",
                })}
              </p>
            </div>
          </div>
        </div>
      </Paper>
    </Grid>
  );
}
