import axios from 'axios';
import { toast, Flip } from 'react-toastify';
import config from '../config';
import { userActions } from "../actions/creators.js";
import swal from "sweetalert"
import headers from '../store/authHeader'

const baseUrl = `${config.apiUrl}`;

const settings = {
    // Edit profile
    edit_profile: (profile_photo, token) => {
        return async dispatch => {
            const formData = new FormData();
            formData.append('image', profile_photo);
            const user_id = localStorage.getItem('user_id')
            dispatch(userActions.uiRequesting());
            await axios.post(`${baseUrl}/user/u/${user_id}/profile-picture`, formData, {
                "headers": {
                    "Content-Type": 'application/json',
                    "Authorization": `Bearer ${token}`
                }
            })
                .then(res => {
                    dispatch(userActions.uiResponded());
                    dispatch(userActions.updateAccountInformation(res.data.data));
                    if (res.data.success === true) {
                        swal("Great!", "Your profile picture has been uploaded successfully!", "success");
                    }
                })
                .catch(error => {
                    dispatch(userActions.uiResponded());
                    toast.error("Error", {
                        position: toast.POSITION.TOP_RIGHT,
                        transition: Flip,
                    })
                })
        }
    },

    // update account information 
    update_user: (update, token) => {
        return async dispatch => {
            dispatch(userActions.uiRequesting());
            const user_id = localStorage.getItem('user_id');

            await axios.patch(`${baseUrl}/user/u/update-profile/${user_id}`, update, {
                "headers": {
                    "Content-Type": 'application/json',
                    "Authorization": `Bearer ${token}`
                }
            })
                .then(res => {
                    dispatch(userActions.uiResponded());
                    dispatch(userActions.updateAccountInformation(res.data.data));
                    if (res.data.response_message === 'User Profile updated successfully') {
                        swal("Great!", "Your profile has been updated successfully!", "success");
                        localStorage.setItem('register_token', (res.data.data.register_token));
                    }
                })
                .catch(error => {
                    dispatch(userActions.uiResponded());
                    toast.error("Error", {
                        position: toast.POSITION.TOP_RIGHT,
                        transition: Flip,
                    })

                })
        }
    },

};

export default settings;

