import React from "react";
import { Collapse, List } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCode,
  faHandHoldingUsd,
  faMoneyBill,
  faQrcode,
} from "@fortawesome/free-solid-svg-icons";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

export default function SideNav(props) {
  const [open, setOpen] = React.useState(false);
  const [openTransfer, setOpenTransfer] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
    setOpenTransfer(false);
  };

  const handleClickTransfer = () => {
    setOpenTransfer(!openTransfer);
    setOpen(false);
  };
  return (
    <div
      className={props.className}
      style={{ width: props.toggle ? "15vw" : "5vw" }}
      id="gNavbar"
    >
      <div className="getri-sidebar" id="g-side">
        <div className="nav-drawer">
          <div className="nav-drawer-header" id="">
            <div className="back-arrow">
              <div
                className="arrow-icon"
                id="arrow"
                onClick={props.hideSidebar}
              >
                <span>Back</span>
                <img src="../Assets/Images/backarrow.svg" alt="" />
              </div>
            </div>
          </div>
          <div className="gSidebar">
            <div className="section user-section">
              <List>
                <ListItem
                  button
                  component={Link}
                  to="/dashboard"
                  className={
                    !props.toggle ? "collapse-list-item" : "getri-list-item"
                  }
                >
                  {props.history.location.pathname === "/dashboard" ? (
                    <ListItemIcon className="side-icon-div">
                      <div className="sidebar-icon">
                        <img src="/images/overview-icon.svg" alt="overview" />
                      </div>
                    </ListItemIcon>
                  ) : (
                    <ListItemIcon className="side-icon-div">
                      <div className="sidebar-icon">
                        <img
                          src="/images/g_dashboard_icon_gray.svg"
                          alt="overview"
                        />
                      </div>
                    </ListItemIcon>
                  )}
                  <ListItemText
                    className={
                      props.toggle ? "side-link-div" : "collapse-side-link"
                    }
                  >
                    <span>Overview</span>
                  </ListItemText>
                </ListItem>

                <ListItem
                  button
                  onClick={handleClickTransfer}
                  className={
                    !props.toggle ? "collapse-list-item" : "getri-list-item"
                  }
                >
                  {props.history.location.pathname === "/transfer" ||
                  props.history.location.pathname === "/transfer/multiple" ||
                  props.history.location.pathname ===
                    "/transfer/file-upload" ? (
                    <ListItemIcon className="side-icon-div">
                      <div className="sidebar-icon">
                        <img
                          src="/images/g_send_money_coloured.svg"
                          alt="transfer"
                        />
                      </div>
                    </ListItemIcon>
                  ) : (
                    <ListItemIcon className="side-icon-div">
                      <div className="sidebar-icon">
                        <img src="/images/transfer-icon.svg" alt="transfer" />
                      </div>
                    </ListItemIcon>
                  )}

                  <ListItemText
                    className={
                      props.toggle ? "side-link-div" : "collapse-side-link"
                    }
                  >
                    <span>Funds Transfer</span>
                  </ListItemText>
                  {openTransfer ? <ExpandLess /> : <ExpandMore />}
                </ListItem>

                {/* Transfer sub menu */}

                <Collapse in={openTransfer} timeout="auto" unmountOnExit>
                  <List
                    component="div"
                    disablePadding
                    style={{ paddingLeft: "20px" }}
                  >
                    <ListItem
                      button
                      component={Link}
                      to="/transfer"
                      className={
                        !props.toggle ? "collapse-list-item" : "getri-list-item"
                      }
                    >
                      {props.history.location.pathname === "/transfer" ? (
                        <ListItemIcon className="side-icon-div">
                          <div className="sidebar-icon">
                            <img
                              src="/images/g_send_money_coloured.svg"
                              alt="single-tranfer"
                            />
                          </div>
                        </ListItemIcon>
                      ) : (
                        <ListItemIcon className="side-icon-div">
                          <div className="sidebar-icon">
                            <img
                              src="/images/transfer-icon.svg"
                              alt="single-transfer"
                            />
                          </div>
                        </ListItemIcon>
                      )}

                      <ListItemText
                        className={
                          props.toggle ? "side-link-div" : "collapse-side-link"
                        }
                      >
                        <span>Single Transfer</span>
                      </ListItemText>
                    </ListItem>

                    <ListItem
                      button
                      component={Link}
                      to="/transfer/multiple"
                      className={
                        !props.toggle ? "collapse-list-item" : "getri-list-item"
                      }
                    >
                      {props.history.location.pathname ===
                      "/transfer/multiple" ? (
                        <ListItemIcon className="side-icon-div">
                          <div className="sidebar-icon">
                            <img
                              src="/images/mobile-data-colored.svg"
                              alt="transfer-multiple"
                            />
                          </div>
                        </ListItemIcon>
                      ) : (
                        <ListItemIcon className="side-icon-div">
                          <div className="sidebar-icon">
                            <img
                              src="/images/mobile-data.svg"
                              alt="transfer-multiple"
                            />
                          </div>
                        </ListItemIcon>
                      )}

                      <ListItemText
                        className={
                          props.toggle ? "side-link-div" : "collapse-side-link"
                        }
                      >
                        <span>Multiple Transfers</span>
                      </ListItemText>
                    </ListItem>

                    <ListItem
                      button
                      component={Link}
                      to="/transfer/file-upload"
                      className={
                        !props.toggle ? "collapse-list-item" : "getri-list-item"
                      }
                    >
                      {props.history.location.pathname ===
                      "/transfer/file-upload" ? (
                        <ListItemIcon className="side-icon-div">
                          <div className="sidebar-icon">
                            <FontAwesomeIcon
                              size="lg"
                              color="blue"
                              icon={faHandHoldingUsd}
                            />
                          </div>
                        </ListItemIcon>
                      ) : (
                        <ListItemIcon className="side-icon-div">
                          <FontAwesomeIcon
                            size="lg"
                            color="gray"
                            icon={faHandHoldingUsd}
                          />
                        </ListItemIcon>
                      )}

                      <ListItemText
                        className={
                          props.toggle ? "side-link-div" : "collapse-side-link"
                        }
                      >
                        <span>File Upload</span>
                      </ListItemText>
                    </ListItem>
                  </List>
                </Collapse>

                <ListItem
                  button
                  onClick={handleClick}
                  className={
                    !props.toggle ? "collapse-list-item" : "getri-list-item"
                  }
                >
                  {props.history.location.pathname === "/bills/airtime" ||
                  props.history.location.pathname === "/bills/data" ||
                  props.history.location.pathname === "/bills/cable-tv" ||
                  props.history.location.pathname === "/bills/utilities" ? (
                    <ListItemIcon className="side-icon-div">
                      <div className="sidebar-icon">
                        <FontAwesomeIcon
                          size="lg"
                          color="blue"
                          icon={faMoneyBill}
                        />
                      </div>
                    </ListItemIcon>
                  ) : (
                    <ListItemIcon className="side-icon-div">
                      <FontAwesomeIcon
                        size="lg"
                        color="gray"
                        icon={faMoneyBill}
                      />
                    </ListItemIcon>
                  )}
                  <ListItemText
                    className={
                      props.toggle ? "side-link-div" : "collapse-side-link"
                    }
                  >
                    <span>Bills</span>
                  </ListItemText>
                  {open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <List
                    component="div"
                    disablePadding
                    style={{ paddingLeft: "20px" }}
                  >
                    <ListItem
                      button
                      component={Link}
                      to="/bills/airtime"
                      className={
                        !props.toggle ? "collapse-list-item" : "getri-list-item"
                      }
                    >
                      {props.history.location.pathname === "/bills/airtime" ? (
                        <ListItemIcon className="side-icon-div">
                          <div className="sidebar-icon">
                            <img
                              src="/images/airtime-colored.svg"
                              alt="airtime"
                            />
                          </div>
                        </ListItemIcon>
                      ) : (
                        <ListItemIcon className="side-icon-div">
                          <div className="sidebar-icon">
                            <img
                              src="/images/g_airtime_gray.svg"
                              alt="airtime"
                            />
                          </div>
                        </ListItemIcon>
                      )}

                      <ListItemText
                        className={
                          props.toggle ? "side-link-div" : "collapse-side-link"
                        }
                      >
                        <span>Buy Airtime</span>
                      </ListItemText>
                    </ListItem>

                    <ListItem
                      button
                      component={Link}
                      to="/bills/data"
                      className={
                        !props.toggle ? "collapse-list-item" : "getri-list-item"
                      }
                    >
                      {props.history.location.pathname === "/bills/data" ? (
                        <ListItemIcon className="side-icon-div">
                          <div className="sidebar-icon">
                            <img
                              src="/images/mobile-data-colored.svg"
                              alt="mobile-data"
                            />
                          </div>
                        </ListItemIcon>
                      ) : (
                        <ListItemIcon className="side-icon-div">
                          <div className="sidebar-icon">
                            <img
                              src="/images/mobile-data.svg"
                              alt="mobile-data"
                            />
                          </div>
                        </ListItemIcon>
                      )}

                      <ListItemText
                        className={
                          props.toggle ? "side-link-div" : "collapse-side-link"
                        }
                      >
                        <span>Mobile Data</span>
                      </ListItemText>
                    </ListItem>

                    <ListItem
                      button
                      component={Link}
                      to="/bills/cable-tv"
                      className={
                        !props.toggle ? "collapse-list-item" : "getri-list-item"
                      }
                    >
                      {props.history.location.pathname === "/bills/cable-tv" ? (
                        <ListItemIcon className="side-icon-div">
                          <div className="sidebar-icon">
                            <img
                              src="/images/cable-tv-colored.svg"
                              alt="cable-tv"
                            />
                          </div>
                        </ListItemIcon>
                      ) : (
                        <ListItemIcon className="side-icon-div">
                          <div className="sidebar-icon">
                            <img src="/images/cable-tv.svg" alt="cable-tv" />
                          </div>
                        </ListItemIcon>
                      )}

                      <ListItemText
                        className={
                          props.toggle ? "side-link-div" : "collapse-side-link"
                        }
                      >
                        <span>Cable TV Bills</span>
                      </ListItemText>
                    </ListItem>

                    <ListItem
                      button
                      component={Link}
                      to="/bills/utilities"
                      className={
                        !props.toggle ? "collapse-list-item" : "getri-list-item"
                      }
                    >
                      {props.history.location.pathname ===
                      "/bills/utilities" ? (
                        <ListItemIcon className="side-icon-div">
                          <div className="sidebar-icon">
                            <img
                              src="/images/bills-colored-icon.svg"
                              alt="utility-bills"
                            />
                          </div>
                        </ListItemIcon>
                      ) : (
                        <ListItemIcon className="side-icon-div">
                          <div className="sidebar-icon">
                            <img
                              src="/images/bills-icon.svg"
                              alt="utility-bills"
                            />
                          </div>
                        </ListItemIcon>
                      )}

                      <ListItemText
                        className={
                          props.toggle ? "side-link-div" : "collapse-side-link"
                        }
                      >
                        <span>Utility Bills</span>
                      </ListItemText>
                    </ListItem>
                  </List>
                </Collapse>

                <ListItem
                  button
                  component={Link}
                  to="/loan"
                  className={
                    !props.toggle ? "collapse-list-item" : "getri-list-item"
                  }
                >
                  {props.history.location.pathname === "/loan" ? (
                    <ListItemIcon className="side-icon-div">
                      <div className="sidebar-icon">
                        <img src="/images/g_loans_coloured.svg" alt="loan" />
                      </div>
                    </ListItemIcon>
                  ) : (
                    <ListItemIcon className="side-icon-div">
                      <div className="sidebar-icon">
                        <img src="/images/loan-icon.svg" alt="loan" />
                      </div>
                    </ListItemIcon>
                  )}
                  <ListItemText
                    className={
                      props.toggle ? "side-link-div" : "collapse-side-link"
                    }
                  >
                    <span>Apply for Loan</span>
                  </ListItemText>
                </ListItem>

                <ListItem
                  button
                  component={Link}
                  to="/savings/overview"
                  className={
                    !props.toggle ? "collapse-list-item" : "getri-list-item"
                  }
                >
                  {props.history.location.pathname === "/savings/overview" ||
                  props.match.path === "/savings/new-plan/:slug" ||
                  props.match.path === "/savings/history/:slug" ? (
                    <ListItemIcon className="side-icon-div">
                      <div className="sidebar-icon">
                        <img src="/images/getriSave_logo.png" alt="loan" />
                      </div>
                    </ListItemIcon>
                  ) : (
                    <ListItemIcon className="side-icon-div">
                      <div className="sidebar-icon">
                        <img src="/images/getriSave_logo_grey.png" alt="loan" />
                      </div>
                    </ListItemIcon>
                  )}
                  <ListItemText
                    className={
                      props.toggle ? "side-link-div" : "collapse-side-link"
                    }
                  >
                    <span>GetriSave</span>
                  </ListItemText>
                </ListItem>

                <ListItem
                  button
                  component={Link}
                  to="/qr"
                  className={
                    !props.toggle ? "collapse-list-item" : "getri-list-item"
                  }
                >
                  {props.history.location.pathname === "/qr" ? (
                    <ListItemIcon className="side-icon-div">
                      <div className="sidebar-icon">
                        <FontAwesomeIcon
                          size="lg"
                          color="blue"
                          icon={faQrcode}
                        />
                      </div>
                    </ListItemIcon>
                  ) : (
                    <ListItemIcon className="side-icon-div">
                      <div className="sidebar-icon">
                        <FontAwesomeIcon
                          size="lg"
                          color="gray"
                          icon={faQrcode}
                        />
                      </div>
                    </ListItemIcon>
                  )}
                  <ListItemText
                    className={
                      props.toggle ? "side-link-div" : "collapse-side-link"
                    }
                  >
                    <span>QR</span>
                  </ListItemText>
                </ListItem>

                <ListItem
                  button
                  component={Link}
                  to="/settings/account-levels"
                  className={
                    !props.toggle ? "collapse-list-item" : "getri-list-item"
                  }
                >
                  <ListItemIcon className="side-icon-div">
                    <div className="sidebar-icon">
                      <img src="/images/settings-icon.svg" alt="logo" />
                    </div>
                  </ListItemIcon>
                  <ListItemText
                    className={
                      props.toggle ? "side-link-div" : "collapse-side-link"
                    }
                  >
                    <span>Upgrade Account</span>
                  </ListItemText>
                </ListItem>

                <ListItem
                  button
                  component={Link}
                  to="/add-account"
                  className={
                    !props.toggle ? "collapse-list-item" : "getri-list-item"
                  }
                >
                  {props.history.location.pathname === "/add-account" ? (
                    <ListItemIcon className="side-icon-div">
                      <div className="sidebar-icon">
                        <img
                          src="/images/g_add_account_icon_large_coloured.svg"
                          alt="add-account"
                        />
                      </div>
                    </ListItemIcon>
                  ) : (
                    <ListItemIcon className="side-icon-div">
                      <div className="sidebar-icon">
                        <img
                          src="/images/g_add_account_gray.svg"
                          alt="add-account"
                        />
                      </div>
                    </ListItemIcon>
                  )}
                  <ListItemText
                    className={
                      props.toggle ? "side-link-div" : "collapse-side-link"
                    }
                  >
                    <span>Add New Account</span>
                  </ListItemText>
                </ListItem>
                
                <ListItem>
                  <ListItemText className="side-link-div">
                    <div
                      className=""
                      style={{ borderBottom: "1px dotted gray" }}
                    ></div>
                  </ListItemText>
                </ListItem>

                <ListItem
                  button
                  component={Link}
                  to="/developer-dashboard"
                  className={
                    !props.toggle ? "collapse-list-item" : "getri-list-item"
                  }
                  style={{ fontSize: "10px important!" }}
                >
                  {props.history.location.pathname ===
                  "/developer-dashboard" ? (
                    <ListItemIcon className="side-icon-div">
                      <div className="sidebar-icon">
                        <FontAwesomeIcon color="blue" icon={faCode} />
                      </div>
                    </ListItemIcon>
                  ) : (
                    <ListItemIcon className="side-icon-div">
                      <div className="sidebar-icon">
                        <FontAwesomeIcon color="gray" icon={faCode} />
                      </div>
                    </ListItemIcon>
                  )}
                  <ListItemText
                    className={
                      props.toggle ? "side-link-div" : "collapse-side-link"
                    }
                  >
                    <span style={{ fontSize: "10px" }}>Developer Accounts</span>
                  </ListItemText>
                </ListItem>
              </List>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
