import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from 'react-router-dom';
import "../../css/register.css";
import "../../css/formfield.css";
import { connect } from 'react-redux';
import auth from "../../services/authService";
import CircularProgress from '@material-ui/core/CircularProgress';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { Helmet } from "react-helmet";


const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: "#0038ba",
        height: "100vh",
        [theme.breakpoints.down("sm")]: {
            backgroundColor: 'white',
        },
    },
    root: {
        padding: theme.spacing(15, 30, 10, 30),
        maxWidth: "100%",
        maxHeight: "100%",
        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(0, 0, 0, 0),
        },
    },
    image: {
        backgroundImage: "url('/images/login-image.png')",
        backgroundRepeat: "no-repeat",
        backgroundColor:
            theme.palette.type === "light"
                ? theme.palette.grey[50]
                : theme.palette.grey[900],
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderBottomLeftRadius: "5%",
        borderTopLeftRadius: "5%",
        maxHeight: "100%",
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("md")]: {
            margin: theme.spacing(8, 1),
        },

    },
    logo: {
        maxWidth: "50%",
        marginBottom: 80
    },
    form: {
        width: "100%",
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        borderRadius: 30,
        backgroundColor: "#0036BA",
        fontWeight: "bold",
        padding: 12,
    },
    pap: {
        backgroundColor: "white",
        borderBottomRightRadius: "5%",
        borderTopRightRadius: "5%",
        [theme.breakpoints.down("sm")]: {
            borderBottomRightRadius: "0%",
            borderTopRightRadius: "0%",
        },
    },
    agree: {
        marginLeft: -25,
        fontSize: 15,
    },
    getri: {
        fontWeight: "bold",
    },
}));

const eye = <FontAwesomeIcon icon={faEye} />;

function Login(props) {
    const classes = useStyles();
    let [inputs, setInputs] = useState({
        email: '',
        password: '',
        channel: 'web',
    });
    const [submitted] = useState(false);
    const { email, password, channel } = inputs;

    const { register, handleSubmit } = useForm();
    const [passwordShown, setPasswordShown] = useState(false);

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    function handleChange(e) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    function onSubmit() {
        if (email && password && channel) {
            props.login(email, password, channel)
            inputs.password = ''
        }

    }

    return (
        <div className={classes.header}>
            <Helmet>
                <title>GetriPay - Login</title>
                <meta name="description" content="Login" />
            </Helmet>
            <Grid container component="main" className={classes.root}>
                <CssBaseline />
                <Grid item xs={false} sm={4} md={7} className={classes.image} />
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={5}
                    component={Paper}
                    elevation={0}
                    square
                    className={classes.pap}
                >
                    <div className={classes.paper}>
                        <div>
                            <img
                                src="images/getripaylogo.png"
                                alt="logo"
                                className={classes.logo}
                            />
                        </div>
                        <div>
                            <h1 className='typo'>Login to your account</h1>
                        </div>

                        {/*Start Form */}
                        <form className={classes.form} name="form" onSubmit={handleSubmit(onSubmit)}>
                            {/* username */}
                            <div>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>
                                        <label>Email/Username/Phone Number</label>
                                        <input value={email} onChange={handleChange} name="email" className={(submitted && !email ? ' is-invalid' : '')} />
                                        {submitted && !email &&
                                            <div className="invalid-feedback">This field is required</div>
                                        }
                                    </Grid>
                                </Grid>
                            </div>
                            {/* password input */}
                            <div>
                                <Grid container spacing={1} alignItems="flex-end">
                                    <Grid item>
                                        <label>Password</label>
                                        <input type={passwordShown ? "text" : "password"}
                                            value={password}
                                            onChange={handleChange}
                                            ref={register({ required: "Password is required." })}
                                            name="password"
                                        />
                                        <i className={classes.eye} onClick={togglePasswordVisiblity}>{eye}</i>
                                    </Grid>
                                </Grid>
                            </div>

                            <Grid container>
                                <Grid item component={Link} to="/forgot-password" className='already'>

                                    Forgot your password?  <span className='terms'> Reset it here</span>
                                </Grid>

                                <Grid item component={Link} to="/register" className='already'>
                                    Don't have an account?  <span className='terms'>Create one here</span>
                                </Grid>

                                <Grid item component={Link} to="/signup/bvn" className='already'>
                                    Have BVN?  <span className='terms'>Create an account with it</span>
                                </Grid>
                            </Grid>

                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                size="large"
                                color="primary"
                                className='submit'
                                disabled={props.isLoading}
                            >
                                {props.isLoading && <CircularProgress className='circle' />}
                                       Login
                                </Button>

                        </form>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        login: (email, password, channel) => dispatch(auth.login_user(email, password, channel))
    }
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.loginUser.isLoading

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
