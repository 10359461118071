import React from "react";
import { Grid, Paper } from "@material-ui/core";
import { Link } from "react-router-dom";

function AccountSetupCard({ props }) {
  return (
    <Grid item xs={12} sm={12} md={4} lg={4}>
      <Paper className="dashboardBox1">
        <div>
          <div className="for-inline-items">
            <div>
              <p className="e-cash"> Account Set up</p>
            </div>
          </div>
          <div className="for-inline-items">
            <div>
              <p className="card-text-sm-warning">
                You have pending account actions
              </p>
            </div>
          </div>
          <div className="for-inline-items">
            {props.user.bvn_verify !== true ? (
              <div className="inner-card">
                <img
                  src="images/bvn-icon.svg"
                  alt="logo"
                  className="card-icons"
                />
                <div className="inner-card-text-bold">
                  <br />
                  <Grid
                    className="two"
                    component={Link}
                    to="/validate-bvn"
                    style={{ cursor: "pointer" }}
                  >
                    Link Your BVN
                  </Grid>
                </div>
              </div>
            ) : (
              ""
            )}
            {props.user.user_profile_complete !== true ? (
              <div className="inner-card">
                <img
                  src="images/profile-icon.svg"
                  alt="logo"
                  className="card-icons"
                />
                <div className="inner-card-text-bold">
                  <br />
                  <Grid
                    className="two"
                    component={Link}
                    to="/settings"
                    style={{ cursor: "pointer" }}
                  >
                    Update Your Profile
                  </Grid>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </Paper>
    </Grid>
  );
}

export default AccountSetupCard;
