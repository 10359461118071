import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Button,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { Helmet } from "react-helmet";
import TopBar from "../../../components/TopBar";
import SideNav from "../../../components/SideNav";
import {
  HeaderBar,
  InputGrid,
  MainContent,
} from "../../../components/Appearance";
import { useStyles } from "../QR/components/utils";
import transactions from "../../../services/transactionService";
import { connect, useDispatch } from "react-redux";
import transfers from "../../../services/transferService";
import bulk from "../../../services/bulkPaymentService";
import Select from "react-select";
import { DeleteOutlined, ArrowBack } from "@material-ui/icons";
import BackDrop from "../../../components/BackDrop";
import numeral from "numeral";
import { userActions } from "../../../actions/creators";

const TransferMultiple = (props) => {
  const classes = useStyles();
  const [toggle, setToggle] = useState(true);
  const [sidenav, setSidenav] = useState("getri-sidenav");
  const [beneficiary, setBeneficiary] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [checked, setChecked] = useState(false);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  const [manual, setManual] = useState({
    user_id: props.user?.id,
    batch_name: "",
    account_number: props.balance.account_number,
    bank_code: "090317",
    bank_name: "GetriPay",
    account_name: props.balance.account_name,
    validation_type: "manual",
    transaction_pin: "",
  });

  const [data, setData] = useState({
    account_number: "",
    bank_code: "",
    bank_name: "",
    amount: "",
    narration: null,
  });

  const options = props.banks.map((banks, i) => ({
    label: banks.InstitutionName,
    value: banks.InstitutionCode,
    picture: banks.BankLogoUrl,
    key: i,
  }));

  var valueDisplay = numeral(data.amount).format();

  const handleToggle = () => {
    const mediaScreen = window.matchMedia(
      `(max-width: 700px),
        (min-device-width: 768px)
        and (max-device-width: 1024px)`
    ); //returns true if

    if (mediaScreen.matches) {
      setSidenav("slideOut");
    } else {
      setToggle(!toggle);
    }
  };
  const hideSidebar = () => {
    setSidenav("slideIn");
  };

  function handleChange(e) {
    const { name, value } = e.target;
    setData((data) => ({ ...data, [name]: value }));
  }

  function handleChangeManual(e) {
    const { name, value } = e.target;
    setManual((manual) => ({ ...manual, [name]: value }));
  }

  function handleChangeGetBank(selectedOption) {
    setSelectedOption(selectedOption);
    setData((data) => ({
      ...data,
      bank_code: selectedOption.value,
      bank_name: selectedOption.label,
    }));
  }

  function addToArray() {
    if (beneficiary.length === 10) {
      alert("You cannot add more than 10 accounts/beneficiaries");
      return;
    } else {
      let newBen = [...beneficiary];
      newBen.push({
        account_number: data.account_number,
        bank_name: data.bank_name,
        bank_code: data.bank_code,
        amount: data.amount.replace(/,/g, ""),
        narration: data.narration,
      });
      setBeneficiary(newBen);
      setData({
        account_number: "",
        bank_code: "",
        bank_name: "",
        amount: "",
        narration: "",
      });
      setSelectedOption(null);
    }
  }

  function deleteItem(i) {
    let newDel = [...beneficiary];
    newDel.splice(i, 1);
    setBeneficiary(newDel);
  }

  function validateAccounts() {
    props.validate_manual(manual, beneficiary);
    setDisabled(true);
  }

  function processPayment() {
    if (props.validateRes.batch_code) {
      props.process_payment(
        manual.user_id,
        props.validateRes.batch_code,
        manual.transaction_pin
      );
    }
  }

  function goBack() {
    dispatch(userActions.validateBulkPaymentSuccess([]));
    setDisabled(false);
  }

  function checkNumber(e) {
    const value = e.target.value.replace(/\D/g, "");

    setData((data) => ({
      ...data,
      account_number: value,
    }));
  }

  console.log(data);

  useEffect(() => {
    props.get_account_balance();
    props.get_bank();
    props.get_beneficiaries();
  }, []);

  function handleChangeGetBeneficary(selectedBeneficiary) {
    setSelectedBeneficiary(selectedBeneficiary);
    setData((data) => ({
      ...data,
      bank_code: selectedBeneficiary.value,
      bank_name: selectedBeneficiary.institution_name,
      account_number: selectedBeneficiary.account_number,
    }));
  }

  const beneficiaryOptions = props.listBeneficiary.map(
    (listBeneficiary, i) => ({
      label:
        listBeneficiary.customer_name +
        " " +
        "||" +
        " " +
        listBeneficiary.account_number +
        " " +
        "||" +
        " " +
        listBeneficiary.institution_name,
      value: listBeneficiary.institution_code,
      account_number: listBeneficiary.account_number,
      institution_name: listBeneficiary.institution_name,
      key: i,
    })
  );
  // List beneficiaries toggle
  const handleChangeToggle = (event) => {
    setChecked((prev) => !prev);
    setShow((prevShow) => !prevShow);
    setData((data) => ({
      ...data,
      bank_code: "",
      bank_name: "",
      account_number: "",
      narration: null,
    }));
  };
  return (
    <>
      <Helmet>
        <title>GetriPay - Fastest growing digital bank with rewards </title>
        <meta name="description" content="Bulk Payment" />
        <meta name="keywords" content="Bulk Payment" />
      </Helmet>

      <TopBar handleToggle={handleToggle} />
      <SideNav
        toggle={toggle}
        className={sidenav}
        hideSidebar={hideSidebar}
        {...props}
      />
      <MainContent toggle={toggle}>
        {props.loadingValidate || props.loadingProcess ? (
          <div>
            <BackDrop />
          </div>
        ) : null}
        <main className={classes.content}>
          <Grid container>
            <Grid item xs={12} md={12} lg={12}>
              <Container maxWidth="lg" className={classes.container}>
                <Container className="main-container">
                  <HeaderBar
                    topic="Transfer to multiple accounts"
                    text={Number(
                      props.balance.available_balance
                    ).toLocaleString()}
                  />
                </Container>

                <Grid container>
                  <Grid item xs={12} md={6} lg={6}>
                    <div className="bulk-payment-plain">
                      <div className={disabled ? "overlay" : ""}></div>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={checked}
                            onChange={handleChangeToggle}
                            name="checkedB"
                            color="primary"
                          />
                        }
                        label="Transfer to a saved beneficary"
                      />
                      <div className="bulkDiv">
                        {show ? (
                          <InputGrid>
                            <Select
                              styles={{
                                control: (base, state) => ({
                                  ...base,
                                  "&:hover": { borderColor: "black" },
                                  border: "none",
                                  borderRadius: "none",
                                  borderBottom: "1px solid #999999",
                                  boxShadow: "none",
                                  padding: "0",
                                }),
                              }}
                              placeholder="Select Beneficiary"
                              value={selectedBeneficiary}
                              onChange={handleChangeGetBeneficary}
                              options={beneficiaryOptions}
                            />
                          </InputGrid>
                        ) : (
                          <InputGrid>
                            <Select
                              styles={{
                                control: (base, state) => ({
                                  ...base,
                                  "&:hover": { borderColor: "black" },
                                  border: "none",
                                  borderRadius: "none",
                                  fontSize: "12px",
                                  borderBottom: "1px solid #999999",
                                  boxShadow: "none",
                                  padding: "0",
                                }),
                              }}
                              placeholder="Select Bank"
                              value={selectedOption}
                              onChange={handleChangeGetBank}
                              options={options}
                            />
                          </InputGrid>
                        )}

                        <InputGrid>
                          <label>Account Number</label>
                          <input
                            className="input-div"
                            maxLength={10}
                            name="account_number"
                            placeholder="Enter account number here"
                            value={data.account_number}
                            onChange={handleChange}
                            onChange={(e) => {
                              handleChange(e);
                              checkNumber(e);
                            }}
                            autoComplete="off"
                          />
                        </InputGrid>

                        <InputGrid>
                          <label>Amount</label>
                          <input
                            className="input-div"
                            name="amount"
                            placeholder="Amount"
                            value={valueDisplay}
                            onChange={handleChange}
                            autoComplete="off"
                          />
                        </InputGrid>

                        <InputGrid>
                          <label>Narration</label>
                          <input
                            type="text"
                            className="input-div"
                            name="narration"
                            placeholder="Enter narration here"
                            value={data.narration}
                            onChange={handleChange}
                            autoComplete="off"
                          />
                        </InputGrid>

                        <Button
                          onClick={addToArray}
                          fullWidth
                          variant="contained"
                          size="small"
                          disabled={data.amount === ""}
                          className="getri-button"
                          style={{
                            backgroundColor:
                              beneficiary.amount === "" ? "" : "#0037ba",
                            color: "#fff",
                          }}
                        >
                          Add
                        </Button>
                      </div>
                      <p>
                        Details you add will be displayed here before validation
                      </p>
                      {beneficiary && (
                        <table style={{ fontSize: "12px" }}>
                          <tr>
                            <th>Account Number</th>
                            <th>Bank Name</th>
                            <th>Amount</th>
                            <th>Remove</th>
                          </tr>

                          {beneficiary.map((res, i) => {
                            return (
                              <tr key={i}>
                                <td>{res.account_number}</td>
                                <td>{res.bank_name}</td>
                                <td>
                                  NGN {Number(res.amount).toLocaleString()}{" "}
                                </td>
                                <td>
                                  <DeleteOutlined
                                    style={{ color: "red" }}
                                    onClick={() => deleteItem(i)}
                                  />{" "}
                                </td>
                              </tr>
                            );
                          })}
                        </table>
                      )}

                      <div style={{ marginTop: "15px" }}>
                        <InputGrid>
                          <label>General Transaction Description</label>
                          <input
                            className="input-div"
                            type="text"
                            name="batch_name"
                            placeholder="Enter a general transaction description here"
                            value={manual.batch_name}
                            onChange={handleChangeManual}
                            autoComplete="off"
                          />
                        </InputGrid>

                        <InputGrid>
                          <label>Transaction Pin</label>
                          <input
                            className="input-div"
                            type="password"
                            name="transaction_pin"
                            placeholder="Enter your transaction pin here"
                            value={manual.transaction_pin}
                            onChange={handleChangeManual}
                            maxLength={4}
                            autoComplete="off"
                          />
                        </InputGrid>
                      </div>

                      <Button
                        onClick={validateAccounts}
                        fullWidth
                        variant="contained"
                        size="small"
                        disabled={
                          manual.batch_name === "" ||
                          manual.transaction_pin === "" ||
                          beneficiary.length === 0
                        }
                        className="getri-button"
                        style={{
                          backgroundColor:
                            manual.batch_name === "" ||
                            manual.transaction_pin === "" ||
                            beneficiary.length === 0
                              ? ""
                              : "#0037ba",
                          color: "#fff",
                        }}
                      >
                        Validate
                      </Button>
                    </div>
                  </Grid>

                  <Grid item xs={12} md={6} lg={6}>
                    <div className="bulk-payment-gray">
                      {disabled && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                        >
                          {" "}
                          <ArrowBack onClick={goBack} />
                          <h6 onClick={goBack}>GO BACK</h6>
                        </div>
                      )}

                      <p>Verified Accounts</p>
                      {props.validateRes?.verify_account?.length !== 0 && (
                        <div>
                          {props.validateRes && (
                            <table style={{ fontSize: "12px" }}>
                              <tr>
                                <th>Account Name</th>
                                <th>Account Number</th>
                                <th>Amount</th>
                                <th>Bank</th>
                              </tr>

                              {props.validateRes?.verify_account?.map(
                                (res, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>{res.account_name}</td>
                                      <td>{res.account_number}</td>
                                      <td>
                                        NGN {numeral(res.amount).format()}{" "}
                                      </td>
                                      <td>{res.bank_name}</td>
                                    </tr>
                                  );
                                }
                              )}
                            </table>
                          )}
                        </div>
                      )}

                      {props.validateRes?.verify_account && (
                        <p style={{ fontSize: "12px" }}>
                          {props.validateRes?.verify_account?.length} Verified
                          Accounts
                        </p>
                      )}

                      <p style={{ fontWeight: "bolder", color: "red" }}>
                        Unverified Accounts
                      </p>

                      {props.validateRes?.un_verify_account?.length !== 0 && (
                        <div>
                          {props.validateRes && (
                            <table style={{ fontSize: "12px", color: "red" }}>
                              <tr>
                                <th>Bank</th>
                                <th>Account Number</th>
                                <th>Amount</th>
                              </tr>

                              {props.validateRes?.un_verify_account?.map(
                                (res, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>{res.bank_name}</td>
                                      <td>{res.account_number}</td>
                                      <td>
                                        NGN {numeral(res.amount).format()}{" "}
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </table>
                          )}
                        </div>
                      )}

                      {props.validateRes?.un_verify_account && (
                        <p style={{ fontSize: "12px", color: "red" }}>
                          {props.validateRes?.un_verify_account?.length}
                          Unverified Accounts
                        </p>
                      )}

                      {props.validateRes?.verify_account ||
                      props.validateRes?.un_verify_account ? (
                        <Button
                          onClick={processPayment}
                          fullWidth
                          variant="contained"
                          size="small"
                          //disabled={props.validateRes.success !== true}
                          className="getri-button"
                          style={{
                            backgroundColor: "#0037ba",
                            color: "#fff",
                          }}
                        >
                          Proceed with payment
                        </Button>
                      ) : null}
                    </div>
                  </Grid>
                </Grid>
              </Container>
            </Grid>
          </Grid>
        </main>
      </MainContent>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_account_balance: (payload) =>
      dispatch(transactions.get_account_balance(payload)),
    get_bank: (payload) => dispatch(transfers.get_bank(payload)),
    validate_manual: (manual, beneficiary) =>
      dispatch(bulk.validate_manual(manual, beneficiary)),
    process_payment: (user_id, batch_data_id, transaction_pin) =>
      dispatch(bulk.process_payment(user_id, batch_data_id, transaction_pin)),
    get_beneficiaries: () => dispatch(transfers.get_beneficiaries()),
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.userDetails.data,
    balance: state.getAccountBalance.data,
    banks: state.fetch.data,
    validateRes: state.validateBulkPayment.data,
    loadingValidate: state.validateBulkPayment.isLoading,
    loadingProcess: state.processBulkPayment.isLoading,
    listBeneficiary: state.getBeneficiary.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TransferMultiple);
