import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect, useSelector } from "react-redux";
import "../../../../css/dashboard.css";
import TransactionScroll from "../../../../components/Skeleton/TransactionScroll";
import bulk from "../../../../services/bulkPaymentService";
import BulkDrawer from "./BulkDrawer";
import Pagination from "./Pagination/Pagination";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  large: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  txnLogo: {
    height: "25px",
    position: "absolute",
    marginLeft: "-50px",
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      marginLeft: "0px",
    },
  },
  small: {
    fontSize: "12px",
    marginTop: theme.spacing(-2),
    marginLeft: "2px",
  },
  ref: {
    marginTop: theme.spacing(-0.5),
    fontSize: "12px",
    marginLeft: "2px",
    fontWeight: "bolder",
  },
  name: {
    fontSize: "11px",
    fontWeight: "bolder",
    marginLeft: "2px",
  },
  amount: {
    fontWeight: "700",
  },
  success: {
    color: "#00e600",
    fontSize: "12px",
    textAlign: "right",
    fontWeight: "500",
  },
  fee: {
    color: "red",
    textAlign: "right",
    fontWeight: "500",
  },
  text: {
    fontSize: "13px",
  },
}));

function BulkHistoryTab(props) {
  const classes = useStyles();
  const user = useSelector((state) => state.userDetails.data);
  const [open, setOpen] = useState(false);
  const [batchName, setBatchName] = useState("");
  const [batchCode, setBatchCode] = useState("");
  const [batchId, setBatchId] = useState("");
  useEffect(() => {
    props.bulk_history(user.id, 1);
  }, []);

  // Change page
  function handlePageClick(data) {
    props.bulk_history(user.id, data.selected + 1);
  }

  return (
    <div className={classes.root}>
      <div style={{ textAlign: "right" }}>
        <Pagination
          handlePageClick={handlePageClick}
          totalItems={props.history?.last_page}
        />
      </div>
      <div style={{ overflowX: "auto" }}>
        {props.isLoading ? (
          <TransactionScroll />
        ) : (
          <table style={{ fontSize: "13px" }}>
            <tr>
              <th>Batch Code</th>
              <th>Date</th>
              <th>Batch Name</th>
              <th>Total Amount</th>
              <th>Amount Transferred</th>
              <th>Status</th>
              <th></th>
            </tr>
            {props.history?.data?.map((transaction, i) => (
              <tr key={i}>
                <td>{transaction?.batch_code}</td>
                <td>{new Date(transaction?.created_at).toLocaleString()}</td>
                <td>{transaction?.batch_name}</td>
                <td>
                  NGN {Number(transaction?.total_amount).toLocaleString()}
                </td>
                <td>
                  NGN {Number(transaction?.amount_transfer).toLocaleString()}
                </td>
                <td>{transaction?.status}</td>
                <td
                  onClick={() => {
                    setOpen(true);
                    setBatchName(transaction?.batch_name);
                    setBatchCode(transaction?.batch_code);
                    setBatchId(transaction?.id);
                    props.bulk_details(transaction?.id, 1);
                  }}
                  style={{
                    color: "blue",
                    fontWeight: "bolder",
                    cursor: "pointer",
                  }}
                >
                  More details
                </td>
              </tr>
            ))}
          </table>
        )}
      </div>
      <BulkDrawer
        drawerActive={open}
        setDrawerActive={setOpen}
        details={props.details}
        batchName={batchName}
        batchCode={batchCode}
        id={batchId}
        bulk_details={props.bulk_details}
        loading={props.isFetching}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.bulkPaymentHistory.isLoading,
    isFetching: state.bulkPaymentDetails.isLoading,
    history: state.bulkPaymentHistory.history,
    details: state.bulkPaymentDetails.details,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    bulk_history: (user_id, selected) =>
      dispatch(bulk.bulk_history(user_id, selected)),
    bulk_details: (batch_id, selected) =>
      dispatch(bulk.bulk_details(batch_id, selected)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkHistoryTab);
