import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Menu, MenuItem } from '@material-ui/core';
import { connect } from 'react-redux';
import '../../../../css/dashboard.css';
import TransactionScroll from '../../../../components/Skeleton/TransactionScroll';
import transactions from '../../../../services/transactionService';

const useStyles = makeStyles((theme) => ({
	large: {
		width: theme.spacing(6),
		height: theme.spacing(6),
	},
	txnLogo: {
		// width: '30px',
		height: '25px',
    position: 'absolute',
    marginLeft: '-35px',
    marginTop: '-7px',
	},
	small: {
		fontSize: '12px',
		// marginTop: theme.spacing(-2),
		marginLeft: '2px',
	},
	name: {
		fontSize: '12px',
		fontWeight: 'bolder',
		marginLeft: '2px',
	},
	amount: {
		fontWeight: '900',
	},
	success: {
		color: '#00e600',
		fontSize: '12px',
		textAlign: 'right',
		fontWeight: '500',
	},
	fee: {
		color: 'red',
		textAlign: 'right',
		fontWeight: '500',
	},
}));

function LoanHistoryTab(props) {
	const classes = useStyles();

	const [sort, setSort] = useState(null);
	const [setSortHighest] = useState(null);
	const [ setSortLowest] = useState(null);
	const [setSortOldest] = useState(null);
	const [setSortRecent] = useState(null);

	const handleClick = (event) => {
		setSort(event.currentTarget);
	};

	const handleClose = () => {
		setSort(null);
	};

	// Sort by highest amount
	const sortByHighestAmount = () => {
		setSortHighest(props.loans.sort((a, b) => b.amount - a.amount));
	};

	// Sort by lowest amount
	const sortByLowestAmount = () => {
		setSortLowest(props.loans.sort((a, b) => a.amount - b.amount));
	};

	// Sort by oldest date
	const sortByOldestDate = () => {
		setSortOldest(props.loans.sort((a, b) => (a.created_at > b.created_at ? 1 : a.created_at < b.created_at ? -1 : 0)));
	};

	// Sort by most recent date
	const sortByRecentDate = () => {
		setSortRecent(props.loans.sort((a, b) => (a.created_at < b.created_at ? 1 : a.created_at > b.created_at ? -1 : 0)));
	};

	return (
		<div className={classes.root}>
			<div>
				<Grid container className='history'>
					<Grid item md={3} lg={3}>
						<h4>All Loans</h4>
					</Grid>
					<Grid container className='item4'>
						<Grid item md={6} lg={6}>
							<span className='arrange2'>
								<img src='/images/filter-icon.svg' className='filter-icon' alt='filter' />
								<span>All Loans</span>
							</span>
						</Grid>

						<Grid item md={6} lg={6}>
							<span className='arrange2' onClick={handleClick}>
								<img src='/images/sort-icon.svg' className='filter-icon' alt='sort' />
								<span>Sort by</span>
							</span>
							<Menu anchorEl={sort} keepMounted open={Boolean(sort)} onClose={handleClose}>
								<MenuItem onClick={sortByLowestAmount}>Lowest Amount</MenuItem>
								<MenuItem onClick={sortByHighestAmount}>Highest Amount</MenuItem>
								<MenuItem onClick={sortByOldestDate}>Oldest Loan</MenuItem>
								<MenuItem onClick={sortByRecentDate}>Most Recent Loan</MenuItem>
							</Menu>
						</Grid>
					</Grid>
				</Grid>

				{props.isGettingLoanHistory ? <TransactionScroll /> : ''}

				{props.loans.map((loan, i) => (
					<div key={i} className='history-table'>
						<div className='user-inline-items'>
							<div style={{ borderRight: '1px solid #cccccc', padding: 'none', paddingRight: '5%' }}>
								<span style={{ fontSize: '10px', display: 'block', color: 'blue', fontWeight: 'bold' }}>
									Amount + Interest
								</span>
								<span className='trans-amount'>NGN {Number(loan.total_amount_and_interest).toLocaleString()}</span>
							</div>

							<div style={{ borderRight: '1px solid #cccccc', padding: 'none', paddingRight: '5%' }}>
								<div className='user-inline-items'>
                <span style={{marginLeft: '1px'}}>
									<img alt='logo' src='/images/yellow-logo.svg' className={classes.txnLogo} />
                </span>
									<div>
										<span className={classes.name} style={{display: 'block'}}>
											Amount Payable: 
											<span style={{ fontSize: '11px', color: 'red', fontWeight: 'bold' }} className={classes.name}>
												NGN {Number(loan.principal_remain).toLocaleString()}
											</span>
										</span>
										<span className={classes.small} style={{display: 'block'}}>
											Interest Payable: 
											<span style={{ fontSize: '11px', color: 'red', fontWeight: 'bold' }} className={classes.name}>
												NGN {Number(loan.interest_remain).toLocaleString()}
											</span>{' '}
										</span>
									</div>
								</div>
							</div>
							<div style={{ borderRight: '1px solid #cccccc', padding: 'none', paddingRight: '5%' }}>
								<span style={{ display: 'block' }} className={classes.small}>
									Loan Start Date: {loan.start_date ? loan.start_date : 'nill'}
								</span>
								<span style={{ display: 'block' }} className={classes.small}>
									Loan End Date: {loan.end_date ? loan.end_date : 'nill'}
								</span>
							</div>
							<div>
								<span style={{ fontSize: '10px', display: 'block', color: 'blue', fontWeight: 'bold' }}>Status</span>
								{loan.status_id === 1 ? (
									<span className='deb3' style={{ display: 'inline-block' }}>
										{(loan.status_id = 'Pending')}
									</span>
								) : loan.status_id === 2 ? (
									<span className='deb2' style={{ display: 'inline-block' }}>
										{(loan.status_id = 'Approved')}
									</span>
								) : loan.status_id === 3 ? (
									<span className='deb4' style={{ display: 'inline-block' }}>
										{(loan.status_id = 'Authorized')}
									</span>
								) : loan.status_id === 4 ? (
									<span className='deb1' style={{ display: 'inline-block' }}>
										{(loan.status_id = 'Rejected')}
									</span>
								) : loan.status_id === 5 ? (
									<span className='deb4' style={{ display: 'inline-block' }}>
										{(loan.status_id = 'Running')}
									</span>
								) : loan.status_id === 6 ? (
									<span className='deb2' style={{ display: 'inline-block' }}>
										{(loan.status_id = 'Paid')}
									</span>
								) : loan.status_id === 7 ? (
									<span className='deb1' style={{ display: 'inline-block' }}>
										{(loan.status_id = 'Overdue')}
									</span>
								) : loan.status_id === 8 ? (
									<span className='deb1' style={{ display: 'inline-block' }}>
										{(loan.status_id = 'Paid Overdue')}
									</span>
								) : (
									<span style={{ display: 'inline-block' }} className='deb1'>
										{loan.status_id}{' '}
									</span>
								)}
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		loans: state.getLoanHistory.data,
		isGettingLoanHistory: state.fetch.isGettingLoanHistory,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		get_loan_history: (payload) => dispatch(transactions.get_loan_history(payload)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LoanHistoryTab);
