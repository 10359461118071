export const developerConstants = {
    CREATE_DEVELOPER_ACCOUNT_REQUEST: 'CREATE_DEVELOPER_ACCOUNT_REQUEST',
    CREATE_DEVELOPER_ACCOUNT_SUCCESS: 'CREATE_DEVELOPER_ACCOUNT_SUCCESS',
    CREATE_DEVELOPER_ACCOUNT_FAILURE: 'CREATE_DEVELOPER_ACCOUNT_FAILURE',

    GET_DEVELOPER_ACCOUNT_REQUEST: 'GET_DEVELOPER_ACCOUNT_REQUEST',
    GET_DEVELOPER_ACCOUNT_SUCCESS: 'GET_DEVELOPER_ACCOUNT_SUCCESS',
    GET_DEVELOPER_ACCOUNT_FAILURE: 'GET_DEVELOPER_ACCOUNT_FAILURE',

    GET_DEVELOPER_KEY_REQUEST: 'GET_DEVELOPER_KEY_REQUEST',
    GET_DEVELOPER_KEY_SUCCESS: 'GET_DEVELOPER_KEY_SUCCESS',
    GET_DEVELOPER_KEY_FAILURE: 'GET_DEVELOPER_KEY_FAILURE',

    UPLOAD_DOCUMENT_REQUEST: 'UPLOAD_DOCUMENT_REQUEST',
    UPLOAD_DOCUMENT_SUCCESS: 'UPLOAD_DOCUMENT_SUCCESS',
    UPLOAD_DOCUMENT_FAILURE: 'UPLOAD_DOCUMENT_FAILURE',

    GET_DOCUMENT_REQUEST: 'GET_DOCUMENT_REQUEST',
    GET_DOCUMENT_SUCCESS: 'GET_DOCUMENT_SUCCESS',
    GET_DOCUMENT_FAILURE: 'GET_DOCUMENT_FAILURE'
}