import React, { useState, useEffect } from "react";
import { Container, Grid, Button } from "@material-ui/core";
import "./qr.css";
import { Helmet } from "react-helmet";
import TopBar from "../../../components/TopBar";
import SideNav from "../../../components/SideNav";
import { HeaderBar, MainContent } from "../../../components/Appearance";
import { SavingsTab, SavingsTabs, useStyles } from "./components/utils";
import transactions from "../../../services/transactionService";
import { connect } from "react-redux";
import { Link, useRouteMatch, Switch, Route } from "react-router-dom";
import qr from "../../../services/qrService";
import { MerchantProfile } from "./MerchantProfile";
import CreateMerchantModal from "./components/CreateMerchantModal";
import { SubMerchant } from "./SubMerchant";

const Qr = (props) => {
  const classes = useStyles();
  const [toggle, setToggle] = useState(true);
  const [sidenav, setSidenav] = useState("getri-sidenav");
  const match = useRouteMatch();
  const user_id = props.user.id;
  const [openCreate, setOpenCreate] = useState(false);

  const handleToggle = () => {
    const mediaScreen = window.matchMedia(
      `(max-width: 700px),
        (min-device-width: 768px)
        and (max-device-width: 1024px)`
    ); //returns true if

    if (mediaScreen.matches) {
      setSidenav("slideOut");
    } else {
      setToggle(!toggle);
    }
  };
  const hideSidebar = () => {
    setSidenav("slideIn");
  };

  useEffect(() => {
    props.get_account_balance();
    props.get_merchant_and_submerchants(user_id);
  }, []);

  const handleOpenCreate = () => {
    setOpenCreate(true);
  };

  const handleCloseCreate = () => {
    setOpenCreate(false);
  };

  return (
    <>
      <Helmet>
        <title>GetriPay - Fastest growing digital bank with rewards </title>
        <meta name="description" content="QR" />
        <meta name="keywords" content="Qr" />
      </Helmet>

      <TopBar handleToggle={handleToggle} />
      <SideNav
        toggle={toggle}
        className={sidenav}
        hideSidebar={hideSidebar}
        {...props}
      />
      <MainContent toggle={toggle}>
        <main className={classes.content}>
          <Grid container>
            <Grid item xs={12} md={12} lg={12}>
              <Container maxWidth="lg" className={classes.container}>
                <Container className="main-container">
                  <HeaderBar
                    topic="QR"
                    text={Number(
                      props.balance.available_balance
                    ).toLocaleString()}
                  />
                </Container>

                <Container>
                  <div position="static" className={classes.tabContainer}>
                    <SavingsTabs
                      value={props.location.pathname}
                      aria-label="simple tabs example"
                    >
                      <SavingsTab
                        component={Link}
                        to={`${match.url}`}
                        value={`${match.url}`}
                        label="Merchant Profile"
                      />
                      <SavingsTab
                        component={Link}
                        to={`${match.url}/submerchants`}
                        value={`${match.url}/submerchants`}
                        label="Submerchants"
                      />
                    </SavingsTabs>
                  </div>

                  <Grid container spacing={0}>
                    <Switch>
                      <Route path={`${match.url}/submerchants`}>
                        <div>
                          <h2 className="history">Submerchants</h2>

                          {props.merchant.length === 0 ? (
                            <div className="empty-merchant">
                              <h2>You do not have a merchant profile, yet</h2>{" "}
                              <img
                                src="/images/void.svg"
                                alt="No data"
                                height="300"
                              />
                              <Grid container>
                                <Button
                                  fullWidth
                                  variant="contained"
                                  size="small"
                                  color="primary"
                                  className="submit"
                                  onClick={handleOpenCreate}
                                >
                                  Create Merchant Profile
                                </Button>
                              </Grid>
                              <CreateMerchantModal
                                open={openCreate}
                                close={handleCloseCreate}
                                balance={props.balance}
                              />
                            </div>
                          ) : (
                            <div>
                              <SubMerchant merchant={props.merchant}  balance={props.balance} />
                            </div>
                          )}
                        </div>
                      </Route>

                      <Route path={`${match.url}`}>
                        <div>
                          {props.merchant.length === 0 ? (
                            <div className="empty-merchant">
                              <h2>You do not have a merchant profile, yet</h2>{" "}
                              <img
                                src="/images/void.svg"
                                alt="No data"
                                height="300"
                              />
                              <Grid container>
                                <Button
                                  fullWidth
                                  variant="contained"
                                  size="small"
                                  color="primary"
                                  className="submit"
                                  onClick={handleOpenCreate}
                                >
                                  Create Merchant Profile
                                </Button>
                              </Grid>
                              <CreateMerchantModal
                                open={openCreate}
                                close={handleCloseCreate}
                                balance={props.balance}
                              />
                            </div>
                          ) : (
                            <div>
                              <h2>Merchant Profile</h2>
                              <MerchantProfile merchant={props.merchant} />
                            </div>
                          )}
                        </div>
                      </Route>
                    </Switch>
                  </Grid>
                </Container>
              </Container>
            </Grid>
          </Grid>
        </main>
      </MainContent>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_account_balance: (payload) =>
      dispatch(transactions.get_account_balance(payload)),
    get_merchant_and_submerchants: (id) =>
      dispatch(qr.get_merchant_and_submerchants(id)),
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.userDetails.data,
    balance: state.getAccountBalance.data,
    merchant: state.getQrMerchantSubmerchant.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Qr);
