import axios from "axios";
import { userActions } from "../actions/creators";
import config from "../config";

const baseUrl = `${config.apiUrl}`;

const transactions = {
  // Get listing of all user's transactions
  get_transactions: (pageNumber) => {
    return async (dispatch) => {
      dispatch(userActions.uiFetching());

      const id = localStorage.getItem("user_id");
      const account_no = localStorage.getItem("account");
      const prevDate = new Date();
      prevDate.setDate(prevDate.getDate() - 30);
      const date = new Date();

      await axios
        .get(
          `${baseUrl}/transaction/get_history/${id}?&debitedAccount=${account_no}&debitedAccount2=true&page=${pageNumber}&startDate=${prevDate}&endDate=${date}`
        )
        .then((res) => {
          localStorage.setItem("totalTxn", res.data.lastPage);
          dispatch(userActions.uiFetched(res.data.transactions));
        });
    };
  },

  get_transactions_filter: (fromDateString, toDateString) => {
    return async (dispatch) => {
      dispatch(userActions.uiFetching());

      const id = localStorage.getItem("user_id");
      const account_no = localStorage.getItem("account");

      await axios
        .get(
          `${baseUrl}/transaction/get_history/${id}?&debitedAccount=${account_no}&per_page=1000&startDate=${fromDateString}&endDate=${toDateString}`
        )
        .then((res) => {
          dispatch(userActions.uiFetched(res.data.transactions));
        });
    };
  },

  // Get listing for all user's loans
  get_loan_history: () => {
    return async (dispatch) => {
      dispatch(userActions.getLoanHistoryRequest());

      const id = localStorage.getItem("user_id");

      await axios
        .get(`${baseUrl}/loan/loans/get_user_loans/${id}`)
        .then((res) => {
          dispatch(userActions.getLoanHistorySuccess(res.data.data));
        });
    };
  },

  // To get transaction count for a particular user
  get_transaction_count: () => {
    return async (dispatch) => {
      dispatch(userActions.getTransactionCountRequest());

      const id = localStorage.getItem("user_id");

      await axios
        .get(`${baseUrl}/transaction/get_transaction_report/${id}`)
        .then((res) => {
          dispatch(
            userActions.getTransactionCountSuccess(
              res?.data?.data?.transactionReport
            )
          );
        });
    };
  },

  // To get Account balance
  get_account_balance: () => {
    return async (dispatch) => {
      dispatch(userActions.getAccountBalanceRequest());

      const account_no = localStorage.getItem("account");

      await axios
        .get(`${baseUrl}/accounts/${account_no}/balance`)
        .then((res) => {
          dispatch(userActions.getAccountBalanceSuccess(res.data.data));
        });
    };
  },

  // To get a user's frequent transaction
  get_frequent_transaction: () => {
    return async (dispatch) => {
      dispatch(userActions.getFrequentTransactionRequest());

      const id = localStorage.getItem("user_id");

      await axios
        .get(`${baseUrl}/transaction/get_frequent_transactions/?id=${id}`)
        .then((res) => {
          dispatch(userActions.getFrequentTransactionSuccess(res.data.data));
        });
    };
  },

  // To get a user's transaction limit
  get_transaction_limit: () => {
    return async (dispatch) => {
      dispatch(userActions.getTransactionLimitRequest());

      const kyc = localStorage.getItem("kyc");
      const id = localStorage.getItem("user_id");

      await axios
        .get(`${baseUrl}/transaction/get_user_txn_limit/${id}/${kyc}`)
        .then((res) => {
          dispatch(userActions.getTransactionLimitSuccess(res.data.data));
        });
    };
  },

  get_transaction_services: () => {
    return async (dispatch) => {
      // dispatch(userActions.getAllBeneficiaryRequest());
      await axios.get(`${baseUrl}/transaction/get_txn_services`).then((res) => {
        //  dispatch(userActions.getAllBeneficiarySuccess(res.data.data));
      });
    };
  },

  get_transaction_fee: (amount, serviceCode) => {
    return async (dispatch) => {
      dispatch(userActions.getTransactionFeeRequest());
      const userId = localStorage.getItem("user_id");
      await axios
        .post(`${baseUrl}/transaction/get_fee`, {
          userId: userId,
          transactionAmount: amount,
          serviceCode: serviceCode,
          transactionCategory: "TC_002_TF",
        })
        .then((res) => {
          dispatch(
            userActions.getTransactionFeeSuccess(res.data.data.transactionFee)
          );
        });
    };
  },

  //   get_account_statement: (fromDateString, toDateString) => {
  //     return async dispatch => {
  //         dispatch(userActions.getAccountStatementRequest())

  //         const id = localStorage.getItem('user_id')

  //         await axios.get(`${baseUrl}/transaction/gen_account_statement/${id}?&startDate=${fromDateString}&endDate=${toDateString}`)
  //             .then((res) => {
  //                dispatch(userActions.getAccountStatementSuccess(res.data.statmentLink));
  //             });
  //     }
  // },

  get_account_statement: (fromDateString, toDateString) => {
    return async (dispatch) => {
      dispatch(userActions.getAccountStatementRequest());

      const account_no = localStorage.getItem("account");

      await axios
        .post(
          `${baseUrl}/user_account/statement`,
          {
            account_number: account_no,
            start_date: fromDateString,
            end_date: toDateString,
            return_pdf: true,
          },
          {
            headers: {
              //   "Content-Type": "application/pdf",
              responseType: "blob",
            },
          }
        )
        .then((res) => {
          dispatch(userActions.getAccountStatementSuccess(res.data));
        });
    };
  },
};

export default transactions;
