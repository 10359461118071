import React from "react";
import { Grid, Paper } from "@material-ui/core";

function FrequentPaymentsCard({ props }) {
  return (
    <Grid item xs={12} sm={12} md={4} lg={4}>
      {/* account details section */}
      <Paper className="dashboardBox1">
        <div>
          <div className="for-inline-items">
            <div>
              <p className="e-cash"> Frequent Payments </p>
            </div>
          </div>

          <div className="for-inline-items">
            <div className="inner-card">
              <img
                src="/images/airtime-colored.svg"
                alt="logo"
                className="card-icons-2"
              />
              <div className="inner-card-text-bold">
                <span className="inner-text-gray">
                  {props?.firstCount?.highestCount}
                </span>
                <br />
                <span>{props?.firstCount?.transactionType}</span>
                <br />
                <p className="card-text-sm">
                  NGN{Number(props?.firstCount?.summedAmount).toLocaleString()}
                </p>
              </div>
            </div>
            <div className="inner-card">
              <img
                src="/images/g_send_money_coloured.svg"
                alt="logo"
                className="card-icons-2"
              />
              <div className="inner-card-text-bold">
                <span className="inner-text-gray">
                  {props?.secondCount?.secondhighestCount}
                </span>
                <br />
                <span>{props?.secondCount?.transactionType2}</span>
                <br />
                <p className="card-text-sm">
                  NGN{" "}
                  {Number(props?.secondCount?.summedAmount2).toLocaleString()}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Paper>
    </Grid>
  );
}

export default FrequentPaymentsCard;
