import React from "react";
import { Divider, IconButton, makeStyles, Drawer } from "@material-ui/core";
import { CloseRounded } from "@material-ui/icons";

const drawerWidth = 500;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  drawerPaper: {
    width: drawerWidth,
    fontSize: "13px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  drawerTitle: {
    fontWeight: "bolder",
    fontSize: "16px",
  },
  small: {
    fontSize: "12px",
    marginTop: theme.spacing(-2),
    marginLeft: "2px",
  },
  ref: {
    marginTop: theme.spacing(-0.5),
    fontSize: "12px",
    marginLeft: "2px",
    fontWeight: "bolder",
  },
  name: {
    fontSize: "11px",
    fontWeight: "bolder",
    marginLeft: "2px",
  },
}));

export default function ChargebackDrawer({
  drawerActive,
  setDrawerActive,
  config,
  chargeback,
  chargebackValue,
}) {
  const classes = useStyles();
  let currentLevel = chargeback?.currentChargeBackLevel?.chargeBackLevelCode;

  const currentConfig = config.filter(
    (item) => item.chargeBackLevelCode === currentLevel
  );

  const month = new Date().toLocaleString("default", {
    month: "long",
    year: "numeric",
  });

  return (
    <Drawer
      anchor="right"
      open={drawerActive}
      classes={{
        paper: classes.drawerPaper,
      }}
      onClose={() => setDrawerActive(false)}
    >
      <div className={classes.drawerHeader}>
        <p className={classes.drawerTitle}>Chargeback Details</p>
        <IconButton onClick={() => setDrawerActive(false)}>
          <CloseRounded />
        </IconButton>
      </div>
      <Divider />

      <div style={{ padding: "20px" }}>
        <p>
          Total airtime purchased in {month}:{" "}
          <strong>
            &#8358; {chargeback?.totalAirtimePurchasedInCurrentCycle}
          </strong>
        </p>
        <hr />
        <p>
          Total charge backs earned in {month} :{" "}
          <strong>&#8358; {chargebackValue?.total} </strong>
        </p>
        <hr />
        <p>
          Charge backs used : <strong>{chargebackValue?.count} of 5 </strong>
        </p>
        <hr />
        <p>
          To earn additional charge backs, purchase airtime worth{" "}
          <strong className="green">
            &#8358;{" "}
            {currentConfig[0] &&
              (
                currentConfig[0]?.airtimePurchaseVolume -
                chargeback?.totalAirtimePurchasedInCurrentCycle
              ).toLocaleString()}
          </strong>{" "}
          more in <strong className="green">{month}</strong>
        </p>
        <hr />
        <p>
          Chargeback cycle period:{" "}
          <strong>
            {" "}
            {currentConfig[0] && currentConfig[0]?.chargeBackCycleInDays} days
          </strong>
        </p>
        <hr />
        <p>
          Chargeback percentage:{" "}
          <strong>
            {" "}
            {currentConfig[0] && currentConfig[0]?.chargeBackPercentage}%
          </strong>{" "}
          of airtime purchased
        </p>
      </div>
    </Drawer>
  );
}
