import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import virtualCard from '../../services/virtualCardService';
import transactions from '../../services/transactionService';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});

function WithdrawVirtual(props) {
    const [price, setPrice] = useState(0);
    const [error, setError] = useState(false);
    const [user, setUser] = useState({
        amount: price,
        user_id: props.details.id,
        currency: "USD",
        trans_ref:
          `${props.details.id}` + Math.floor(Math.random() * 900000000000000000),
        card_id: props.card.id,
        account_no: props.balance.account_number,
      });
    
      useEffect(() => {
        setUser({
          ...user,
          account_no: props.balance.account_number,
        });
      }, [props]);
    
    const rateRequest = {
        amount: price,
        type: 'selling',
        currency: 'USD'
    }
    const token = props.details.token;
    const id = props.details.id

    function handleChange(e) {
        setPrice(e.target.value)
    }

    // GET VIRTUAL CARD RATE
    function getRate() {
        if (price) {
            props.get_rate(rateRequest, token)
        }
    }

    // SUMBIT VIRTUAL CARD REQUEST
    function submit() {
        if (props.details.kyc_level === 1) {
            alert('Please upgrade your account before withdrawing from virtual card')
        }

        if (price > Number(props.card.amount)) {
            setError(true);
        }

        if (user && token && props.details.kyc_level !== 1 && price <= Number(props.card.amount)) {
            props.withdraw_virtual_card(user, token)
                .then(() => {
                    props.clear_rate();
                    props.get_account_balance();
                    props.get_user_virtual_card(id, token)
                });
        }
    };

    return (
        <div>
            <Dialog
                open={
                    props.withdraw.success === true ? false
                        :
                        props.open === true ? true
                            :
                            false
                }
                TransitionComponent={Transition}
                keepMounted
                onClose={props.close}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <Typography variant="p" style={{ padding: '20px' }}>
                    Funds will be credited to your GetriPay account
                 </Typography>

                <DialogContent dividers style={{ backgroundColor: '#eee' }}>
                    <Typography variant='span' gutterBottom className='request-modal-title'>
                        Available Balance:  {''}
                    </Typography>
                    <Typography variant='span' className='request-modal-text'>
                        NGN  {Number(props.balance.available_balance).toLocaleString()}
                    </Typography>
                    <br /><br />

                    <TextField
                        autoFocus
                        onChange={handleChange}
                        value={price}
                        margin="dense"
                        label="Amount in dollar"
                        type="text"
                    />
                    <Button variant="contained" color="primary" className='virtual-rate-button' onClick={getRate} >
                        {props.isGettingRate ?
                            <CircularProgress style={{ fontSize: '30px', color: '#fff' }} />
                            :
                            'Calculate'
                        }
                    </Button>
                    <br />
                    <Typography variant='span' className='request-modal-text' style={{ color: 'red', fontSize: '11px' }}>
                        Amount must be greater than $1
                    </Typography>

                    <br /><br />
                    {error &&
                        <Typography variant='span' className='request-modal-text' style={{ color: 'red', fontSize: '14px' }}>
                            Amount to be withdrawn must not be greater than available funds
                 </Typography>
                    }
                    <br />
                    {props.rate.data
                        ?
                        <Typography variant='p'>
                            {/* You're selling $1 at the rate of {props.rate.data.$1}
                            <br /> */}
                           {/* Your GetriPay account will be credited with */}
                            <span style={{ color: 'black', fontSize: '14px', fontWeight: '900' }}> {props.rate.response_message}</span>
                        </Typography>
                        :
                        ''
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.close} color="primary" className='request-modal-button-cancel'>
                        CANCEL
                    </Button>
                    <Button disabled={props.rate.response_message == undefined} color='primary' variant="contained" size="small" onClick={submit} className='request-modal-button'>
                        {props.isWithdrawingVCard ?
                            <CircularProgress style={{ fontSize: '30px', color: '#fff' }} />
                            :
                            'Withdraw Funds'
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        withdraw_virtual_card: (user, token) => dispatch(virtualCard.withdraw_virtual_card(user, token)),
        get_rate: (payload, token) => dispatch(virtualCard.get_rate(payload, token)),
        get_user_virtual_card: (id, token) => dispatch(virtualCard.get_user_virtual_card(id, token)),
        clear_rate:()=>dispatch(virtualCard.clear_rate()),
        get_account_balance: (payload) => dispatch(transactions.get_account_balance(payload)),
    };
};
const mapStateToProps = (state) => {
    return {
        isWithdrawingVCard: state.getVirtualCard.isWithdrawingVCard,
        isGettingRate: state.getVirtualCard.isGettingRate,
        rate: state.getVirtualCard.rate,
        withdraw: state.getVirtualCard.withdraw

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawVirtual);