import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import security from '../../services/securityServices';
import updateUser from "../../services/userObjectUpdate";
import CircularProgress from '@material-ui/core/CircularProgress';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
	form: {
		marginTop: '30px',
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
}));

function TransactionPin(props) {
	const classes = useStyles();
	let [transaction, setTransaction] = useState({
		transaction_pin: '',
		c_transaction_pin: '',
		password: '',
	});



	const { register, handleSubmit, watch, errors } = useForm({
		mode: 'onBlur',
	});

	const watchTransactionPin = watch('transaction_pin', '');

	function handleChange(e) {
		const { name, value } = e.target;
		setTransaction((transaction) => ({ ...transaction, [name]: value }));
	}

	const token = props.user.token

	function onSubmit(e) {
		if (transaction.transaction_pin && transaction.password) {
			props.pin(transaction.transaction_pin, transaction.password, token).then(() => {
				props.update_user_object();
			});
		}
	}

	function checkLength() {
		if (transaction.transaction_pin.length !== 4) {
			toast.error('Your pin must be 4 digits')
		}
	}

	return (
		<form className={classes.form} name='form' onSubmit={handleSubmit(onSubmit)}>
			<div>
				<label>Enter pin</label>
				<input
					type='password'
					name='transaction_pin'
					className='input-div'
					//value={transaction_pin}
					onBlur={checkLength}
					onChange={handleChange}
					ref={register({
						required: 'Maximum of 4 characters',
						min: 4,
						maxLength: 4
					})}
					style={{
						borderColor: errors.transaction_pin && 'red',
						borderBottom: '1px solid lightgray',
						width: '100%',
						marginBottom: '8px',
					}}
				/>
				{errors.transaction_pin && <p className='error'>{errors.transaction_pin.message}</p>}
			</div>

			<div>
				<label>Confirm pin</label>
				<input
					type='password'
					name='c_transaction_pin'
					className='input-div'
					//value={c_transaction_pin}
					onChange={handleChange}
					ref={register({
						validate: (value) => value === watchTransactionPin || 'Transaction pin does not match',
					})}
					style={{
						borderColor: errors.c_transaction_pin && 'red',
						borderBottom: '1px solid lightgray',
						width: '100%',
						marginBottom: '8px',
					}}
				/>
				{errors.c_transaction_pin && <p className='error'>{errors.c_transaction_pin.message}</p>}
			</div>
			<div>
				<label>Confirm your password</label>
				<input
					type='password'
					name='password'
					className='input-div'
					//value={password}
					onChange={handleChange}
					ref={register({
						required: 'Minimum of 6 characters required',
						min: 6,
					})}
					style={{
						borderColor: errors.password && 'red',
						borderBottom: '1px solid lightgray',
						width: '100%',
						marginBottom: '8px',
					}}
				/>
				{errors.password && <p className='error'>{errors.password.message}</p>}
			</div>

			<div item xs={12} sm={12} className='mag' style={{ margin: '10px 0' }}>
				<Button variant='contained' color='primary' className='settings-button' type='submit' disabled={props.isLoading}>
					{props.isLoading && <CircularProgress className="circle" />}
					Save Changes
				</Button>
			</div>
		</form>
	);
}

const mapDispatchToProps = (dispatch) => {
	return {
		pin: (transaction_pin, password, token) => dispatch(security.setTransactionPin(transaction_pin, password, token)),
		update_user_object: (payload) => dispatch(updateUser.update_user_object(payload))
	};
};

const mapStateToProps = (state) => {
	return {
		user: state.userDetails.data,
		isLoading: state.response.isLoading,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionPin);
