import React, { useEffect } from "react";
import transfers from "./services/transferService";
import bills from "./services/billPaymentService";
import SessionTimeout from "./Session";
import { Router, Switch, Route } from "react-router-dom";
import { history } from "./store/history";
import ValidateBvn from "./pages/Dashboard/ValidateBvn";
import DashboardRoot from "./pages/Dashboard/DasboardRoot";
import Settings from "./pages/Dashboard/Settings";
import TransferRoot from "./pages/Dashboard/Transfer/TransferRoot";
import LoanRoot from "./pages/Dashboard/Loan/LoanRoot";
import RequireAuth from "./AutheticatedRoutes";
import BillPaymentRoot from "./pages/Dashboard/BillPayment/BillPaymentRoot";
import DeveloperDashboard from "./pages/Dashboard/DeveloperDashboard/DeveloperDashboard";
import { connect, useDispatch } from "react-redux";
import UpgradeAccount from "./pages/Dashboard/UpgradeAccount";
import SavingsRoot from "./pages/Dashboard/GetriSave/SavingsRoot";
import Qr from "./pages/Dashboard/QR/Qr";
import { userActions } from "./actions/creators";
import NewAccountRoot from "./pages/Dashboard/NewAccount/NewAccountRoot.";

function SessionPath(props) {
  const dispatch = useDispatch();
  // These are the routes that require timeout after inactivity

  useEffect(() => {
    if (props.location.pathname !== "/transfer") {
      setTimeout(() => {
        props.clear();
      }, 1000);
    }
    if (
      props.location.pathname === "/transfer/file-upload" ||
      props.location.pathname === "/transfer/multiple"
    ) {
      setTimeout(() => {
        dispatch(userActions.validateBulkPaymentSuccess([]));
      }, 1000);
    }
    if (props.match.path !== "/bills/pay-bills/:slug/:logo/:tag/:cat") {
      setTimeout(() => {
        props.clearBills();
      }, 1000);
    }
    sessionStorage.setItem("history", props.location.pathname);
  }, [props.location.pathname]);
  return (
    <div className="app-start">
      <SessionTimeout />
      <RequireAuth />
      <Router history={history}>
        <Switch>
          <Route path="/dashboard" component={DashboardRoot} />
          <Route path="/settings" component={Settings} />
          <Route path="/upgrade-account" component={UpgradeAccount} />
          <Route path="/validate-bvn" component={ValidateBvn} />
          <Route path="/transfer" component={TransferRoot} />
          <Route path="/loan" component={LoanRoot} />
          <Route path="/bills" component={BillPaymentRoot} />
          <Route path="/developer-dashboard" component={DeveloperDashboard} />
          <Route path="/savings" component={SavingsRoot} />
          <Route path="/qr" component={Qr} />
          <Route path='/add-account' component={NewAccountRoot}/>
        </Switch>
      </Router>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    clear: () => dispatch(transfers.clear_success()),
    clearBills: () => dispatch(bills.clear_success()),
  };
};

export default connect(null, mapDispatchToProps)(SessionPath);
