import { userConstants } from "../actions/types";

const initialState = {
    isLoading:false,
    earnings:{},
    data: {},
};

export function getEarnings(state = initialState, action) {
    switch (action.type) {
        case userConstants.GET_EARNINGS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case userConstants.GET_EARNINGS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                earnings: action.payload,
            }
        default:
            return state;
    }
};

export function getInterestHistory(state = initialState, action) {
    switch (action.type) {
        case userConstants.GET_INTEREST_HISTORY_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case userConstants.GET_INTEREST_HISTORY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data:action.payload
            }
        default:
            return state;
    }
};
