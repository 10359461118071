import React, { useEffect } from "react";
import { connect } from "react-redux";
import { toast, Flip } from 'react-toastify';


function RequireAuth(props) {
  function checkAuth() {
    if (props.user.token === null) {
      toast.error('Please log in first', {
        position: toast.POSITION.TOP_RIGHT,
        transition: Flip
      });
    }
  };

  useEffect(() => {
    checkAuth();
  }, [props.user.token])

  return (
    <div></div>
  );
};


const mapStateToProps = (state) => {
  return {
    user: state.userDetails.data,
  };
};

export default connect(mapStateToProps, null)(RequireAuth);
