import { userConstants } from '../actions/types'

const initialState = {
    isSavingBeneficiary: false,
    data: [],
}
 
function saveBeneficiary(state = initialState, action) {
    switch (action.type) {
        case userConstants.SAVE_BENEFICIARY_REQUEST:
            return {
                ...state,
                isSavingBeneficiary: true,
            
            };
        case userConstants.SAVE_BENEFICIARY_SUCCESS:
            return {
                ...state,
                isSavingBeneficiary: false,
                data: action.payload, 
            };
            case userConstants.SAVE_BENEFICIARY_FAILURE:
                return {
                    ...state,
                    isSavingBeneficiary: false, 
                };
        default:
            return state
    }
}

export default saveBeneficiary;