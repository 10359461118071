import { userConstants } from "../actions/types";

const initialState = {
  isVerifyingRegisterBvnOtp: false,
  data: null,
};

function validateRegisterBvnOtp(state = initialState, action) {
  switch (action.type) {
    case userConstants.VALIDATE_REGISTER_BVN_OTP_REQUEST:
      return {
        ...state,
        isVerifyingRegisterBvnOtp: true,
      };
    case userConstants.VALIDATE_REGISTER_BVN_OTP_SUCCESS:
      return {
        ...state,
        isVerifyingRegisterBvnOtp: false,
        data: action.payload,
      };
      case userConstants.VALIDATE_REGISTER_BVN_OTP_FAILURE:
        return {
          ...state,
          isVerifyingRegisterBvnOtp: false,
        };
    default:
      return state;
  }
}

export default validateRegisterBvnOtp;
