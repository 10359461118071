import axios from 'axios';
import config from '../config';
import { userActions } from "../actions/creators.js";
import swal from "sweetalert";
import { history } from '../store/history';

const baseUrl = `${config.apiUrl}`;

const savings = {
    // To list all available savings product/plan
    get_product: (id) => {
        return async dispatch => {
            dispatch(userActions.savingsProductRequest());

            await axios.get(`${baseUrl}/savinvest/user/savings_products/${id}`)
                .then(res => {
                    dispatch(userActions.savingsProductSuccess(res.data.data));
                })
                .catch(error => {
                    dispatch(userActions.savingsProductSuccess());
                });
        }
    },

    get_user_savings: (id, action) => {
        return async dispatch => {
            dispatch(userActions.uiFetching());

            await axios.get(`${baseUrl}/savinvest/user/user_savings/${id}?action=${action}`)
                .then(res => {

                    dispatch(userActions.uiFetched(res.data.data));
                })
                .catch(error => {
                    dispatch(userActions.uiFetched());
                });
        }
    },


    get_inactive_savings: (id, action) => {
        return async dispatch => {
            dispatch(userActions.getInactiveSavingsRequest());

            await axios.get(`${baseUrl}/savinvest/user/user_savings/${id}?action=${action}`)
                .then(res => {

                    dispatch(userActions.getInactiveSavingsSuccess(res.data.data));
                })
                .catch(error => {
                    dispatch(userActions.getInactiveSavingsSuccess([]));
                });
        }
    },

    // To get the details of one savings product
    get_savings_product_detail: (product_id) => {
        return async dispatch => {
            dispatch(userActions.savingsProductDetailRequest());

            await axios.get(`${baseUrl}/savinvest/savings_products/${product_id}`)
                .then(res => {
                    dispatch(userActions.savingsProductDetailSuccess(res.data.data));
                })
                .catch(error => {
                    dispatch(userActions.savingsProductDetailSuccess());
                });
        }
    },

    // To create a new savings plan for a user
    create_savings_plan: (payload) => {

        const new_amount = typeof (payload.amount) === 'number' ? payload.amount : payload.amount.replace(/,/g, "")
        const tag_amount = typeof (payload.target_amount) === 'number' ? payload.target_amount : payload.target_amount.replace(/,/g, "")

        const data = {
            savings_product_id: payload.savings_product_id,
            user_id: payload.user_id,
            name: payload.name,
            user_group_id: payload.user_group_id,
            amount: new_amount,
            trans_ref: payload.trans_ref,
            account_no: payload.account_no,
            transaction_pin: payload.transaction_pin,
            auto_save: payload.auto_save,
            frequency_type: payload.frequency_type,
            start_date: payload.start_date,
            maturity_date: payload.maturity_date,
            target_amount: tag_amount,
        };
        return async dispatch => {
            dispatch(userActions.createSavingsPlanRequest());

            await axios.post(`${baseUrl}/savinvest/user_savings`, data)
                .then(res => {
                    dispatch(userActions.createSavingsPlanSuccess(res.data.data));
                    swal("Great!", "Savings plan created successfully!", "success");
                    history.push('/savings/overview')
                })
                .catch(error => {
                    dispatch(userActions.createSavingsPlanFailure());
                    if (error.response.data.response_message) {
                        swal("Oh Oh!", error.response.data.response_message, "error");
                    } else {
                        swal("Oh Oh!", 'Server error. Please try again later', "error");
                    }

                });
        }
    },

    // To get a user's wallet balance
    get_wallet: (id) => {
        return async dispatch => {
            dispatch(userActions.walletBalanceRequest());

            await axios.get(`${baseUrl}/savinvest/user/user_wallets/${id}`)
                .then(res => {
                    dispatch(userActions.walletBalanceSuccess(res.data.data));
                })
                .catch(error => {
                    dispatch(userActions.walletBalanceSuccess());
                });
        }
    },

    // To get savings summary for one particular plan
    get_savings_summary: (save_id) => {
        return async dispatch => {
            dispatch(userActions.savingsSummaryRequest());

            await axios.get(`${baseUrl}/savinvest/user_savings/${save_id}`)
                .then(res => {
                    dispatch(userActions.savingsSummarySuccess(res.data.data));
                })
                .catch(error => {
                    dispatch(userActions.savingsSummarySuccess());
                });
        }
    },

    // To get savings history for one particular plan
    get_savings_history: (save_id) => {
        return async dispatch => {
            dispatch(userActions.savingsHistoryRequest());

            await axios.get(`${baseUrl}/savinvest/savings_histories/${save_id}`)
                .then(res => {
                    dispatch(userActions.savingsHistorySuccess(res.data.data));
                })
                .catch(error => {
                    dispatch(userActions.savingsHistorySuccess());
                });
        }
    },

    savings_history_sort: (result) => {
        return async dispatch => {
            dispatch(userActions.savingsHistorySuccess(result));
        }
    },

    // Fund Savings
    fund_savings: (data) => {
        return async dispatch => {
            dispatch(userActions.fundSavingsRequest());

            await axios.post(`${baseUrl}/savinvest/add_fund_to_savings_plan`, data)
                .then(res => {
                    dispatch(userActions.fundSavingsSuccess(res.data.data));
                    swal("Great!", "Savings plan funding successful!", "success");
                })
                .catch(error => {
                    dispatch(userActions.fundSavingsFailure());
                    swal("Oh Oh!", error.response.data.response_message, "error");
                });
        }
    },

    // Withdraw from Savings
    withdraw_from_savings: (data) => {
        return async dispatch => {
            dispatch(userActions.withdrawSavingsRequest());

            await axios.post(`${baseUrl}/savinvest/withdraw_from_savings_plan`, data)
                .then(res => {
                    dispatch(userActions.withdrawSavingsSuccess(res.data.data));
                    swal("Great!", "Savings withdrawal successful!", "success");
                    window.location.reload();
                })
                .catch(error => {
                    dispatch(userActions.withdrawSavingsFailure());
                    swal("Oh Oh!", error.response.data.response_message, "error");
                //    window.location.reload();
                });
        }
    },

    //Change withdrawal date
    change_date: (user_id, withdrawal_date) => {
        return async dispatch => {
            dispatch(userActions.changeDateRequest());

            await axios.post(`${baseUrl}/savinvest/user/withdrawal_dates`, { user_id, withdrawal_date })
                .then(res => {
                    dispatch(userActions.changeDateSuccess(res.data.data));
                    swal("Great!", "Withdrawal date changed successfully!", "success");
                })
                .catch(error => {
                    dispatch(userActions.changeDateFailure());
                    swal("Oh Oh!", error.response.data.response_message, "error");
                });
        }
    },

    //Set withdrawal date
    show_date: (id) => {
        return async dispatch => {
            dispatch(userActions.showDateRequest());

            await axios.get(`${baseUrl}/savinvest/user/withdrawal_dates/${id}`)
                .then(res => {
                    dispatch(userActions.showDateSuccess(res.data.data));
                })
                .catch(error => {
                    dispatch(userActions.showDateSuccess());
                });
        }
    },

    // To create a group savings
    create_group_savings: (payload) => {

        const new_amount = typeof (payload.amount) === 'number' ? payload.amount : payload.amount.replace(/,/g, "")
        const max_amount = typeof (payload.maximum_amount) === 'number' ? payload.maximum_amount : payload.maximum_amount.replace(/,/g, "")

        const data = {
            savings_product_id: payload.savings_product_id,
            user_id: payload.user_id,
            name: payload.name,
            description: payload.description,
            maximum_participants: payload.maximum_participants,
            group_type: payload.group_type,
            amount: new_amount,
            maximum_amount: max_amount,
            trans_ref: payload.trans_ref,
            account_no: payload.account_no,
            transaction_pin: payload.transaction_pin,
            group_pin: payload.group_pin,
            start_date: payload.start_date,
            maturity_date: payload.maturity_date,
        };
        return async dispatch => {
            dispatch(userActions.createGroupSavingsRequest());

            await axios.post(`${baseUrl}/savinvest/savings_groups`, data)
                .then(res => {
                    dispatch(userActions.createGroupSavingsSuccess(res.data.data));
                    swal("Great!", "Group savings created successfully!", "success");
                    history.push('/savings/overview')

                })
                .catch(error => {
                    dispatch(userActions.createGroupSavingsFailure());
                    if (error.response.data.response_message) {
                        swal("Oh Oh!", error.response.data.response_message, "error");
                    } else {
                        swal("Oh Oh!", 'Server error. Please try again later', "error");
                    }
                });
        }
    },


    // To get a list of user's group savings
    get_user_group_savings: (user_id) => {
        return async dispatch => {
            dispatch(userActions.userGroupSavingsRequest());

            await axios.get(`${baseUrl}/savinvest/user/savings_groups/${user_id}`)
                .then(res => {
                    dispatch(userActions.userGroupSavingsSuccess(res.data.data));
                })
                .catch(error => {
                    dispatch(userActions.userGroupSavingsSuccess());
                });
        }
    },

    // To get a list of user's group savings
    get_all_savings_group: () => {
        return async dispatch => {
            dispatch(userActions.allSavingsGroupRequest());

            await axios.get(`${baseUrl}/savinvest/savings_groups`)
                .then(res => {
                    dispatch(userActions.allSavingsGroupSuccess(res.data.data));
                })
                .catch(error => {
                    dispatch(userActions.allSavingsGroupSuccess());
                });
        }
    },

    // To get a list of user's group savings
    savings_group_detail: (group_id) => {
        return async dispatch => {
            dispatch(userActions.savingsGroupDetailRequest());

            await axios.get(`${baseUrl}/savinvest/savings_groups/${group_id}`)
                .then(res => {
                    dispatch(userActions.savingsGroupDetailSuccess(res.data.data));
                })
                .catch(error => {
                    dispatch(userActions.savingsGroupDetailSuccess());
                });
        }
    },


    // To join a group savings
    join_group_savings: (payload) => {

        const new_amount = typeof (payload.amount) === 'number' ? payload.amount : payload.amount.replace(/,/g, "")
        const data = {
            savings_product_id: payload.savings_product_id,
            user_id: payload.user_id,
            name: payload.name,
            amount: new_amount,
            frequency_type: payload.frequency_type,
            trans_ref: payload.trans_ref,
            account_no: payload.account_no,
            transaction_pin: payload.transaction_pin,
            start_date: payload.start_date,
            group_pin: payload.group_pin,
            slug: payload.slug,
            maturity_date: payload.maturity_date,
        };

        return async dispatch => {
            dispatch(userActions.joinGroupSavingsRequest());

            await axios.post(`${baseUrl}/savinvest/join/savings_groups`, data)
                .then(res => {
                    dispatch(userActions.joinGroupSavingsSuccess(res.data.data));
                    swal("Great!", "Savings group joined successfully!", "success");
                })
                .catch(error => {
                    dispatch(userActions.joinGroupSavingsFailure());
                    swal("Oh Oh!", error.response.data.response_message, "error");
                });
        }
    },


    // To view information about a savings group
    view_group_info: (group_id) => {
        return async dispatch => {
            dispatch(userActions.viewGroupInfoRequest());

            await axios.get(`${baseUrl}/savinvest/view/savings_groups/${group_id}`)
                .then(res => {
                    dispatch(userActions.viewGroupInfoSuccess(res.data.data));
                })
                .catch(error => {
                    dispatch(userActions.viewGroupInfoSuccess());
                });
        }
    },


    // To get a group wallet 
    get_group_wallet: (id) => {
        return async dispatch => {
            dispatch(userActions.groupWalletRequest());

            await axios.get(`${baseUrl}/savinvest/user_wallets/${id}`)
                .then(res => {
                    dispatch(userActions.groupWalletSuccess(res.data.data));
                })
                .catch(error => {
                    dispatch(userActions.groupWalletSuccess());
                });
        }
    },

    // To invite people to a group savings
    invite_to_group: (invitations) => {
        return async dispatch => {
            dispatch(userActions.inviteToGroupRequest());

            await axios.post(`${baseUrl}/savinvest/invitations/savings_groups`, invitations)
                .then(res => {
                    dispatch(userActions.inviteToGroupSuccess(res.data.data));
                    swal("Great!", "Group invite sent successfully", "success");
                })
                .catch(error => {
                    dispatch(userActions.inviteToGroupSuccess());
                    swal("Oh oh!", "Error", "error");
                });
        }
    },



    // To authorize a closed savings
    authorize_withdrawal: (payload) => {
        return async dispatch => {
            dispatch(userActions.uiRequesting());

            await axios.post(`${baseUrl}/savinvest/withdrawal_authorises`, payload)
                .then(res => {
                    dispatch(userActions.uiResponded());
                    swal("Great!", "Withdrawal authorized successfully", "success");
                })
                .catch(error => {
                    dispatch(userActions.uiResponded());
                    swal("Oh oh!", error.response.data.data, "error");
                });
        }
    },


    // To overide wthdrawal in  a closed savings
    override_withdrawal: (payload) => {
        return async dispatch => {
            dispatch(userActions.uiRequesting());

            await axios.post(`${baseUrl}/savinvest/withdrawal_override/savings_groups`, payload)
                .then(res => {
                    dispatch(userActions.uiResponded());
                    swal("Great!", "Withdrawal override successful", "success");
                })
                .catch(error => {
                    dispatch(userActions.uiResponded());
                    swal("Oh oh!", error.response.data.data, "error");
                });
        }
    },


    // To get the people that hava authorized a savings
    get_withdrawal_authorities: (group_id) => {
        return async dispatch => {
            dispatch(userActions.getAuthorizeRequest());

            await axios.get(`${baseUrl}/savinvest/group/withdrawal_authorises/${group_id}`)
                .then(res => {
                    dispatch(userActions.getAuthorizeSuccess(res.data.data));
                })
                .catch(error => {
                    dispatch(userActions.getAuthorizeSuccess());
                });
        }
    },


    // Svaings notification settings
    savings_notifications: (payload) => {
        return async dispatch => {
            dispatch(userActions.savingsNotificationsRequest());

            await axios.post(`${baseUrl}/savinvest/user_notifications`, payload)
                .then(res => {
                    dispatch(userActions.savingsNotificationsSuccess(res.data.data));
                    swal("Great!", "Settings saved successfully", "success");
                })
                .catch(error => {
                    dispatch(userActions.savingsNotificationsSuccess());
                });
        }
    },

    toggle_autosave: (payload) => {
        return async dispatch => {
            dispatch(userActions.uiRequesting());

            await axios.post(`${baseUrl}/savinvest/disable_or_enable_auto_save/user_savings`, payload)
                .then(res => {
                    dispatch(userActions.uiResponded(res.data.data));
                    swal("Great!", res.data.Response_message, "success");
                })
                .catch(error => {
                    dispatch(userActions.uiResponded());
                });
        }
    },

    get_wallet_transactions: (wallet_id) => {
        return async dispatch => {
            dispatch(userActions.walletTransactionsRequest());

            await axios.get(`${baseUrl}/savinvest/user/wallet_transactions/${wallet_id}`)
                .then(res => {
                    dispatch(userActions.walletTransactionsSuccess(res.data.data));
                })
                .catch(error => {
                    dispatch(userActions.walletTransactionsSuccess([]));
                });
        }
    },

    get_available_wallet_transactions: (wallet_id) => {
        return async dispatch => {
            dispatch(userActions.availableWalletTranscationsRequest());

            await axios.get(`${baseUrl}/savinvest/user/available_wallet_transactions/${wallet_id}`)
                .then(res => {
                    dispatch(userActions.availableWalletTranscationsSuccess(res.data.data));
                })
                .catch(error => {
                    dispatch(userActions.availableWalletTranscationsSuccess([]));
                });
        }
    },

};

export default savings;

