import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import virtualCard from '../../services/virtualCardService';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});

function BlockVirtual(props) {

    const user = {
        user_id: props.details.id,
        card_id: props.card.id,
    };

    const token = props.details.token;
    

    // SUMBIT VIRTUAL CARD REQUEST
    function submit() {
        if (user && token) {
            props.block_virtual_card(user, token )
                // .then(() => {
                //     props.get_user_virtual_card(id, token)
                // });
        };
    };

    return (
        <div>
            <Dialog
                open={
                    props.block.success === true ? false
                        :
                        props.open === true ? true
                            :
                            false
                }
                TransitionComponent={Transition}
                keepMounted
                onClose={props.close}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <Typography variant="p" style={{ padding: '20px', color:'red' }}>
                    Are you sure you want to block your Virtual Card?
                 </Typography>

                <DialogContent dividers style={{ backgroundColor: '#eee' }}>
                    <Typography variant='span' gutterBottom className='request-modal-title'>
                        Available Dollar Balance:  {''}
                    </Typography>
                    <Typography variant='span' className='request-modal-text'>
                        $ {Number(props.card.amount).toLocaleString()}
                    </Typography>
                    <br /><br />
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.close} color="primary" className='request-modal-button-cancel'>
                        CANCEL
                    </Button>
                    <Button color='primary' variant="contained" size="small" onClick={submit} className='request-modal-button'>
                        {props.isBlockingVCard ?
                            <CircularProgress style={{ fontSize: '30px', color: '#fff' }} />
                            :
                            'Block Virtual Card'
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        block_virtual_card: (user, token) => dispatch(virtualCard.block_virtual_card(user, token)),
        get_user_virtual_card: (id, token) => dispatch(virtualCard.get_user_virtual_card(id, token)),
    };
};
const mapStateToProps = (state) => {
    return {
        isBlockingVCard: state.getVirtualCard.isBlockingVCard,
        block: state.getVirtualCard.block
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BlockVirtual);