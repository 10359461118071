import { userConstants } from '../actions/types'

const initialState = {
    isGettingItem: false,
    data: [],
}
 
function getBillerItem(state = initialState, action) {
    switch (action.type) {
        case userConstants.GET_BILLER_ITEM_REQUEST:
            return {
                ...state,
                isGettingItem: true,
            
            };
        case userConstants.GET_BILLER_ITEM_SUCCESS:
            return {
                ...state,
                isGettingItem: false,
                data: action.payload, 
            };
            case userConstants.GET_BILLER_ITEM_FAILURE:
                return {
                    ...state,
                    isGettingItem: false,
                    data: [], 
                    success: false,
                };
        default:
            return state
    }
}

export default getBillerItem;