import React from 'react';
import { Switch, Route } from 'react-router-dom';
import AvailableWalletTransactions from './AvailableWalletTransactions';
import CreateGroupSavings from './CreateGroupSavings';
import JoinGroupSavings from './JoinGroupSavings';
import NewPlan from './NewPlan';
import SavingsHistory from './SavingsHistory';
import SavingsHistoryGroup from './SavingsHistoryGroup';
import SavingsOverview from './SavingsOverview';
import WalletTransactions from './WalletTransactions';

export default function SavingsRoot({ match }) {
    return (
        <div>
            <Switch>
                <Route exact path={`${match.url}/`} />
                <Route path={`${match.url}/overview`} component={SavingsOverview} />
                <Route path={`${match.url}/new-plan/:slug`} component={NewPlan} />
                <Route path={`${match.url}/history/:slug`} component={SavingsHistory} />
                <Route path={`${match.url}/history-group/:slug/:group_id/:wallet_id`} component={SavingsHistoryGroup} />
                <Route path={`${match.url}/create-group-savings/:slug`} component={CreateGroupSavings} />
                <Route path={`${match.url}/join-group-savings/:slug/:id`} component={JoinGroupSavings} />
                <Route path={`${match.url}/wallet-transactions/:slug`} component={WalletTransactions} />
                <Route path={`${match.url}/available-wallet-transactions/:slug`} component={AvailableWalletTransactions} />
            </Switch>
        </div>

    );
}