import { userConstants } from "../actions/types";

const initialState = {
  isPaying: false,
  data: null,
};

function payBill(state = initialState, action) {
  switch (action.type) {
    case userConstants.PAY_BILL_REQUEST:
      return {
        isPaying: true,
        data: null,
      };
    case userConstants.PAY_BILL_SUCCESS:
      return {
        isPaying: false,
        success: true,
        data: action.payload,
      };
    case userConstants.PAY_BILL_FAILURE:
      return {
        success: false,
        isPaying: false,
        data: null,
      };
    case userConstants.CLEAR:
      return {
        isPaying: false,
        data: null,
      };
    default:
      return state;
  }
}

export default payBill;
