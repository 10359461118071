import axios from "axios";
import { toast, Flip } from "react-toastify";
import config from "../config";
import { userActions } from "../actions/creators.js";
import { history } from "../store/history";

const baseUrl = `${config.apiUrl}`;

const auth = {
  // Register User
  register_user: (payload) => {
    return async (dispatch) => {
      dispatch(userActions.uiRequesting());
      await axios
        .post(`${baseUrl}/user/users/register/web`, payload)
        .then((res) => {
          dispatch(userActions.uiResponded(payload));
          if (res.data.response_code === "USM011") {
            toast.success(
              "Please check your email/phone for your verification code",
              {
                position: toast.POSITION.TOP_RIGHT,
                transition: Flip,
              }
            );
            localStorage.setItem(
              "register_token",
              res.data.data.register_token
            );
            localStorage.setItem("user_id", res.data.data.user_id);
            history.push("/verify/phone");
          }
        })
        .catch((error) => {
          dispatch(userActions.uiResponded());
          let dataObj = error.response.data.data;
          let errorMessage = `${
            dataObj.phone_number ? dataObj.phone_number[0] + "," : ""
          }, ${dataObj.c_password ? dataObj.c_password[0] + "," : ""} ${
            dataObj.email ? dataObj.email[0] + "," : ""
          }`;

          if (error.response.data.response_message === "Validation Error.") {
            toast.error(errorMessage, {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
          } else {
            toast.error("ERROR", {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
          }
        });
    };
  },

  // User Login
  login_user: (email, password, channel) => {
    return async (dispatch) => {
      dispatch(userActions.loginUserRequest());

      await axios
        .post(`${baseUrl}/user/users/login`, { email, password, channel })
        .then((res) => {
          dispatch(userActions.loginUserSuccess(res.data.data));

          localStorage.setItem("email", res.data.data.email);
          localStorage.setItem("register_token", res.data.data.register_token);
          localStorage.setItem("user_id", res.data.data.user_id);
          if (res.data.response_code === "USM006") {
            toast.success(
              "Success. Kindly provide an answer to your security question below ",
              {
                position: toast.POSITION.TOP_RIGHT,
                transition: Flip,
              }
            );
            // history.push('/verify/login')
            history.push("/verify/security-question");
          } else if (res.data.response_code === "USM004") {
            toast.error("Your account has not been verified", {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
            history.push("/verify/phone");
          }
        })
        .catch((error) => {
          dispatch(userActions.loginUserFailure());

          if (error.response.data.response_message === "Incorrect password") {
            toast.error("Incorrect Credentials", {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
          } else if (error.response.data.response_code === "USM012") {
            toast.error("You do not have an account, please register", {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
          } else if (error.response.data.response_code === "USM027") {
            toast.error("You have not set your security question", {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
            localStorage.setItem(
              "user_id",
              error.response.data.response_message.user_id
            );
            history.push("/security-question");
          } else if (error.response.data.response_code === "USM031") {
            toast.error(error.response.data.data, {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
              autoClose: 5000,
            });
          } else {
            toast.error("ERROR", {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
          }
        });
    };
  },

  referralIsUnique(parent_id) {
    return async (dispatch) => {
      dispatch(userActions.validateReferralRequest());

      axios
        .get(`${baseUrl}/user/users/verify/${parent_id}`)
        .then((res) => {
          dispatch(userActions.validateReferralSuccess(res.data));
        })
        .catch((error) => {
          dispatch(userActions.validateReferralFailure());
        });
    };
  },
  register_bvn: (bvn) => {
    return async (dispatch) => {
      dispatch(userActions.registerBvnRequest());

      const bvn_number = bvn;

      await axios
        .post(`${baseUrl}/user/register/validate-bvn`, {
          channel: "register_bvn_web",
          bvn_number,
        })
        .then((res) => {
          dispatch(userActions.registerBvnSuccess(res.data.data.trans_ref));
          if (res.data.data.message === "Try again") {
            toast.error("Please try again", {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
          } else if (res.data.data.message !== "Try again") {
            toast.success(res.data.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
            history.push("/verify/bvn");
          }
        })
        .catch((error) => {
          dispatch(userActions.registerBvnFailure());
          if (error.response.data.response_code === "USM025") {
            toast.error("User already exists", {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
          } else if (error.response.data.response_code === "USM023") {
            toast.error("User with this BVN already exists", {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
          } else
            toast.error("Please enter a valid Bvn", {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
        });
    };
  },
};

export default auth;
