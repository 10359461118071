import React from 'react';
import { Paper, Grid, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';


export default function LoanCard() {
  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
       <Paper className='dashboardBox1'>
              <div className='for-inline-items'>
                  <div >
                      <p className='e-cash'> Loans </p>
                  </div>
                  
              </div>
              
              <div className='mag'>
                  <img src="/images/loan-icon.svg" alt="logo" className='loan-img' />
              </div>
              <div className='mag'>
                  <p className='card-text-sm'>You have not requested for a <br /> loan yet</p>
              </div>
              <div className='mag'>
              <Link to="/loan">
                  <Button
                      variant="contained"
                      color="primary"
                      className='loan-button'
                  >
                      Request for a Loan
                  </Button>
              </Link>
              </div>
      </Paper>
      </Grid>
  );
}
