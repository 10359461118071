import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Grid,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  MenuItem,
  Select,
} from "@material-ui/core";
import "../../../css/dashboard.css";
import TopBar from "../../../components/TopBar";
import { MainContent, Footer, HeaderBar } from "../../../components/Appearance";
import SideNav from "../../../components/SideNav";
import BottomNav from "../../../components/bottomNavigation";
import { Helmet } from "react-helmet";
import clsx from "clsx";
import thisStyles from "./styles";
import { connect } from "react-redux";
import savings from "../../../services/savingsService";
import ConfirmSaveModal from "./components/ConfirmSaveModal";
import { history } from "../../../store/history";
import numeral from "numeral";
import transactions from "../../../services/transactionService";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: "white",
  },
  content: {
    flexGrow: 1,
    backgroundColor: "white",
  },
  container: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(4),
    backgroundColor: "white",
  },
  large: {
    width: 100,
    height: 100,
  },
  customBadge: {
    color: "white",
    marginRight: "-55px",
    width: "120px",
  },
  fixed: {
    color: "#ff0000",
    fontSize: "12px",
    display: "block",
  },
  others: {
    color: "#000",
    fontWeight: "700",
    fontSize: "14px",
  },
  popover: {
    pointerEvents: "none",
  },
  paper: {
    marginTop: "-80px",
    padding: theme.spacing(1),
  },
}));

function StyledRadio(props) {
  const classes = thisStyles();
  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

function CreateGroupSavings(props) {
  const classes = useStyles();
  const [toggle, setToggle] = useState(true);
  const [sidenav, setSidenav] = useState("getri-sidenav");
  const [value2, setValue2] = useState("");
  const [showDate, setShowDate] = useState("");
  const [start, setStart] = useState(new Date().toLocaleDateString("en-CA"));
  const [open, setOpen] = useState(false);
  const [saveName, setSaveName] = useState("");
  const [saveAmount, setSaveAmount] = useState("");
  const [desc, setDesc] = useState("");
  const [max, setMax] = useState("");
  const [group, setGroup] = useState("");
  const [targetAmount, setTargetAmount] = useState(0);
  const [initialAmount, setInitialAmount] = useState("");
  const [newDay, setNewDay] = useState("");
  const [pin, setPin] = useState("");

  const handleToggle = () => {
    const mediaScreen = window.matchMedia(
      `(max-width: 700px),
        (min-device-width: 768px)
        and (max-device-width: 1024px)`
    ); //returns true if

    if (mediaScreen.matches) {
      setSidenav("slideOut");
    } else {
      setToggle(!toggle);
    }
  };
  const hideSidebar = () => {
    setSidenav("slideIn");
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [save, setSave] = useState({
    savings_product_id: props.details.id,
    user_id: props.user.id,
    name: saveName,
    description: desc,
    maximum_participants: max,
    group_type: group,
    amount: initialAmount,
    maximum_amount:
      props.details.amount === "0.00" && props.details.amount_type !== "range"
        ? saveAmount
        : props.details.amount_type === "range"
        ? targetAmount
        : props.details.amount,
    trans_ref:
      `${props.user.id}` + Math.floor(Math.random() * 900000000000000000),
    account_no: props?.balance?.account_number,
    transaction_pin: "",
    group_pin: group === "close" ? pin : "",
    start_date: start,
    maturity_date: showDate ? showDate : "",
  });

  const product_id = props.match.params.slug;

  useEffect(() => {
    props.get_savings_product_detail(product_id);
    props.get_account_balance();
  }, []);

  useEffect(() => {
    calcDate();
    convertToDay();
    setSave({
      savings_product_id: props.details.id,
      user_id: props.user.id,
      name: saveName,
      description: desc,
      maximum_participants: max,
      group_type: group ? group : "open",
      amount: initialAmount,
      maximum_amount:
        props.details.amount === "0.00" && props.details.amount_type !== "range"
          ? saveAmount
          : props.details.amount_type === "range"
          ? targetAmount
          : props.details.amount,
      trans_ref: `${props.user.id}` + Math.floor(Math.random() * 9000),
      account_no: props?.balance?.account_number,
      transaction_pin: "",
      group_pin: group === "close" ? pin : "",
      start_date: start,
      maturity_date: showDate ? showDate : "",
    });
  }, [
    props.details,
    props.balance,
    showDate,
    start,
    value2,
    saveName,
    saveAmount,
    targetAmount,
    save.target_amount,
    group,
    desc,
    max,
    initialAmount,
    pin,
  ]);

  const handleChange2 = (event) => {
    setValue2(event.target.value);
  };
 
  const handleChangeGroup = (event) => {
    setGroup(event.target.value);
  };

  function handleChangeSave(e) {
    const { name, value } = e.target;
    setSave((save) => ({ ...save, [name]: value }));
  }

  function handleChangeStart(e) {
    setStart(e.target.value);
  }
  function handleChangeSaveName(e) {
    setSaveName(e.target.value);
  }
  function handleChangeSaveAmount(e) {
    setSaveAmount(e.target.value);
  }
  function handleChangeTargetAmount(e) {
    setTargetAmount(e.target.value);
  }
  function handleChangeDesc(e) {
    setDesc(e.target.value);
  }
  function handleChangeMax(e) {
    setMax(e.target.value);
  }
  function handleChangeInitial(e) {
    setInitialAmount(e.target.value);
  }
  function handleChangePin(e) {
    setPin(e.target.value);
  }

  function buildOptions() {
    var arr = [];
    for (
      let i = Number(props.details.min_amount);
      i <= Number(props.details.max_amount);
      i += Number(props.details.amount_step)
    ) {
      arr.push(
        <MenuItem key={i} value={i}>
          NGN {i}
        </MenuItem>
      );
    }
    return arr;
  }

  function daysOfAYear(year) {
    return isLeapYear(year) ? 366 : 365;
  }
  function isLeapYear(year) {
    return year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0);
  }

  const valueDisplay = numeral(save.maximum_amount).format();
  const valueDisplay2 = numeral(save.amount).format();

  let days_in_a_year = daysOfAYear(new Date(start).getFullYear());

  function convertToDay() {
    if (props.details.maturity_period_type === "month") {
      setNewDay(props.details.maturity_period * 30);
    } else if (props.details.maturity_period_type === "day") {
      setNewDay(props.details.maturity_period * 1);
    } else if (props.details.maturity_period_type === "week") {
      setNewDay(props.details.maturity_period * 7);
    } else if (props.details.maturity_period_type === "year") {
      setNewDay(props.details.maturity_period * days_in_a_year);
    }
  }

  const conDate = Number(value2);

  function calcDate() {
    if (newDay) {
      const targetDate = new Date(start);
      targetDate.setDate(targetDate.getDate() + newDay);
      setShowDate(targetDate.toLocaleDateString("en-CA"));
    } else if (props.details.maturity_period_type === "day" && value2) {
      const targetDate = new Date(start);
      targetDate.setDate(targetDate.getDate() + conDate);
      setShowDate(targetDate.toLocaleDateString("en-CA"));
    }
  }

  function submit() {
    if (showDate === "") {
      alert("please select length of savings");
    } else {
      props.create_group_savings(save);
    }
  }

  return (
    <>
      <Helmet>
        <title>GetriPay - Fastest growing digital bank with rewards </title>
        <meta name="description" content="Savings" />
        <meta name="keywords" content="savings" />
      </Helmet>
      <TopBar handleToggle={handleToggle} />
      <SideNav
        toggle={toggle}
        className={sidenav}
        hideSidebar={hideSidebar}
        {...props}
      />
      <MainContent toggle={toggle}>
        <main className={classes.content}>
          <Grid container>
            <Grid item xs={12} md={12} lg={12}>
              <Container maxWidth="lg" className={classes.container}>
                <Container className="main-container">
                  <HeaderBar
                    topic={props.details.name}
                    text={Number(
                      props.balance.available_balance
                    ).toLocaleString()}
                  />
                </Container>
              </Container>
            </Grid>

            <Container>
              <Container>
                <Grid container>
                  <Grid item lg={8}>
                    <Grid
                      container
                      spacing={3}
                      style={{ alignItems: "center" }}
                    >
                      <Grid item lg={12}>
                        <h4>Create a group savings</h4>
                      </Grid>

                      <Grid item lg={6}>
                        <span>What are you saving for ? </span>
                      </Grid>
                      <Grid item lg={6}>
                        <input
                          name="saveName"
                          value={saveName}
                          onChange={handleChangeSaveName}
                          type="text"
                          className="input-div"
                          placeholder="Purpose of the savings"
                        />
                        <span className={classes.fixed}>
                          For example: Birthday, anniversary, new car, rent
                        </span>
                      </Grid>

                      <Grid item lg={6}>
                        <span>Description </span>
                      </Grid>
                      <Grid item lg={6}>
                        <input
                          name="desc"
                          value={desc}
                          onChange={handleChangeDesc}
                          type="text"
                          className="input-div"
                          placeholder="Description of the savings"
                        />
                      </Grid>

                      <Grid item lg={6}>
                        <span>Maximum Participants</span>
                      </Grid>
                      <Grid item lg={6}>
                        <input
                          name="max"
                          value={max}
                          onChange={handleChangeMax}
                          type="text"
                          className="input-div"
                          placeholder="Maximum number of participants on the group"
                        />
                      </Grid>

                      <Grid item lg={6}>
                        <p>Savings Group Type </p>
                        <span className={classes.fixed}>
                          A closed group requires a pin to be set while an open
                          group does not
                        </span>
                      </Grid>
                      <Grid item lg={6}>
                        <div>
                          <FormControl component="fieldset">
                            <RadioGroup
                              aria-label="value"
                              name="group"
                              value={group}
                              onChange={handleChangeGroup}
                            >
                              <FormControlLabel
                                value="open"
                                control={<StyledRadio />}
                                label="Open group"
                              />
                              <FormControlLabel
                                value="close"
                                control={<StyledRadio />}
                                label="Closed group"
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </Grid>

                      {group === "close" ? (
                        <>
                          <Grid item lg={6}>
                            <p>Set a group pin </p>
                          </Grid>
                          <Grid item lg={6}>
                            <input
                              onChange={handleChangePin}
                              type="password"
                              placeholder="Set your group pin"
                              value={pin}
                              name="pin"
                            />
                            <span className={classes.fixed}>
                              This should not be your transaction pin. This pin
                              will be shared to other group members to join the
                              group
                            </span>
                          </Grid>
                        </>
                      ) : (
                        ""
                      )}

                      <Grid item lg={6}>
                        <p>Maximum amount to be saved by the group </p>
                      </Grid>

                      {props.details.amount === "0.00" &&
                      props.details.amount_type !== "range" ? (
                        <>
                          <Grid item lg={6}>
                            <div>
                              <input
                                name="amount"
                                value={valueDisplay}
                                onChange={handleChangeSaveAmount}
                                type="text"
                                className="input-div"
                                placeholder="Amount to save"
                              />
                            </div>
                          </Grid>
                        </>
                      ) : props.details.amount_type === "range" ? (
                        <>
                          <Grid item lg={6}>
                            <div>
                              <Select
                                onChange={handleChangeTargetAmount}
                                className="input-div"
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={targetAmount}
                                name="targetAmount"
                              >
                                {buildOptions()}
                              </Select>
                            </div>
                          </Grid>
                        </>
                      ) : (
                        <Grid item lg={6}>
                          <span>
                            NGN {Number(props.details.amount).toLocaleString()}
                          </span>
                          <hr />
                          <span className={classes.fixed}>
                            NOTE: This amount is fixed{" "}
                          </span>
                        </Grid>
                      )}

                      <Grid item lg={6}>
                        <p>Initial amount to save </p>
                      </Grid>
                      <Grid item lg={6}>
                        <div>
                          <input
                            type="text"
                            name="initialAmount"
                            value={valueDisplay2}
                            onChange={handleChangeInitial}
                            placeholder="Amount you want to save"
                          />
                        </div>
                      </Grid>

                      <Grid item lg={6}>
                        <p>When will you like to start saving? </p>
                      </Grid>
                      <Grid item lg={6}>
                        <div>
                          <input
                            step="1"
                            type="datetime-local"
                            name="start"
                            value={start}
                            onChange={handleChangeStart}
                          />
                        </div>
                      </Grid>

                      {props.details.amount_type !== "variable" ? (
                        <Grid item lg={6}>
                          <p>Length/Period of savings </p>
                        </Grid>
                      ) : (
                        <Grid item lg={6}>
                          <p>How long would you like to save for? </p>
                        </Grid>
                      )}

                      {props.details.amount_type !== "variable" &&
                      props.details.maturity_period === 0 ? (
                        <Grid item lg={6}>
                          <FormControl component="fieldset">
                            <RadioGroup
                              aria-label="value2"
                              name="value2"
                              value={value2}
                              onChange={handleChange2}
                            >
                              <FormControlLabel
                                value="daily"
                                control={<StyledRadio />}
                                label="3 months"
                              />
                              <FormControlLabel
                                value="weekly"
                                control={<StyledRadio />}
                                label="6 months"
                              />
                              <FormControlLabel
                                value="monthly"
                                control={<StyledRadio />}
                                label="1 year"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      ) : props.details.amount_type === "variable" &&
                        props.details.maturity_period === 0 ? (
                        <Grid item lg={6}>
                          <FormControl component="fieldset">
                            <RadioGroup
                              aria-label="value2"
                              name="value2"
                              value={value2}
                              onChange={handleChange2}
                            >
                              <FormControlLabel
                                value="30"
                                control={<StyledRadio />}
                                label="30 days"
                              />
                              <FormControlLabel
                                value="60"
                                control={<StyledRadio />}
                                label="60 days"
                              />
                              <FormControlLabel
                                value="90"
                                control={<StyledRadio />}
                                label="90 days"
                              />
                              <FormControlLabel
                                value="120"
                                control={<StyledRadio />}
                                label="120 days"
                              />
                              <FormControlLabel
                                value="150"
                                control={<StyledRadio />}
                                label="150 days"
                              />
                              <FormControlLabel
                                value="180"
                                control={<StyledRadio />}
                                label="180 days"
                              />
                            </RadioGroup>
                          </FormControl>
                          <span className={classes.fixed}>
                            NOTE: This is required{" "}
                          </span>
                        </Grid>
                      ) : (
                        <div>
                          <span>
                            {props.details.maturity_period}{" "}
                            {props.details.maturity_period_type}s - ({newDay}{" "}
                            days)
                          </span>

                          <span className={classes.fixed}>
                            NOTE: This period is fixed{" "}
                          </span>
                        </div>
                      )}
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    lg={4}
                    sm={12}
                    xs={12}
                    style={{ marginBottom: "100px" }}
                  >
                    <div className="preview">
                      <Grid container style={{ alignItems: "center" }}>
                        <Grid item lg={6}>
                          <h5>Preview</h5>
                        </Grid>
                        <Grid>
                          <p className="back" onClick={history.goBack}>
                            Go back
                          </p>
                        </Grid>
                      </Grid>
                      <div className="sav-section">
                        <p
                          style={{
                            color: "#33cc33",
                            fontSize: "16px",
                            textTransform: "capitalize",
                          }}
                        >
                          {save.name}
                        </p>
                        <Grid container>
                          <Grid item lg={6}>
                            Amount
                          </Grid>
                          <Grid item lg={6} className={classes.others}>
                            {props.details.amount === "0.00" ||
                            props.details.amount_type === "range"
                              ? valueDisplay
                              : props.details.amount}
                          </Grid>
                        </Grid>

                        <hr />
                        <Grid container>
                          <Grid item lg={6}>
                            Start Date
                          </Grid>
                          <Grid item lg={6} className={classes.others}>
                            {save.start_date}
                          </Grid>
                        </Grid>
                        <hr />
                        <Grid container>
                          <Grid item lg={6}>
                            Maturity Date
                          </Grid>
                          <Grid item lg={6} className={classes.others}>
                            {" "}
                            {showDate}{" "}
                          </Grid>
                        </Grid>
                        <hr />
                        <Grid container>
                          <Grid item lg={6}>
                            Interest Rate
                          </Grid>
                          <Grid item lg={6} className={classes.others}>
                            {props.details.interest_rate}% P.A
                          </Grid>
                        </Grid>
                        <hr />
                        {/* <Grid container>
                                                    <Grid item lg={6}>Estimated Returns</Grid>
                                                    <Grid item lg={6} className={classes.others}>NGN{estimate}</Grid>
                                                </Grid> */}
                        <hr />
                        <Grid container>
                          <Button
                            fullWidth
                            variant="contained"
                            size="small"
                            color="primary"
                            className="getri-button submit"
                            onClick={handleClickOpen}
                          >
                            Continue
                          </Button>
                          <ConfirmSaveModal
                            save={save}
                            amount={
                              props.details.amount === "0.00" ||
                              props.details.amount_type === "range"
                                ? valueDisplay
                                : props.details.amount
                            }
                            data={props.details}
                            open={open}
                            close={handleClose}
                            value={save.transaction_pin}
                            change={handleChangeSave}
                            submit={submit}
                            loading={props.loading}
                          />
                        </Grid>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Container>
            </Container>
          </Grid>
          <BottomNav {...props} />
          <Footer />
        </main>
      </MainContent>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    get_savings_product_detail: (product_id) =>
      dispatch(savings.get_savings_product_detail(product_id)),
    create_group_savings: (payload) =>
      dispatch(savings.create_group_savings(payload)),
    get_account_balance: (payload) =>
      dispatch(transactions.get_account_balance(payload)),
  };
};

const mapStateToProps = (state) => {
  return {
    user: state.userDetails.data,
    details: state.getSavingsProductDetail.data,
    loading: state.createGroupSavings.isLoading,
    balance: state.getAccountBalance.data,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateGroupSavings);
