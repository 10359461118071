import React, { useState } from 'react';
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, Switch, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import savings from '../../../../services/savingsService';


function AuthorizeModal(props) {
    const data ={
        user_id : props.user.id,
        user_group_id: props.id
    };

    function submit() {
        props.authorize_withdrawal(data)

    }

    return (
        <div>
            <Dialog
                maxWidth='xs'
                open={props.open}
                onClose={props.close}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle >Withdrawal Authorization</DialogTitle>

                <DialogContent>
                    <p>I, <span style={{ color: '#0037ba', textTransform: 'capitalize', fontWeight: '800' }}> {props.user.first_name} {props.user.last_name} </span>
                    hereby authorize withdrawal from this group, <span style={{ color: '#0037ba', textTransform: 'capitalize', fontWeight: '800' }}>{props.name}</span> </p>

                    <p style={{ color: 'red', fontSize: '13px' }}>NB: Please note that clicking the authorize button below means you authorize withdrawal from this closed group and cannot be changed.</p>
                    <Button
                        fullWidth
                        variant="contained"
                        size="small"
                        color="primary"
                        className='getri-button submit'
                        onClick={submit}
                    >
                        {props.loading ?
                            <CircularProgress style={{ fontSize: '30px', color: '#fff' }} />
                            :
                            'Authorize'
                        }

                    </Button>
                </DialogContent>
            </Dialog>
        </div>

    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        authorize_withdrawal: (data) => dispatch(savings.authorize_withdrawal(data))
    };
};

const mapStateToProps = (state) => {
    return {
        user: state.userDetails.data,
        loading: state.response.isLoading,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizeModal)
