import { userConstants } from '../actions/types'

const initialState = {
    isGettingProduct: false,
    data: [],
}
 
function getBillerProduct(state = initialState, action) {
    switch (action.type) {
        case userConstants.GET_BILLER_PRODUCT_REQUEST:
            return {
                ...state,
                isGettingProduct: true,
            
            };
        case userConstants.GET_BILLER_PRODUCT_SUCCESS:
            return {
                ...state,
                isGettingProduct: false,
                data: action.payload, 
            };
            case userConstants.GET_BILLER_PRODUCT_FAILURE:
                return {
                    ...state,
                    success: false,
                    isGettingProduct: false,
                    data: [], 
                };
        default:
            return state
    }
}

export default getBillerProduct;